import { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import "./styles/webflow.css";
import "./styles/alt.webflow.css";
import "./styles/customize.css";

import "bootstrap/dist/js/bootstrap";

import "./assets/libs/bootstrap.css";
import "./assets/libs/bootstrap-datepicker.css";

import { checkCredentialCookies, getCredentialCookies } from "./utils";
import { withNetworkDetector } from "./hoc";
import { setUser } from "./features";
import { RenderRoutes } from "./routes";
import { getUserGeoLocationDetails } from "./utils/utils";
import { useGetClientNotificationQuery } from "./features/dashboard";

function App() {
  useGetClientNotificationQuery();
  const dispatch = useDispatch();

  useEffect(() => {
    if (checkCredentialCookies()) {
      dispatch(setUser(getCredentialCookies()));
    }
    getUserGeoLocationDetails();
  }, [dispatch]);

  return (
    <Router>
      <RenderRoutes />
      <ToastContainer
        theme="dark"
        position="bottom-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />
    </Router>
  );
}

export default withNetworkDetector(App);
