import { camelCase, startCase } from "lodash";

import uuid from "react-uuid";
import classnames from "classnames";

import {
  PressableCore,
  PressableCoreBase,
  PressableContext,
} from "../Pressable";

function Select(props) {
  const {
    value,
    options,
    defaultText,
    isStartCase,
    size,
    className,
    classNames,
    width,
    marginStyle,
    topSize,
    ...rest
  } = props;
  const classes = classnames(className, "sbox", "PressableButton", "Select", {
    [`Select--${size}--medium`]: size,
  });

  return (
    <PressableCore
      flex={{ display: "inline-flex" }}
      height="medium"
      width="auto"
      cursor="pointer"
      radius="all"
      className={classes}
      style={{
        width: width || 130,
        maxWidth: "100%",
        marginBottom: { marginStyle },
      }}
      data-custom="mainn"
    >
      <PressableCoreBase data-custom={`${classNames}`}>
        <PressableContext
          as="select"
          display="inline-flex"
          fontLineHeight={20}
          fontSize={14}
          fontWeight="500"
          height="medium"
          radius="all"
          width="auto"
          className="Select-element Padding-right--24 Padding-left--8 Padding-vertical--4 PressableContext--cursor--pointer colr1"
          defaultValue={value}
          {...rest}
        >
          <option value="">{defaultText}</option>
          {options?.map((option) => {
            if (typeof option !== "object") {
              return (
                <option key={uuid()} value={option} selected={option === value}>
                  {isStartCase ? startCase(camelCase(option)) : option}
                </option>
              );
            }
            return (
              <option
                key={uuid()}
                selected={option.value === value}
                value={option.value}
              >
                {isStartCase
                  ? startCase(camelCase(option.label))
                  : option.label}
              </option>
            );
          })}
        </PressableContext>
        <div className="Select-arrows colr1" style={{ top: topSize }}>
          <div
            aria-hidden="true"
            className="SVGInline SVGInline--cleaned SVG Icon Icon--arrowUpDown Icon-color Icon-color--gray800 Box-root Flex-flex"
          >
            <svg
              aria-hidden="true"
              className="SVGInline-svg SVGInline--cleaned-svg SVG-svg Icon-svg Icon--arrowUpDown-svg Icon-color-svg Icon-color--gray800-svg"
              height={12}
              width={12}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
            >
              <path d="M11.891 9.992a1 1 0 1 1 1.416 1.415l-4.3 4.3a1 1 0 0 1-1.414 0l-4.3-4.3A1 1 0 0 1 4.71 9.992l3.59 3.591 3.591-3.591zm0-3.984L8.3 2.417 4.709 6.008a1 1 0 0 1-1.416-1.415l4.3-4.3a1 1 0 0 1 1.414 0l4.3 4.3a1 1 0 1 1-1.416 1.415z" />
            </svg>
          </div>
        </div>
      </PressableCoreBase>
    </PressableCore>
  );
}

Select.defaultProps = {
  options: [],
  defaultText: "Select",
  startCase: false,
};

export default Select;
