import { useState, useCallback, useEffect, createContext } from "react";
import { useParams } from "react-router-dom";
import useInfiniteScroll from "@closeio/use-infinite-scroll";
import { useSelector, useDispatch } from "react-redux";

import { isAdmin } from "@atd/utils";
import { Main } from "@atd/layouts";

import { resetRequestSendLater } from "@atd/features";
import { withSelector } from "@atd/hoc";

import SendLaterList from "./SendLatersList";

import "./SendLaters.css";

const RequestContent = createContext({
  onResetData: () => {},
});

function SendLater({ user }) {
  const params = useParams();

  const [isResetData, setIsResetData] = useState(false);

  const { hasMore, isSearch } = useSelector((state) => state.requestSendLater);

  const dispatch = useDispatch();

  const onResetData = useCallback(() => {
    setIsResetData(true);
    dispatch(resetRequestSendLater());
  }, [dispatch]);

  const [page, loaderRef, scrollerRef] = useInfiniteScroll({
    hasMore,
    reset: isResetData,
  });

  const { query } = params || {};
  const { role } = user || {};

  useEffect(
    () => () => {
      if (query) {
        onResetData();
      }
    },
    [onResetData, query],
  );

  useEffect(
    () => () => {
      setIsResetData(true);
      dispatch(resetRequestSendLater());
    },
    [dispatch],
  );

  const headingTitle = isAdmin() ? "Send Later" : "";
  const headingText = isSearch ? "Search Results" : headingTitle;

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <RequestContent.Provider value={{ onResetData }}>
      <Main ref={scrollerRef} onResetData={onResetData}>
        <SendLaterList
          page={page}
          role={role}
          headerText={headingText}
          isSearch={isSearch}
          loaderRef={loaderRef}
          scrollerRef={scrollerRef}
          onResetData={onResetData}
          setIsResetData={setIsResetData}
        />
      </Main>
    </RequestContent.Provider>
  );
}

export default withSelector((state) => state.user)(SendLater);
