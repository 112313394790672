import { FileCardIcon, Tooltip } from "@atd/components";
import { useEffect, useState, useRef } from "react";

import "./RightPanel.css";

const RightPanel = ({ file, isLoading, onChangeFile, annotation }) => {
  const [files, setFiles] = useState([]);
  const itemRefs = useRef([]);
  useEffect(() => {
    if (file.length > 0) {
      setFiles(file);
    }
  }, [file]);

  useEffect(() => {
    if (annotation?.length > 0) {
      const storedAnnotations = annotation.flat().reduce((acc, ele) => {
        if (Array.isArray(ele.annotation)) {
          ele.annotation.forEach((anno) => {
            if (Array.isArray(anno.comments) && anno.comments.length > 0) {
              acc.push(...anno.comments);
            }
          });
        }
        return acc;
      }, []);
      storedAnnotations.sort((a, b) => {
        return new Date(a.modified_date) - new Date(b.modified_date);
      });
      const isEditEnabled = storedAnnotations.some((ele, idx) =>
        ele.list.some((item) => item.enableEdit),
      );
      const onChangeTab = storedAnnotations.some((ele) => ele.isClick);

      if (!isEditEnabled && onChangeTab) {
        const findIndex = storedAnnotations.findIndex((ele) => ele.isClick);
        const isValidate = storedAnnotations.some(
          (ele, _) =>
            findIndex !== _ && ele.list.some((item) => item.enableEdit),
        );
        if (!isValidate) {
          const filterData = storedAnnotations.find((ele) => ele.isClick);
          const updatedFiles = files.map((file, index) => ({
            ...file,
            isSelect: index === filterData.page_Id,
          }));
          console.log("updatedFiles", updatedFiles, storedAnnotations);
          setFiles(updatedFiles);
          // Scroll to the selected file
          if (filterData && itemRefs.current[filterData.page_Id]) {
            itemRefs.current[filterData.page_Id].scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
          }
        }
      }
    }
  }, [annotation]);

  console.log("files3456", files);
  return (
    <>
      {!isLoading && (
        <div className="column rightcol align-rightcol">
          <div
            className="nmgt1 rightwrap"
            style={{
              paddingBottom: "80px",
              overflowY: "scroll",
              textAlign: "inherit",
            }}
          >
            {files.length > 0 &&
              files.map((item, index) => (
                <div
                  key={index}
                  ref={(el) => (itemRefs.current[index] = el)}
                  className={`imgsdiv ${item.isSelect ? "actv" : ""}`}
                  onClick={() => onChangeFile(item)}
                >
                  {item.image_type ? (
                    <>
                      <div style={{ textAlign: "justify" }}>
                        <img src={item.url} alt={""} className="rightimg" />
                        <Tooltip content={item?.name} placement="top">
                          <div
                            style={{
                              fontSize: "13px",
                              color: "#666",
                              width: "100px",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {String(index + 1).padStart(2, "0")}
                            {"."}
                            {"  "} {item.name}
                          </div>
                        </Tooltip>
                      </div>
                    </>
                  ) : (
                    <>
                      <div style={{ width: "100px", height: "100px" }}>
                        <FileCardIcon icon={item.format} />
                      </div>

                      <div style={{ textAlign: "justify" }}>
                        <Tooltip content={item?.name} placement="top">
                          <div
                            style={{
                              fontSize: "13px",
                              color: "#666",
                              width: "100px",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {String(index + 1).padStart(2, "0")}
                            {"."}
                            {"  "} {item.name}
                          </div>
                        </Tooltip>
                      </div>
                    </>
                  )}
                </div>
              ))}
          </div>
        </div>
      )}
    </>
  );
};

export default RightPanel;
