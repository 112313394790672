import moment from "moment";
import styled from "styled-components";

import {
  Box,
  Tooltip,
  ScheduledDatePicker,
  ScheduledDateTimePicker,
} from "@atd/components";
import { useDispatch } from "react-redux";

import { useParams } from "react-router-dom";

import {
  ifNoReplyUpDateStatusAsync,
  getRequestThreads,
  sendLaterUpDateStatusAsync,
} from "@atd/features";

import Timer from "../Timer";
import ActivityText from "./ActivityText";
import { Icon } from "@iconify/react";
import { useCallback } from "react";

import {
  AssignUserText,
  ActivityContent,
  StatusLabelMain,
  SplitPipelineLabel,
} from "./Activity.style";

const AcitivityDate = styled.div``;

function Status(props) {
  const {
    request,
    created,
    user_name,
    activity,
    old_status_name,
    new_status_name,
    activity_type,
    from,
    to,
  } = props;
  const dispatch = useDispatch();
  const params = useParams();
  const { request_name } = request || {};
  const changeScheduleDate = async (value, item) => {
    let payload = {
      request_id: item?.request_id,
      reply_id: item?.reply_id,
      activity_id: item.id,
      is_done: 0,
      is_snooze: 1,
      is_snooze_data: {
        date_string: value,
        time_in_seconds: 0,
      },
    };
    try {
      const response = await dispatch(
        ifNoReplyUpDateStatusAsync(payload),
      ).unwrap();

      if (response.status === "success") {
        await dispatch(
          getRequestThreads({
            id: params.id,
            params: { tab: "details" },
            isUpdate: true,
          }),
        );
      }
    } catch (err) {}
  };
  const clearUpdate = async (item) => {
    let payload = {
      request_id: item?.request_id,
      reply_id: item?.reply_id,
      activity_id: item.id,
      is_done: 1,
      is_snooze: 0,
      is_snooze_data: {
        date_string: "",
        time_in_seconds: "",
      },
    };
    try {
      const response = await dispatch(
        ifNoReplyUpDateStatusAsync(payload),
      ).unwrap();

      if (response.status === "success") {
        await dispatch(
          getRequestThreads({
            id: params.id,
            params: { tab: "details" },
            isUpdate: true,
          }),
        );
      }
    } catch (err) {}
  };
  const clearUpdateSendLater = async (item) => {
    let payload = {
      request_id: item?.request_id,
      reply_id: item?.reply_id,
      activity_id: item.id,
      is_done: 1,
      is_snooze: 0,
      is_snooze_data: {
        date_string: "",
        time_in_seconds: "",
      },
    };
    try {
      const response = await dispatch(
        sendLaterUpDateStatusAsync(payload),
      ).unwrap();

      if (response.status === "success") {
        await dispatch(
          getRequestThreads({
            id: params.id,
            params: { tab: "details" },
            isUpdate: true,
          }),
        );
      }
    } catch (err) {}
  };
  const scheduleDateTimerPicker = async (date, time, item) => {
    let payload = {
      request_id: item?.request_id,
      reply_id: item?.reply_id,
      activity_id: item.id,
      is_done: 0,
      is_snooze: 1,
      is_snooze_data: {
        date_string: date,
        time_in_seconds: time,
      },
    };
    try {
      const response = await dispatch(
        sendLaterUpDateStatusAsync(payload),
      ).unwrap();

      if (response.status === "success") {
        await dispatch(
          getRequestThreads({
            id: params.id,
            params: { tab: "details" },
            isUpdate: true,
          }),
        );
      }
    } catch (err) {}
  };

  const onChangeDatePicker = useCallback((value, item) => {
    console.log("onChangeDatePicker", value, item);
    if (value === "clear") {
      clearUpdate(item);
    } else {
      changeScheduleDate(value, item);
    }
  }, []);

  const onChangeDateTimePicker = useCallback((date, time, item) => {
    console.log("onChangeDateTimePicker", date, time, item);
    if (date === "clear") {
      clearUpdateSendLater(item);
    } else {
      scheduleDateTimerPicker(date, time, item);
    }
  }, []);

  const renderRequestName = (nameString) => {
    if (nameString?.length > 24) {
      return (
        <Tooltip content={nameString}>
          <strong>
            {nameString?.substr(0, 24)}
            ...
          </strong>
        </Tooltip>
      );
    }

    return <strong>{nameString}</strong>;
  };

  const isArchive = activity_type === "archive" ? "Archive" : "Unarchive";
  const isArchiveUnarchive = ["archive", "unarchive"].includes(activity_type)
    ? isArchive
    : renderRequestName(request_name);
  const fromText =
    activity_type === "status_change"
      ? old_status_name
      : activity_type === "request_opened"
      ? "opened"
      : activity_type === "delivery_scheduled"
      ? "delivery"
      : activity_type === "reply_scheduled"
      ? "reply scheduled "
      : activity_type === "reply_ifnoreply"
      ? "if no reply "
      : isArchiveUnarchive;

  const isAssignUser = ["assign_user", "reassign_user"].includes(activity_type)
    ? to
    : ["archive", "unarchive"].includes(activity_type);
  const isStatusChange =
    activity_type === "status_change" ? new_status_name : isAssignUser;
  const isStatus = isStatusChange ?? renderRequestName(request_name);

  const toText =
    activity_type === "in_progress"
      ? "In Progress"
      : activity_type === "request_opened"
      ? request?.request_name
      : activity_type === "delivery_scheduled"
      ? activity.sheduled_datetime
      : activity_type === "reply_ifnoreply"
      ? activity.sheduled_datetime
      : activity_type === "reply_scheduled"
      ? activity.sheduled_datetime
      : isStatus;

  if (activity_type === "timer_status") {
    return <Timer activity={activity} />;
  }

  return (
    <Box
      flex
      margin={{ bottom: 20 }}
      className={
        activity_type === "delivery_scheduled"
          ? "red-box"
          : activity_type === "reply_scheduled" && !activity?.is_done
          ? "sky-blue-box"
          : activity_type === "reply_ifnoreply" && !activity?.is_done
          ? "light-green-blue-box"
          : activity_type === "reply_ifnoreply" && activity?.is_done
          ? "yellow-box"
          : activity_type === "reply_scheduled" && activity?.is_done
          ? "yellow-box"
          : activity_type === "annotation"
          ? "annotation-yellow-box"
          : ""
      }
    >
      <ActivityContent
        flex={{ alignItems: "center", justifyContent: "space-between" }}
        style={{ flex: 1 }}
      >
        <AssignUserText>
          {["archive", "unarchive"].includes(activity_type) && (
            <ActivityText label={user_name} />
          )}
          {activity_type === "assign_user" && (
            <ActivityText label={from} text="Assigned" />
          )}
          {activity_type === "reassign_user" && (
            <ActivityText label={user_name} text="has reassigned the" />
          )}
          {activity_type === "annotation" && (
            <>
              <Tooltip
                content={user_name + " " + activity?.description}
                placement="top"
              >
                <div class="annotation-log">
                  <ActivityText
                    label={user_name}
                    text={activity?.description}
                  />
                </div>
              </Tooltip>
            </>
          )}
          {activity_type === "status_change" && (
            <ActivityText
              style={{ marginRight: 5 }}
              label={user_name}
              text="status changed from"
            />
          )}
          {activity_type === "in_progress" && (
            <ActivityText
              style={{ marginRight: 5 }}
              label={user_name}
              text="changed status of"
            />
          )}
          {activity_type === "request_opened" && (
            <ActivityText
              style={{ marginRight: 5 }}
              label={user_name}
              text="has"
            />
          )}
          {activity_type === "delivery_scheduled" && (
            <ActivityText
              style={{ marginRight: 5 }}
              label={user_name}
              text="has scheduled a"
            />
          )}
          {activity_type === "reply_scheduled" && (
            <ActivityText
              style={{ marginRight: 5 }}
              label={user_name}
              text="has scheduled a"
            />
          )}
          {activity_type === "reply_ifnoreply" && (
            <ActivityText
              style={{ marginRight: 5 }}
              label={user_name}
              text="has scheduled a"
            />
          )}
          {activity_type !== "annotation" && (
            <StatusLabelMain>
              <SplitPipelineLabel>{fromText}</SplitPipelineLabel>
            </StatusLabelMain>
          )}{" "}
          {activity_type !== "delivery_scheduled" &&
            activity_type !== "reply_scheduled" &&
            activity_type !== "reply_ifnoreply" &&
            activity_type !== "annotation" && <span>→ </span>}
          {""}
          {activity_type !== "delivery_scheduled" &&
            activity_type !== "reply_scheduled" &&
            activity_type !== "reply_ifnoreply" &&
            activity_type !== "annotation" && (
              <StatusLabelMain>
                <SplitPipelineLabel>{toText}</SplitPipelineLabel>
              </StatusLabelMain>
            )}{" "}
          {activity_type === "delivery_scheduled" && (
            <div>
              <Icon
                style={{ width: "17px", height: "13px", marginTop: "15px" }}
                icon="ant-design:clock-circle-outlined"
              />
              <span>
                Scheduled at{" "}
                <StatusLabelMain>
                  <SplitPipelineLabel>
                    {moment(toText).format("MMM DD, YYYY hh:mm A")}
                  </SplitPipelineLabel>
                </StatusLabelMain>
              </span>
            </div>
          )}{" "}
          {activity_type === "reply_scheduled" && (
            <div>
              <Icon
                style={{ width: "17px", height: "13px", marginTop: "15px" }}
                icon="ant-design:clock-circle-outlined"
              />
              <span>
                Scheduled {activity?.is_done && <span>Cancel</span>} at{" "}
                <StatusLabelMain>
                  <SplitPipelineLabel>
                    {moment(toText).format("MMM DD, YYYY hh:mm A")}
                  </SplitPipelineLabel>
                </StatusLabelMain>
              </span>
            </div>
          )}
          {activity_type === "reply_ifnoreply" && (
            <div>
              <Icon
                style={{ width: "17px", height: "13px", marginTop: "15px" }}
                icon="ant-design:clock-circle-outlined"
              />
              <span>
                Scheduled {activity?.is_done && <span>Cancel</span>} at{" "}
                <StatusLabelMain>
                  <SplitPipelineLabel>
                    {moment(toText).format("MMM DD, YYYY hh:mm A")}
                  </SplitPipelineLabel>
                </StatusLabelMain>
              </span>
            </div>
          )}{" "}
        </AssignUserText>

        <AcitivityDate>
          {activity_type === "reply_ifnoreply" && !activity?.is_done && (
            <span>
              <ScheduledDatePicker
                item={activity}
                onChange={onChangeDatePicker}
              />
            </span>
          )}
          {activity_type === "reply_scheduled" && !activity?.is_done && (
            <span>
              <ScheduledDateTimePicker
                item={activity}
                onChangeDateTimePicker={onChangeDateTimePicker}
              />
            </span>
          )}
          {"      "}
          {moment(created).format("MMM DD, YYYY hh:mm A")}
        </AcitivityDate>
      </ActivityContent>
    </Box>
  );
}

export default Status;
