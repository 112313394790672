/* eslint-disable no-nested-ternary */

import { useNavigate } from "react-router-dom";

import moment from "moment";
import styled from "styled-components";
import uuid from "react-uuid";
import { useState, useEffect } from "react";
import RequestService from "@atd/features/request/requestApi";

import {
  EmptyState,
  Tooltip,
  ScheduleDateTimePicker,
  MoreButton,
  Dropdown,
  Badge,
  AssignUser,
} from "@atd/components";
import { useWindowSize } from "@atd/hooks";

import { withSelector } from "@atd/hoc";

const Table = styled.table`
  min-width: 1095px;
`;

const actionMenus = [
  {
    id: "cancel",
    value: "Cancel",
  },
];
const Company = styled.div`
  display: flex;
  align-items: center;

  & .status {
    width: 6px;
    height: 6px;
    border-radius: 100px;
    margin-right: 10px;
    background-color: ${({ backgroundColor }) => backgroundColor};
  }
`;

function TabContentHeaderIfNoReply() {
  return (
    <thead className="stik">
      <tr>
        <th scope="col" className="border-0 tb-title pl5">
          REQUEST
        </th>
        <th scope="col" className="border-0 tb-title pl5">
          COMPANY
        </th>
        <th scope="col" className="border-0 tb-title pl5">
          CATEGORY
        </th>
        <th scope="col" className="border-0 tb-title pl5">
          ASSIGNED TO
        </th>
        <th scope="col" className="border-0 tb-title pl5">
          STATUS
        </th>
        {/* <th scope="col" className="border-0 tb-title pl5">
          TYPE
        </th> */}
        <th scope="col" className="border-0 tb-title pl5">
          SCHEDULE DATE
        </th>

        <th scope="col" className="border-0 tb-title pl5"></th>
      </tr>
    </thead>
  );
}

function TabContentSendLater({
  item,
  role,
  users,
  status,
  hideAssignTo,
  showPriority = true,
  isGroupBy,
  showRating,
  handleClicks,
  scheduleDate,
}) {
  const navigate = useNavigate();
  const [assignTo, setAssignTo] = useState([]);
  const { request_id, assigned_to } = item || {};
  const size = useWindowSize();

  const excerptName = (nameString, length) => {
    if (nameString?.length > length) {
      return (
        <Tooltip content={nameString}>
          <span>
            {nameString?.substr(0, length)}
            ...
          </span>
        </Tooltip>
      );
    }

    return <span>{nameString}</span>;
  };

  const planNonProColor = ["Premium", "Ultimate"].includes(
    item.company_data?.plan_name,
  )
    ? "#D82E57"
    : "#30B284";
  const planColor =
    item.company_data?.plan_name === "Pro" ? "#5469d4" : planNonProColor;
  const onChangeDateTimePicker = (date, time) => {
    scheduleDate(date, time, item);
  };
  const actionMenuHandler = async (actionId) => {
    switch (actionId) {
      case "cancel":
        handleClicks(item);
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    setAssignTo(assigned_to);
  }, [assigned_to]);
  return (
    <tr>
      <td
        aria-hidden="true"
        onClick={() => navigate(`/requests/thread/${item.request_id}`)}
        style={{ borderLeft: isGroupBy ? "none" : null }}
      >
        <span className="ticid">{item.request_number}</span>{" "}
        {excerptName(item.request_name, size.width <= 1366 ? 25 : 32)}
      </td>
      <td
        aria-hidden="true"
        onClick={() => navigate(`/requests/thread/${item.request_id}`)}
      >
        <Company
          backgroundColor={planColor}
          style={{
            fontWeight:
              item.company_data?.plan_name === "Ultimate" ? "bold" : null,
          }}
        >
          <Tooltip content={item.company_data?.plan_name}>
            <div className="status" />
          </Tooltip>

          {excerptName(
            item.company_data.company_name,
            size.width <= 1366 ? 20 : 28,
          )}
        </Company>
      </td>
      <td
        aria-hidden="true"
        onClick={() => navigate(`/requests/thread/${item.request_id}`)}
      >
        {" "}
        {item.category_item_id}
      </td>
      {!hideAssignTo && (
        <td className="assign">
          <AssignUser
            multiple
            users={users}
            value={assignTo || []}
            isDisabled={item.in_progress || [6, 7].includes(role)}
            onChange={(value) => {
              setAssignTo(value);
              RequestService.assignUser(request_id, {
                assigned_to: value,
                assigned_to_string: value.toString(),
              });
            }}
          />
        </td>
      )}

      <td
        aria-hidden="true"
        onClick={() => navigate(`/requests/thread/${item.request_id}`)}
      >
        <Badge
          type={
            item.is_scheduled_data?.is_sent === 0
              ? "success"
              : item.is_scheduled_data?.is_sent === 1
              ? "default"
              : ""
          }
        >
          {item.is_scheduled_data?.is_sent === 0
            ? "Scheduled"
            : item.is_scheduled_data?.is_sent === 1
            ? "Sent"
            : ""}
        </Badge>
      </td>
      <td
        aria-hidden="true"
        title={moment(item?.is_scheduled_data?.date_string).format(
          "DD MMM ,YYYY",
        )}
      >
        <ScheduleDateTimePicker
          item={item}
          onChangeDateTimePicker={onChangeDateTimePicker}
        />
      </td>
      <td className="dropdown" style={{ padding: " 8px 10px 8px 60px" }}>
        {
          <Dropdown as={MoreButton} onChange={actionMenuHandler}>
            <Dropdown.Options>
              {actionMenus.map((action) => (
                <Dropdown.Option key={action.id} value={action.id}>
                  {action.value}
                </Dropdown.Option>
              ))}
            </Dropdown.Options>
          </Dropdown>
        }
      </td>
    </tr>
  );
}

function AdminTabContent({
  data,
  user,
  users,
  error,
  isGroupBy,
  fetching,
  hasError,
  activeTabStatus,
  activeTab,
  handleClick,
  handleClicks,
  scheduleDate,
  ...rest
}) {
  if (hasError && error.message !== "Aborted") {
    return (
      <EmptyState
        icon="client"
        title="Something went wrong!"
        description={<span>{error.message}</span>}
      />
    );
  }

  if (!fetching && data?.length === 0) {
    return (
      <EmptyState
        icon="requests"
        title="No results found"
        description={
          <span>There aren&rsquo;t any results for that query.</span>
        }
      />
    );
  }

  return (
    <>
      {activeTab === "sendlater" && (
        <Table className="table table-bordered mb-0 sm-ht fulltable">
          <TabContentHeaderIfNoReply />
          {isGroupBy && (
            <>
              {data.map((item) => (
                <>
                  {"row_type" in item && item.row_type === "header" && (
                    <thead>
                      <tr className="ccss9">
                        <th
                          scope="col"
                          colSpan="5"
                          className="border-0 tb-title pl5 blu-txt"
                        >
                          {item.header_name}
                        </th>
                      </tr>
                    </thead>
                  )}
                  <tbody>
                    {"row_type" in item && item.row_type === "row" && (
                      <TabContentSendLater
                        key={uuid()}
                        isGroupBy={isGroupBy}
                        role={user?.role}
                        status={activeTabStatus}
                        users={users}
                        item={item}
                        {...rest}
                        handleClicks={handleClicks}
                        scheduleDate={scheduleDate}
                      />
                    )}
                  </tbody>
                </>
              ))}
            </>
          )}
          {!isGroupBy && (
            <tbody>
              {data.map((item) => (
                <TabContentSendLater
                  key={uuid()}
                  role={user?.role}
                  status={activeTabStatus}
                  users={users}
                  item={item}
                  {...rest}
                  handleClicks={handleClicks}
                  scheduleDate={scheduleDate}
                />
              ))}
            </tbody>
          )}
        </Table>
      )}
    </>
  );
}

export default withSelector((state) => state.user)(AdminTabContent);
