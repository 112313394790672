import axios from "axios";
import RequestIfNoReplyApi from "./requestIfNoReplyApi";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  error: null,
  request: null,
  creating: false,
  success: false,
  loading: true,
  fetching: false,
  hasErrors: false,
  errorMsg: "",
  requests: [],
  request_types: [],
  hasMore: false,
  isRequestLoading: true,
  search: null,
  counts: {},
  isSearch: false,
  processing: false,
};

export const getAllRequestIfNoReplyAsync = createAsyncThunk(
  "requestIfNoReply/getAllRequestAsync",
  async (payload, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    const response = await RequestIfNoReplyApi.getAllRequestsIfNoReply(
      payload,
      source.token,
    );
    return response;
  },
);

export const ifNoReplyUpDateStatusAsync = createAsyncThunk(
  "requestIfNoReply/ifNoReplyUpDateStatusAsync",
  async (payload, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    const response = await RequestIfNoReplyApi.IfNoReplyUpDateStatus(
      payload,
      source.token,
    );
    console.log("response", response);
    return response;
  },
);

export const requestIfNoReplySlice = createSlice({
  name: "requestIfNoReply",
  initialState,
  reducers: {
    addErrors: (state, action) => {
      state.error = action.payload;
    },
    resetRequests: (state) => {
      state.requests = [];
      state.loading = true;
      state.hasMore = false;
    },
    resetRequestDatas: (state) => {
      state.requests = [];
    },
    updateCurrentRequests: (state, action) => {
      state.request = { ...state.request, ...action.payload };
    },
    setSearchQuery: (state, action) => {
      state.search = action.payload;
    },
  },
  extraReducers: {
    [getAllRequestIfNoReplyAsync.pending]: (state) => {
      state.fetching = true;
      state.isSearch = false;
    },
    [getAllRequestIfNoReplyAsync.fulfilled]: (state, action) => {
      state.isSearch = false;
      state.fetching = false;
      state.loading = false;
      state.hasErrors = false;
      state.requests = [
        ...state.requests,
        ...(action.payload.results?.data || {}),
      ];
      state.hasMore = action.payload.results?.has_more;
    },
    [getAllRequestIfNoReplyAsync.rejected]: (state, action) => {
      // state.fetching = false;
      state.isSearch = false;
      state.loading = false;
      state.hasErrors = true;
      state.error = action.error;
      state.hasMore = false;
    },
    [ifNoReplyUpDateStatusAsync.pending]: (state) => {
      state.errorMsg = "";
    },
    [ifNoReplyUpDateStatusAsync.fulfilled]: (state, action) => {
      if (action.meta.arg.is_done === 0) {
        state.requests.map((curr) => {
          if (
            action.meta.arg.request_id === curr.request_id &&
            action.meta.arg.reply_id === curr.id
          ) {
            return (curr.if_no_reply_data.date_string =
              action.meta.arg.is_snooze_data.date_string);
          }
        });
      } else if (action.meta.arg.is_done === 1) {
        const findIndex = state.requests.findIndex(
          (curr) =>
            action.meta.arg.request_id === curr.request_id &&
            action.meta.arg.reply_id === curr.id,
        );
        state.requests = state.requests.filter(
          (list, index) => index !== findIndex,
        );
      }
    },
    [ifNoReplyUpDateStatusAsync.rejected]: (state, action) => {
      state.errorMsg = action.error.message;
    },
  },
});

export const {
  resetRequests,
  resetRequestDatas,
  updateCurrentRequests,
  setSearchQuerys,
} = requestIfNoReplySlice.actions;

export default requestIfNoReplySlice.reducer;
