import { axios, getCurrentTimeZone } from "@atd/utils";

class DeliveryService {
  async add(id, payload, allAttachments) {
    console.log("delivery", payload);
    try {
      let ele = {
        body: payload.body,
        attachments: allAttachments?.length > 0 ? allAttachments : [],
        if_no_reply: payload?.ifNoReply === true ? 1 : 0,
        if_no_reply_data: {
          is_replied: 0,
          is_remined: 0,
          date_string: payload?.ifNoReplyDate ? payload?.ifNoReplyDate : "",
          target_date_time_in_utc: "0",
          time_in_seconds: 0,
          action:
            payload?.replyType === "0"
              ? "reminder"
              : payload?.replyType === "1"
              ? "sequence"
              : "",
          seq_data: {
            is_sent: 0,
            attachments: [],
            body: "",
            followups: [],
          },
        },
        is_scheduled: 0,
        is_scheduled_data: {
          date_string: "",
          target_date_time_in_utc: "",
          time_in_seconds: "",
          is_sent: 0,
        },
      };
      if (ele?.if_no_reply_data?.action === "sequence") {
        ele.if_no_reply_data.seq_data = {
          id: payload.ifNoReplySeqs?.id ? payload.ifNoReplySeqs?.id : "",
          attachments: payload.ifNoReplySeqs.attachments
            ? payload.ifNoReplySeqs.attachments
            : [],
          is_sent: 0,
          body: payload.ifNoReplySeqs.body ? payload.ifNoReplySeqs.body : "",
          followups: [],
        };
      }
      if (
        payload?.onScheduleDate !== undefined &&
        payload?.onScheduleDate !== "" &&
        payload?.onScheduleTime !== undefined &&
        payload?.onScheduleTime !== null
      ) {
        ele.is_scheduled = 1;
        ele.is_scheduled_data = {
          date_string: payload.onScheduleDate,
          target_date_time_in_utc: "",
          time_in_seconds: payload.onScheduleTime,
          is_sent: 0,
        };
      } else {
        ele.is_scheduled = 0;
        ele.is_scheduled_data = {
          date_string: "",
          target_date_time_in_utc: "",
          time_in_seconds: "",
          is_sent: 0,
        };
      }

      const { data } = await axios.post(`/deliver/${id}`, ele, {
        params: {
          time_zone: getCurrentTimeZone(),
        },
      });
      return data;
    } catch (err) {
      throw new Error(err);
    }
  }
}

export default new DeliveryService();
