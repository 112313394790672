import ReactModal from "react-modal";
import { NewVersion, AlertInfo } from "@atd/components";
import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

function AddNewVersion({
  version,
  onClosePopup,
  finishUpload,
  uploadFile,
  removeAttachFile,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [files, setFiles] = useState([]);
  const [notifyTimeout, setNotifyTimeout] = useState(false);

  const StyledDiv = styled.div`
    position: relative;
    display: block;
    vertical-align: middle;
    margin: 0 auto;
    text-align: left;
    z-index: 1045;
  `;

  useEffect(() => {
    if (version === true) {
      setIsOpen(true);
      setFiles([]);
    }
  }, [version]);

  const onClose = useCallback(() => {
    setIsLoading(false);
    setIsOpen(false);
    setIsSubmit(false);
    onClosePopup();
  }, [onClosePopup]);

  const uploadFiles = async (event) => {
    const file = event.target.files[0];

    if (file) {
      setIsLoading(() => !isLoading);
      const fileName = file.name;

      // Check if a file with the same name already exists in the files array
      const isDuplicate = files?.some(
        (existingFile) => existingFile.file_name === fileName,
      );
      if (isDuplicate) {
        setIsLoading(false);
        alert("file already exists.");
        return;
      }

      const response = await uploadFile(event);
      if (response?.status === "success") {
        setIsOpen(true);
        // setNotifyTimeout(true);
        const fileType = file.name.split(".")[1];

        const type = [
          "jpg",
          "jpeg",
          "png",
          "gif",
          "bmp",
          "cur",
          "ico",
          "svg",
          "jfif",
          "jpe",
          "jps",

          "exr",
          "fts",
          "hdr",
          "mng",
          "dds",
          "pcx",
          "pgm",
          "pnm",
          "ppm",
          "ras",
          "sgi",
          "tga",
          "tiff",
          "xbm",
          "xpm",
          "xwd",
          "pbm",
          "pcd",
          "picon",
          "wbmp",
          "pnm",
          "cr2",
          "dng",
          "erf",
          "heic",
          "heif",
          "jp2",
          "nef",
          "nrw",
          "orf",
          "pef",
          "pes",
          "raf",
          "rw2",
          "pfm",
          "pict",
          "psd",
        ].includes(fileType);
        const types = [
          "jpg",
          "jpeg",
          "png",
          "gif",
          "bmp",
          "cur",
          "ico",
          "svg",
          "jfif",
          "jpe",
          "jps",
        ].includes(fileType);

        // Create a URL for the file
        const fileUrl = URL.createObjectURL(file);
        const imageData = {
          image_url: fileUrl,
          file_type: file.type,
          file_name: fileName,
          image_type: type,
          support_image: types ? true : false,
        };

        const image_src = [...files, imageData];
        setFiles(image_src);
        setIsLoading(false);
        // setTimeout(() => {
        //   setNotifyTimeout(false);
        // }, 1000);
      } else {
        setIsLoading(false);
        setNotifyTimeout(false);
      }
    }
  };
  const remove = useCallback(
    (index) => {
      setFiles((prevFiles) => {
        if (prevFiles[index]) {
          return prevFiles.filter((_, i) => i !== index);
        }
        return prevFiles;
      });
      removeAttachFile(index);
    },
    [setFiles],
  );

  const customStyles = {
    overlay: {},
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "20px",
      zIndex: 1045,
    },
  };

  const submit = useCallback(async () => {
    setIsSubmit(true);
    const response = await finishUpload();
    if (response?.status === "success") {
      setIsLoading(false);
      setIsOpen(false);
      setIsSubmit(false);
    } else {
      setIsLoading(false);
      setIsSubmit(false);
    }
  }, [finishUpload]);
  return (
    <>
      <ReactModal
        role="dialog"
        isOpen={isOpen}
        closeTimeoutMS={300}
        overlayClassName="atd-modal-overlay"
        style={customStyles}
      >
        <StyledDiv>
          <NewVersion
            files={files}
            uploadFiles={uploadFiles}
            remove={remove}
            onClose={onClose}
            submit={submit}
            isLoading={isLoading}
            isSubmit={isSubmit}
            notifyTimeout={notifyTimeout}
          />
        </StyledDiv>
      </ReactModal>
      {notifyTimeout && (
        <div className="notification">
          <AlertInfo isLoading={null} message={"Upload Successfully"} />
        </div>
      )}
    </>
  );
}
export default AddNewVersion;
