import "./NewVersion.css";
import newVersion from "assets/images/version.jpg";
import { useEffect, useRef } from "react";

import { Spinner, AddNewFiles } from "@atd/components";

const NewVersion = ({
  files,
  uploadFiles,
  remove,
  submit,
  isSubmit,
  onClose,
  isLoading,
}) => {
  useEffect(() => {}, [isLoading]);
  const handleFileChange = (e) => {
    uploadFiles(e);
    fileInputRef.current.value = "";
  };

  const fileInputRef = useRef(null);

  console.log("browse file", isLoading, isSubmit);
  return (
    <>
      <div
        id="adduser"
        className="zoom-anim-dialog mfp-hide dialog"
        style={{ width: "500px", overflow: "hidden" }}
      >
        <div className="pop-title-new-version Box-divider--light-bottom-1">
          <span> {files?.length === 0 ? "New version" : "Upload Images"}</span>
          {!isLoading && !isSubmit && (
            <button
              type="button"
              className="new-version-close"
              onClick={onClose}
            >
              x
            </button>
          )}
        </div>
        {files?.length > 0 && (
          <AddNewFiles
            files={files}
            uploadFiles={uploadFiles}
            remove={remove}
            onClose={onClose}
            submit={submit}
            isLoading={isLoading}
            isSubmit={isSubmit}
          />
        )}
        {files?.length === 0 && (
          <div>
            <div className="pd16">
              <div>
                Ready to start your next round of feedback with a new version?
                Don't worry, older versions will still be accessible through the
                versions menu.
              </div>

              <img
                src={newVersion}
                style={{ width: "100%", marginTop: "10px" }}
                alt=""
              />

              <div className="mgt2 notesbox center">
                <div className="notesdetail">
                  Supports: JPG, JPEG, PNG, SVG, GIF , PDF, ZIP
                </div>
              </div>

              <div className="browbtn">
                <div className="Box-root Flex-flex mgt2 fltrgt">
                  <button
                    type="button"
                    className="Box-root Box-hideIfEmpty pointer1"
                    disabled={isLoading}
                  >
                    <a className="bl-but-new-version" href="#">
                      <div style={{ display: "flex" }}>
                        <input
                          disabled={isLoading}
                          type="file"
                          title=""
                          name="fileUploadIconInput-newCommentComposers"
                          id="fileUploadIconInput-newCommentComposers"
                          // accept="image/png,image/jpeg,image/gif"
                          multiple=""
                          className="file_upload_icon_input--fileInputs--qwRav"
                          onChange={(e) => handleFileChange(e)}
                          ref={fileInputRef}
                        />
                        {isLoading && <Spinner />} &nbsp; Browse
                      </div>
                    </a>
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default NewVersion;
