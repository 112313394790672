import React, { useState, forwardRef, useEffect } from "react";
import { ImagesPreview } from "./ImagesPreview/ImagesPreview";
import { FileCard, ThumbnailContainer } from "@atd/components";
import { useNavigate } from "react-router-dom";
import { getCredentialCookies } from "@atd/utils";
import { pdfjs } from "react-pdf";
import AWSService from "../../features/AWS/AWSConfig";
import axios from "axios";
// Set PDF.js worker source
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const FilePreview = forwardRef(
  (
    {
      list,
      file,
      name,
      fullName,
      role,
      type,
      onRemove,
      version,
      format,
      isDeletable,
      activity,
      files,
      activityType,
      replyId,
      requestId,
      attachmentId,
      annotation_id,
      notification,
      activity_type,
      isDraggable, // Add isDraggable prop
      ...rest
    },
    ref,
  ) => {
    const [imageType, setImageType] = useState(null);
    const [pdfType, setPdfType] = useState(null);
    const [notifications, setNotifications] = useState(0);
    const [pdfPageCount, setPdfPageCount] = useState("");
    const [pdfPageLoading, setPdfPageLoading] = useState(false);
    const navigate = useNavigate();

    const permissionStart =
      !notification?.annotation_id && getCredentialCookies()?.role === "5"
        ? true
        : notification?.annotation_id
        ? true
        : false;
    useEffect(() => {
      const checkFileType = async () => {
        try {
          const imageTypes = [
            "jpg",
            "jpeg",
            "png",
            "gif",
            "bmp",
            "cur",
            "ico",
            "svg",
            "jfif",
            "jpe",
            "jps",
          ];
          const fileTypes = ["pdf"];

          const isImage = imageTypes.includes(type);
          const isFile = fileTypes.includes(type);

          if (isImage) {
            setImageType("image");
          } else if (isFile) {
            setPdfType("pdf");
            if (activityType === "delivery" && permissionStart) {
              const urlObject = new URL(file);
              const filePath = urlObject.pathname.substring(1);

              const getObjectParams = {
                Bucket: AWSService.getAWSBucketName(),
                Key: filePath,
              };

              try {
                const signedUrl = await new Promise((resolve, reject) => {
                  AWSService.getAWSAccessKey().getSignedUrl(
                    "getObject",
                    getObjectParams,
                    (err, url) => {
                      if (err) {
                        console.error("Error generating pre-signed URL:", err);
                        reject(err);
                      } else {
                        resolve(url);
                      }
                    },
                  );
                });

                const response = await axios.get(signedUrl, {
                  responseType: "blob",
                });
                const blob = response.data;
                const arrayBuffer = await blob.arrayBuffer();

                const pdf = await pdfjs.getDocument({ data: arrayBuffer })
                  .promise;
                const canvas = document.createElement("canvas");
                canvas.className = "canv";

                for (let i = 1; i <= pdf.numPages; i++) {
                  const page = await pdf.getPage(i);
                  const scale = 2.0;
                  const viewport = page.getViewport({ scale });

                  canvas.height = viewport.height;
                  canvas.width = viewport.width;

                  const renderContext = {
                    canvasContext: canvas.getContext("2d"),
                    viewport,
                  };

                  await page.render(renderContext).promise;
                }
                if (pdf.numPages > 0) {
                  setPdfPageCount(pdf.numPages);
                  setPdfPageLoading(true);
                } else {
                  setPdfPageCount(0);
                  setPdfPageLoading(false);
                }

                console.log("PDF page count:", pdf.numPages);
              } catch (error) {
                console.error("Error handling PDF file:", error);
              }
            }
          } else {
            setImageType("unsupported");
            setPdfType("unsupported");
          }

          console.log("File type:", {
            pdfType,
            imageType,
            isFile,
            isImage,
            file,
          });
        } catch (error) {
          console.error("Error fetching or checking file type:", error);
        }
      };

      checkFileType();
    }, [type]);

    const fileDownload = () => {
      const isFile = [
        "jpg",
        "jpeg",
        "png",
        "gif",
        "bmp",
        "cur",
        "ico",
        "svg",
        "jfif",
        "jpe",
        "jps",
        "pdf",
      ].includes(type);
      console.log("OnClick345", isFile, activityType);
      if (isFile) {
        if (activityType === "delivery" && permissionStart) {
          navigate(
            `/requests/annotation?request_id=${requestId}&reply_id=${replyId}&attachment_id=${attachmentId}&annotation_id=${annotation_id}&page_Id=0`,
          );
        } else {
          window.open(file);
        }
      } else {
        window.open(file);
      }
    };

    useEffect(() => {
      if (notification) {
        console.log("notification", notification);
        const userDetails = getCredentialCookies();
        setNotifications(() =>
          userDetails?.role === "1"
            ? notification?.admin_notification
            : userDetails?.role === "7"
            ? notification?.designer_notification
            : userDetails?.role === "5"
            ? notification?.customer_notification
            : notification?.admin_notification,
        );
      }
    }, [notification]);

    const object = {
      version: version,
      name: name,
      full_name: fullName,
      type: type,
      activityType: activityType,
      replyId: replyId,
      requestId: requestId,
      attachmentId: attachmentId,
      annotation_id: annotation_id,
      notification: notifications,
      openFile: activityType === "delivery" && permissionStart,
      pdfPageCount: pdfPageCount,
      pdfPageLoading: pdfPageLoading,
      comment_count: notification?.comment_count,
      resolved_count: notification?.resolved_count,
      file: file,
    };

    return (
      <>
        {imageType === "image" ? (
          <ImagesPreview
            file={file}
            version={version}
            name={name}
            full_name={fullName}
            type={type}
            activityType={activityType}
            replyId={replyId}
            requestId={requestId}
            attachmentId={attachmentId}
            annotation_id={annotation_id}
            notification={notifications}
            comment_count={notification?.comment_count}
            resolved_count={notification?.resolved_count}
          ></ImagesPreview>
        ) : pdfType === "pdf" ? (
          <>
            <div style={{ padding: "20px 20px 0 0" }}>
              <ThumbnailContainer
                url={file.file}
                fileName={fullName}
                file={object}
                loading={true}
                error={true}
              />
            </div>
          </>
        ) : (
          <>
            <div
              onClick={() => fileDownload(file)}
              style={{ padding: "20px 20px 0 0" }}
            >
              <FileCard
                style={{ marginTop: 10, opacity: 1 }}
                type={type}
                file={file}
                isDeletable={false}
                fullName={fullName}
                name={name}
                notification={notifications}
                {...rest}
              />
            </div>
          </>
        )}
      </>
    );
  },
);

export default FilePreview;
