import * as React from "react";

function SvgIndd(props) {
  return (
    <svg
      id="indd_svg__Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 240 234"
      style={{
        enableBackground: "new 0 0 240 234",
      }}
      className="mxht"
      xmlSpace="preserve"
      {...props}
    >
      <style>{".indd_svg__st3{fill:#f36}"}</style>
      <g id="indd_svg__Surfaces_00000027587425554370309040000001964056534605681329_">
        <g id="indd_svg__Publishing_Surface_00000068661546931895398870000005839857684712259761_">
          <path
            d="M42.5 0h155C221 0 240 19 240 42.5v149c0 23.5-19 42.5-42.5 42.5h-155C19 234 0 215 0 191.5v-149C0 19 19 0 42.5 0z"
            style={{
              fill: "#49021f",
            }}
            id="indd_svg__Outline_no_shadow_00000054983946053196278080000005044778020058851507_"
          />
        </g>
      </g>
      <g id="indd_svg__Outlined_Mnemonics_Logos_00000152975788979015935760000007129702699218663334_">
        <g id="indd_svg__Id_00000147181623133474315950000002237984869197711494_">
          <path
            className="indd_svg__st3"
            d="M87.2 61.2v102.6c0 1.1-.5 1.6-1.4 1.6H66.2c-.9 0-1.3-.5-1.3-1.6V61.2c0-.9.5-1.3 1.4-1.3h19.5c.6-.1 1.2.3 1.3 1 .1.1.1.2.1.3zM144.8 167c-7.4.1-14.8-1.4-21.5-4.5-6.3-2.9-11.5-7.7-15.1-13.6-3.7-6.1-5.5-13.7-5.5-22.8-.1-7.4 1.8-14.7 5.5-21.1 3.8-6.5 9.3-11.9 15.9-15.5 7-3.9 15.4-5.8 25.3-5.8.5 0 1.2 0 2.1.1.9.1 1.9.1 3.1.2V52.4c0-.7.3-1.1 1-1.1h20.3c.5-.1.9.3 1 .7v95.4c0 1.8.1 3.8.2 6 .2 2.1.3 4.1.4 5.8 0 .7-.3 1.3-1 1.6-5.2 2.2-10.7 3.8-16.3 4.8-5 .9-10.2 1.4-15.4 1.4zm9.8-20v-44c-.9-.2-1.8-.4-2.7-.5-1.1-.1-2.2-.2-3.3-.2-3.9 0-7.8.8-11.3 2.6-3.4 1.7-6.3 4.2-8.5 7.4s-3.3 7.5-3.3 12.7c-.1 3.5.5 7 1.7 10.3 1 2.7 2.5 5.1 4.5 7.1 1.9 1.8 4.2 3.2 6.8 4 2.7.9 5.5 1.3 8.3 1.3 1.5 0 2.9-.1 4.2-.2 1.3.1 2.5-.1 3.6-.5z"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgIndd;
