import React from "react";
import { Navigate, useRoutes } from "react-router-dom";

// Import Pages
import {
  Login,
  Teams,
  Profile,
  Requests,
  Clients,
  Brands,
  ClientsDetail,
  BrandCreate,
  Signup,
  Services,
  Reports,
  Request,
  Forgot,
  Reset,
  EmailTemplates,
  CreateEmailTemplate,
  CreateRequest,
  // AccountSetup,
  // ConfirmPlan,
  ConfirmPlanDetails,
  CreateAccount,
  Accept,
  Plans,
  Payment,
  Ticket,
  Announcements,
  Notifications,
  CreateAnnouncement,
  CreateNotification,
  ActivityLog,
  Feedback,
  AnalyticalReport,
  AddMeeting,
  IfNoReply,
  SendLater,
  SubPaused,
  Annotation,
} from "@atd/pages";
import { Root, Settings } from "@atd/layouts";
import { isAdmin } from "@atd/utils";
import { getCredentialCookies } from "@atd/utils/tokenUtilities";
import PrivateRoute from "./PrivateRoute";
const { token } = getCredentialCookies();
const path = window.location.href;
const clientRoutes = [
  {
    path: "brands",
    element: <Brands />,
  },
  {
    path: "brands/:id",
    element: <BrandCreate />,
  },
  {
    path: "brands/:id/edit",
    element: <BrandCreate isEdit />,
  },
];

const adminRoutes = [
  {
    path: "clients",
    element: <Clients />,
  },
  {
    path: "clients/:id",
    element: <ClientsDetail />,
  },
  {
    path: "clients/meeting/:id",
    element: <AddMeeting />,
  },
  {
    path: "clients/meeting/:id/:meetingId",
    element: <AddMeeting />,
  },
  {
    path: "activity",
    element: <ActivityLog />,
  },
  {
    path: "services",
    element: <Services />,
  },
  {
    path: "reports",
    element: <Reports />,
    children: [
      {
        path: "feedback",
        element: <Feedback />,
      },
      {
        path: "team",
        element: <Feedback />,
      },
      {
        path: "sales",
        element: <Feedback />,
      },
      {
        path: "analytics/:path",
        element: <AnalyticalReport />,
      },
      {
        path: "analytics/:path/:id",
        element: <AnalyticalReport />,
      },
    ],
  },
];

const routes = [
  {
    path: "/login",
    element: <Login />,
  },
  { path: "/signup", element: <Signup /> },
  { path: "/forgot", element: <Forgot /> },
  { path: "/reset", element: <Reset /> },
  { path: "/account-setup", element: <CreateAccount /> },
  { path: "/confirm-plan", element: <ConfirmPlanDetails /> },
  { path: "/accept", element: <Accept /> },
  {
    path: "/requests/annotation",
    element: <Annotation />,
  },
  {
    path: "/ticket",
    element: <Ticket />,
  },
  {
    path: "/tickets",
    element: <Ticket />,
  },
  {
    path: "/",
    element: !token ? (
      <PrivateRoute path={path} />
    ) : (
      <PrivateRoute path={path}>
        <Root />
      </PrivateRoute>
    ),
    children: [
      {
        index: true,
        element: <Navigate replace to="/requests" />,
      },
      {
        path: "requests",
        element: <Requests />,
      },
      {
        path: "search/:query",
        element: <Requests />,
      },
      {
        path: "requests/thread/:id",
        element: <Request />,
      },
      {
        path: "/requests/ifnoreply",
        element: <IfNoReply />,
      },
      {
        path: "/requests/sendlater",
        element: <SendLater />,
      },
      {
        path: "/requests/subscriptionpaused",
        element: <SubPaused />,
      },
      {
        path: "teams",
        element: <Teams isMain />,
      },

      ...(isAdmin() ? adminRoutes : []),
      ...(!isAdmin() ? clientRoutes : []),
    ],
  },
  {
    path: "/requests/:id",
    element: (
      <PrivateRoute path={path}>
        <CreateRequest />
      </PrivateRoute>
    ),
  },
  {
    path: "/requests/:id/new",
    element: (
      <PrivateRoute>
        <CreateRequest />
      </PrivateRoute>
    ),
  },
  {
    path: "/settings",
    element: (
      <PrivateRoute path={path}>
        <Root>
          <Settings />
        </Root>
      </PrivateRoute>
    ),
    children: [
      {
        path: "account",
        element: <Profile />,
      },
      {
        path: "email-templates",
        element: <EmailTemplates />,
      },
      {
        path: "announcements",
        element: <Announcements />,
      },
      {
        path: "announcements/:id",
        element: <CreateAnnouncement />,
      },
      {
        path: "notifications",
        element: <Notifications />,
      },
      {
        path: "notifications/:id",
        element: <CreateNotification />,
      },
      {
        path: "email-templates/:id",
        element: <CreateEmailTemplate />,
      },
      {
        path: "teams",
        element: <Teams isMain={false} />,
      },
      {
        path: "plans",
        element: <Plans />,
      },
      {
        path: "payment",
        element: <Payment />,
      },
    ],
  },
  { path: "*", element: <Navigate replace to="/requests" /> },
];

export default routes;

export function RenderRoutes() {
  const elementRoutes = useRoutes(routes);
  return elementRoutes;
}
