import { toast } from "react-toastify";

import { axios, getErrorMessage, getCurrentTimeZone } from "@atd/utils";

class RequestService {
  async getAllRequests(payload, cancelToken) {
    try {
      const { data } = await axios.get("/requests", {
        params: { ...payload, time_zone: getCurrentTimeZone() },
        cancelToken,
      });

      if (data.status === "error") {
        throw data;
      }

      return data;
    } catch (err) {
      if (err?.code !== "ERR_CANCELED") {
        toast.error(err.message);
      }
      throw err;
    }
  }

  async getSingle(id) {
    const { data } = await axios.get(`/requests/${id}`, {
      params: {
        time_zone: getCurrentTimeZone(),
      },
    });
    return data;
  }

  async addRequest() {
    try {
      const { data } = await axios.post("/requests", {
        request_name: "",
        request_type: "",
        brand_id: "",
        request_icon: "",
        file_dimentions: [
          {
            category_id: "",
            category_item_id: "",
            title_id: "",
            height: 0,
            length: 0,
            units: "px",
            icon: "",
          },
        ],
        royalty_free_images: [],
        file_type: [],
        project_details: "",
        attachments: [],
        inspiration_url: "",
        video_url: "",
      });

      if (data.code === 200) {
        return data;
      }

      throw new Error(data.message);
    } catch (err) {
      throw new Error(err);
    }
  }

  async submitRequest(id, payload) {
    try {
      const { data } = await axios.put(`/requests/submit/${id}`, payload);

      if (data.status === "success") {
        toast.success(data.message);
        return data;
      }
      throw new Error(data.message);
    } catch (err) {
      if (err.message === "Validation error") {
        toast.error(err.results?.[0]?.msg);
      } else {
        toast.error(err.message);
      }
      throw new Error(err);
    }
  }

  async getRequestTypes() {
    try {
      const { data } = await axios.get("/categories");
      if (data.code === 200) {
        return {
          request_types: data.results,
        };
      }
      // display error message
      if (data.results && data.results.length > 0) {
        data.results.forEach((item) => {
          toast.error(`${data.message} : ${item.msg}`);
        });
      } else {
        toast.error(data.message);
      }

      throw new Error(data.message);
    } catch (err) {
      throw new Error(err);
    }
  }

  async deleteRequest(payload) {
    toast.info("Deleting request...");
    try {
      const { data } = await axios.delete("/requests", {
        data: payload,
      });
      if (data.status === "success") {
        toast.success(data.message);
        return { id: payload.request_ids?.[0] };
      }
      toast.error(data.message);
      throw new Error(data.message);
    } catch (err) {
      throw new Error(err);
    }
  }

  async updateRequest(payload, id, params) {
    try {
      const { data } = await axios.put(
        `/requests${payload?.id || id ? `/${payload?.id || id}` : ""}`,
        payload.data,
        {
          params,
        },
      );

      if (
        ["archive", "unarchive", "priority"].includes(
          payload.data.update_type,
        ) &&
        data.status === "success"
      ) {
        toast.success(data.message);
      }

      if (
        ["archive", "unarchive", "priority"].includes(
          payload.data.update_type,
        ) &&
        data.status === "error"
      ) {
        toast.error(data.message);
        throw new Error(data.message);
      }

      return payload.data;
      // return { id: payload.id, data: payload.data, ...data };
    } catch (err) {
      throw new Error(err);
    }
  }

  async linkRequest(payload) {
    try {
      const { data } = await axios({
        method: "link",
        url: `/requests/${payload.id}`,
        params: payload.params,
      });
      if (data.status === "success") {
        toast.success(data.message);
        return { id: payload.id };
      }
      // display error message
      if (data.results && data.results.length > 0) {
        data.results.forEach((item) => {
          toast.error(`${data.message} : ${item.msg}`);
        });
      } else {
        toast.error(data.message);
      }

      throw new Error(data.message);
    } catch (err) {
      throw new Error(err);
    }
  }

  async cloneRequest(id) {
    try {
      const { data } = await axios({
        method: "COPY",
        url: `/requests/${id}`,
      });

      if (data.status === "success") {
        return data.results;
      }

      throw new Error(data.message);
    } catch (err) {
      throw new Error(err);
    }
  }

  async addNote(id, payload) {
    try {
      let attachments = [];
      attachments = payload?.attachments.filter((list) => !list?.error);
      payload.attachments = attachments;
      const { data } = await axios.post(`/note/${id}`, payload, {
        params: {
          time_zone: getCurrentTimeZone(),
        },
      });

      if (data.status === "error") {
        const errorMessage = getErrorMessage(data);
        toast.error(errorMessage);
        throw new Error(data.message);
      }

      toast.success(data.message);
      return data;
    } catch (err) {
      throw new Error(err);
    }
  }

  async updateNote(requestId, id, payload) {
    try {
      const { data } = await axios.put(`/note/${requestId}/${id}`, payload, {
        params: {
          time_zone: getCurrentTimeZone(),
        },
      });

      if (data.status === "error") {
        const errorMessage = getErrorMessage(data);
        toast.error(errorMessage);
        throw new Error(data.message);
      }

      toast.success(data.message);
      return data;
    } catch (err) {
      throw new Error(err);
    }
  }

  async deleteNote(requestId, id) {
    try {
      const { data } = await axios.delete(`/note/${requestId}/${id}`);
      if (data.status === "error") {
        toast.error(data.message);
        return data;
      }
      toast.success(data.message);
      return data;
    } catch (err) {
      throw new Error(err);
    }
  }

  async assignUser(id, payload) {
    try {
      const { data } = await axios.put(`/requests/assign_user/${id}`, payload);
      if (data.status === "success") {
        toast.success(data.message);
        return data;
      }
      throw new Error(data.message);
    } catch (err) {
      toast.error(err.message);
      throw new Error(err);
    }
  }

  async search(params) {
    const { data } = await axios.get("/search", {
      params,
    });
    if (data.status === "error") {
      throw data;
    }
    return data;
  }

  async markAsComplete(id, payload) {
    try {
      const { data } = await axios.put(`/requests/close/${id}`, payload);
      if (data.status === "error") {
        return data;
      }
      return { ...data, id };
    } catch (err) {
      return err;
    }
  }

  async getRequestsCount(params) {
    delete params?.activeTab;
    const { data } = await axios.get("/requests_count", {
      params,
    });
    return data;
  }

  async setRequestTimer(id, payload) {
    const { data } = await axios.put(`/requests/timer/${id}`, payload);
    return data;
  }
}

export default new RequestService();
