import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import RequestSendLater from "./requestSendLaterApi";
import axios from "axios";

const initialState = {
  error: null,
  request: null,
  creating: false,
  success: false,
  loading: true,
  fetching: false,
  hasErrors: false,
  errorMsg: "",
  requests: [],
  request_types: [],
  hasMore: false,
  isRequestLoading: true,
  search: null,
  counts: {},
  isSearch: false,
  processing: false,
};

export const getAllRequestSendLaterAsync = createAsyncThunk(
  "requestSendLater/getAllRequestSendLaterAsync",
  async (payload, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    const response = await RequestSendLater.getAllRequestSendLater(
      payload,
      source.token,
    );
    return response;
  },
);
export const sendLaterUpDateStatusAsync = createAsyncThunk(
  "requestSendLater/sendLaterUpDateStatusAsync",
  async (payload, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    const response = await RequestSendLater.sendLaterUpDateStatus(
      payload,
      source.token,
    );
    return response;
  },
);

export const requestSendLaterSlice = createSlice({
  name: "requestSendLater",
  initialState,
  reducers: {
    addErrorSendLater: (state, action) => {
      state.error = action.payload;
    },
    resetRequestSendLater: (state) => {
      state.requests = [];
      state.loading = true;
      state.hasMore = false;
    },
    resetRequestDataSendLater: (state) => {
      state.requests = [];
    },
    updateCurrentRequestSendLater: (state, action) => {
      state.request = { ...state.request, ...action.payload };
    },
    setSearchQuerySendLater: (state, action) => {
      state.search = action.payload;
    },
  },
  extraReducers: {
    [getAllRequestSendLaterAsync.pending]: (state) => {
      state.fetching = true;
      state.isSearch = false;
    },
    [getAllRequestSendLaterAsync.fulfilled]: (state, action) => {
      state.isSearch = false;
      state.fetching = false;
      state.loading = false;
      state.hasErrors = false;

      state.requests = [
        ...state.requests,
        ...(action.payload.results?.data || {}),
      ];
      state.hasMore = action.payload.results?.has_more;
    },
    [getAllRequestSendLaterAsync.rejected]: (state, action) => {
      // state.fetching = false;
      state.isSearch = false;
      state.loading = false;
      state.hasErrors = true;
      state.error = action.error;
      state.hasMore = false;
    },
    [sendLaterUpDateStatusAsync.pending]: (state) => {
      state.errorMsg = "";
    },
    [sendLaterUpDateStatusAsync.fulfilled]: (state, action) => {
      if (action.meta.arg.is_done === 0) {
        state.requests.map((curr) => {
          if (
            action.meta.arg.request_id === curr.request_id &&
            action.meta.arg.reply_id === curr.id
          ) {
            return (curr.is_scheduled_data.date_string =
              action.meta.arg.is_snooze_data.date_string);
          }
        });
      } else if (action.meta.arg.is_done === 1) {
        const findIndex = state.requests.findIndex(
          (curr) =>
            action.meta.arg.request_id === curr.request_id &&
            action.meta.arg.reply_id === curr.id,
        );
        state.requests = state.requests.filter(
          (list, index) => index !== findIndex,
        );
      }
    },
    [sendLaterUpDateStatusAsync.rejected]: (state, action) => {
      state.errorMsg = action.error.message;
    },
  },
});

export const {
  resetRequestSendLater,
  resetRequestDataSendLater,
  updateCurrentRequestSendLater,
  setSearchQuerySendLater,
} = requestSendLaterSlice.actions;

export default requestSendLaterSlice.reducer;
