import { useCallback, useEffect, useState, useRef, useMemo } from "react";
import "./ZoomFiles.css";
import { AlertInfo } from "@atd/components";

const Dropdown = ({
  isOpen,
  position,
  toggleDropdown,
  file,
  handleCopyImage,
}) => {
  const handleItemClick = (ele) => {
    window.open(ele?.attachment_url);
    toggleDropdown();
  };

  return (
    isOpen && (
      <div
        tabIndex="0"
        className="zoom-dropdown-menu"
        style={{ top: position.top - 140, left: position.left - 190 }}
        role="menu"
        aria-labelledby="dropdown-button"
        onClick={(e) => e.stopPropagation()}
      >
        <div
          className="zoom-dropdown-item"
          role="menuitem"
          tabIndex="0"
          onClick={() => handleItemClick(file)}
        >
          Download media file
        </div>
        <div
          className="zoom-dropdown-item"
          role="menuitem"
          tabIndex="0"
          onClick={() => handleCopyImage(file)}
        >
          Copy as PNG
        </div>
      </div>
    )
  );
};

function ZoomFiles({ zoomFiles, onClose }) {
  const imgRef = useRef(null);
  const containerRef = useRef(null);
  const [file, setFile] = useState(null);
  const [resize, setResize] = useState({ zoomId: 6, percentage: "50%" });
  const [dragging, setDragging] = useState(false);
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const [zoomFile, setZoomFile] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
  const [copyNotify, setCopyNotify] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [notifyTimeout, setNotifyTimeout] = useState(false);

  const zoomPercentage = useMemo(
    () => [
      { zoomId: 0, percentage: "1%" },
      { zoomId: 1, percentage: "2%" },
      { zoomId: 2, percentage: "3%" },
      { zoomId: 3, percentage: "13%" },
      { zoomId: 4, percentage: "25%" },
      { zoomId: 5, percentage: "50%" },
      { zoomId: 6, percentage: "65%" },
      { zoomId: 7, percentage: "100%" },
      { zoomId: 8, percentage: "150%" },
      { zoomId: 9, percentage: "200%" },
    ],
    [],
  );

  useEffect(() => {
    if (zoomFiles) {
      const file = zoomFiles?.find((item) => item.isSelect);
      setZoomFile(zoomFiles);
      setFile(file);

      console.log("file456789", zoomFiles);
    }
  }, [zoomFiles]);

  const handleMouseDown = (e) => {
    if (e.button === 2) {
      handleContextMenu(e);
      return;
    }
    setDragging(true);
    const { clientX, clientY } = e;
    setPosition((prevPosition) => ({
      ...prevPosition,
      initialX: clientX - prevPosition.left,
      initialY: clientY - prevPosition.top,
    }));
  };

  const handleMouseMove = (e) => {
    if (dragging) {
      const { clientX, clientY } = e;
      const newLeft = clientX - position.initialX;
      const newTop = clientY - position.initialY;
      setPosition({ ...position, top: newTop, left: newLeft });
    }
  };

  const handleMouseUp = () => {
    setDragging(false);
  };

  const handleContextMenu = (e) => {
    e.preventDefault();
    setDropdownPosition({ top: e.clientY, left: e.clientX });
    setDropdownOpen(true);
  };

  const handleClick = (e) => {
    e.preventDefault();
    setDropdownOpen(false);
    setDragging(false);
  };

  const zoomIn = useCallback(() => {
    const findIndex = zoomPercentage.findIndex(
      (ele) => ele?.zoomId === resize?.zoomId,
    );
    const zoomIns = zoomPercentage[findIndex + 1];
    console.log("zoomIn", zoomIns, findIndex);
    if (zoomIns !== undefined) {
      setResize(zoomIns);
    }
  }, [resize?.zoomId, zoomPercentage]);

  const zoomOut = useCallback(() => {
    const findIndex = zoomPercentage.findIndex(
      (ele) => ele?.zoomId === resize?.zoomId,
    );
    const zoomIns = zoomPercentage[findIndex - 1];
    console.log("zoomIn", zoomIns, findIndex);
    if (zoomIns !== undefined) {
      setResize(zoomIns);
    }
  }, [resize?.zoomId, zoomPercentage]);

  const selectFile = useCallback(
    (index) => {
      const files = zoomFiles.map((ele, i) => ({
        ...ele,
        isSelect: i === index ? true : false,
      }));
      const file = zoomFiles.find((_, i) => i === index);
      setZoomFile(files);
      setFile(file);
      setResize({ zoomId: 6, percentage: "50%" });
    },
    [zoomFiles],
  );

  const forwardAction = () => {
    const findexIndex = zoomFile.findIndex((ele) => ele.isSelect);
    const files = zoomFiles.map((ele, i) => ({
      ...ele,
      isSelect: i === findexIndex + 1 ? true : false,
    }));
    const file = files.find((_, i) => i === findexIndex + 1);
    if (file !== undefined) {
      setZoomFile(files);
      setFile(file);
      setResize({ zoomId: 6, percentage: "50%" });
    }
  };
  const backwardAction = () => {
    const findexIndex = zoomFile.findIndex((ele) => ele.isSelect);
    const files = zoomFiles.map((ele, i) => ({
      ...ele,
      isSelect: i === findexIndex - 1 ? true : false,
    }));
    const file = files.find((_, i) => i === findexIndex - 1);
    if (file !== undefined) {
      setZoomFile(files);
      setFile(file);
      setResize({ zoomId: 6, percentage: "50%" });
    }
  };

  const getContainerStyle = () => {
    const baseStyle = {
      backgroundColor: "rgb(245, 245, 245)",
    };

    if (dragging) {
      return {
        ...baseStyle,
        cursor: "grabbing",
      };
    } else if (dropdownOpen) {
      return {
        ...baseStyle,
        cursor: "default",
      };
    } else {
      return {
        ...baseStyle,
        cursor: "grab",
      };
    }
  };

  const handleCopyImage = async (file) => {
    try {
      setIsLoading(true);
      setNotifyTimeout(true);
      setCopyNotify("Copying media file...");
      // Fetch the image data
      const response = await fetch(file?.image_url);
      let imageData = await response.blob();

      // Convert GIF to PNG if attachment format is GIF
      if (file.attachment_format === "gif") {
        imageData = await convertGifToPng(imageData);
      }

      // Create a Blob from the image data
      const imageBlob = new Blob([imageData], {
        type: "image/png", // Always copy as PNG format
      });

      // Copy the Blob to the clipboard
      let clipBoardItem = {};
      clipBoardItem["image/png"] = imageBlob;

      await navigator.clipboard.write([new ClipboardItem(clipBoardItem)]);

      setDropdownOpen(!dropdownOpen);
      setTimeout(() => {
        setIsLoading(false);
        setCopyNotify("Media file copied to clipboard");
      }, 800);
      setTimeout(() => {
        setNotifyTimeout(false);
      }, 3000);

      console.log("Image copied to clipboard successfully!");
    } catch (error) {
      setDropdownOpen(!dropdownOpen);
      setIsLoading(false);
      setCopyNotify("Error copying image to clipboard");
      console.error("Error copying image to clipboard:", error);
    }
  };

  async function convertGifToPng(gifBlob) {
    return new Promise((resolve, reject) => {
      const img = document.createElement("img");
      img.onload = function () {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        canvas.toBlob(resolve, "image/png");
      };
      img.onerror = reject;
      img.src = URL.createObjectURL(gifBlob);
    });
  }

  const widthPercentage = {
    "1%": "7%",
    "2%": "8%",
    "3%": "10%",
  };

  const width = widthPercentage[resize.percentage] || resize.percentage;

  return (
    <>
      <div
        id="imgpop"
        className="zoom-anim-dialog mfp-hide dialog"
        style={{ width: "80vw" }}
        onClick={handleClick}
      >
        <div className="pop-title-new-version Box-divider--light-bottom-1">
          <span>{file?.attachment_full_name}</span>
          <button type="button" className="zoom-close" onClick={onClose}>
            x
          </button>
        </div>

        <div className="attachment_detail_modal--contentSectionContainer">
          <div className="attachment_detail_modal--selectedContent">
            <Dropdown
              isOpen={dropdownOpen}
              position={dropdownPosition}
              file={file}
              handleCopyImage={handleCopyImage}
              toggleDropdown={() => setDropdownOpen(!dropdownOpen)}
            />
            <div
              ref={containerRef}
              className="feed_post_zoom_pan--zoomPanContainer"
              style={getContainerStyle()}
              onMouseDown={handleMouseDown}
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}
              onContextMenu={handleContextMenu}
              onClick={handleClick}
            >
              <div
                className="feed_post_zoom_pan--guidelineLayer feed_post_zoom_pan--zoomPanInteractionLayer"
                style={{ overflow: "hidden" }}
              >
                <div
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    boxSizing: "border-box",
                  }}
                ></div>
                <div
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    boxSizing: "border-box",
                  }}
                ></div>
              </div>

              <div
                style={{
                  position: "relative",
                  top: position.top,
                  left: position.left,
                }}
              >
                <img
                  className="feed_post_zoom_pan--transformedImage"
                  src={file?.image_url}
                  alt={file?.attachment_full_name}
                  style={{
                    visibility: "visible",
                    border: "none",
                    width,
                    margin: "0 auto",
                    padding: "30px",
                    maxWidth: width,
                  }}
                  onContextMenu={handleContextMenu}
                />
              </div>

              <div
                className="feed_post_zoom_pan--zoomPanInteractionLayer"
                role="none"
              ></div>
              <div className="zoom_menu--feedZoomPan feed_post_zoom_pan--zoomControls feed_post_detail_modal--contentOverlayPill">
                <div
                  role="none"
                  className="zoom_menu--zoomMenuBase zoom_menu--zoomControls"
                >
                  <button
                    className="zoom_menu--zoomMinus zoom_menu--enabled raw_components--iconButtonEnabled raw_components--_iconButton"
                    tabIndex="0"
                    aria-label="Zoom out"
                    aria-disabled="false"
                    onClick={zoomOut}
                  >
                    <span
                      className="svg-container"
                      style={{ marginTop: "37%" }}
                    >
                      <svg
                        className="svg"
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                      >
                        <path
                          fill="#000"
                          fillOpacity="1"
                          fillRule="evenodd"
                          stroke="none"
                          d="M21 16H10v-1h11v1z"
                        ></path>
                      </svg>
                    </span>
                  </button>

                  <button
                    className="action--enabled action--root toolbar_styles--enabledButton zoom_menu--middleZoomButton"
                    aria-label="Zoom/view options"
                  >
                    <div className="zoom_menu--zoomAmount">
                      {resize?.percentage}
                    </div>
                  </button>

                  <button
                    className="zoom_menu--zoomPlus zoom_menu--enabled raw_components--iconButtonEnabled raw_components--_iconButton"
                    tabIndex="0"
                    aria-label="Zoom in"
                    aria-disabled="false"
                    onClick={zoomIn}
                  >
                    <span
                      className="svg-container"
                      style={{ marginTop: "37%" }}
                    >
                      <svg
                        className="svg"
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        style={{ marginTop: "15%" }}
                      >
                        <path
                          fill="#000"
                          fillOpacity="1"
                          fillRule="evenodd"
                          stroke="none"
                          d="M15 15v-5h1v5h5v1h-5v5h-1v-5h-5v-1h5z"
                        ></path>
                      </svg>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          {zoomFile?.length > 1 && (
            <div className="attachment_detail_modal--thumbnailSection">
              <button
                className="button--buttonBase button--buttonText button--withNoChildren button--buttonHugContents"
                onClick={backwardAction}
                disabled={zoomFile?.findIndex((_) => _.isSelect) === 0}
              >
                <div className="button--iconContainer">
                  <span className="svg-container">
                    <svg
                      className="svg"
                      title="chevron left 32"
                      aria-label="chevron left 32"
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      style={{ marginTop: "15%" }}
                    >
                      <path
                        fill="#000"
                        fillOpacity=".9"
                        fillRule="nonzero"
                        stroke="none"
                        d="M18.908 11.708 18.2 11l-5.206 4.999 5.206 5 .708-.708-4.5-4.292 4.5-4.291z"
                      ></path>
                    </svg>
                  </span>
                </div>
              </button>

              <div className="attachment_detail_modal--thumbnailContainer">
                {zoomFile?.length > 0 &&
                  zoomFile?.map((ele, i) => (
                    <div
                      className="attachment_detail_modal--thumbnailWrapper"
                      key={i}
                    >
                      <button
                        onClick={() => selectFile(i)}
                        className={`attachment--selectedThumbnailButton attachment--thumbnailButton  ${
                          ele?.isSelect === true ? "zoom-In" : ""
                        }`}
                      >
                        <img
                          ref={imgRef}
                          src={ele.image_url}
                          alt={ele.attachment_full_name}
                          className="attachment--thumbnailImage--Jx4fa"
                        />
                        <div className="attachment--lightOverlay attachment--overlay"></div>
                      </button>
                    </div>
                  ))}
              </div>
              <button
                className="button--buttonBase button--buttonText button--withNoChildren button--buttonHugContents"
                onClick={forwardAction}
                disabled={
                  zoomFile?.findIndex((_) => _.isSelect) ===
                  zoomFile?.length - 1
                }
              >
                <div className="button--iconContainer">
                  <span className="svg-container">
                    <svg
                      className="svg"
                      title="chevron right 32"
                      aria-label="chevron right 32"
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                    >
                      <path
                        fill="#000"
                        fillOpacity=".9"
                        fillRule="nonzero"
                        stroke="none"
                        d="m12.994 20.29.708.709 5.206-5L13.702 11l-.708.708 4.5 4.292-4.5 4.29z"
                      ></path>
                    </svg>
                  </span>
                </div>
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="notification" style={{ left: "32%", bottom: "-7%" }}>
        {notifyTimeout && (
          <AlertInfo isLoading={isLoading} message={copyNotify} />
        )}
      </div>
    </>
  );
}
export default ZoomFiles;
