import styled from "styled-components";

const Load = styled.div`
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: skeleton-loading 1.5s infinite;
  height: 16px;
  width: ${(props) => props.variant};
  border-radius: 1rem;
  @keyframes skeleton-loading {
    0% {
      background-position: 200% 0;
    }
    100% {
      background-position: -200% 0;
    }
  }
`;

function SkeletonLoader() {
  return (
    <>
      <div>
        <div className="thread-list-item-container">
          <div className="thread-list-item-content closed">
            <div className="thread-list-item closed">
              <div style={{ display: "flex" }}>
                <span className="thread-labels">{<Load variant="80px" />}</span>
              </div>
              <div className="thread-list-item-message-column">
                <div className="thread-list-item-message-column-header">
                  <span className="message-author">
                    {<Load variant="140px" />}
                  </span>
                </div>
                <div style={{ marginTop: "10px" }}>
                  <p className="fnt12 truncated-text">
                    {<Load variant="200px" />}
                  </p>
                </div>
              </div>
              <div className="thread-list-item-notes-container"></div>
              <div className="message-toolbar"></div>
            </div>
          </div>
        </div>
        <div className="thread-list-group-separator"></div>
      </div>
      <div>
        <div className="thread-list-item-container">
          <div className="thread-list-item-content closed">
            <div className="thread-list-item closed">
              <div style={{ display: "flex" }}>
                <span className="thread-labels">{<Load variant="80px" />}</span>
              </div>
              <div className="thread-list-item-message-column">
                <div className="thread-list-item-message-column-header">
                  <span className="message-author">
                    {<Load variant="140px" />}
                  </span>
                </div>
                <div style={{ marginTop: "10px" }}>
                  <p className="fnt12 truncated-text">
                    {<Load variant="200px" />}
                  </p>
                </div>
              </div>
              <div className="thread-list-item-notes-container"></div>
              <div className="message-toolbar"></div>
            </div>
          </div>
        </div>
        <div className="thread-list-group-separator"></div>
      </div>
      <div>
        <div className="thread-list-item-container">
          <div className="thread-list-item-content closed">
            <div className="thread-list-item closed">
              <div style={{ display: "flex" }}>
                <span className="thread-labels">{<Load variant="80px" />}</span>
              </div>
              <div className="thread-list-item-message-column">
                <div className="thread-list-item-message-column-header">
                  <span className="message-author">
                    {<Load variant="140px" />}
                  </span>
                </div>
                <div style={{ marginTop: "10px" }}>
                  <p className="fnt12 truncated-text">
                    {<Load variant="200px" />}
                  </p>
                </div>
              </div>
              <div className="thread-list-item-notes-container"></div>
              <div className="message-toolbar"></div>
            </div>
          </div>
        </div>
        <div className="thread-list-group-separator"></div>
      </div>

      <div>
        <div className="thread-list-item-container">
          <div className="thread-list-item-content closed">
            <div className="thread-list-item closed">
              <div style={{ display: "flex" }}>
                <span className="thread-labels">{<Load variant="80px" />}</span>
              </div>
              <div className="thread-list-item-message-column">
                <div className="thread-list-item-message-column-header">
                  <span className="message-author">
                    {<Load variant="140px" />}
                  </span>
                </div>
                <div style={{ marginTop: "10px" }}>
                  <p className="fnt12 truncated-text">
                    {<Load variant="200px" />}
                  </p>
                </div>
              </div>
              <div className="thread-list-item-notes-container"></div>
              <div className="message-toolbar"></div>
            </div>
          </div>
        </div>
        <div className="thread-list-group-separator"></div>
      </div>
      <div>
        <div className="thread-list-item-container">
          <div className="thread-list-item-content closed">
            <div className="thread-list-item closed">
              <div style={{ display: "flex" }}>
                <span className="thread-labels">{<Load variant="80px" />}</span>
              </div>
              <div className="thread-list-item-message-column">
                <div className="thread-list-item-message-column-header">
                  <span className="message-author">
                    {<Load variant="140px" />}
                  </span>
                </div>
                <div style={{ marginTop: "10px" }}>
                  <p className="fnt12 truncated-text">
                    {<Load variant="200px" />}
                  </p>
                </div>
              </div>
              <div className="thread-list-item-notes-container"></div>
              <div className="message-toolbar"></div>
            </div>
          </div>
        </div>
        <div className="thread-list-group-separator"></div>
      </div>
    </>
  );
}
export default SkeletonLoader;
