import AWS from "aws-sdk";

class AWSService {
  getAWSAccessKey() {
    AWS.config.update({
      accessKeyId: process.env.REACT_APP_AWS_S3_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_S3_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_AWS_S3_REGION,
    });
    return new AWS.S3();
  }

  getAWSBucketName() {
    const Bucket = process.env.REACT_APP_AWS_S3_BUCKET_NAME;
    return Bucket;
  }
}
export default new AWSService();
