import { useEffect } from "react";
import TemplateMain from "./TemplateMain";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setCurrentPlan } from "../../features";
import AccountSetup from "./AccountSetup";
import PlanCard from "./components/PlanCard";
import { Helmet } from "react-helmet";
const CreateAccount = () => {
  const [searchParams] = useSearchParams();

  const dispatch = useDispatch();

  useEffect(() => {
    const plan_id = searchParams.get("offers") === "off_50" ? "s_11" : "s_1";
    dispatch(setCurrentPlan(searchParams.get("plan_id") || plan_id));
  }, []);

  return (
    <>
      <div>
        <Helmet>
          <meta content="width=device-width, initial-scale=1" name="viewport" />
        </Helmet>
        <TemplateMain page={0}>
          <div className="co-bottom-wrapper">
            <div className="container_ w-container">
              <div className="co-grid-wrapper">
                {/* <!-- hidden input --> */}
                <input
                  type="hidden"
                  name="__plan_id"
                  id="__plan_id"
                  value="monthly_699_All Time Design_team_2022"
                />
                <input type="hidden" name="__coupon" id="__coupon" />
                {/* <!-- content account --> */}
                <AccountSetup />

                {/* <!-- content sidebar --> */}
                <PlanCard onClick={onclick} cardPage={0} />
              </div>
            </div>
          </div>
        </TemplateMain>
      </div>
    </>
  );
};

export default CreateAccount;
