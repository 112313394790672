/* eslint-disable no-unused-vars */
import { useFormikContext } from "formik";

import { Button } from "@atd/components";
import SendLater from "./Reply/SendLater";
import { useRef, useState } from "react";
import { isAdmin } from "@atd/utils";

function EditorBottomToolbar({
  open,
  body,
  ifNoReply,
  ifNoReplyDate,
  replyType,
  ifNoReplySeqs,
  showAttachmentsButton,
  inputProps,
  onSubmit,
  onCancel,
  render,
  onSchedule,
  type,
}) {
  const { isSubmitting } = useFormikContext();
  const buttonRef = useRef();
  const [show, setShow] = useState(false);

  let ifNoReplyEnable = false;

  if (ifNoReply === true) {
    if (replyType === "0" && ifNoReplyDate?.length > 0) {
      ifNoReplyEnable = false;
    } else if (replyType === "1" && ifNoReplyDate && ifNoReplySeqs) {
      ifNoReplyEnable = false;
    } else {
      ifNoReplyEnable = true;
    }
  }

  return (
    <div className="bottom-toolbar" style={{ display: "block" }}>
      {render && render()}
      <table className="table wt-100">
        <tbody>
          <tr>
            {/* {showFileTypes && (
              <td>
                <RequiredFormats />
              </td>
            )} */}
            <td className="wt-20">
              <div className="Box-root Flex-flex Flex-direction--row Flex-justifyContent--flexEnd Flex-wrap--nowrap mgt-8">
                {showAttachmentsButton && (
                  <div className="upload-fbtn-wrapper mgl-7">
                    <div className="Box-root Box-hideIfEmpty Margin-top--12 Margin-bottom--8 Margin-right--8 Margin-left--8 customcss4">
                      <Button
                        type="button"
                        icon="plus"
                        variant="default"
                        label="Attach"
                        onClick={open}
                      />
                      <input name="myfile" id="fileinput" {...inputProps} />
                    </div>
                  </div>
                )}
                <div className="Box-root Box-hideIfEmpty Margin-top--12 Margin-left--8 pointer1">
                  <Button
                    type="button"
                    variant="default"
                    label="Cancel"
                    disabled={isSubmitting}
                    onClick={onCancel}
                  />
                </div>
                <div className="Box-root Box-hideIfEmpty Margin-top--12 Margin-left--8 pointer1">
                  <Button
                    type="button"
                    disabled={
                      isSubmitting || body?.length === 0 || ifNoReplyEnable
                    }
                    variant="primary"
                    icon="arrow-top-right"
                    label="Submit"
                    iconColor="white"
                    onClick={onSubmit}
                  />
                </div>
                {isAdmin() && type === "reply" && (
                  <div className="Box-root Box-hideIfEmpty Margin-top--12 Margin-left--8 pointer1">
                    <SendLater
                      ref={buttonRef}
                      show={show}
                      setShow={setShow}
                      onSendLater={(date, time) => onSchedule(date, time)}
                      disabled={false}
                      isSubmitting={isSubmitting}
                    />
                  </div>
                )}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

EditorBottomToolbar.defaultProps = {
  showFileTypes: true,
  showAttachmentsButton: true,
};

export default EditorBottomToolbar;
