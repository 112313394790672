import { Dropdown } from "@atd/components";

import { forwardRef, useRef, useState, useEffect } from "react";
import moment from "moment";

import { DayPickerSingleDateController } from "react-dates";
import "../../pages/Requests/Request/Acitivities/Reply/SendLater.css";

import { Link } from "react-router-dom";

import "../../pages/Requests/Request/Acitivities/Reply/DatePicker.css";

const getDateFormat = (value, unit) => {
  return moment(new Date().toISOString().slice(0, 10))
    .add(value, unit)
    .format("YYYY-MM-DD");
};
const ScheduledDatePicker = ({ item, onChange }) => {
  const [dates, setDates] = useState([]);
  const [dataFromChild, setDataFromChild] = useState(null);

  useEffect(() => {
    const calculateDate = (daysToAdd) => {
      if (daysToAdd === 1 || daysToAdd === 2 || daysToAdd === 3) {
        const newDate = new Date();
        newDate.setDate(newDate.getDate() + daysToAdd);
        return newDate.toLocaleDateString(undefined, {
          weekday: "short",
        });
      } else if (daysToAdd === 7 || daysToAdd === 14 || daysToAdd === 29) {
        const newDate = new Date();
        newDate.setDate(newDate.getDate() + daysToAdd);

        const month = newDate.toLocaleString("default", { month: "short" });
        const day = newDate.getDate();

        return `${month} ${day}`;
      }
    };

    const calculatedDates = [
      calculateDate(1),
      calculateDate(2),
      calculateDate(3),
      calculateDate(7),
      calculateDate(14),
      calculateDate(29),
    ];

    setDates(calculatedDates);
  }, []);
  const onClick = () => {
    setDataFromChild(false);
    if (dataFromChild === false) {
      setDataFromChild("Open");
    }
  };
  const ScheduleDate = forwardRef((props, ref) => (
    <span>
      <p
        style={{
          position: "relative",
          color: "#3c4257",
          display: "inline-block",
        }}
        {...props}
      >
        <div style={{ display: "inline-block" }} ref={ref}>
          <span class="IconSVG IconSVG--regular" style={{ cursor: "pointer" }}>
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16px"
                height="16px"
                viewBox="0 0 24 24"
              >
                <g fill="none" stroke="currentColor" stroke-width="2">
                  <circle cx="12" cy="12" r="7" />
                  <path
                    stroke-linecap="round"
                    d="M5.965 3.136a4 4 0 0 0-2.829 2.829m14.899-2.829a4 4 0 0 1 2.829 2.829M12 8v3.75c0 .138.112.25.25.25H15"
                  />
                </g>
              </svg>
            </span>
          </span>
        </div>
      </p>
    </span>
  ));

  const MyDatePicker = ({ onDataFromChild, onChange, item }) => {
    const dateRef = useRef(null);
    const [dateInput, setDateInput] = useState(null);
    const [focused, setFocused] = useState(true);

    useEffect(() => {
      if (item) {
        setDateInput("");
      }
    }, [item]);

    const QuickPicks = () => {
      return (
        <div className="quick-picks">
          <div className="clear-reminder">
            <Link
              onClick={(e) => {
                e.preventDefault();
                onDataFromChild(true);
                onChange("clear", item);
              }}
            >
              <span>Clear Reminder</span>
            </Link>
          </div>
          <hr />

          <Link
            data-unit="days"
            data-value="1"
            to="#"
            title="Snooze selected items until tomorrow"
            onClick={(e) => {
              e.preventDefault();

              onChange(getDateFormat(1, "day"), item);
              onDataFromChild(true);
            }}
          >
            <span>
              1 Day <span style={{ marginLeft: "73%" }}>{dates[0]}</span>
            </span>
          </Link>

          <Link
            data-unit="days"
            data-value="1"
            to="#"
            title="Snooze selected items until tomorrow"
            onClick={(e) => {
              e.preventDefault();

              onChange(getDateFormat(2, "day"), item);
              onDataFromChild(true);
            }}
          >
            <span>
              2 Days <span style={{ marginLeft: "70%" }}>{dates[1]}</span>
            </span>
          </Link>
          <Link
            data-unit="days"
            data-value="1"
            to="#"
            title="Snooze selected items until tomorrow"
            onClick={(e) => {
              e.preventDefault();

              onChange(getDateFormat(3, "day"), item);
              onDataFromChild(true);
            }}
          >
            <span>
              3 Days <span style={{ marginLeft: "70%" }}>{dates[2]}</span>
            </span>
          </Link>
          <hr />
          <Link
            data-unit="weeks"
            data-value="1"
            to="#"
            title="Snooze selected items for 1 week"
            onClick={(e) => {
              e.preventDefault();
              onDataFromChild(true);

              onChange(getDateFormat(1, "weeks"), item);
            }}
          >
            <span>
              1 Week <span style={{ marginLeft: "62%" }}>{dates[3]}</span>
            </span>
          </Link>
          <Link
            data-unit="weeks"
            data-value="1"
            to="#"
            title="Snooze selected items for 1 week"
            onClick={(e) => {
              e.preventDefault();
              onDataFromChild(true);

              onChange(getDateFormat(2, "weeks"), item);
            }}
          >
            <span>
              2 Weeks
              <span style={{ marginLeft: "60%" }}>{dates[4]}</span>
            </span>
          </Link>
          <hr />
          <Link
            data-unit="months"
            data-value="1"
            to="#"
            title="Snooze selected items for 1 month"
            onClick={(e) => {
              e.preventDefault();
              onDataFromChild(true);

              onChange(getDateFormat(1, "months"), item);
            }}
          >
            <span>
              1 Month <span style={{ marginLeft: "61%" }}>{dates[5]}</span>
            </span>
          </Link>
          <hr />
        </div>
      );
    };
    return (
      <div style={{ listStyle: "none", padding: "0px", width: "265px" }}>
        <div ref={dateRef}>
          <span className="send-later-btn-container send-later-btn-group">
            <li>
              <div className="if-no-reply">
                <QuickPicks />
              </div>
              <div style={{ padding: "11px" }}>
                <DayPickerSingleDateController
                  noBorder
                  date={dateInput}
                  daySize={32}
                  focused={focused}
                  isFocused={true}
                  numberOfMonths={1}
                  // isOutsideRange={() => true}
                  isOutsideRange={(day) =>
                    day.isBefore(moment().startOf("day"))
                  }
                  hideKeyboardShortcutsPanel={true}
                  // autoFocus={true}
                  horizontalMonthPadding={0}
                  renderMonthElement={({ month }) => (
                    <div className="Calendar_sharedComponents_monthWrapper">
                      <div className="Calendar_sharedComponents_month">
                        {month.format("MMMM")}
                      </div>
                      <div>{month.year()}</div>
                    </div>
                  )}
                  transitionDuration={130}
                  // focusedInput={'startDate'}
                  onDateChange={(date) => {
                    setDateInput(date);
                    onChange(moment(date).format("YYYY-MM-DD"), item);
                    onDataFromChild(true);
                  }}
                  onFocusChange={() => setFocused(true)}
                  renderDayContents={(day) => (
                    <div className="CalendarDay__dayWrapper CalendarDay__dayWrapper--singleDate">
                      <div className="CalendarDay__day">{day.format("D")}</div>
                    </div>
                  )}
                />
              </div>
            </li>
          </span>
        </div>
      </div>
    );
  };
  const handleDataFromChild = (data) => {
    setDataFromChild(data);
  };

  return (
    <>
      <span>
        <Dropdown
          as={ScheduleDate}
          item={item}
          ZIndex={9999}
          backgroundColor={"#fafafa"}
          render={dataFromChild}
          onClick={onClick}
          popover={{ placement: "bottom-start", offset: [5, 6] }}
        >
          {(!dataFromChild || dataFromChild === "Open") && (
            <MyDatePicker
              onChange={onChange}
              item={item}
              onDataFromChild={handleDataFromChild}
            />
          )}
        </Dropdown>
      </span>
    </>
  );
};
export default ScheduledDatePicker;
