/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, forwardRef, useRef, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { Icon } from "@iconify/react";
import { DayPickerSingleDateController } from "react-dates";

import moment from "moment";
import { toast } from "react-toastify";

import { TimePickers } from "@atd/components";
import prefixStyles from "../../../../../components/InputField/PrefixedInput/PrefixedInput.module.css";

import { useOnClickOutside } from "@atd/hooks";
import "./SendLater.css";

const SendLater = forwardRef(
  ({ show, disabled, setShow, onSendLater, isSubmitting }, ref) => {
    const defaultRef = useRef(null);
    const resolvedRef = ref || defaultRef;

    const [dayOffset, setDayOffset] = useState(0);
    const [dateSched, setDateSched] = useState("");
    const [timeSched, setTimeSched] = useState("");
    const [showTime, setShowTime] = useState(false);
    const [dateInput, setDateInput] = useState(null);
    const [focused, setFocused] = useState(true);
    const [schedDate, setSchedDate] = useState("");
    const [isSubmit, setIsSubmit] = useState(false);

    useEffect(() => {
      setDateInput(null);
      setSchedDate("");
    }, []);
    useOnClickOutside(ref, () => setShow(false));

    const onChangeTimeSchedule = (value) => {
      if (dayOffset === 0 && value <= 14400) {
        const activeDaySelected = moment(dateInput).format("YYYY-MM-DD");
        setDateSched(activeDaySelected);
        setDayOffset(1);
      } else {
        const activeDaySelected = moment(dateInput).format("YYYY-MM-DD");
        setDateSched(activeDaySelected);
      }
      setIsSubmit(false);
      setTimeSched(value);
    };

    const onSchedule = (e) => {
      e.preventDefault();
      console.log("timeSched", timeSched, moment(dateSched).isValid());
      if (
        moment(dateSched).isValid() &&
        timeSched !== undefined &&
        timeSched !== ""
      ) {
        onSendLater(dateSched, timeSched || 0);
        setIsSubmit(true);
        setShow((show) => !show);
      } else if (!moment(dateSched).isValid()) {
        toast.error("select a date schedule");
      } else if (timeSched === undefined || timeSched === "") {
        toast.error("select a time schedule");
      }
    };

    const onScheduleClose = (e) => {
      e.preventDefault();
      setShow((show) => !show);
    };

    return (
      <span className="send-later-btn-container">
        <Dropdown
          show={show}
          className="send-later-btn-group"
          drop="up"
          ref={resolvedRef}
        >
          <Dropdown.Toggle
            variant=""
            className={`btn-small btn-icon-alt send-later   ${
              isSubmit ? "enable" : null
            }`}
            disabled={isSubmitting}
            onClick={() => setShow((show) => !show)}
          >
            <Icon icon="ant-design:clock-circle-outlined" width=".9rem" />
            Send Later
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <li>
              <DayPickerSingleDateController
                noBorder
                date={dateInput}
                daySize={32}
                focused={focused}
                isFocused={true}
                numberOfMonths={1}
                // isOutsideRange={() => true}
                isOutsideRange={(day) =>
                  day.isBefore(moment().startOf("day")) ||
                  day.isAfter(moment().add(3, "M"))
                }
                hideKeyboardShortcutsPanel={true}
                // autoFocus={true}
                horizontalMonthPadding={0}
                renderMonthElement={({ month }) => (
                  <div className="Calendar_sharedComponents_monthWrapper">
                    <div className="Calendar_sharedComponents_month">
                      {month.format("MMMM")}
                    </div>
                    <div>{month.year()}</div>
                  </div>
                )}
                transitionDuration={130}
                // focusedInput={'startDate'}
                onDateChange={(date) => {
                  setSchedDate(moment(date).format("MM/DD/YYYY"));
                  setDateSched(moment(date).format("YYYY-MM-DD"));
                  setDateInput(date);
                  setIsSubmit(false);
                }}
                onFocusChange={() => setFocused(true)}
                renderDayContents={(day) => (
                  <div className="CalendarDay__dayWrapper CalendarDay__dayWrapper--singleDate">
                    <div className="CalendarDay__day">{day.format("D")}</div>
                  </div>
                )}
              />
            </li>
            <li className="datepicker-container date-time-picker">
              <div>
                <div className={prefixStyles.wrapper}>
                  <span
                    className={prefixStyles.prefix}
                    style={{ minWidth: 8, padding: 0 }}
                  ></span>
                  <input
                    className={prefixStyles.input}
                    type="text"
                    placeholder={"MM/DD/YYYY"}
                    value={schedDate || ""}
                    disabled={disabled}
                  />
                  <span
                    className={`${prefixStyles.fakeInput}${
                      disabled ? " " + prefixStyles.fakeInputDisabled : ""
                    }`}
                  />
                </div>
              </div>
              <TimePickers
                appendTo="parent"
                visible={showTime}
                value={timeSched}
                onScheduleDate={schedDate}
                onChange={onChangeTimeSchedule}
                onHide={() => setShowTime(false)}
                placeholder="Time"
              />
            </li>
            <li className="divider" />

            <li className="schedule-button-cancel">
              <button
                className="btn btn-small btn-block btn-primary schedule"
                onClick={onScheduleClose}
              >
                Cancel
              </button>
              {isSubmit}
              <button
                className={`btn btn-small btn-block btn-primary schedule ${
                  isSubmit === true ? "enable" : ""
                }`}
                onClick={onSchedule}
              >
                Schedule
              </button>
            </li>
          </Dropdown.Menu>
        </Dropdown>
      </span>
    );
  },
);

export default SendLater;
