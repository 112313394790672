import { useState, useEffect, useCallback, memo } from "react";
import { Tab, TabList, TabPanel } from "react-tabs";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useLocalStorage } from "@atd/hooks";
import { AppHeader, ContentState } from "@atd/components";
import { resetRequest, fetchClient, updateInvoice } from "@atd/features";
import { Main } from "@atd/layouts";

import Users from "@atd/pages/Teams/Users";
import RequestsList from "@atd/pages/Requests/RequestsList";
import useInfiniteScroll from "@closeio/use-infinite-scroll";
import CompanyDetails from "./CompanyDetails";
import InvoiceDetails from "./InvoiceDetails";
import Meetings from "./Meetings/Meetings";
import UITabs from "../../Tabs";

import { isAdmin } from "@atd/utils";
import { useGetBillingQuery } from "@atd/features/user";

const tabList = [
  { id: "company_details", label: "Company Details " },
  { id: "tickets", label: "Tickets " },
  { id: "users", label: "Users" },
  { id: "invoice_details", label: "Invoice Details" },
  { id: "meetings", label: "Meetings" },
];

function ClientsDetail() {
  const params = useParams();

  const { id } = params || {};
  const { data } = useGetBillingQuery(`company_id=${id}`);
  const param = {
    tab: "company_details",
  };
  const [isResetData, setIsResetData] = useState(false);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [customerFilters, setCustomerFilters] = useLocalStorage(
    "customer-filters",
    param,
  );
  const [queryParams, setQueryParams] = useState(customerFilters);

  const { client, loading, invoiceData } = useSelector((state) => state.client);
  const { hasMore } = useSelector((state) => state.requests);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      updateInvoice({
        show_invoice: data?.show_invoice,
        show_card_update: data?.show_card_update,
      }),
    );
  }, [data, dispatch]);

  const [page, loaderRef, scrollerRef] = useInfiniteScroll({
    hasMore,
    reset: isResetData,
  });
  useEffect(() => {
    if (customerFilters.index !== 2) {
      dispatch(resetRequest());
      dispatch(fetchClient({ id, params: queryParams }));
    }
    if (customerFilters.hasOwnProperty("index")) {
      setActiveTabIndex(customerFilters["index"]);
    }
  }, [customerFilters, dispatch, id, queryParams]);

  const onResetData = useCallback(() => {
    setIsResetData(true);
    dispatch(resetRequest());
  }, [dispatch]);

  const onClickTabItem = (index) => {
    // setIsResetData(true);
    onResetData();
    setActiveTabIndex(index);
    if (!["tickets", "users", "meetings"].includes(tabList[index].id)) {
      setQueryParams({
        ...params,
        tab: tabList[index].id,
      });
    }
    setCustomerFilters({
      ...params,
      index: index,
      tab: tabList[index].id,
    });
  };

  return (
    <Main ref={scrollerRef}>
      <AppHeader
        hasTabs
        spread="appContent"
        title={client?.company_details?.company_name || "Client"}
      />
      <UITabs
        selectedTabClassName="active"
        selectedIndex={activeTabIndex}
        onSelect={onClickTabItem}
      >
        <TabList className="tabs">
          {tabList.map((item) => (
            <Tab key={item.id} className="tab-item capitalize">
              {item.label}
            </Tab>
          ))}
        </TabList>
        <div className="tab-content">
          <TabPanel>
            {loading && <ContentState />}
            {!loading && <CompanyDetails company={id} client={client || {}} />}
          </TabPanel>
          <TabPanel>
            <RequestsList
              params={{ company_id: id, status: "all" }}
              page={page}
              showFilters={false}
              loaderRef={loaderRef}
              onResetData={onResetData}
              setIsResetData={setIsResetData}
            />
          </TabPanel>
          <TabPanel>
            <Users params={{ company_id: id }} showActions={false} />
          </TabPanel>
          <TabPanel>
            {loading && <ContentState />}
            {!loading && isAdmin() && (
              <InvoiceDetails
                invoiceData={data?.invoice_data}
                company={id}
                client={invoiceData || {}}
              />
            )}
          </TabPanel>
          <TabPanel>
            <Meetings />
          </TabPanel>
        </div>
      </UITabs>
    </Main>
  );
}

export default memo(ClientsDetail);
