import { axios, getCurrentTimeZone } from "@atd/utils";
import { toast } from "react-toastify";

class RequestSendLater {
  async getAllRequestSendLater(payload, cancelToken) {
    try {
      delete payload?.activeTab;
      const { data } = await axios.get("/send_later_admin", {
        params: { ...payload, time_zone: getCurrentTimeZone() },
        cancelToken,
      });
      if (data.status === "error") {
        throw data;
      }
      return data;
    } catch (err) {
      if (err.code !== "ERR_CANCELED") {
        toast.error(err.message);
      }
      throw err;
    }
  }
  async sendLaterUpDateStatus(payload) {
    try {
      const { data } = await axios.put(
        `/send_later_admin?time_zone=${getCurrentTimeZone()}`,
        payload,
      );
      if (data.status === "error") {
        throw data;
      }
      if (data.status === "success") {
        toast.success("Update Successfully");
      }
      return data;
    } catch (err) {
      if (err.code !== "ERR_CANCELED") {
        toast.error(err.message);
      }
      throw err;
    }
  }
}

export default new RequestSendLater();
