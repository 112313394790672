import uuid from "react-uuid";
import styled from "styled-components";

import { Box, FileCard, Color, Text } from "@atd/components";

const Colors = styled(Box)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 10px;

  .color-code {
    text-align: center;
    height: 40px;
    line-height: 40px;
  }

  .colors__color {
    border: 1px solid #e3e8ee;
    border-radius: 5px;
    overflow: hidden;
    width: 100px;
  }

  .color {
    width: auto;
    height: 40px;
  }
`;

function BrandDetail({ brand }) {
  if (!brand) {
    return (
      <Box flex={{ alignItems: "center", justifyContent: "center" }}>
        <p>No brand details</p>
      </Box>
    );
  }

  return (
    <>
      <div className="title2 mgt2">Brand Name</div>
      <div className="cont2 mgt1">{brand.brand_name}</div>
      <div className="title2 mgt2">Target Audience</div>
      <div className="cont2 mgt1">{brand.target_audience}</div>
      <div className="title2 mgt2">Brand description</div>
      <div
        className="cont2 mgt1"
        style={{
          display: "inline-block",
          width: "300px",
          textAlign: "justify",
        }}
        dangerouslySetInnerHTML={{ __html: brand.description }}
      />
      {brand?.hex_colors?.length > 0 && (
        <>
          <div className="title2 mgt2">Colors</div>
          <Colors flex margin={{ right: 16 }} className="mgt1">
            {brand.hex_colors.map((color) => (
              <Box
                key={uuid()}
                className="colors__color"
                margin={{ right: 8 }}
                flex={{ direction: "column" }}
              >
                <Color
                  className="color"
                  color={color}
                  style={{ borderRadius: 0 }}
                />
                <Text className="color-code">{color}</Text>
              </Box>
            ))}
          </Colors>
        </>
      )}

      {brand?.logo?.length > 0 && (
        <>
          <div className="title2 mgt2">Logo</div>
          <div className="mgt1">
            {brand.logo.map((logo) => (
              <div className="lgo">
                <FileCard
                  type={logo.format}
                  file={logo.link}
                  fullName={logo.full_name}
                  name={logo.name}
                  isDeletable={false}
                  onClick={() => window.open(logo.link)}
                />
              </div>
            ))}
          </div>
        </>
      )}

      {brand?.attachments?.length > 0 && (
        <>
          <div className="title2 mgt2">Brand Guide</div>
          <div className="mgt1">
            {brand.attachments.map((attachment) => (
              <div className="lgo">
                <FileCard
                  type={attachment.format}
                  file={attachment.link}
                  fullName={attachment.full_name}
                  name={attachment.name}
                  isDeletable={false}
                  onClick={() => window.open(attachment.link)}
                />
              </div>
            ))}
          </div>
        </>
      )}

      {brand?.fonts?.length > 0 && (
        <>
          <div className="title2 mgt2">Fonts</div>
          <div className="mgt1">
            {brand.fonts.map((fonts) => (
              <div className="lgo">
                <FileCard
                  type={fonts.format}
                  file={fonts.link}
                  fullName={fonts.full_name}
                  name={fonts.name}
                  isDeletable={false}
                  onClick={() => window.open(fonts.link)}
                />
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
}

export default BrandDetail;
