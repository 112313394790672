import { axios, getCurrentTimeZone } from "@atd/utils";
import { toast } from "react-toastify";

class AnnotationService {
  async getAttachement(request_id, payload) {
    try {
      const formData = new FormData();
      formData.append("attach", payload);

      const { data } = await axios.post(`/attach?${request_id}`, formData);
      if (data.status === "success") {
        toast.success("Upload Successfully", {
          zIndex: 9999,
        });
        return data;
      } else {
        toast.error(data.message, {
          zIndex: 9999,
        });
      }
    } catch (err) {
      toast.error(err.message, {
        zIndex: 9999,
      });
      throw new Error(err);
    }
  }

  async getAnnotationFile(request_id, reply_id) {
    try {
      const { data } = await axios.get(
        `/annotation/${request_id}/${reply_id}`,
        {
          params: {
            time_zone: getCurrentTimeZone(),
          },
        },
      );
      if (data.status === "success") {
        return data;
      } else {
        toast.error(data.message, {
          zIndex: 9999,
        });
        return data;
      }
    } catch (err) {
      throw new Error(err);
    }
  }

  async add(payload) {
    try {
      const { data } = await axios.post("/annotation", payload, {
        params: {
          time_zone: 0,
        },
      });
      if (data.status === "success") {
        toast.success(data.message, {
          zIndex: 9999,
        });
        return data;
      } else {
        toast.error(data.message, {
          zIndex: 9999,
        });
        return data;
      }
    } catch (err) {
      toast.error(err.message, {
        zIndex: 9999,
      });
      throw new Error(err);
    }
  }

  async get(payload) {
    try {
      const { data } = await axios.get(`/annotation/${payload}`);
      if (data.status === "success") {
        return data;
      } else {
        toast.success(data.message, {
          zIndex: 9999,
        });
      }
    } catch (err) {
      toast.error(err.message, {
        zIndex: 9999,
      });
      throw new Error(err);
    }
  }

  async update(payload, annotation_id) {
    try {
      const { data } = await axios.post("/annotation/update", payload, {
        params: {
          time_zone: 0,
          annotation_id: annotation_id,
        },
      });
      if (data.status === "success") {
        toast.success(data.message, {
          zIndex: 9999,
        });
        return data;
      } else {
        toast.error(data.message, {
          zIndex: 9999,
        });
        return data;
      }
    } catch (err) {
      toast.error(err.message, {
        zIndex: 9999,
      });
      throw new Error(err);
    }
  }

  async resolve(payload, annotation_id) {
    try {
      const { data } = await axios.post("/annotation/resolve", payload, {
        params: {
          time_zone: 0,
          annotation_id: annotation_id,
        },
      });
      if (data.status === "success") {
        toast.success(
          payload.resolved === 1
            ? "Resolved Successfully"
            : "Unresolved Successfully",
        );
        return data;
      } else {
        toast.error(data.message);
      }
    } catch (err) {
      toast.error(err.message);
      throw new Error(err);
    }
  }
  async changeStatus(payload) {
    try {
      const { data } = await axios.post("/annotation/thread", payload, {
        params: {
          time_zone: 0,
        },
      });
      if (data.status === "success") {
        toast.success(data.message);
        return data;
      } else {
        toast.error(data.message);
        return data;
      }
    } catch (err) {
      toast.error(err.message);
      throw new Error(err);
    }
  }

  async deleteThread(payload) {
    try {
      const { data } = await axios.post("/annotation/comment", payload, {
        params: {
          time_zone: 0,
        },
      });
      if (data.status === "success") {
        toast.success(data.message);
        return data;
      } else {
        toast.error(data.message);
      }
    } catch (err) {
      toast.error(err.message);
      throw new Error(err);
    }
  }

  async deleteAttachementFile(payload) {
    try {
      const { data } = await axios.post("/annotation/attachment", payload, {
        params: {
          time_zone: 0,
        },
      });
      if (data.status === "success") {
        toast.success(data.message);
        return data;
      } else {
        toast.error(data.message);
      }
    } catch (err) {
      toast.error(err.message);
      throw new Error(err);
    }
  }

  async newDeliverRequest(payload, id) {
    try {
      let ele = {
        body: payload.body,
        attachments: payload.attachments,
        if_no_reply: 0,
        if_no_reply_data: {
          is_replied: 0,
          is_remined: 0,
          date_string: "2024-06-22",
          target_date_time_in_utc: "0",
          time_in_seconds: 0,
          action: "",
          seq_data: {
            is_sent: 0,
            attachments: [],
            body: "",
            followups: [],
          },
        },
        is_scheduled: 0,
        is_scheduled_data: {
          date_string: "",
          target_date_time_in_utc: "",
          time_in_seconds: "",
          is_sent: 0,
        },
      };
      const { data } = await axios.post(`/deliver/${id}`, ele, {
        params: {
          time_zone: 0,
        },
      });
      if (data.status === "success") {
        toast.success(data.message);
        return data;
      } else {
        toast.error(data.message);
        return data;
      }
    } catch (err) {
      toast.error(err.message);
      throw new Error(err);
    }
  }
}

export default new AnnotationService();
