import * as icons from "./icons";

export const ratingIcon = {
  1: {
    icon: icons.Terrible,
    label: "Terrible",
    activeColor: "rgba(255,169,141,1)",
  },
  2: {
    icon: icons.Bad,
    label: "Bad",
    activeColor: "rgba(255,195,133,1)",
  },
  3: {
    icon: icons.Okay,
    label: "Okay",
    activeColor: "rgba(255,216,133,1)",
  },
  4: {
    icon: icons.Good,
    label: "Good",
    activeColor: "rgba(255,216,133,1)",
  },
  5: {
    icon: icons.Great,
    label: "Great",
    activeColor: "rgba(255,216,133,1)",
  },
};

const rating = [
  {
    id: 1,
    label: "Terrible",
    fill: "#FFA98D",
    description:
      "We're sorry we let you down. We'd love to hear what went wrong and what we could do better.",
  },
  {
    id: 2,
    label: "Bad",
    fill: "#FFC385",
    description:
      "We're sorry we let you down. We'd love to hear what went wrong and what we could do better.",
  },
  {
    id: 3,
    label: "Okay",
    description: "We'd love to know if there's anything we can do better.",
  },
  {
    id: 4,
    label: "Good",
    description:
      "We're glad to hear that! We'd love to know what we can do better.",
  },
  {
    id: 5,
    label: "Great",
    description:
      "We're glad to hear that! If there's anything else, please let us know.",
  },
];

export default rating;
