import Cookies from "universal-cookie";

const cookies = new Cookies();

// options - for accessing your domain cookies
// const options = {};
// if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
//   options.path = "/";
//   options.domain = "localhost";
// } else {
//   options.path = "/";
//   options.domain = "sloovi.com";
// }

// ======= Get Credentials from Cookies =======
export function checkCredentialCookies() {
  if (cookies.get("token") && cookies.get("user_type")) {
    return true;
  }
  return false;
}
export function getCredentialCookies() {
  return {
    name: cookies.get("name"),
    email: cookies.get("email"),
    created: cookies.get("created"),
    token: cookies.get("token"),
    user_type: cookies.get("user_type"),
    user_id: cookies.get("user_id"),
    icon: cookies.get("icon"),
    role: cookies.get("role"),
  };
}
export function setCredentialCookies(user) {
  cookies.set("name", user.name, { path: "/" });
  cookies.set("email", user.email, { path: "/" });
  cookies.set("created", user.created, { path: "/" });
  cookies.set("token", user.token, { path: "/" });
  cookies.set("user_type", user.user_type, { path: "/" });
  cookies.set("user_id", user.user_id, { path: "/" });
  cookies.set("icon", user.icon, { path: "/" });
  cookies.set("role", user.role, { path: "/" });
}
export function removeCredentialCookies() {
  cookies.remove("name", { path: "/" });
  cookies.remove("email", { path: "/" });
  cookies.remove("created", { path: "/" });
  cookies.remove("token", { path: "/" });
  cookies.remove("user_type", { path: "/" });
  cookies.remove("user_id", { path: "/" });
  cookies.remove("icon", { path: "/" });
  cookies.remove("role", { path: "/" });
}
