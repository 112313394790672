import React, { useEffect, useState, useRef, useCallback } from "react";
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import { Link } from "react-router-dom";
import moment from "moment";
import classnames from "classnames";
import { Icon } from "@iconify/react";
import "./DatePicker.css";

import "timepicker/jquery.timepicker";
import "selectize/dist/js/selectize";
import "bootstrap-datepicker/dist/js/bootstrap-datepicker";
import "datepair.js/dist/datepair.js";
import "datepair.js/dist/jquery.datepair.js";
import { DayPickerSingleDateController } from "react-dates";
import "./SendLater.css";
// import OnOutsiceClick from 'react-outclick';
import { Tooltips } from "@atd/components";

const getDateFormat = (value, unit) => {
  return moment(new Date().toISOString().slice(0, 10))
    .add(value, unit)
    .format("YYYY-MM-DD");
};

const DatePicker = ({
  rel,
  title,
  value,
  type,
  icon,
  position,
  className,
  toggleClassName,
  showClearAction,
  right = false,
  onChange,
  onClear,
  renderButton,
  disabled,
}) => {
  const dateRef = useRef(null);
  const [dateLabel, setDateLalbel] = useState("in 1 week");
  const [show, setShow] = useState(false);
  const [dates, setDates] = useState([]);

  // useEffect(() => {
  //   onChange(getDateFormat(1, "weeks"));
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    const calculateDate = (daysToAdd) => {
      if (daysToAdd === 1 || daysToAdd === 2 || daysToAdd === 3) {
        const newDate = new Date();
        newDate.setDate(newDate.getDate() + daysToAdd);
        return newDate.toLocaleDateString(undefined, {
          weekday: "short",
        });
      } else if (daysToAdd === 7 || daysToAdd === 14 || daysToAdd === 29) {
        const newDate = new Date();
        newDate.setDate(newDate.getDate() + daysToAdd);

        const month = newDate.toLocaleString("default", { month: "short" });
        const day = newDate.getDate();

        return `${month} ${day}`;
      }
    };

    const calculatedDates = [
      calculateDate(1),
      calculateDate(2),
      calculateDate(3),
      calculateDate(7),
      calculateDate(14),
      calculateDate(29),
    ];

    setDates(calculatedDates);
  }, []);

  useEffect(() => {
    if (dateLabel === "in 1 week") {
      onChange(moment(getDateFormat(1, "weeks")).format("YYYY-MM-DD"));
      setDateLalbel("in 1 week");
    }
  }, []);

  const onChanges = (values) => {
    if (values) {
      if (values === getDateFormat(1, "weeks")) {
        setDateLalbel("in 1 week");
        onChange(moment(getDateFormat(1, "s")).format("YYYY-MM-DD"));
        setShow(false);
      } else {
        onChange(moment(values).format("YYYY-MM-DD"));
        setDateLalbel(`by ${moment(values).format("DD/MM/YYYY")}`);
        setShow(false);
      }
    }
  };

  const MyDatePicker = ({ onChange, item }) => {
    const dateRef = useRef(null);
    const [dateInput, setDateInput] = useState(null);
    const [focused, setFocused] = useState(true);

    useEffect(() => {
      if (item) {
        setDateInput(moment(item));
      }
    }, [item]);

    return (
      <div style={{ listStyle: "none", padding: "11px", width: "265px" }}>
        <div ref={dateRef}>
          <span className="send-later-btn-container send-later-btn-group">
            <li>
              <DayPickerSingleDateController
                noBorder
                date={dateInput}
                daySize={32}
                focused={focused}
                isFocused={true}
                numberOfMonths={1}
                // isOutsideRange={() => true}
                isOutsideRange={(day) => day.isBefore(moment().startOf("day"))}
                hideKeyboardShortcutsPanel={true}
                // autoFocus={true}
                horizontalMonthPadding={0}
                renderMonthElement={({ month }) => (
                  <div className="Calendar_sharedComponents_monthWrapper">
                    <div className="Calendar_sharedComponents_month">
                      {month.format("MMMM")}
                    </div>
                    <div>{month.year()}</div>
                  </div>
                )}
                transitionDuration={130}
                // focusedInput={'startDate'}
                onDateChange={(date) => {
                  setDateInput(date);
                  onChange(moment(date).format("YYYY-MM-DD"), item);
                }}
                onFocusChange={() => setFocused(true)}
                renderDayContents={(day) => (
                  <div className="CalendarDay__dayWrapper CalendarDay__dayWrapper--singleDate">
                    <div className="CalendarDay__day">{day.format("D")}</div>
                  </div>
                )}
              />
            </li>
          </span>
        </div>
      </div>
    );
  };

  const onClearDate = useCallback(
    (e) => {
      e.stopPropagation();
      // $(dateRef.current).datepicker('clearDates');
      setShow(false);
      onClear();
    },
    [onClear],
  );

  const toggleButtonClass = classnames(toggleClassName, {
    // "Btn Btn--mini": type !== "icon" && !renderButton,
    // "btn-icon": type === "icon" && !renderButton,
    transform: "translate3d(693px, -158px, 0px)",
  });

  const CustomToggle = React.forwardRef(
    ({ children, className, onClick, innerRef, ...rest }, ref) => {
      const buttonRender = (
        <button
          {...rest}
          ref={innerRef}
          type="button"
          onClick={(e) => {
            e.stopPropagation();
            onClick();
          }}
          disabled={disabled}
          className={`dropdown-toggle ${className}`}
        >
          {children}
        </button>
      );

      if (!rel) {
        return buttonRender;
      }

      return (
        <Tooltips allowHTML content={title} maxWidth={400}>
          {buttonRender}
        </Tooltips>
      );
    },
  );

  const QuickPicks = () => {
    return (
      <div className="quick-picks">
        <Link
          data-unit="days"
          data-value="1"
          to="#"
          title="Snooze selected items until tomorrow"
          onClick={(e) => {
            e.preventDefault();
            setDateLalbel("in 1 day");
            onChange(getDateFormat(1, "day"));
            setShow(false);
          }}
        >
          <span>
            1 Day <span style={{ marginLeft: "73%" }}>{dates[0]}</span>
          </span>
        </Link>

        <Link
          data-unit="days"
          data-value="1"
          to="#"
          title="Snooze selected items until tomorrow"
          onClick={(e) => {
            e.preventDefault();
            setDateLalbel("in 2 days");
            onChange(getDateFormat(2, "day"));
            setShow(false);
          }}
        >
          <span>
            2 Days <span style={{ marginLeft: "70%" }}>{dates[1]}</span>
          </span>
        </Link>
        <Link
          data-unit="days"
          data-value="1"
          to="#"
          title="Snooze selected items until tomorrow"
          onClick={(e) => {
            e.preventDefault();
            setDateLalbel("in 3 days");
            onChange(getDateFormat(3, "day"));
            setShow(false);
          }}
        >
          <span>
            3 Days <span style={{ marginLeft: "70%" }}>{dates[2]}</span>
          </span>
        </Link>
        <hr />
        <Link
          data-unit="weeks"
          data-value="1"
          to="#"
          title="Snooze selected items for 1 week"
          onClick={(e) => {
            e.preventDefault();
            setDateLalbel("in 1 week");
            onChange(getDateFormat(1, "weeks"));
            setShow(false);
          }}
        >
          <span>
            1 Week <span style={{ marginLeft: "62%" }}>{dates[3]}</span>
          </span>
        </Link>
        <Link
          data-unit="weeks"
          data-value="1"
          to="#"
          title="Snooze selected items for 1 week"
          onClick={(e) => {
            e.preventDefault();
            setDateLalbel("in 2 weeks");
            onChange(getDateFormat(2, "weeks"));
            setShow(false);
          }}
        >
          <span>
            2 Weeks
            <span style={{ marginLeft: "60%" }}>{dates[4]}</span>
          </span>
        </Link>
        <hr />
        <Link
          data-unit="months"
          data-value="1"
          to="#"
          title="Snooze selected items for 1 month"
          onClick={(e) => {
            e.preventDefault();
            setDateLalbel("in 1 month");
            onChange(getDateFormat(1, "months"));
            setShow(false);
          }}
        >
          <span>
            1 Month
            <span style={{ marginLeft: "61%" }}>{dates[5]}</span>
          </span>
        </Link>
        <hr />
      </div>
    );
  };

  return (
    <>
      <div>
        <Dropdown
          className={`${className}`}
          isOpen={show}
          toggle={() => setShow((show) => !show)}
        >
          <DropdownToggle tag={CustomToggle} className={toggleButtonClass}>
            {renderButton ? (
              renderButton()
            ) : type === "icon" ? (
              typeof icon === "function" ? (
                icon()
              ) : (
                <Icon icon="mdi:alarm-light" width="1rem" color="#7D7D7D" />
              )
            ) : (
              <>
                {dateLabel} <span className="better-caret" />
              </>
            )}
          </DropdownToggle>
          <DropdownMenu right={right} className="custom-dropdown-menu">
            {position === "up" && <QuickPicks />}
            {/* <div ref={dateRef} className="datepicker-container" /> */}
            <div ref={dateRef} className="datepicker-container">
              <MyDatePicker onChange={onChanges} item={value} />
            </div>
            {position === "down" && <QuickPicks />}
            {showClearAction && (
              <div className="clear-action">
                <button
                  className="btn btn-block js-clear"
                  onClick={onClearDate}
                >
                  Clear Reminder
                </button>
              </div>
            )}
          </DropdownMenu>
        </Dropdown>
      </div>
    </>
  );
};

export default React.memo(DatePicker);
