import * as React from "react";

const SvgPayments = (props) => (
  <svg
    className="Payments_svg__SVGInline-svg Payments_svg__SVGInline--cleaned-svg Payments_svg__SVG-svg Payments_svg__db-SideNav-icon-svg Payments_svg__db-SideNav-icon--payments-svg Payments_svg__wh-16"
    width={16}
    height={16}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="currentColor"
      d="M1 3.875C1 3.392 1.392 3 1.875 3h10.5a.875.875 0 1 1 0 1.75h-10.5A.875.875 0 0 1 1 3.875ZM2.75 8.25c0-.484.392-.875.875-.875h10.5a.874.874 0 1 1 0 1.75h-10.5a.875.875 0 0 1-.875-.875Zm9.625 5.25h-10.5a.875.875 0 1 1 0-1.75h10.5a.874.874 0 1 1 0 1.75Z"
    />
  </svg>
);

export default SvgPayments;
