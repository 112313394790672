import {
  AddNewVersion,
  FinishReview,
  Tooltip,
  Modal,
  PageExitAlert,
} from "@atd/components";
import { useState, useCallback, useEffect } from "react";

function FooterAnnotation({
  isSubmit,
  versionFile,
  finishReview,
  finishUpload,
  uploadFile,
  onChangeVersion,
  onClosePoup,
  downloadFile,
  loading,
  rolePermissions,
  versionHistory,
  isLoading,
  removeAttachFile,
}) {
  const enableFinishReview = versionFile
    .find((ele) => ele.isSelect)
    ?.files.some((_) => !_.annotation && _.isSelect);

  console.log(
    "FooterAnnotation",
    versionFile,
    enableFinishReview,
    versionFile,
    rolePermissions,
  );

  const [newVersion, setNewVersion] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenExitModal, setIsOpenExitModal] = useState(false);

  const selectType = useCallback(
    (e) => {
      if (e.target.value === "Add Version") {
        setNewVersion(true);
      } else {
        const selectFile = versionFile.find(
          (ele) => ele.reply_id === e.target.value,
        );
        onChangeVersion(selectFile);
      }
    },
    [onChangeVersion, versionFile],
  );

  const selectedVersion = versionFile.find((ele) => ele.isSelect) || "";

  const onClose = useCallback(() => {
    setIsOpen(false);
    setNewVersion(false);
    onClosePoup();
  }, [onClosePoup]);

  const toggleFinishReview = () => {
    setIsOpen((prevFinishReview) => !prevFinishReview);
  };

  const addOneMoreReview = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const handlePopState = (event) => {
      event.preventDefault();
      console.log("Exit");
      setIsOpenExitModal(() => !isOpenExitModal);
    };
    window.addEventListener("popstate", handlePopState);
    window.history.pushState({ modalOpened: false }, "");

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [isOpenExitModal]);

  const cancel = () => {
    setIsOpenExitModal(() => !isOpenExitModal);
  };
  console.log("versionFile", versionFile);

  return (
    <>
      <div className="ftr">
        <div className="row">
          <div className="column leftcl">
            <div className="PressableCore PressableCore--cursor--pointer PressableCore--height--medium PressableCore--radius--all PressableCore--width PressableCore--width--auto PressableButton Select Select--size--medium Box-root Flex-inlineFlex sbox">
              <div className="PressableCore-base Box-root">
                <select
                  onChange={(e) => selectType(e)}
                  value={selectedVersion.reply_id}
                  aria-invalid="false"
                  className="Select-element PressableContext Padding-right--24 Padding-left--8 Padding-vertical--4 PressableContext--cursor--pointer PressableContext--display--inlineFlex PressableContext--fontLineHeight--20 PressableContext--fontSize--14 PressableContext--fontWeight--medium PressableContext--height PressableContext--height--medium PressableContext--radius--all PressableContext--width PressableContext--width--auto colr1"
                >
                  {versionFile?.map((ele, index) => (
                    <option
                      key={index}
                      value={ele.reply_id}
                      selected={ele.isSelect}
                    >
                      Version {ele.version.split("")[1]}
                    </option>
                  ))}

                  {versionFile?.length > 0 &&
                    rolePermissions?.add_new_version && (
                      <option value="Add Version">+ Add Version</option>
                    )}
                  {versionFile?.length === 0 && (
                    <option value="">Version</option>
                  )}
                </select>

                <div className="Select-arrows colr1">
                  <div
                    aria-hidden="true"
                    className="SVGInline SVGInline--cleaned SVG Icon Icon--arrowUpDown Icon-color Icon-color--gray800 Box-root Flex-flex"
                  >
                    <svg
                      aria-hidden="true"
                      className="SVGInline-svg SVGInline--cleaned-svg SVG-svg Icon-svg Icon--arrowUpDown-svg Icon-color-svg Icon-color--gray800-svg"
                      height="12"
                      width="12"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 16 16"
                    >
                      <path d="M11.891 9.992a1 1 0 1 1 1.416 1.415l-4.3 4.3a1 1 0 0 1-1.414 0l-4.3-4.3A1 1 0 0 1 4.71 9.992l3.59 3.591 3.591-3.591zm0-3.984L8.3 2.417 4.709 6.008a1 1 0 0 1-1.416-1.415l4.3-4.3a1 1 0 0 1 1.414 0l4.3 4.3a1 1 0 1 1-1.416 1.415z"></path>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <Tooltip content="Download" placement="top">
              <button
                type="button"
                onClick={() => downloadFile(selectedVersion)}
              >
                <svg
                  style={{ marginTop: "5px" }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="20px"
                  height="20px"
                  className="downl"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-2M7 11l5 5l5-5m-5-7v12"
                  />
                </svg>
              </button>
            </Tooltip>
          </div>
          <div className="column rightcl right">
            <div className="Box-root Flex-flex fltrgt but-grp">
              <div
                className="Box-root Box-hideIfEmpty Margin-right--8 pointer1"
                onClick={cancel}
              >
                <a className="wt-but canc">
                  <div className="Box-root"></div>
                  Cancel
                </a>
              </div>

              <button
                className="Box-root Box-hideIfEmpty pointer1"
                onClick={toggleFinishReview}
                type="button"
                disabled={!rolePermissions?.finish_review || enableFinishReview}
              >
                <a
                  className="bl-but"
                  style={{
                    cursor:
                      !rolePermissions?.finish_review ||
                      enableFinishReview ||
                      isLoading
                        ? "not-allowed"
                        : "pointer",
                    backgroundColor:
                      !rolePermissions?.finish_review ||
                      enableFinishReview ||
                      isLoading
                        ? "#7ba4c2"
                        : "#3781b8",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14px"
                    height="14px"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M13.3 12.22A4.92 4.92 0 0 0 15 8.5a5 5 0 0 0-10 0a4.92 4.92 0 0 0 1.7 3.72A8 8 0 0 0 2 19.5a1 1 0 0 0 2 0a6 6 0 0 1 12 0a1 1 0 0 0 2 0a8 8 0 0 0-4.7-7.28M10 11.5a3 3 0 1 1 3-3a3 3 0 0 1-3 3m11.71-2.37a1 1 0 0 0-1.42 0l-2 2l-.62-.63a1 1 0 0 0-1.42 0a1 1 0 0 0 0 1.41l1.34 1.34a1 1 0 0 0 1.41 0l2.67-2.67a1 1 0 0 0 .04-1.45"
                    />
                  </svg>
                  &nbsp; Finish review
                </a>
              </button>
            </div>
          </div>
        </div>
      </div>

      <AddNewVersion
        version={newVersion}
        onClosePopup={onClose}
        finishUpload={finishUpload}
        removeAttachFile={removeAttachFile}
        uploadFile={uploadFile}
        onClosePoup={onClosePoup}
      />
      <FinishReview
        isSubmit={isSubmit}
        loading={loading}
        isOpen={isOpen}
        selectedVersion={selectedVersion?.version}
        createDate={selectedVersion?.created_on}
        finishReview={(event) => finishReview(event, selectedVersion)}
        addOneMoreReview={addOneMoreReview}
        rolePermissions={rolePermissions}
        versionHistory={versionHistory}
        onClose={onClose}
      />

      <Modal
        id="adduser"
        title={`${"Any unsaved changes will be lost. Are you sure you want to leave?"}`}
        isOpen={isOpenExitModal}
        onClose={isOpenExitModal}
        width={400}
      >
        <PageExitAlert setIsOpenEditModal={setIsOpenExitModal} />
      </Modal>
    </>
  );
}
export default FooterAnnotation;
