import { Dropdown } from "@atd/components";

import { forwardRef, useRef, useState, useEffect } from "react";
import moment from "moment";

import { DayPickerSingleDateController } from "react-dates";
import "../../pages/Requests/Request/Acitivities/Reply/SendLater.css";

import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";

import "../../pages/Requests/Request/Acitivities/Reply/DatePicker.css";

const getDateFormat = (value, unit) => {
  return moment(new Date().toISOString().slice(0, 10))
    .add(value, unit)
    .format("YYYY-MM-DD");
};
const ScheduleDatePicker = ({ item, onChange }) => {
  const [dates, setDates] = useState([]);
  const [dataFromChild, setDataFromChild] = useState(null);

  useEffect(() => {
    const calculateDate = (daysToAdd) => {
      if (daysToAdd === 1 || daysToAdd === 2 || daysToAdd === 3) {
        const newDate = new Date();
        newDate.setDate(newDate.getDate() + daysToAdd);
        return newDate.toLocaleDateString(undefined, {
          weekday: "short",
        });
      } else if (daysToAdd === 7 || daysToAdd === 14 || daysToAdd === 29) {
        const newDate = new Date();
        newDate.setDate(newDate.getDate() + daysToAdd);

        const month = newDate.toLocaleString("default", { month: "short" });
        const day = newDate.getDate();

        return `${month} ${day}`;
      }
    };

    const calculatedDates = [
      calculateDate(1),
      calculateDate(2),
      calculateDate(3),
      calculateDate(7),
      calculateDate(14),
      calculateDate(29),
    ];

    setDates(calculatedDates);
  }, []);
  const onClick = () => {
    setDataFromChild(false);
    if (dataFromChild === false) {
      setDataFromChild("Open");
    }
  };
  const handleDataFromChild = (data) => {
    setDataFromChild(data);
  };
  const ScheduleDate = forwardRef((props, ref) => (
    <span>
      <p
        style={{
          position: "relative",
          color: "#3c4257",
          display: "inline-block",
        }}
        {...props}
      >
        <div style={{ display: "flex" }} ref={ref}>
          <div style={{ width: "95px" }}>
            {" "}
            {moment(item.if_no_reply_data?.date_string).format(
              "MMM DD, YYYY",
            )}{" "}
          </div>
          <span class="IconSVG IconSVG--regular">
            <span>
              <Icon
                className="IconSVG"
                icon="material-symbols:edit"
                style={{
                  position: "absolute",
                  top: "10%",
                  left: "100%",
                  marginLeft: "5px",
                  fontSize: "14px",
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
              />
            </span>
          </span>
        </div>
      </p>
    </span>
  ));

  const MyDatePicker = ({ onDataFromChild, onChange, item }) => {
    const dateRef = useRef(null);
    const [dateInput, setDateInput] = useState(null);
    const [focused, setFocused] = useState(true);

    useEffect(() => {
      if (item) {
        setDateInput(moment(item.if_no_reply_data?.date_string));
      }
    }, [item]);

    const QuickPicks = () => {
      return (
        <div className="quick-picks">
          <Link
            data-unit="days"
            data-value="1"
            to="#"
            title="Snooze selected items until tomorrow"
            onClick={(e) => {
              e.preventDefault();
              onDataFromChild(true);
              onChange(getDateFormat(1, "day"), item);
            }}
          >
            <span>
              1 Day <span style={{ marginLeft: "73%" }}>{dates[0]}</span>
            </span>
          </Link>

          <Link
            data-unit="days"
            data-value="1"
            to="#"
            title="Snooze selected items until tomorrow"
            onClick={(e) => {
              e.preventDefault();
              onDataFromChild(true);
              onChange(getDateFormat(2, "day"), item);
            }}
          >
            <span>
              2 Days <span style={{ marginLeft: "70%" }}>{dates[1]}</span>
            </span>
          </Link>
          <Link
            data-unit="days"
            data-value="1"
            to="#"
            title="Snooze selected items until tomorrow"
            onClick={(e) => {
              e.preventDefault();
              onDataFromChild(true);
              onChange(getDateFormat(3, "day"), item);
            }}
          >
            <span>
              3 Days <span style={{ marginLeft: "70%" }}>{dates[2]}</span>
            </span>
          </Link>
          <hr />
          <Link
            data-unit="weeks"
            data-value="1"
            to="#"
            title="Snooze selected items for 1 week"
            onClick={(e) => {
              e.preventDefault();
              onDataFromChild(true);
              onChange(getDateFormat(1, "weeks"), item);
            }}
          >
            <span>
              1 Week <span style={{ marginLeft: "62%" }}>{dates[3]}</span>
            </span>
          </Link>
          <Link
            data-unit="weeks"
            data-value="1"
            to="#"
            title="Snooze selected items for 1 week"
            onClick={(e) => {
              e.preventDefault();
              onDataFromChild(true);
              onChange(getDateFormat(2, "weeks"), item);
            }}
          >
            <span>
              2 Weeks
              <span style={{ marginLeft: "60%" }}>{dates[4]}</span>
            </span>
          </Link>
          <hr />
          <Link
            data-unit="months"
            data-value="1"
            to="#"
            title="Snooze selected items for 1 month"
            onClick={(e) => {
              e.preventDefault();
              onDataFromChild(true);
              onChange(getDateFormat(1, "months"), item);
            }}
          >
            <span>
              1 Month
              <span style={{ marginLeft: "61%" }}>{dates[5]}</span>
            </span>
          </Link>
          <hr />
        </div>
      );
    };
    return (
      <div style={{ listStyle: "none", padding: "0px", width: "265px" }}>
        <div ref={dateRef}>
          <span className="send-later-btn-container send-later-btn-group">
            <li>
              <div className="if-no-reply">
                <QuickPicks />
              </div>
              <div style={{ padding: "11px" }}>
                <DayPickerSingleDateController
                  noBorder
                  date={dateInput}
                  daySize={32}
                  focused={focused}
                  isFocused={true}
                  numberOfMonths={1}
                  // isOutsideRange={() => true}
                  isOutsideRange={(day) =>
                    day.isBefore(moment().startOf("day"))
                  }
                  hideKeyboardShortcutsPanel={true}
                  // autoFocus={true}
                  horizontalMonthPadding={0}
                  renderMonthElement={({ month }) => (
                    <div className="Calendar_sharedComponents_monthWrapper">
                      <div className="Calendar_sharedComponents_month">
                        {month.format("MMMM")}
                      </div>
                      <div>{month.year()}</div>
                    </div>
                  )}
                  transitionDuration={130}
                  // focusedInput={'startDate'}
                  onDateChange={(date) => {
                    onDataFromChild(true);
                    setDateInput(date);
                    onChange(moment(date).format("YYYY-MM-DD"), item);
                  }}
                  onFocusChange={() => setFocused(true)}
                  renderDayContents={(day) => (
                    <div className="CalendarDay__dayWrapper CalendarDay__dayWrapper--singleDate">
                      <div className="CalendarDay__day">{day.format("D")}</div>
                    </div>
                  )}
                />
              </div>
            </li>
          </span>
        </div>
      </div>
    );
  };

  return (
    <>
      <span>
        <Dropdown
          as={ScheduleDate}
          item={item}
          ZIndex={9999}
          backgroundColor={"#fafafa"}
          OverFlow={"unset"}
          render={dataFromChild}
          onClick={onClick}
          popover={{ placement: "bottom-start", offset: [5, 6] }}
        >
          {(!dataFromChild || dataFromChild === "Open") && (
            <MyDatePicker
              onChange={onChange}
              item={item}
              onDataFromChild={handleDataFromChild}
            />
          )}
        </Dropdown>
      </span>
    </>
  );
};
export default ScheduleDatePicker;
