/* eslint-disable import/no-anonymous-default-export */
import styled from "styled-components";

import * as icons from "./icons";

const fileIcons = {
  ai: icons.Ai,
  docx: icons.Docx,
  indd: icons.Indd,
  jpg: icons.Jpeg,
  jpeg: icons.Jpeg,
  png: icons.Jpeg,
  gif: icons.Jpeg,
  pdf: icons.Pdf,
  ppt: icons.Ppt,
  pptx: icons.Ppt,
  psd: icons.Psd,
  txt: icons.Text,
  zip: icons.Zip,
  xls: icons.Xls,
  xlsx: icons.Xls,
  csv: icons.Xls,
  video: icons.Video,
  mp4: icons.Video,
  files: icons.Files,
};

const Icon = styled.span`
  width: 22px;
  height: 22px;
  margin-right: 5px;
`;

function FileCardIcon({ icon, ...rest }) {
  const FileIcon = fileIcons[icon];

  if (!FileIcon) {
    const FileIcon = fileIcons["files"];
    return (
      <Icon className="FileCard__Icon">
        <FileIcon {...rest} icon={icon} />
      </Icon>
    );
  }

  return (
    <Icon className="FileCard__Icon">
      <FileIcon {...rest} />
    </Icon>
  );
}

export default FileCardIcon;
