import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { Icon } from "@atd/components";
import { isAdmin } from "@atd/utils";

import { Customers, Payments, Settings } from "../../components/Icon/icons";

// import Logo from "./Logo";
// import Menu from "./Menu";
// import Profile from "./Profile";

import Sidebar from "./Sidebar";

import "./Sidebar.css";

function MainSidebar() {
  const { pathname } = useLocation();

  const { user: currentUser } = useSelector((state) => state.user);
  const { role } = currentUser || {};

  const reportsChildrens = [
    // {
    //   to: "/reports/feedback",
    //   name: "Customer",
    // },
    {
      to: "/reports/team",
      name: "Team",
    },
    // {
    //   to: "/reports/sales",
    //   name: "Sales",
    // },
  ];
  const allRequestsChildrens = [
    {
      to: "/requests/ifnoreply",
      name: "If No Reply",
      restricted: !isAdmin(),
      isAccessible: true,
    },
    {
      to: "/requests/sendlater",
      restricted: !isAdmin(),
      name: "Send Later",
      isAccessible: true,
    },
    {
      to: "/requests/subscriptionpaused",
      name: "Subscription Paused",
      restricted: !isAdmin(),
      isAccessible: true,
    },
  ];
  // Client Sidebar Menus
  const clientMenus = [
    {
      to: "/requests",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 16"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M14.871 9.472 13.2 2.685A.87.87 0 0 0 12.351 2H3.62a.873.873 0 0 0-.847.662l-1.672 6.81a3.49 3.49 0 0 0-.101.835v2.197c0 .964.782 1.746 1.746 1.746h10.48c.964 0 1.746-.782 1.746-1.746v-2.196c0-.282-.033-.563-.101-.836ZM3.964 3.334h8.047l1.397 5.677h-2.366a.654.654 0 0 0-.586.362l-.69 1.384h-3.56l-.69-1.384a.654.654 0 0 0-.586-.362H2.564l1.4-5.677Zm9.698 9.17c0 .24-.196.436-.437.436H2.746a.437.437 0 0 1-.436-.436V10.32h2.215l.692 1.384a.652.652 0 0 0 .586.363h4.366c.248 0 .475-.14.586-.363l.691-1.384h2.216v2.184Z"
          />
        </svg>
      ),
      name: "Requests",
      isAccessible: true,
    },
    {
      to: "/brands",
      icon: <Payments />,
      name: "Brands",
      isAccessible: true,
    },
    {
      to: "/teams",
      icon: <Icon icon="client" />,
      name: "Teams",
      isAccessible: true,
    },
    {
      to: "/settings/account",
      icon: <Settings />,
      name: "Settings",
      isAccessible: true,
    },
  ];
  // Admin Sidebar Menus

  const adminMenus = [
    {
      to: "/requests",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 16"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M14.871 9.472 13.2 2.685A.87.87 0 0 0 12.351 2H3.62a.873.873 0 0 0-.847.662l-1.672 6.81a3.49 3.49 0 0 0-.101.835v2.197c0 .964.782 1.746 1.746 1.746h10.48c.964 0 1.746-.782 1.746-1.746v-2.196c0-.282-.033-.563-.101-.836ZM3.964 3.334h8.047l1.397 5.677h-2.366a.654.654 0 0 0-.586.362l-.69 1.384h-3.56l-.69-1.384a.654.654 0 0 0-.586-.362H2.564l1.4-5.677Zm9.698 9.17c0 .24-.196.436-.437.436H2.746a.437.437 0 0 1-.436-.436V10.32h2.215l.692 1.384a.652.652 0 0 0 .586.363h4.366c.248 0 .475-.14.586-.363l.691-1.384h2.216v2.184Z"
          />
        </svg>
      ),
      name: "All Requests",
      isAccessible: true,
      children: allRequestsChildrens,
    },
    {
      to: "/clients",
      icon: <Icon icon="client" />,
      name: "Customer",
      restricted: isAdmin() && [6, 7].includes(role),
      isAccessible: true,
    },
    {
      to: "/activity",
      icon: <Customers />,
      name: "Activity Log",
      restricted: isAdmin() && [2, 6, 7].includes(role),
      isAccessible: true,
    },
    {
      to: "/settings/account",
      icon: <Settings />,
      name: "Settings",
      isAccessible: true,
    },
    {
      to: "/reports/team",
      icon: <Icon icon="reports" />,
      name: "Reports",
      isAccessible: false,
      isActive: reportsChildrens.map(({ to }) => to).includes(pathname),
      restricted: role !== 1,
      children: reportsChildrens,
    },
  ];

  const activeMenus = isAdmin() ? adminMenus : clientMenus;

  return <Sidebar items={activeMenus} />;

  // return (
  //   <div className="LeftNav_wrapper">
  //     <div className="LeftNav">
  //       {/* <Logo /> */}
  //       <Profile />
  //       <nav className="menu">
  //         <ul className="menuList">
  //           {activeMenus.length > 0 &&
  //             activeMenus.map((menuItem) => {
  //               return <Menu key={menuItem.name} {...menuItem} />;
  //             })}
  //         </ul>
  //       </nav>
  //     </div>
  //   </div>
  // );
}

export default MainSidebar;
