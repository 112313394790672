import { useState, useCallback, useEffect } from "react";

import axios from "@atd/app/axiosClient";

const useAttachment = (files, params) => {
  const [attachments, setAttachments] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (files) {
      setAttachments(files || []);
    } else {
      setAttachments([]);
    }
  }, [files]);
  const onSetAttachments = useCallback(
    async (currentFiles) => {
      if (!currentFiles) {
        return;
      }
      const formData = new FormData();
      formData.append("attach", currentFiles[0]);

      setProgress(0);
      setIsUploading(true);

      try {
        const { data } = await axios({
          url: "/attach",
          method: "POST",
          data: formData,
          params,
          onUploadProgress(progressEvent) {
            const percentCompleted =
              (progressEvent.loaded / progressEvent.total) * 100;
            setProgress(percentCompleted);

            if (percentCompleted === 100) {
              setTimeout(() => {
                setProgress(0);
              }, 1000);
            }
          },
        });

        if (data?.status === "error") {
          setIsUploading(false);
          let fileDatils = currentFiles[0];
          fileDatils = {
            ...fileDatils,
            error: true,
            name: fileDatils.path.split(".")[0],
            format: fileDatils.path.split(".")[1],
            full_name: fileDatils.path,
          };
          setAttachments([fileDatils, ...attachments]);
        }

        if (data.code === 201 && Object.keys(data.results).length > 0) {
          setAttachments([data.results, ...attachments]);
          setIsUploading(false);
        }
        return data;
      } catch (error) {
        setIsUploading(false);
        let fileDatils = currentFiles[0];
        fileDatils = {
          ...fileDatils,
          error: true,
          name: fileDatils.path.split(".")[0],
          format: fileDatils.path.split(".")[1],
          full_name: fileDatils.path,
        };
        setAttachments([fileDatils, ...attachments]);
      }

      return null;
    },
    [attachments, params],
  );

  const onRemoveAttachment = useCallback(
    (index) => {
      const updatedAttachments = [...attachments];
      if (index > -1) {
        updatedAttachments.splice(index, 1); // 2nd parameter means remove one item only
      }
      // const updatedAttachments = [
      //   ...attachments?.slice(0, index),
      //   ...attachments?.splice(index + 1),
      // ];
      setAttachments(updatedAttachments);
    },
    [attachments],
  );

  return {
    progress,
    attachments,
    isUploading,
    setAttachments,
    onRemoveAttachment,
    onSetAttachments,
  };
};

export default useAttachment;
