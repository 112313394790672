import * as React from "react";
function SvgFiles(props) {
  return (
    <svg
      id="text_svg__Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 240 234"
      style={{
        enableBackground: "new 0 0 240 234",
      }}
      xmlSpace="preserve"
      className="mxht"
      {...props}
    >
      <style>
        {".text_svg__st9{fill:#fefefe}.text_svg__st33{fill:#dadada}"}
      </style>
      <g id="text_svg___x30_04zhI_00000123405859764296315150000010072043425054068405_">
        <path
          className="text_svg__st9"
          d="M214.5 48.8c.5.6.3 1.3.3 2v169.5c0 5.7-4.1 9.8-9.8 9.8H51c-3.7 0-6.6-1.5-8.6-4.7-1-1.6-1.4-3.4-1.4-5.3V112.5c-.4-.7-.6-1.4-.6-2.2V98.5c0-.6 0-1.2.4-1.7s.9-.4 1.4-.5H150.9c3.1 0 3.1 0 3.1-3.2V42.9c0-2.8-.1-3-2.9-2.9-4.1.1-8.1-.2-12.2.2-1.5.1-2.9-.2-4.4-.2H49.7c-2.5 0-4.9.3-7.4.2-.6 0-1.3.1-1.4-.8 0-8.1.1-16.1 0-24.2-.1-5.6 4.5-10 10-10 39.6.1 79.2 0 118.8.1.5 0 1.1-.2 1.6.3.4.8.4 1.5.4 2.4-.1 2.2-.1 4.5-.1 6.8V33c0 2.6.2 5.2.3 7.8.2 3.6 3.7 7 7.4 7.5 1.8.2 3.5.3 5.3.3h24.6c1 0 2 .1 3-.2.8-.3 1.5.1 2.3.4z"
        />
        <path
          d="M40.9 39.4c1.5.3 3 .1 4.5.1H153.2c1.2 0 1.5.3 1.5 1.5v54.4c0 1.3-.3 1.6-1.6 1.6H40.9c-.4.4-1 .5-1.5.6H27.2c-.7 0-1.4-.2-1.9-.6-.4-.4-.2-.9-.2-1.4V41.4c0-1.6.1-1.7 1.7-1.7h11.7l2.4-.3z"
          style={{
            fill: "#5ba4e7",
          }}
        />
        <path
          d="M214.5 48.8c-1.3.1-2.6.2-3.8.2h-28.3c-3.4 0-6.5-.7-8.9-3.5-1.1-1.3-1.9-2.7-2.1-4.4-.2-1.6-.3-3.3-.3-4.9V8.8c0-1.1.2-2.1.2-3.2 3.6 3.5 7.2 7.1 10.7 10.6l31.6 31.6c.4.3.7.5.9 1z"
          style={{
            fill: "#dfdfdf",
          }}
        />
        <path
          d="M25.4 96.9c5.2 0 10.4 0 15.6.1 0 5.2 0 10.4-.1 15.6-1.4-.9-2.4-2.2-3.5-3.4-3.8-3.7-7.6-7.6-11.4-11.4-.2-.3-.6-.5-.6-.9z"
          style={{
            fill: "#295bb6",
          }}
        />
        <path
          className="text_svg__st33"
          d="M81.8 155.7c.4.6.6.9.9 1.1-.1.1-.2.1-.2.1-1.3-1.5-3-1.3-4.7-1.3h-5.9c-1.4 0-2.1-.5-1.8-1.8.3-1.7.2-3.3.2-4.9 0-1 .4-1.5 1.4-1.4H179c.8 0 1.4-.1 1.9-.8.3-.4.9-.9 1.6-.6.4.2.7.5.7 1 0 .7-.1 1.4-.9 1.7-.8.2-.8.9-.6 1.4.3.7 0 1.4.1 2 .2 1.2-.3 2.4.3 3.4-2.2.8-4.4.1-6.6.2-2.4.1-4.8-.1-7.2-.1H81.8zM80.9 166c0 .6-.6.7-.5 1.3 1.7.2 3.3.3 5 0 .9-.2 1.7.1 2.6.1h71.1c1.1 0 2.2.2 3.1-.8l.2-.1c1.5 1.8 1.9-.6 2.9-.8-.1.6-.8.8-.9 1.6 1.6 0 3.3.2 4.9 0 3-.4 5.9.1 8.8.1 3.6-.1 3.6 0 3.6 3.6 0 1-.1 1.9 0 2.9.1 1.2-.3 1.7-1.6 1.6-4.8-.1-9.6 0-14.4 0H71.6c-.9 0-1.3-.3-1.3-1.3 0-2 0-4.1-.1-6.1 0-.7.3-.7.8-.7 1.2 0 2.4-.1 3.6 0 1.3.1 2.7-.2 4-.1 1 .1 1.5-.8 2.3-1.3zM126 195.3H72.3c-2 0-2 0-2-2 0-1.6.1-3.1 0-4.7-.1-1.1.3-1.4 1.4-1.4h108.2c1.8 0 1.8 0 1.8 1.8v5.2c0 .8-.3 1.1-1.1 1.1H126zM126.1 135.9H72.2c-1.9 0-1.9 0-1.9-1.8v-4.6c0-1.6.1-1.7 1.7-1.7H179.7c2 0 2 0 2 2v4.9c0 .8-.3 1.2-1.1 1.2h-54.5z"
        />
      </g>
      <text
        transform="translate(59.172 78.12)"
        className="text_svg__st9"
        style={{
          letterSpacing: 2,
          fontSize: "31.4011px",
          fontFamily: "'Helvetica-Bold'",
        }}
      >
        {props.icon}
      </text>
    </svg>
  );
}

export default SvgFiles;
