import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Select, Box, CheckBox } from "@atd/components";
import { fetchTemplates } from "@atd/features";
import DatePicker from "./DatePicker";

const replyType = [
  { value: "remind me to follow up", id: "0" },
  { value: "enroll in a Workflow", id: "1" },
];

function SelectTemplateField({
  value,
  onChange,
  setDates,
  ifNoReplys,
  replyTypes,
  setTemplateSeqs,
}) {
  const [isBatch, setIsBatch] = useState(false);
  const [ifNoReply, setIfNoReply] = useState(false);

  const { templates } = useSelector((state) => state.template);
  const [replyMessageType, setReplyMessageType] = useState([]);
  const [selectType, setSelectType] = useState("");
  const [chooseDate, setChooseDate] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [templateSeq, setTemplateSeq] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTemplates());
  }, [dispatch]);

  useEffect(() => {
    setReplyMessageType(replyType);
    setDisabled(true);
  }, []);

  const templateOptions = Array.isArray(templates)
    ? templates?.map((template) => ({
        ...template,
        value: template.id,
        label: template.name,
      }))
    : [];

  const templateOptionsSeq = Array.isArray(templates)
    ? templates?.map((template) => ({
        ...template,
        value: template.id,
        label: template.name,
      }))
    : [];

  const templateOption = Array.isArray(replyMessageType)
    ? replyMessageType?.map((template) => ({
        ...template,
        value: template.id,
        label: template.value,
      }))
    : [];

  const onChangeDate = useCallback((value) => {
    setChooseDate(value);
    setDates(value);
  });

  return (
    <>
      <Box
        flex={{
          alignItems: "center",
          direction: "row",
          justifyContent: "flex-start",
          wrap: "nowrap",
        }}
      >
        <Box flex={{ alignItems: "center" }} className="cont2 pd-10">
          <CheckBox
            id="batch"
            checked={isBatch}
            onChange={() => setIsBatch((prevBatch) => !prevBatch)}
          />
          <label htmlFor="batch" style={{ marginLeft: 25, marginTop: 5 }}>
            Batch
          </label>
        </Box>
        <Box flex={{ alignItems: "center" }} className="cont2 pd-10">
          <CheckBox
            id="reply"
            checked={ifNoReply}
            onChange={() => {
              setIfNoReply((prevBatch) => {
                ifNoReplys(!prevBatch);
                return !prevBatch;
              });
              setDisabled((prevBatchs) => !prevBatchs);

              if (ifNoReply === true) {
                setSelectType("");
                replyTypes("");
              }
            }}
          />
          <label
            htmlFor="reply"
            style={{ marginLeft: 25, width: 65, marginTop: 5 }}
          >
            If no reply
          </label>
        </Box>
        <DatePicker
          position="up"
          className="btn-group snooze-btn-group action "
          toggleClassName="Btn Btn--mini"
          value={chooseDate}
          onChange={onChangeDate}
          disabled={disabled}
        />
        <div style={{ margin: "9px" }}>then</div>
        <div id="templ" style={{ minWidth: 215 }}>
          <Select
            value={selectType}
            size="medium"
            placeholder="Select Workflow"
            className="Box-root Flex-flex wt-auto"
            isDisabled={disabled}
            options={templateOption}
            hasSearchField={disabled}
            onChange={(templateValue) => {
              setSelectType(templateValue);
              replyTypes(templateValue);
            }}
          />
        </div>
        <div id="templ" style={{ minWidth: 215 }}>
          <Select
            value={value}
            size="medium"
            placeholder="Select Template"
            className="Box-root Flex-flex wt-auto"
            options={templateOptions}
            onChange={(templateValue) => {
              const template = templateOptions.find(
                (tmpl) => tmpl.id === templateValue,
              );
              onChange(template ?? templateValue);
            }}
            renderOption={({ label, subject }) => (
              <div className="templatelist">
                {label} <span dangerouslySetInnerHTML={{ __html: subject }} />
              </div>
            )}
          />
        </div>
      </Box>
      {selectType === "1" && (
        <Box
          flex={{
            alignItems: "center",
            direction: "row",
            justifyContent: "flex-start",
            wrap: "nowrap",
          }}
        >
          <div
            id="templ"
            style={{ minWidth: 215, padding: "3px 226px 7px 364px" }}
          >
            <Select
              value={templateSeq}
              size="medium"
              placeholder="Select Workflow"
              className="Box-root Flex-flex wt-auto"
              options={templateOptionsSeq}
              onChange={(templateValue) => {
                const template = templateOptionsSeq.find(
                  (tmpl) => tmpl.id === templateValue,
                );
                setTemplateSeq(template?.value);
                setTemplateSeqs(template);
              }}
              renderOption={({ label, subject }) => (
                <div className="templatelist">
                  {label} <span dangerouslySetInnerHTML={{ __html: subject }} />
                </div>
              )}
            />
          </div>
        </Box>
      )}
    </>
  );
}

export default SelectTemplateField;
