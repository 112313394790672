import { omit } from "lodash";
import uuid from "react-uuid";

import {
  Button,
  Box,
  DateFilter,
  GroupBy,
  SearchFilter,
  GroupByFilter,
} from "@atd/components";
import { useFiltersQuery } from "@atd/features/request";
import { getFilterOptions } from "@atd/utils";
import { isAdmin } from "@atd/utils";

import Status from "./Filters/Status";

function RequestFilters({
  params,
  onResetFilters,
  onChangeFilter,
  showStatus = false,
}) {
  const { data: filters, isSuccess } = useFiltersQuery(params?.status || "new");

  const {
    date: dateFilter,
    status: statusFilter,
    group: groupFilter,
    company: companyFilter,
    assigned_to_users: assignedToUsersFilter,
  } = filters || {};

  const dateFilterOptions = isSuccess ? getFilterOptions(dateFilter) : [];
  const statusFilterOptions = isSuccess ? getFilterOptions(statusFilter) : [];
  const groupFilterOptions = isSuccess ? getFilterOptions(groupFilter) : [];
  const companyFilterOptions = isSuccess
    ? getFilterOptions(companyFilter, "company")
    : [];
  const assignedToUsersOptions =
    isSuccess && Array.isArray(assignedToUsersFilter)
      ? assignedToUsersFilter?.map((userType) => ({
          id: uuid(),
          value: userType.title,
          options: userType.users.map((user) => ({
            id: user.id,
            name: user.name,
            value: user.name,
            icon: user.user_icon,
          })),
        }))
      : [];

  const hasFilters =
    params && Object.keys(omit(params, ["page", "status"])).length;
  return (
    <Box
      flex={{
        direction: "row",
        justifyContent: "space-between",
        alignItems: "center",
        wrap: "nowrap",
      }}
      className="ccss1"
    >
      <Box className="db-InlineFilterForm-bottom pointer2">
        <Box
          flex={{
            direction: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            wrap: "nowrap",
          }}
          className="mglmgt-8"
        >
          <DateFilter
            value={params?.date_string}
            startDate={params?.start_date}
            endDate={params?.end_date}
            options={dateFilterOptions}
            onChange={onChangeFilter}
          />

          {isAdmin() && showStatus && (
            <Status
              value={params?.filter_status}
              options={statusFilterOptions}
              onChange={onChangeFilter}
            />
          )}
          {isAdmin() && (
            <GroupBy
              field="group_by"
              placeholder="Group by"
              value={params?.group_by}
              options={groupFilterOptions}
              onChange={onChangeFilter}
            />
          )}

          {isAdmin() && params?.group_by === "designer" && (
            <GroupByFilter
              field="assigned_to"
              placeholder="Assign To"
              value={params?.assigned_to}
              options={assignedToUsersOptions}
              onChange={onChangeFilter}
            />
          )}
          {isAdmin() && params?.group_by === "company" && (
            <SearchFilter
              field="company_id"
              placeholder="Company"
              value={params?.company_id}
              options={companyFilterOptions}
              onChange={onChangeFilter}
            />
          )}
        </Box>
      </Box>
      {hasFilters ? (
        <Button variant="default" label="Clear all" onClick={onResetFilters} />
      ) : null}
    </Box>
  );
}

export default RequestFilters;
