import React, { useState, useEffect, useRef } from "react";
import Tippy from "@tippyjs/react/headless";
import { getTimeOptions } from "../../helpers";
import { SelectChecked } from "../../helpers/icons";
import useClientRect from "../../hooks/useClientRect";
import { Clock } from "../Icon/icons";
import prefixStyles from "../InputField/PrefixedInput/PrefixedInput.module.css";
import styles from "../Popover/Popover.module.css";
import moment from "moment";

import "./TimePickers.css";

export const TimePickers = ({
  disabled,
  value,
  onChange,
  onHide,
  visible,
  placeholder,
  onScheduleDate,
  disableDuration = false,
  appendTo = document.body,
  ...props
}) => {
  const timePickerRef = useRef(null);

  const [timeValue, setTimeValue] = useState("");
  const [timeInstance, setTimeInstance] = useState(null);
  const [optionList, setOptionList] = useState([]);

  const [rect, inputRef] = useClientRect();
  const { width } = rect;

  let optionsList = disableDuration ? getTimeOptions() : getTimeOptions(true);

  useEffect(() => {
    let options = disableDuration ? getTimeOptions() : getTimeOptions(true);
    setOptionList(options);
    if (visible) {
      timeInstance?.show();

      timePickerRef?.current?.focus();
    } else {
      timeInstance?.hide();
    }
  }, [visible, timeInstance, inputRef]);

  useEffect(() => {
    if (value !== "") {
      const selectedTime = optionsList.find((el) => el.value === value);
      setTimeValue(selectedTime?.label);
    } else {
      setTimeValue("");
    }
  }, [value, optionsList]);

  useEffect(() => {
    if (moment(new Date()).format("MM/DD/YYYY") === onScheduleDate) {
      onChange("");
      const list = optionsList.filter((list) =>
        moment(list.label, "h:mm A").isAfter(moment()),
      );
      console.log("funture time", list);
      setOptionList(list);
    } else {
      setOptionList(optionsList);
    }
  }, [onScheduleDate]);

  return (
    <Tippy
      trigger="click"
      placement={"bottom-start"}
      interactive={true}
      appendTo={appendTo}
      disabled={disabled}
      onShow={(instance) => {
        const SelectItems = timeInstance.popper.querySelectorAll(".SelectItem");
        [...SelectItems].forEach(function (item) {
          item.addEventListener("click", () => {
            timeInstance?.hide?.();
          });
        });
      }}
      onHide={(instance) => {
        const SelectItems = timeInstance.popper.querySelectorAll(".SelectItem");
        [...SelectItems].forEach(function (item) {
          item.removeEventListener("click", () => {
            timeInstance?.hide?.();
          });
        });
      }}
      // onHidden={onHide ? () => onHide() : null}
      onCreate={(instance) => {
        setTimeInstance(instance);
      }}
      render={(attrs) => (
        <div
          className={styles.popover}
          {...attrs}
          style={{ minWidth: width + 32 }}
        >
          <>
            <ul className="Select__list components_Menu_isScrollable TimeInput_menu">
              {optionList.length > 0 ? (
                optionList.map((option, i) => (
                  <li
                    key={i}
                    className={`SelectItem ${
                      value === option.value ? "components_Item_selected" : ""
                    }`}
                    onClick={() => onChange(option.value)}
                  >
                    {value === option.value ? (
                      <span>
                        <SelectChecked />
                      </span>
                    ) : null}
                    {option.label}
                  </li>
                ))
              ) : (
                <li className="Select__noMatches">No matches</li>
              )}
            </ul>
            <div className={styles.arrow} data-popper-arrow="true" />
          </>
        </div>
      )}
    >
      <div>
        <div className={prefixStyles.wrapper}>
          <span className={prefixStyles.prefix}>
            <span className="TimeInput_clockIcon">
              <Clock />
              {/* <Icon icon={baselineAccessTime} width="1rem" /> */}
            </span>
          </span>
          <input
            {...props}
            ref={inputRef}
            // readOnly
            type="text"
            className={prefixStyles.input}
            defaultValue={timeValue}
            disabled={disabled}
            placeholder={placeholder || "Time"}
            onChange={({ target }) => {
              const selectedTime = optionList.find(
                (el) => el.label === target.value,
              );
              if (selectedTime) {
                onChange(selectedTime.value);
              }
            }}
          />
          <span
            className={`${prefixStyles.fakeInput}${
              disabled ? " " + prefixStyles.fakeInputDisabled : ""
            }`}
          />
        </div>
      </div>
    </Tippy>
  );
};

export default TimePickers;
