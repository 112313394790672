import { useRef } from "react";
import "./AddNewFiles.css";
import { Spinner, FileCardIcon, Tooltip } from "@atd/components";

function AddNewFiles({
  files,
  uploadFiles,
  remove,
  submit,
  isLoading,
  isSubmit,
}) {
  const fileInputRef = useRef(null);
  const handleFileChange = (e) => {
    uploadFiles(e);
    fileInputRef.current.value = "";
  };

  return (
    <>
      <div
        className="pd16"
        style={{ maxHeight: "500px", overflowY: "auto", overflowX: "hidden" }}
      >
        {files?.length > 0 &&
          files.map((item, index) => (
            <div className="fileboxs" key={index}>
              <div className="flec">
                {item.image_type ? (
                  <>
                    <div
                      style={{
                        height: "50px",
                        width: "100px",
                        marginRight: "20px",
                      }}
                    >
                      {!item.support_image ? (
                        <FileCardIcon icon="files" />
                      ) : (
                        <img
                          src={item.image_url}
                          alt={item.file_name}
                          className="va1"
                          style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "cover",
                          }}
                        />
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      style={{
                        height: "50px",
                        width: "60px",
                        marginRight: "20px",
                      }}
                    >
                      <FileCardIcon icon={item.file_name.split(".")[1]} />
                    </div>
                  </>
                )}

                <Tooltip content={item?.file_name} placement="top">
                  <div className="file-name-upload"> {item.file_name}</div>
                </Tooltip>
              </div>
              {!isSubmit && (
                <span
                  onClick={() => remove(index)}
                  aria-hidden="true"
                  className="SVGInline SVGInline--cleaned SVG Icon Icon--cancel Icon-color Icon-color--gray600 va2 tsform2"
                >
                  <svg
                    aria-hidden="true"
                    className="SVGInline-svg SVGInline--cleaned-svg SVG-svg Icon-svg Icon--cancel-svg Icon-color-svg Icon-color--gray600-svg"
                    height="12"
                    width="12"
                    viewBox="0 0 16 16"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8 6.585l4.593-4.592a1 1 0 0 1 1.415 1.416L9.417 8l4.591 4.591a1 1 0 0 1-1.415 1.416L8 9.415l-4.592 4.592a1 1 0 0 1-1.416-1.416L6.584 8l-4.59-4.591a1 1 0 1 1 1.415-1.416z"
                      fillRule="evenodd"
                    ></path>
                  </svg>
                </span>
              )}
            </div>
          ))}
      </div>
      <div className="browbtn pd16">
        <div className="Box-root Flex-flex fltrgt but-grp">
          <div className="Box-root Box-hideIfEmpty Margin-right--8 pointer1">
            <button className="wt-but" disabled={isLoading}>
              <div className="Box-root"></div>
              <input
                disabled={isLoading || isSubmit}
                type="file"
                title=""
                name="ffileUploadIconInput-newCommentComposers-browse"
                id="fileUploadIconInput-newCommentComposers-browse"
                // accept="image/png,image/jpeg,image/gif"
                multiple=""
                className="file_upload_icon_input--fileInputs--qwRav"
                onChange={(e) => handleFileChange(e)}
                ref={fileInputRef}
              />
              {isLoading ? <Spinner /> : "+"} &nbsp; Add files
            </button>
          </div>

          <div className="Box-root Box-hideIfEmpty pointer1">
            <button
              style={{
                cursor: files?.length === 0 ? "not-allowed" : "pointer",
                backgroundColor: files?.length === 0 ? "#7ba4c2" : "#3781b8",
              }}
              className="bl-but"
              onClick={submit}
              disabled={files?.length === 0 || isSubmit || isLoading}
            >
              {isSubmit ? <Spinner /> : ""} &nbsp; Finish uploading
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
export default AddNewFiles;
