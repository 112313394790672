/* eslint-disable no-nested-ternary */
import { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
  fetchTeams,
  sendLaterUpDateStatusAsync,
  getAllRequestSendLaterAsync,
  searchRequest,
  getRequestsCount,
} from "@atd/features";
import { isAdmin, getCredentialCookies } from "@atd/utils";
import { Box, ContentState, AppHeader, Tabs, Tab } from "@atd/components";
import { useLocalStorage } from "@atd/hooks";

import AdminTabContent from "./TabContent/AdminTabContent";

import SendLaterFilters from "./SendLaterFilters";
import { tabList } from "./SendLaterTabOptions";

function SendLaterList({
  page,
  role,
  loading,
  params,
  isSearch,
  headerText,
  loaderRef,
  setIsResetData,
  onResetData,
  inifiniteScroll = true,
  onAddNewDesignRequest,
  renderAboveTitle,
}) {
  const { query } = useParams();

  const { user_id } = getCredentialCookies();

  const [requestFilters, setRequestFilters] = useLocalStorage(
    `${user_id}-send-later-filters`,
    {
      ...params,
      page: 1,
    },
  );

  const hasCustomParams = params && Object.keys(params).length > 0;

  const findTabIndex = tabList.find((t) => t.id === "sendlater");

  const [activeTab, setActiveTab] = useState(
    !findTabIndex || hasCustomParams ? params?.status : findTabIndex.id,
  );

  // const [activeTabIndex, setActiveTabIndex] = useState(
  //   findTabIndex === -1 || hasCustomParams ? 0 : findTabIndex,
  // );

  const [queryParams, setQueryParams] = useState(
    isAdmin()
      ? hasCustomParams
        ? params
        : requestFilters
      : { status: requestFilters?.status },
  );

  const { fetching, error, hasError, hasMore, requests } = useSelector(
    (state) => state.requestSendLater,
  );

  requestFilters.status = findTabIndex.id;
  const { teams } = useSelector((state) => state.team);
  const { counts } = useSelector((state) => state.requests);
  const dispatch = useDispatch();

  const [showFilters, setShowFilters] = useState(false);

  useEffect(() => {
    setShowFilters(true);
    setIsResetData(false);
    const realPage = page + 1;
    const promise = query
      ? dispatch(
          searchRequest({
            ...queryParams,
            search: query,
            page: inifiniteScroll ? realPage : 1,
          }),
        )
      : dispatch(
          getAllRequestSendLaterAsync({
            ...queryParams,
            page: inifiniteScroll ? realPage : 1,
          }),
        );

    promise.then(() => {
      setIsResetData(false);
      dispatch(
        getRequestsCount({
          ...queryParams,
        }),
      );
    });
    return () => {
      promise.abort();
    };
  }, [
    page,
    dispatch,
    queryParams,
    inifiniteScroll,
    setIsResetData,
    query,
    activeTab,
  ]);

  useEffect(() => {
    dispatch(
      fetchTeams({
        type: "accepted",
        dropdown: "yes",
        delete: "yes",
      }),
    );
    // setRequestFilters({
    //   status: isAdmin() ? findTabIndex.id : "",
    //   page: 1,
    // });
  }, [dispatch]);

  const onChangeFilter = (field, value) => {
    setIsResetData(true);
    onResetData();
    if (field === "group_by" && !["company", "designer"].includes(value)) {
      setQueryParams((prevParams) => ({
        ...prevParams,
        company_id: null,
        assigned_to: null,
        [field]: value,
      }));
    } else {
      setQueryParams((prevParams) => ({
        ...prevParams,
        [field]: value,
      }));
    }

    if (isAdmin() && !hasCustomParams) {
      setRequestFilters((prevParams) => ({
        ...prevParams,
        [field]: value,
      }));
    }
    // dispatch(getRequestsCount());
  };

  const onResetFilters = () => {
    setIsResetData(true);
    onResetData();
    if (isAdmin() && !hasCustomParams) {
      setActiveTab(findTabIndex.id);
      setRequestFilters({
        status: isAdmin() ? findTabIndex.id : "",
        page: 1,
        filter_status: null,
      });
    }
    setQueryParams({
      page: 1,
      status: isAdmin() ? findTabIndex.id : "",
    });
  };

  const isGroupBy = !!queryParams?.group_by;
  const hasErrors = hasError && error?.message !== "Aborted";

  const renderAdminContent = (options = {}) => {
    if (!isAdmin()) return null;

    const {
      hidePlan,
      hideAssignTo,
      showRating,
      showPriority,
      activeTab,
      handleClick,
      handleClicks,
      scheduleDate,
    } = options;

    return (
      <>
        <AdminTabContent
          data={requests}
          error={error}
          hasError={hasError}
          isGroupBy={isGroupBy}
          activeTabStatus={queryParams.status}
          fetching={fetching}
          users={teams}
          hidePlan={hidePlan ?? false}
          hideAssignTo={hideAssignTo ?? false}
          showRating={showRating ?? false}
          showPriority={showPriority}
          activeTab={activeTab}
          handleClick={handleClick}
          handleClicks={handleClicks}
          scheduleDate={scheduleDate}
        />
        {(hasMore || fetching) && !hasErrors && (
          <Box ref={loaderRef}>
            <ContentState text="Loading..." />
          </Box>
        )}
      </>
    );
  };

  const adminTabContentOptions = {
    hidePlan: true,
  };

  if (isSearch && isAdmin()) {
    return renderAdminContent(adminTabContentOptions);
  }

  const statusUpdateSendLater = async (item) => {
    let payload = {
      request_id: item?.request_id,
      reply_id: item?.id,
      is_done: 1,
      is_snooze: 0,
      is_snooze_data: {
        date_string: "",
        time_in_seconds: "",
      },
    };
    try {
      const response = await dispatch(
        sendLaterUpDateStatusAsync(payload),
      ).unwrap();
      if (response.status === "success") {
        dispatch(
          getRequestsCount({
            ...queryParams,
          }),
        );
      }
    } catch (err) {}
  };

  const scheduleDateTimerPicker = async (date, time, item) => {
    let payload = {
      request_id: item?.request_id,
      reply_id: item?.id,
      is_done: 0,
      is_snooze: 1,
      is_snooze_data: {
        date_string: date,
        time_in_seconds: time,
      },
    };
    try {
      await dispatch(sendLaterUpDateStatusAsync(payload)).unwrap();
    } catch (err) {}
  };

  return (
    <>
      <AppHeader
        title={headerText}
        spread="appContent"
        marginBottom={false}
        tabs={
          <Tabs activeTab={activeTab}>
            {tabList.map((item) => (
              <Tab
                key={item.id}
                tabTitle={item.value}
                tabSlug={item.id}
                className="tab-item capitalize"
                count={counts?.[item.id] && isAdmin() ? counts[item.id] : null}
              />
            ))}
          </Tabs>
        }
        renderAboveTitle={renderAboveTitle}
      ></AppHeader>

      {isAdmin() && showFilters && (
        <SendLaterFilters
          params={queryParams}
          setQueryParams={setQueryParams}
          onResetFilters={onResetFilters}
          onChangeFilter={onChangeFilter}
          // showStatus={showStatus}
        />
      )}

      {isAdmin() && (
        <>
          {activeTab === findTabIndex.id
            ? renderAdminContent({
                activeTab,
                handleClicks: statusUpdateSendLater,
                scheduleDate: scheduleDateTimerPicker,
              })
            : ""}
        </>
      )}
    </>
  );
}

export default SendLaterList;
