import "./PinUser.css";

function PinUser({ existsUser, onClickUser, options }) {
  const handleOptionClick = (index) => {
    const userName = options[index].user_name;
    onClickUser(userName);
  };
  return (
    <>
      {existsUser && (
        <div
          role="listbox"
          tabIndex="-1"
          className="typeahead--typeahead mentions_typeahead--mentionsTypeahead mentions_typeahead--commentsMentionsTypeaheadUp"
          data-testid="comment-mentions-typeahead"
          style={{ left: "39px", width: "250px" }}
        >
          <div style={{ height: "6px" }}></div>
          {options.map((option, index) => (
            <div
              key={index}
              role="option"
              tabIndex="-1"
              className="mentions_typeahead--mentionsTypeaheadRow 
                   mentions_typeahead--mentionsTypeaheadRowSelected--AEr6h
                  "
              onClick={() => handleOptionClick(index)}
            >
              <div className="mentions_typeahead--avatar">
                <div className="avatar--avatar avatar--circle">
                  {/* <img
                    data-testid="image-avatar"
                    className=""
                    src={option.avatar}
                    alt=""
                  /> */}
                  {option.firstLetter}
                </div>
              </div>
              <div className="mentions_typeahead--profile">
                <div className="mentions_typeahead--name--08XKn mentions_typeahead--profileItem">
                  {option.user_name}
                </div>
                <div
                  className="mentions_typeahead--metadata mentions_typeahead--profileItem"
                  data-testid="mention-typeahead-metadata"
                >
                  <span className="mentions_typeahead--email"></span>
                </div>
              </div>
            </div>
          ))}
          <div style={{ height: "6px" }}></div>
        </div>
      )}
    </>
  );
}
export default PinUser;
