// Annotation.js
import React, { useRef, useState, useEffect } from "react";
import { PinOut } from "@atd/components";

const Annotation = ({
  annotation,
  x,
  y,
  z,
  newComment,
  children,
  onUpdate,
  onClicked,
  mainIndex,
}) => {
  const badgeRef = useRef({});
  const dragRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState({ x, y, z });
  const [firstLetter, setFirstLetter] = useState(null);
  const [message, setMessage] = useState(null);
  const [totalMessage, setTotalMessage] = useState(null);
  const [parentRectWidth, setParentRectWidth] = useState(null);

  useEffect(() => {
    if (!annotation) return;

    const updateParentRectWidth = () => {
      if (badgeRef.current[annotation.uniqueId]?.parentElement) {
        const parentRect =
          badgeRef.current[
            annotation.uniqueId
          ].parentElement.getBoundingClientRect();
        setParentRectWidth(parentRect.width);
      }
    };

    updateParentRectWidth();

    if (annotation.isClick && badgeRef.current[annotation.uniqueId]) {
      badgeRef.current[annotation.uniqueId].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }

    setPosition({ x, y, z });
    setTotalMessage(annotation?.list?.length);
    setMessage(annotation?.list[0]);
    setFirstLetter(getUniqueUserNamesFromList(annotation?.list));
  }, [annotation, x, y, z]);

  useEffect(() => {
    const handleDrag = (event) => {
      event.preventDefault();
      if (!badgeRef.current[annotation.uniqueId]?.parentElement) return;

      const parentRect =
        badgeRef.current[
          annotation.uniqueId
        ].parentElement.getBoundingClientRect();
      const imageRect =
        badgeRef.current[
          annotation.uniqueId
        ].parentElement.firstChild.getBoundingClientRect();

      const dragElementWidth = dragRef.current.offsetWidth;
      const dragElementHeight = dragRef.current.offsetHeight;

      const newX = Math.max(
        imageRect.left - parentRect.left,
        Math.min(
          imageRect.right - parentRect.left - dragElementWidth + 32,
          event.clientX - parentRect.left - dragElementWidth / 2,
        ),
      );

      const newY = Math.max(
        imageRect.top - parentRect.top,
        Math.min(
          imageRect.bottom - parentRect.top - dragElementHeight,
          event.clientY - parentRect.top - dragElementHeight / 2,
        ),
      );

      const offsetFromRight = parentRect.width - (newX + dragElementWidth / 2);

      const newXPercent = (newX / parentRect.width) * 100;
      const newYPercent = (newY / parentRect.height) * 100;
      const offsetFromRightPercent = (offsetFromRight / parentRect.width) * 100;

      const updatedPosition = {
        x: newXPercent,
        y: newYPercent,
        z: offsetFromRightPercent,
      };

      setPosition(updatedPosition);
      onUpdate(updatedPosition);
    };

    const handleDragEnd = () => {
      setIsDragging(false);
      document.removeEventListener("mousemove", handleDrag);
      document.removeEventListener("mouseup", handleDragEnd);
    };

    if (isDragging) {
      document.addEventListener("mousemove", handleDrag);
      document.addEventListener("mouseup", handleDragEnd);
    }

    return () => {
      document.removeEventListener("mousemove", handleDrag);
      document.removeEventListener("mouseup", handleDragEnd);
    };
  }, [isDragging, annotation, onUpdate]);

  const getUniqueUserNamesFromList = (list) => {
    const uniqueUsers = list.reduce(
      (accumulator, comment) => {
        const { user_name, user_id, isSubmit } = comment;
        if (
          isSubmit &&
          !(user_id in accumulator.uniqueUserIds) &&
          user_name !== undefined
        ) {
          accumulator.uniqueUserIds[user_id] = true;
          const firstLetter = user_name.charAt(0).toUpperCase();
          accumulator.users.push({ user_id, user_name, firstLetter });
        }
        return accumulator;
      },
      { uniqueUserIds: {}, users: [] },
    );

    return uniqueUsers.users;
  };

  const calculateTopPosition = ({ y }, newComment, isOnLeftHalf) =>
    y <= 50
      ? `${y + (newComment ? (y <= 20 ? 9 : -10) : y >= 8 ? -1 : 2)}%`
      : newComment
      ? `${
          y < 50
            ? y - 30
            : y > 60 && y < 70
            ? y - 30
            : y > 70 && y < 80
            ? y - 40
            : y - 20
        }%`
      : y < 50
      ? `${y + (newComment ? 10 : -1)}%`
      : `${y + (newComment ? 0 : -4)}%`;

  const getDivStyle = () => {
    if (!badgeRef.current[annotation.uniqueId]?.parentElement) return {};

    const isOnLeftHalf = position.x > 65;
    const leftPosition = isOnLeftHalf
      ? "auto"
      : !newComment
      ? `${position.x + 1}%`
      : `${position.x - 1}%`;

    console.log("position", position, newComment);

    const topPosition = calculateTopPosition(
      position,
      newComment,
      isOnLeftHalf,
    );

    const containerWidth = parentRectWidth;
    const positionInPixels = position.x + position.z;
    const positionInPercentage = (positionInPixels / containerWidth) * 100;

    const rightPosition = !newComment
      ? `${positionInPercentage * 0.5 + position.z}%`
      : isOnLeftHalf
      ? `${positionInPercentage * 4.7 + position.z}%`
      : "auto";

    return {
      position: "absolute",
      top: topPosition,
      right: rightPosition,
      left: leftPosition,
      color: "white",
      fontSize: "14px",
      cursor: "grab",
      zIndex: 999,
      height: "90px",
      transition: "transform .1s linear, -webkit-transform .1s linear",
    };
  };

  const onDrag = () => {
    if (annotation?.isDrag) setIsDragging(true);
  };

  return (
    <>
      {annotation.isShow && (
        <>
          <div
            ref={(el) => (badgeRef.current[annotation.uniqueId] = el)}
            key={mainIndex}
            style={{
              position: "absolute",
              top: `${position.y}%`,
              left: `${position.x}%`,
              color: "white",
              fontSize: "14px",
              cursor: annotation?.isDrag ? "grab" : "auto",
              zIndex: 995,
              transition: "transform .1s linear, -webkit-transform .1s linear",
            }}
            className={newComment === false ? "anchor-commentPinIcon" : ""}
            onMouseDown={onDrag}
          >
            <div
              ref={dragRef}
              style={{
                width: "100%",
                height: "100%",
                alignContent: "center",
                cursor: "pointer",
              }}
            >
              <PinOut
                mainIndex={mainIndex}
                firstLetter={firstLetter}
                onClicked={onClicked}
                isClick={annotation.isClick}
                message={message}
                totalMessage={totalMessage}
              />
            </div>
          </div>
          <div style={getDivStyle()}>{children}</div>
        </>
      )}
    </>
  );
};

export default Annotation;
