import { useEffect, useState, useMemo, useCallback, useRef } from "react";
import { Dropdown, MoreButton, SkeletonLoader, Tooltip } from "@atd/components";
import { TimeAgo, readStatus, getCredentialCookies } from "@atd/utils";
import { toast } from "react-toastify";
import moment from "moment";
import "./LeftPanel.css";

import "../MiddlePanel/MiddelPanel.css";

const LeftPanel = ({
  file,
  isLoading,
  rolePermissions,
  annotation,
  onClickMessgae,
  onUpdateAnnotation,
  onUpdateStatus,
  onResolved,
  onDeleteThread,
  onSideNav,
  sideNavAction,
}) => {
  const [activeTab, setActiveTab] = useState("active");
  const [annotationList, setAnnotationList] = useState([]);
  const [expandedTasks, setExpandedTasks] = useState({});
  const [groupedByPageId, setGroupedByPageId] = useState([]);
  const [initialLoad, setInitialLoad] = useState(true);

  const itemRefs = useRef([]);

  const actionMenus = useMemo(
    () => [
      {
        id: 0,
        value: "Mark as unread",
        enable: rolePermissions?.change_status,
      },
      {
        id: 1,
        value: "Mark as read",
        enable: rolePermissions?.change_status,
      },
      // { id: 2, value: "Copy link", enable: true },
      {
        id: 3,
        value: "Delete thread",
        enable: rolePermissions?.delete_thread,
      },
    ],
    [rolePermissions?.change_status, rolePermissions?.delete_thread],
  );

  useEffect(() => {
    if (annotation?.length > 0) {
      console.log("leftPannel", annotation);
      const storedAnnotations = annotation.flat().reduce((acc, ele) => {
        if (Array.isArray(ele.annotation)) {
          ele.annotation.forEach((anno) => {
            if (Array.isArray(anno.comments) && anno.comments.length > 0) {
              acc.push(
                ...anno.comments.map((_) => {
                  return {
                    ..._,
                    attachment_full_name: anno.attachment_full_name,
                    _attachment_id: anno._attachment_id,
                  };
                }),
              );
            }
          });
        }
        return acc;
      }, []);
      storedAnnotations.sort((a, b) => {
        return new Date(a.modified_date) - new Date(b.modified_date);
      });
      const storedAnnotationes = [...storedAnnotations];
      const groupedByPageId = storedAnnotationes.reduce((acc, item) => {
        if (!acc[item.page_Id]) {
          acc[item.page_Id] = {
            page_Id: item.page_Id,
            attachment_full_name: item.attachment_full_name,
            uniqueId: item.uniqueId,
            commentes: [],
          };
        }
        // Add comments from the item
        acc[item.page_Id].commentes.push(item);

        return acc;
      }, {});

      setGroupedByPageId(Object.values(groupedByPageId));
      console.log(
        "groupCode",
        groupedByPageId,
        Object.values(groupedByPageId),
        storedAnnotations,
      );

      const filterData = storedAnnotations.find((ele) => ele.isClick);
      console.log("expandedTasks", expandedTasks);

      if (filterData && itemRefs.current[filterData.uniqueId]) {
        itemRefs.current[filterData.uniqueId].scrollIntoView({
          behavior: "smooth",
        });
      }

      setAnnotationList(storedAnnotations);
    }
  }, [annotation]);

  useEffect(() => {
    if (initialLoad && groupedByPageId?.length > 0 && !isLoading) {
      groupedByPageId.forEach((taskId) => {
        setExpandedTasks((prev) => ({
          ...prev,
          [taskId.uniqueId]: !prev[taskId.uniqueId],
        }));
      });
      setInitialLoad(false);
    }
  }, [groupedByPageId, initialLoad, isLoading]);

  const getUniqueUserNamesFromList = (list) => {
    const uniqueUsers = list.reduce(
      (accumulator, comment) => {
        const { user_name, user_id, isSubmit } = comment;
        if (
          isSubmit &&
          !(user_name in accumulator.uniqueUserNames) &&
          !(user_id in accumulator.uniqueUserNames) &&
          user_name !== undefined
        ) {
          accumulator.uniqueUserNames[user_id] = user_name;
          const firstLetter = user_name.charAt(0).toUpperCase();
          accumulator.users.push({ user_id, user_name, firstLetter });
        }
        return accumulator;
      },
      { uniqueUserNames: {}, users: [] },
    );

    return uniqueUsers.users;
  };

  const highlightMentions = (text) => {
    return text.replace(
      /(@\w+\s+\w+)/g,
      '<span style="color: blue;">$1</span>',
    );
  };

  const actionMenu = (item) => {
    const isRead = readStatus(item)?.read_status?.read;
    console.log("actionMenu2344", item, isRead);
    const menu = actionMenus.reduce((acc, curr) => {
      if (
        ((isRead && curr.id !== 1) || (!isRead && curr.id !== 0)) &&
        curr.enable
      ) {
        acc.push(curr);
      }
      if (curr.id === 3 && !item.pin_id) {
        acc.push(curr);
      }
      return acc;
    }, []);

    const uniqueData = menu.filter(
      (item, index, self) => index === self.findIndex((t) => t.id === item.id),
    );
    return uniqueData;
  };

  const actionMenuHandler = useCallback(
    async (item, actionId, uniqueId) => {
      const findValue = actionMenus.find((ele) => ele.id === actionId);
      if (!findValue) {
        console.error("No matching action found for actionId:", actionId);
        return;
      }
      if (findValue.id === 0 || findValue.id === 1) {
        const filterData = annotationList.filter(
          (ele) => ele.page_Id === item.page_Id,
        );
        const updatedAnnotations = filterData.map((ele, i) => {
          if (ele.uniqueId === uniqueId) {
            const dynamicKey = readStatus(ele)?.param.read_status;
            return {
              ...ele,
              [dynamicKey]: {
                read: findValue.id === 1 ? true : false,
                read_date:
                  findValue.id === 1
                    ? new Date()
                    : readStatus(ele)?.read_status?.read_date,
              },
            };
          }
          return ele;
        });
        console.log("updatedAnnotations", updatedAnnotations);

        const object = file.find((_) => _.page_Id === item.page_Id);
        onUpdateStatus(
          updatedAnnotations,
          object.attachment_id,
          object.page_Id,
        );
      } else if (findValue.id === 3) {
        const filterData = annotationList.filter(
          (ele) => ele.page_Id === item.page_Id,
        );
        const object = file.find((_) => _.page_Id === item.page_Id);
        onDeleteThread(
          filterData,
          object.attachment_id,
          object.page_Id,
          uniqueId,
        );
      } else {
        const currentUrl = window.location.href;
        navigator.clipboard
          .writeText(currentUrl)
          .then(() => {
            toast.success("Link to selection copied to clipboard");
            console.log("URL copied to clipboard!");
          })
          .catch((err) => {
            toast.message("Failed to copy the Link");
            console.error("Failed to copy the URL: ", err);
          });
      }
    },
    [actionMenus, annotationList, file, onDeleteThread, onUpdateStatus],
  );

  const onClick = useCallback(
    (value) => {
      annotationList.map((ele) => (ele.isClick = false));
      const filterData = annotationList.filter(
        (ele) => ele.page_Id === value.page_Id,
      );
      const annotations = filterData.map((item) =>
        item.uniqueId === value.uniqueId ? { ...item, isClick: true } : item,
      );
      const object = file.find((ele) => ele.page_Id === value.page_Id);
      onClickMessgae(
        annotations,
        object.attachment_id,
        object.page_Id,
        activeTab,
      );
    },
    [activeTab, annotationList, file, onClickMessgae],
  );

  const onResolve = async (value) => {
    annotationList.map((ele) => (ele.isClick = false));
    const filterData = annotationList.filter(
      (ele) => ele.page_Id === value.page_Id,
    );
    const updateList = filterData.map((item) =>
      item.uniqueId === value.uniqueId
        ? {
            ...item,
            resolved: {
              resolve: !item.resolved.resolve,
              resolve_date: item.resolved.resolve_date,
            },
            isClick: false,
          }
        : { ...item, isClick: false },
    );
    const object = file.find((ele) => ele.page_Id === value.page_Id);
    onResolved(
      updateList,
      object.attachment_id,
      object.page_Id,
      value.uniqueId,
    );
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    if (tab === "active") {
      const check_role = ["1", "2", "6"].includes(getCredentialCookies()?.role);
      rolePermissions.resolve = check_role;
      rolePermissions.un_resolve = false;
    } else if (tab === "resolved") {
      const check_role = ["3", "5"].includes(getCredentialCookies()?.role);
      rolePermissions.un_resolve = check_role;
      rolePermissions.resolve = false;
    }

    annotationList.map((ele) => (ele.isClick = false));
    const object = file.find((_) => _.isSelect);
    const filterData = annotationList.filter(
      (ele) => ele.page_Id === object.page_Id,
    );
    onUpdateAnnotation(filterData, object.attachment_id, object.page_Id, tab);
  };

  const toggleExpand = (taskId) => {
    setExpandedTasks((prev) => ({
      ...prev,
      [taskId]: !prev[taskId],
    }));
  };

  return (
    <>
      <div className={`column ${sideNavAction ? "collapsed" : "leftcol"}`}>
        <div className="thread-sidebar-column">
          <div className="thread-list-top-nav pause-comments">
            <div className="thread-list-top-nav__lower-element">
              <div className="thread-list-tabs pause-comments">
                <div className="thread-list-tabs__option">
                  <div
                    className={`thread-list-tabs__option ${
                      activeTab === "active"
                        ? file?.some(
                            (list) =>
                              list.annotation === true &&
                              list.isSelect === true,
                          )
                          ? "thread-list-tabs__option--selected"
                          : ""
                        : ""
                    }`}
                    style={{
                      cursor: file?.some(
                        (list) =>
                          list.annotation === false && list.isSelect === true,
                      )
                        ? "not-allowed"
                        : "pointer",
                    }}
                    onClick={() => {
                      if (
                        !file?.some(
                          (list) =>
                            list.annotation === false && list.isSelect === true,
                        )
                      ) {
                        handleTabClick("active");
                      }
                    }}
                  >
                    <div className="thread-list-tabs__option-text">
                      {!isLoading && (
                        <span className="thread-list-tabs__option-count">
                          {file.some(
                            (item) => item.isSelect && !item?.annotation,
                          )
                            ? "0"
                            : annotationList.reduce((acc, item) => {
                                const filtered = item.list.filter(
                                  (ele) =>
                                    (ele?.comment !== "" ||
                                      ele?.attachments?.length !== 0) &&
                                    ele?.isSubmit === true,
                                );

                                if (
                                  filtered.length > 0 &&
                                  item.resolved.resolve === false
                                ) {
                                  return acc + 1;
                                }

                                return acc;
                              }, 0)}
                        </span>
                      )}{" "}
                      Active
                    </div>
                  </div>
                </div>
                <div className="thread-list-tabs__option">
                  <div
                    className={`thread-list-tabs__option ${
                      activeTab === "resolved"
                        ? file?.some(
                            (list) =>
                              list.annotation === true &&
                              list.isSelect === true,
                          )
                          ? "thread-list-tabs__option--selected"
                          : ""
                        : ""
                    }`}
                    style={{
                      cursor: file?.some(
                        (list) =>
                          list.annotation === false && list.isSelect === true,
                      )
                        ? "not-allowed"
                        : "pointer",
                    }}
                    onClick={() => {
                      if (
                        !file?.some(
                          (list) =>
                            list.annotation === false && list.isSelect === true,
                        )
                      ) {
                        handleTabClick("resolved");
                      }
                    }}
                  >
                    <div className="thread-list-tabs__option-text">
                      {!isLoading && (
                        <span className="thread-list-tabs__option-count">
                          {file.some(
                            (item) => item.isSelect && !item?.annotation,
                          )
                            ? "0"
                            : annotationList.reduce((acc, item) => {
                                const filtered = item.list.filter(
                                  (ele) =>
                                    (ele?.comment !== "" ||
                                      ele?.attachments?.length !== 0) &&
                                    ele?.isSubmit === true,
                                );

                                if (
                                  filtered.length > 0 &&
                                  item.resolved.resolve === true
                                ) {
                                  return acc + 1;
                                }

                                return acc;
                              }, 0)}
                        </span>
                      )}{" "}
                      Resolved
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="thread-list">
            <div className="thread-list-group open">
              <div
                style={{
                  height: "92vh",
                  opacity: 1,
                  overflowY: "auto",
                  paddingBottom: "200px",
                }}
              >
                {isLoading && (
                  <>
                    <SkeletonLoader />
                  </>
                )}
                {annotationList.length > 0 &&
                  !isLoading &&
                  file.some((item) => item.isSelect && item?.annotation) &&
                  (activeTab === "active" ? (
                    <>
                      {groupedByPageId
                        .filter((element) =>
                          element.commentes.some(
                            (item) =>
                              item.list.some(
                                (ele) =>
                                  (ele?.comment || ele?.attachments?.length) &&
                                  ele?.isSubmit === true,
                              ) && item.resolved.resolve === false,
                          ),
                        )
                        .map((task, i) => (
                          <>
                            <div
                              key={i}
                              className="task-item"
                              style={{
                                borderBottom: !expandedTasks[task.uniqueId]
                                  ? "1px solid #e9ebec"
                                  : "",
                              }}
                            >
                              <div
                                className="task-header"
                                onClick={() => toggleExpand(task.uniqueId)}
                              >
                                <div className="left">
                                  <Tooltip
                                    content={task.attachment_full_name}
                                    placement="bottom"
                                  >
                                    <span className="task-list-file-name">
                                      {String(task.page_Id + 1).padStart(
                                        2,
                                        "0",
                                      )}
                                      {"."} {task.attachment_full_name}
                                    </span>
                                  </Tooltip>
                                </div>
                                <div className="right">
                                  <span className="thread-counter">
                                    {
                                      task?.commentes.filter(
                                        (item) =>
                                          item.list.some(
                                            (ele) =>
                                              (ele?.comment ||
                                                ele?.attachments?.length) &&
                                              ele?.isSubmit === true,
                                          ) && item.resolved.resolve === false,
                                      )?.length
                                    }
                                  </span>
                                  <button
                                    className={
                                      expandedTasks[task.uniqueId]
                                        ? "expanded"
                                        : "unexpanded"
                                    }
                                  >
                                    <svg
                                      viewBox="0 0 16 17"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="icon thread-list-item-group-header-toggle-button open"
                                      width="16"
                                      height="16"
                                    >
                                      <path
                                        d="m13 10.884-4.73-4.88a.37.37 0 0 0-.27-.12.36.36 0 0 0-.27.12L3 10.884"
                                        stroke="#000"
                                        strokeWidth="1.2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </button>
                                </div>
                              </div>
                              {expandedTasks[task.uniqueId] && (
                                <div className="accor__item-question">
                                  {task.commentes
                                    .filter(
                                      (item) =>
                                        item.list.some(
                                          (ele) =>
                                            (ele?.comment ||
                                              ele?.attachments?.length) &&
                                            ele?.isSubmit === true,
                                        ) && item.resolved.resolve === false,
                                    )
                                    .map((item, index) => (
                                      <>
                                        <div
                                          className={`thread-list-item-container ${
                                            item.isClick ? "active" : "inactive"
                                          }`}
                                          ref={(el) =>
                                            (itemRefs.current[item.uniqueId] =
                                              el)
                                          }
                                        >
                                          <div className="thread-list-item-content closed">
                                            <div className="thread-list-item closed">
                                              <div
                                                onClick={() => onClick(item)}
                                              >
                                                <div
                                                  style={{ display: "flex" }}
                                                >
                                                  {getUniqueUserNamesFromList(
                                                    item.list,
                                                  ).map((user, userIndex) => (
                                                    <>
                                                      {!readStatus(item)
                                                        ?.read_status?.read && (
                                                        <span className="comments_row_presentation--blueDot--n7bLI" />
                                                      )}
                                                      <span
                                                        className="thread-label"
                                                        style={{
                                                          marginRight: "-17px",
                                                          zIndex: "999",
                                                        }}
                                                      >
                                                        {user.firstLetter}
                                                      </span>
                                                    </>
                                                  ))}
                                                </div>
                                                <div className="thread-list-item-message-column">
                                                  <div className="thread-list-item-message-column-header">
                                                    <span
                                                      className="message-author"
                                                      title={
                                                        item.list[0].user_name
                                                      }
                                                    >
                                                      {item.list[0].user_name}
                                                    </span>
                                                    <Tooltip
                                                      content={moment(
                                                        item.modified_date,
                                                      ).format(
                                                        "MMM DD, YYYY hh:mm A",
                                                      )}
                                                      placement="top"
                                                    >
                                                      <div className="message-time">
                                                        <span>
                                                          <TimeAgo
                                                            timestamp={
                                                              item.modified_date
                                                            }
                                                          />
                                                        </span>
                                                      </div>
                                                    </Tooltip>
                                                  </div>
                                                  <div>
                                                    <p className="fnt12 truncated-text">
                                                      <span
                                                        dangerouslySetInnerHTML={{
                                                          __html:
                                                            highlightMentions(
                                                              item.list[0]
                                                                .comment,
                                                            ),
                                                        }}
                                                      />
                                                    </p>
                                                  </div>
                                                  {item.list.length - 1 > 0 && (
                                                    <div>
                                                      <p
                                                        className="fnt12"
                                                        style={{
                                                          color: item.isClick
                                                            ? "#0047ff"
                                                            : "#00000080",
                                                          paddingTop: "5px",
                                                        }}
                                                      >
                                                        {item.list.length - 1}{" "}
                                                        {item.list.length === 2
                                                          ? "reply"
                                                          : "replies"}
                                                      </p>
                                                    </div>
                                                  )}
                                                </div>
                                                <div className="thread-list-item-notes-container" />
                                              </div>

                                              <div className="message-toolbar">
                                                {actionMenu(item)?.length >
                                                  0 && (
                                                  <div className="dropdown-container thread-list-item-more-dropdown-wrapper">
                                                    <div className="dropdown">
                                                      <Dropdown
                                                        as={MoreButton}
                                                        onClick={() =>
                                                          onClick(item)
                                                        }
                                                      >
                                                        <Dropdown.Options>
                                                          {actionMenu(item).map(
                                                            (action) => (
                                                              <Dropdown.Option
                                                                key={action.id}
                                                                value={
                                                                  action.id
                                                                }
                                                                onClick={() =>
                                                                  actionMenuHandler(
                                                                    item,
                                                                    action.id,
                                                                    item.uniqueId,
                                                                  )
                                                                }
                                                              >
                                                                {action.value}
                                                              </Dropdown.Option>
                                                            ),
                                                          )}
                                                        </Dropdown.Options>
                                                      </Dropdown>
                                                    </div>
                                                  </div>
                                                )}

                                                {rolePermissions?.resolve &&
                                                  rolePermissions?.finish_review && (
                                                    <Tooltip
                                                      content={
                                                        "Mark as resolved"
                                                      }
                                                      placement="bottom"
                                                    >
                                                      <button
                                                        className="resolve-button resolve-button--unresolved thread-header__icon"
                                                        onClick={() =>
                                                          onResolve(item)
                                                        }
                                                      >
                                                        <div className="resolve-button__flares">
                                                          <span />
                                                          <span />
                                                          <span />
                                                          <span />
                                                          <span />
                                                          <span />
                                                        </div>
                                                        <span className="check">
                                                          <svg
                                                            viewBox="0 0 16 16"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            className="icon"
                                                            width="16"
                                                            height="16"
                                                          >
                                                            <path
                                                              d="m1.5 8 3.641 4.552a.5.5 0 0 0 .754.031L14.5 3.5"
                                                              stroke="#000"
                                                              strokeWidth="1.2"
                                                              strokeLinecap="round"
                                                            />
                                                          </svg>
                                                        </span>
                                                      </button>
                                                    </Tooltip>
                                                  )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="thread-list-group-separator" />
                                      </>
                                    ))}
                                </div>
                              )}
                            </div>
                          </>
                        ))}
                    </>
                  ) : activeTab === "resolved" ? (
                    <>
                      {groupedByPageId
                        .filter((element) =>
                          element.commentes.some(
                            (element) =>
                              element.list.some(
                                (ele) =>
                                  (ele?.comment || ele?.attachments?.length) &&
                                  ele?.isSubmit === true,
                              ) && element.resolved.resolve === true,
                          ),
                        )
                        .map((task, i) => (
                          <>
                            <div
                              key={i}
                              className="task-item"
                              style={{
                                borderBottom: !expandedTasks[task.uniqueId]
                                  ? "1px solid #e9ebec"
                                  : "",
                              }}
                            >
                              <div
                                className="task-header"
                                onClick={() => toggleExpand(task.uniqueId)}
                              >
                                <div className="left">
                                  <Tooltip
                                    content={task.attachment_full_name}
                                    placement="bottom"
                                  >
                                    <span className="task-list-file-name">
                                      {String(task.page_Id + 1).padStart(
                                        2,
                                        "0",
                                      )}
                                      {"."} {task.attachment_full_name}
                                    </span>
                                  </Tooltip>
                                </div>
                                <div className="right">
                                  <span className="thread-counter">
                                    {
                                      task?.commentes.filter(
                                        (item) =>
                                          item.list.some(
                                            (ele) =>
                                              (ele?.comment ||
                                                ele?.attachments?.length) &&
                                              ele?.isSubmit === true,
                                          ) && item.resolved.resolve === true,
                                      )?.length
                                    }
                                  </span>
                                  <button
                                    className={
                                      expandedTasks[task.uniqueId]
                                        ? "expanded"
                                        : "unexpanded"
                                    }
                                  >
                                    <svg
                                      viewBox="0 0 16 17"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="icon thread-list-item-group-header-toggle-button open"
                                      width="16"
                                      height="16"
                                    >
                                      <path
                                        d="m13 10.884-4.73-4.88a.37.37 0 0 0-.27-.12.36.36 0 0 0-.27.12L3 10.884"
                                        stroke="#000"
                                        strokeWidth="1.2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </button>
                                </div>
                              </div>
                            </div>

                            {task.commentes
                              .filter(
                                (element) =>
                                  element.list.some(
                                    (ele) =>
                                      (ele?.comment ||
                                        ele?.attachments?.length) &&
                                      ele?.isSubmit === true,
                                  ) && element.resolved.resolve === true,
                              )
                              .map((item, index) => (
                                <div
                                  key={index}
                                  ref={(el) =>
                                    (itemRefs.current[item.uniqueId] = el)
                                  }
                                >
                                  {expandedTasks[task.uniqueId] && (
                                    <div
                                      className={`thread-list-item-container ${
                                        item.isClick ? "active" : "inactive"
                                      }`}
                                    >
                                      <div className="thread-list-item-content closed">
                                        <div className="thread-list-item closed">
                                          <div onClick={() => onClick(item)}>
                                            <div style={{ display: "flex" }}>
                                              {getUniqueUserNamesFromList(
                                                item.list,
                                              ).map((user, index) => (
                                                <>
                                                  {!readStatus(item)
                                                    ?.read_status?.read && (
                                                    <span className="comments_row_presentation--blueDot--n7bLI" />
                                                  )}
                                                  <span
                                                    key={index}
                                                    className="thread-label"
                                                    style={{
                                                      marginRight: "-17px",
                                                    }}
                                                  >
                                                    {user.firstLetter}
                                                  </span>
                                                </>
                                              ))}
                                            </div>

                                            <div className="thread-list-item-message-column">
                                              <div className="thread-list-item-message-column-header">
                                                <span
                                                  className="message-author"
                                                  title={item.list[0].user_name}
                                                >
                                                  {item.list[0].user_name}
                                                </span>
                                                <Tooltip
                                                  content={moment(
                                                    item.modified_date,
                                                  ).format(
                                                    "MMM DD, YYYY hh:mm A",
                                                  )}
                                                  placement="top"
                                                >
                                                  <div className="message-time">
                                                    <span>
                                                      <TimeAgo
                                                        timestamp={
                                                          item.modified_date
                                                        }
                                                      />
                                                    </span>
                                                  </div>
                                                </Tooltip>
                                              </div>
                                              <div>
                                                <p className="fnt12 truncated-text ">
                                                  <span
                                                    dangerouslySetInnerHTML={{
                                                      __html: highlightMentions(
                                                        item.list[0].comment,
                                                      ),
                                                    }}
                                                  ></span>
                                                </p>
                                              </div>
                                              {item.list?.length - 1 > 0 && (
                                                <div>
                                                  <p
                                                    className="fnt12"
                                                    style={{
                                                      color: item?.isClick
                                                        ? "#0047ff"
                                                        : "#00000080",
                                                      paddingTop: "5px",
                                                    }}
                                                  >
                                                    {item.list.length - 1}{" "}
                                                    {item.list.length === 2
                                                      ? "reply"
                                                      : "replies"}
                                                  </p>
                                                </div>
                                              )}
                                            </div>
                                            <div className="thread-list-item-notes-container"></div>
                                          </div>

                                          <div className="message-toolbar">
                                            {rolePermissions?.un_resolve &&
                                              rolePermissions?.finish_review && (
                                                <Tooltip
                                                  content={"Mark as unresolved"}
                                                  placement="bottom"
                                                >
                                                  <button
                                                    className="resolve-button resolve-button--unresolved  thread-header__icon"
                                                    onClick={() =>
                                                      onResolve(item)
                                                    }
                                                  >
                                                    <div className="resolve-button__flares">
                                                      <span></span>
                                                      <span></span>
                                                      <span></span>
                                                      <span></span>
                                                      <span></span>
                                                      <span></span>
                                                    </div>
                                                    <span
                                                      className="check"
                                                      style={{
                                                        backgroundColor:
                                                          "#0fc60f",
                                                        color: "#fff",
                                                        border:
                                                          "1px solid #fff4f4",
                                                      }}
                                                    >
                                                      <svg
                                                        viewBox="0 0 16 16"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="icon"
                                                        width="16"
                                                        height="16"
                                                      >
                                                        <path
                                                          d="m1.5 8 3.641 4.552a.5.5 0 0 0 .754.031L14.5 3.5"
                                                          stroke="#000"
                                                          strokeWidth="1.2"
                                                          strokeLinecap="round"
                                                        ></path>
                                                      </svg>
                                                    </span>
                                                  </button>
                                                </Tooltip>
                                              )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                  <div className="thread-list-group-separator"></div>
                                </div>
                              ))}
                          </>
                        ))}
                    </>
                  ) : (
                    ""
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {!isLoading && (
        <div
          className={` ${
            sideNavAction
              ? "thread-sidebar-toggle-collapsed"
              : "thread-sidebar-toggle"
          }`}
        >
          <button
            className="thread-sidebar-toggle-button"
            onClick={() => onSideNav()}
          >
            {!sideNavAction && (
              <svg
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="icon thread-sidebar-toggle-icon"
                width="16"
                height="16"
              >
                <path
                  d="M10.5 13.384 5.62 8.653a.37.37 0 0 1-.12-.27.36.36 0 0 1 .12-.268l4.88-4.731"
                  stroke="#000"
                  stroke-width="1.2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
            )}
            {sideNavAction && (
              <svg
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="icon thread-sidebar-toggle-icon"
                width="16"
                height="16"
              >
                <path
                  d="m5.5 3.384 4.88 4.73a.37.37 0 0 1 .12.27.36.36 0 0 1-.12.269l-4.88 4.73"
                  stroke="#000"
                  stroke-width="1.2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
            )}
          </button>
        </div>
      )}
    </>
  );
};
export default LeftPanel;
