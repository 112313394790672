import React from "react";
import styled from "styled-components";

// import Box from "../Box/Box";

const FooterStyle = styled.div`
  /* position: fixed; */
  bottom: 0;
  left: 0;
  width: 100%;
  background: transparent;
  border-bottom-left-radius: 4px;
`;

function PreviewModalFooter({ backgroundColor, children }) {
  return (
    <FooterStyle backgroundColor={backgroundColor}>
      {/* <Box
        flex={{ justifyContent: "flex-end" }}
        divider={{ color: "light", side: "top" }}
        padding={{ all: 8 }}
      > */}
      {children}
      {/* </Box> */}
    </FooterStyle>
  );
}

export default PreviewModalFooter;
