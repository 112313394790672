import "./PinTooltip.css";
import { TimeAgo } from "@atd/utils";

function PinTooltip({
  firstLetters,
  message,
  totalMessage,
  onClicked,
  mainIndex,
  setShowTooltip,
}) {
  const highlightMentions = (text) => {
    return text.replace(
      /(@\w+\s+\w+)/g,
      '<span style="color: blue;">$1</span>',
    );
  };

  return (
    <>
      <div
        className="comment_cluster_elements--avatarPinContainer---daUJA"
        style={{ marginTop: totalMessage >= 2 ? "-45px" : "-20px" }}
        onClick={() => {
          onClicked(mainIndex);
          setShowTooltip(false);
        }}
      >
        <figma-pinnable-comment-pin
          id="793174560"
          className="comment_cluster_elements--avatarPinAnchor comment_cluster_elements--avatarsHoverContainer comment_cluster_elements--preview--06uH5"
          preview="true"
        >
          <div className="comment_cluster_elements--avatarPinContainer">
            <div className="comment_cluster_elements--pinnedThreadToFileIndicator"></div>
            <figma-avatars
              clamp="2"
              className="comment_cluster_elements--avatarsRoot"
            >
              <div className="comment_cluster_elements--avatarsContainer">
                <div
                  className="comment_cluster_elements--avatarsSecondaryContainer"
                  style={{ display: "none" }}
                ></div>
                <div
                  className="comment_cluster_elements--avatarsOverflowContainer"
                  style={{ display: "none" }}
                >
                  <span
                    className="comment_cluster_elements--avatarOverflowCounter"
                    style={{ visibility: "hidden" }}
                  ></span>
                </div>
                <div
                  className="comment_cluster_elements--avatarsPrimaryContainer"
                  style={{ display: "flex" }}
                >
                  {firstLetters?.length > 0 &&
                    firstLetters.map((ele) => (
                      <figma-avatar
                        initial="B"
                        id="1269236027411200408"
                        className="comment_cluster_elements--avatarContainer"
                      >
                        <span
                          className="comment_cluster_elements--fallbackAvatar comment_cluster_element_updatable--avatarImage--DbdLm"
                          style={{
                            display: "flex",
                            backgroundColor: "rgb(151, 71, 255)",
                            color: "#fff",
                          }}
                        >
                          {ele.firstLetter}
                        </span>
                        {/* <img
                    className="comment_cluster_elements--avatar comment_cluster_element_updatable--avatarImage--DbdLm"
                    style={{ display: "none" }}
                    draggable="false"
                    src="https://picsum.photos/seed/picsum/200/300"
                  /> */}
                      </figma-avatar>
                    ))}
                </div>
                <div></div>
              </div>
            </figma-avatars>
            <div className="comment_cluster_elements--avatarPinPreview">
              <div className="comment_cluster_elements--avatarPinPreviewHeader">
                <div
                  className="comment_cluster_elements--avatarPinPreviewAuthorName"
                  dir="auto"
                >
                  {message?.user_name}
                </div>
                <div className="comment_cluster_elements--avatarPinPreviewTimestamp">
                  <TimeAgo timestamp={message?.modified_date} />
                </div>
              </div>
              <div
                className="comment_cluster_elements--avatarPinPreviewText"
                dir="auto"
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: highlightMentions(message && message?.comment),
                  }}
                ></span>
              </div>
              {totalMessage >= 2 && (
                <span className="comment_cluster_elements--avatarPinPreviewReplySummary">
                  <div>
                    <p
                      className="fnt12"
                      style={{
                        color: "#333333",
                        paddingTop: "5px",
                      }}
                    >
                      {totalMessage - 1}{" "}
                      {totalMessage === 2 ? "reply" : "replies"}
                    </p>
                  </div>
                </span>
              )}
            </div>
          </div>
        </figma-pinnable-comment-pin>
      </div>
    </>
  );
}

export default PinTooltip;
