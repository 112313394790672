export const SelectChecked = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 16 16"
    fill="currentColor"
  >
    <defs>
      <path
        id="check-a"
        d="M13.3170158,3.12101579 L6.01901579,10.4190158 L2.68301579,7.08201579 C2.52139429,6.9206614 2.25963728,6.9206614 2.09801579,7.08201579 L1.12101579,8.05901579 C0.959661405,8.22063728 0.959661405,8.48239429 1.12101579,8.64401579 L5.72701579,13.2500158 C5.88863728,13.4113702 6.15039429,13.4113702 6.31201579,13.2500158 L14.8790158,4.68301579 C15.0403702,4.52139429 15.0403702,4.25963728 14.8790158,4.09801579 L13.9020158,3.12101579 C13.7403943,2.9596614 13.4786373,2.9596614 13.3170158,3.12101579 Z"
      />
    </defs>
    <use fillRule="evenodd" xlinkHref="#check-a" />
  </svg>
);
export const ArrowLeft = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    fill="currentColor"
  >
    <path
      fillRule="evenodd"
      d="M5.43876786,3.12551786 L6.13969643,3.82644643 C6.30944643,3.99619643 6.30666071,4.27230357 6.13351786,4.43858929 L3.25073214,7.20708929 L15.5643036,7.20708929 C15.8009821,7.20708929 15.992875,7.39898214 15.992875,7.63566071 L15.992875,8.63566071 C15.992875,8.87233929 15.8009821,9.06423214 15.5643036,9.06423214 L3.25073214,9.06423214 L6.13348214,11.8327321 C6.30666071,11.9990179 6.30944643,12.275125 6.13966071,12.444875 L5.43873214,13.1458036 C5.271375,13.3131607 5.00001786,13.3131607 4.832625,13.1458036 L0.125517857,8.43869643 C-0.0418392857,8.27133929 -0.0418392857,7.99998214 0.125517857,7.83258929 L4.83269643,3.12551786 C5.00005357,2.95816071 5.27141071,2.95816071 5.43876786,3.12551786 L5.43876786,3.12551786 Z"
    />
  </svg>
);

export const ArrowRight = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    fill="currentColor"
  >
    <title>Arrow Right Icon</title>
    <path
      fillRule="evenodd"
      d="M10.5541071,3.12551786 L9.85317857,3.82644643 C9.68342857,3.99619643 9.68621429,4.27230357 9.85935714,4.43858929 L12.7421429,7.20708929 L0.428571429,7.20708929 C0.191892857,7.20708929 0,7.39898214 0,7.63566071 L0,8.63566071 C0,8.87233929 0.191892857,9.06423214 0.428571429,9.06423214 L12.7421429,9.06423214 L9.85939286,11.8327321 C9.68621429,11.9990179 9.68342857,12.275125 9.85321429,12.444875 L10.5541429,13.1458036 C10.7215,13.3131607 10.9928571,13.3131607 11.16025,13.1458036 L15.8673571,8.43869643 C16.0347143,8.27133929 16.0347143,7.99998214 15.8673571,7.83258929 L11.1601786,3.12551786 C10.9928214,2.95816071 10.7214643,2.95816071 10.5541071,3.12551786 L10.5541071,3.12551786 Z"
    />
  </svg>
);
