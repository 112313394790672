/* eslint-disable no-nested-ternary */
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";
import styled from "styled-components";
import uuid from "react-uuid";

import {
  AssignUser,
  Badge,
  EmptyState,
  Dropdown,
  MoreButton,
  Tooltip,
} from "@atd/components";
import { ReviewStar } from "@atd/components/Feedback/FeedbackReviewLegacy";
import { ratingIcons } from "@atd/components/Feedback/ratings";
import { deleteRequest, getRequestsCount, updateRequest } from "@atd/features";
import { useWindowSize } from "@atd/hooks";
import RequestService from "@atd/features/request/requestApi";
import { withSelector } from "@atd/hoc";

const Table = styled.table`
  min-width: 1095px;
`;

const RatingStyle = styled.div`
  display: flex;
  align-items: center;

  & svg {
    width: 16px;
    height: 16px;
    margin-right: 5px;

    & path:not([class*="svg__base"]) {
      fill: #655f52;
    }
  }
`;

const Company = styled.div`
  display: flex;
  align-items: center;

  & .status {
    width: 6px;
    height: 6px;
    border-radius: 100px;
    margin-right: 10px;
    background-color: ${({ backgroundColor }) => backgroundColor};
  }
`;

function Rating({ rating }) {
  const ratingNumber = Math.floor(rating);
  const RatingIcon = ratingIcons[ratingNumber].icon;

  return (
    <RatingStyle
      className="icon"
      style={{
        fill: ratingIcons[ratingNumber].activeColor,
      }}
    >
      <RatingIcon /> {ratingIcons[ratingNumber].label}
    </RatingStyle>
  );
}

function TabContentHeader({ hideAssignTo, showPriority = true, showRating }) {
  return (
    <thead className="stik">
      <tr>
        <th scope="col" className="border-0 tb-title pl5">
          REQUEST
        </th>
        <th scope="col" className="border-0 tb-title pl5">
          COMPANY
        </th>
        <th scope="col" className="border-0 tb-title pl5">
          CATEGORY
        </th>
        {!hideAssignTo && (
          <th scope="col" className="border-0 tb-title pl5">
            ASSIGNED TO
          </th>
        )}
        {showPriority && (
          <th scope="col" className="border-0 tb-title pl5">
            PRIORITY
          </th>
        )}
        <th scope="col" className="border-0 tb-title pl5">
          STATUS
        </th>
        <th scope="col" className="border-0 tb-title pl5">
          DATE
        </th>
        {showRating && (
          <th scope="col" className="border-0 tb-title pl5">
            FEEDBACK
          </th>
        )}
        <th aria-hidden="true" scope="col" className="border-0 wt-4" />
      </tr>
    </thead>
  );
}

function TabContentItem({
  item,
  role,
  users,
  status,
  hideAssignTo,
  showPriority = true,
  isGroupBy,
  showRating,
}) {
  const navigate = useNavigate();

  const [assignTo, setAssignTo] = useState([]);

  const dispatch = useDispatch();

  const size = useWindowSize();

  const { id, assigned_to } = item || {};

  useEffect(() => {
    setAssignTo(assigned_to);
  }, [assigned_to]);

  const actionMenus = [
    {
      id: "closed",
      value: "Mark as Complete",
    },
    status === "archived"
      ? {
          id: "unarchive",
          value: "Unarchive",
        }
      : {
          id: "archive",
          value: "Archive",
        },
    {
      id: "delete",
      value: "Delete Project",
      textColor: "red-txt",
    },
  ];

  const actionMenuHandler = async (actionId) => {
    switch (actionId) {
      case "archive":
        await dispatch(
          updateRequest({
            status,
            data: { request_ids: [item.id], update_type: "archive" },
          }),
        );
        break;
      case "unarchive":
        await dispatch(
          updateRequest({
            status,
            data: { request_ids: [item.id], update_type: "unarchive" },
          }),
        );
        break;
      case "delete":
        await dispatch(deleteRequest({ request_ids: [item.id] }));
        break;
      case "closed":
        if (
          // eslint-disable-next-line no-alert
          window.confirm(
            "Are you sure you don't have any more revisions to send our way?",
          )
        ) {
          navigate(`/requests/thread/${item.id}?feedback=show`);
        }
        break;
      default:
        break;
    }
    await dispatch(getRequestsCount());
  };

  const excerptName = (nameString, length) => {
    if (nameString?.length > length) {
      return (
        <Tooltip content={nameString}>
          <span>
            {nameString?.substr(0, length)}
            ...
          </span>
        </Tooltip>
      );
    }

    return <span>{nameString}</span>;
  };

  const planNonProColor = ["Premium", "Ultimate"].includes(
    item.company_data?.plan_name,
  )
    ? "#D82E57"
    : "#30B284";
  const planColor =
    item.company_data?.plan_name === "Pro" ? "#5469d4" : planNonProColor;

  return (
    <tr>
      <td
        aria-hidden="true"
        onClick={() => navigate(`/requests/thread/${item.id}`)}
        style={{ borderLeft: isGroupBy ? "none" : null }}
      >
        <span className="ticid">{item.s_no}</span>{" "}
        {excerptName(item.request_name, size.width <= 1366 ? 25 : 32)}
      </td>
      <td
        aria-hidden="true"
        onClick={() => navigate(`/requests/thread/${item.id}`)}
      >
        <Company
          backgroundColor={planColor}
          style={{
            fontWeight:
              item.company_data?.plan_name === "Ultimate" ? "bold" : null,
          }}
        >
          <Tooltip content={item.company_data?.plan_name}>
            <div className="status" />
          </Tooltip>
          {/* {item.company_data.company_name} */}
          {excerptName(
            item.company_data.company_name,
            size.width <= 1366 ? 20 : 28,
          )}
        </Company>
      </td>
      {/* {!hidePlan && <td>{item.company_data.plan_name}</td>} */}
      <td
        aria-hidden="true"
        onClick={() => navigate(`/requests/thread/${item.id}`)}
      >
        {item.req_type}
      </td>
      {!hideAssignTo && (
        <td className="assign">
          <AssignUser
            multiple
            users={users}
            value={assignTo || []}
            isDisabled={item.in_progress || [6, 7].includes(role)}
            onChange={(value) => {
              setAssignTo(value);
              RequestService.assignUser(id, {
                assigned_to: value,
                assigned_to_string: value.toString(),
              });
            }}
          />
        </td>
      )}
      {showPriority && (
        <td
          aria-hidden="true"
          onClick={() => navigate(`/requests/thread/${item.id}`)}
        >
          <Badge
            type={
              item.priority === 1
                ? "error-inverse"
                : [1, 2, 3].includes(item.priority)
                ? "warning"
                : "success"
            }
          >
            {item.priority === 1
              ? "High"
              : [1, 2, 3].includes(item.priority)
              ? "Medium"
              : "Low"}
          </Badge>
        </td>
      )}
      <td
        aria-hidden="true"
        onClick={() => navigate(`/requests/thread/${item.id}`)}
      >
        <Badge
          type={
            item.status_name === "In Progress"
              ? "default"
              : item.status_name === "Delivered"
              ? "info"
              : ["New", "Closed"].includes(item.status_name)
              ? "success"
              : "default"
          }
        >
          {item.status_name}
        </Badge>
      </td>
      <td
        aria-hidden="true"
        title={moment(item.modified).format("DD MMM YYYY, HH:mm")}
        onClick={() => navigate(`/requests/thread/${item.id}`)}
      >
        {item.modified_string}
      </td>
      {showRating && (
        <td className="pl5" style={{ paddingRight: 0 }}>
          {item.ticket_type === "old" && (
            <>
              {Array(5)
                .fill()
                .map((_, index) => (
                  <ReviewStar
                    index={index + 1}
                    rating={item?.rating}
                    key={uuid()}
                    style={{ fontSize: 17, padding: 0 }}
                  />
                ))}
            </>
          )}
          {item.ticket_type === "new" && <Rating rating={item?.rating ?? 1} />}
        </td>
      )}
      <td className="dropdown">
        {![6, 7].includes(role) && (
          <Dropdown as={MoreButton} onChange={actionMenuHandler}>
            <Dropdown.Options>
              {actionMenus
                .filter((menu) => {
                  if (role === 2) {
                    return !["delete", "closed"].includes(menu.id) && menu;
                  }
                  return menu;
                })
                .map((action) => (
                  <Dropdown.Option key={action.id} value={action.id}>
                    {action.id === "delete" && (
                      <span className="red-txt">{action.value}</span>
                    )}
                    {action.id !== "delete" && action.value}
                  </Dropdown.Option>
                ))}
            </Dropdown.Options>
          </Dropdown>
        )}
      </td>
    </tr>
  );
}

function AdminTabContent({
  data,
  user,
  users,
  error,
  isGroupBy,
  fetching,
  hasError,
  activeTabStatus,
  activeTab,
  handleClick,
  handleClicks,
  scheduleDate,
  ...rest
}) {
  if (hasError && error.message !== "Aborted") {
    return (
      <EmptyState
        icon="client"
        title="Something went wrong!"
        description={<span>{error.message}</span>}
      />
    );
  }

  if (!fetching && data?.length === 0) {
    return (
      <EmptyState
        icon="requests"
        title="No results found"
        description={
          <span>There aren&rsquo;t any results for that query.</span>
        }
      />
    );
  }

  return (
    <>
      {
        <Table className="table table-bordered mb-0 sm-ht fulltable">
          <TabContentHeader {...rest} />
          {isGroupBy && (
            <>
              {data.map((item) => (
                <>
                  {"row_type" in item && item.row_type === "header" && (
                    <thead>
                      <tr className="ccss9">
                        <th
                          scope="col"
                          colSpan="5"
                          className="border-0 tb-title pl5 blu-txt"
                        >
                          {item.header_name}
                        </th>
                      </tr>
                    </thead>
                  )}
                  <tbody>
                    {"row_type" in item && item.row_type === "row" && (
                      <TabContentItem
                        key={uuid()}
                        isGroupBy={isGroupBy}
                        role={user?.role}
                        status={activeTabStatus}
                        users={users}
                        item={item}
                        {...rest}
                      />
                    )}
                  </tbody>
                </>
              ))}
            </>
          )}
          {!isGroupBy && (
            <tbody>
              {data.map((item) => (
                <TabContentItem
                  key={uuid()}
                  role={user?.role}
                  status={activeTabStatus}
                  users={users}
                  item={item}
                  {...rest}
                />
              ))}
            </tbody>
          )}
        </Table>
      }
    </>
  );
}

export default withSelector((state) => state.user)(AdminTabContent);
