import { useState, useEffect, forwardRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { toast } from "react-toastify";
import styled from "styled-components";

import {
  Dropdown,
  Box,
  EmptyState,
  Icon,
  MoreButton,
  Tooltip,
  Badge,
} from "@atd/components";

import { deleteRequest } from "@atd/features";

import RequestService from "@atd/features/request/requestApi";
import { withSelector } from "@atd/hoc";

const defaultRequestIcon = require("../../../assets/images/req-img10.png");

const Table = styled.table`
  td img {
    max-width: none;
  }
`;

function TabContentHeader({ status, showPriority }) {
  return (
    <thead>
      <tr>
        {/* {status === "queue" && (
          <th
            aria-hidden="true"
            scope="col"
            className="border-0 wt-2"
            style={{ width: 41 }}
          />
        )} */}
        <th scope="col" className="border-0 tb-title pl5 wt3">
          {showPriority && "Priority"}
        </th>
        <th
          scope="col"
          className={`border-0 tb-title ${showPriority ? "wt-4" : ""}`}
        >
          NAME
        </th>
        {showPriority && (
          <th
            aria-hidden="true"
            scope="col"
            className="border-0 tb-title pl5"
          />
        )}
        {(status === "delivered" || status === "closed") && (
          <th scope="col" className="border-0 tb-title pl5 wt-10">
            STATUS
          </th>
        )}

        <th scope="col" className="border-0 tb-title pl5 wt-10">
          UPDATED
        </th>
        <th aria-hidden="true" scope="col" className="border-0 wt-4" />
        <th aria-hidden="true" scope="col" className="border-0 wt-4" />
      </tr>
    </thead>
  );
}

const TabContentItem = forwardRef(
  (
    { item, index, role, status, showPriority, dragHandleProps, ...rest },
    ref,
  ) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [showScrollBar, setShowScrollBar] = useState(false);
    const actionMenus = [
      !["draft"].includes(status) &&
        item.status !== 7 && {
          id: "closed",
          value: "Mark as Complete",
        },
      status === "draft" && {
        id: "edit",
        value: "Edit Project",
      },
      status !== "draft" && {
        id: "duplicate",
        value: "Duplicate Project",
      },
      status === "draft" && {
        id: "delete",
        value: "Delete Project",
        textColor: "red-txt",
      },
    ];

    const actionMenuHandler = async (id) => {
      switch (id) {
        case "delete":
          dispatch(deleteRequest({ request_ids: [item.id] }));
          break;
        case "duplicate":
          try {
            const response = await RequestService.cloneRequest(item.id);
            if (!response) {
              throw new Error("Something went wrong!");
            }
            navigate(`/requests/${response.id}`);
          } catch (error) {
            toast.error(error.message);
          }
          break;
        case "edit":
          navigate(`/requests/${item.id}`);
          break;
        case "closed":
          if (
            window.confirm(
              "Are you sure you don't have any more revisions to send our way?",
            )
          ) {
            navigate(`/requests/thread/${item.id}?feedback=show`);
          }
          break;
        default:
          break;
      }
    };

    const onClickRequest = (id) => {
      if (status === "draft") {
        navigate(`/requests/${id}`);
      } else {
        navigate(`/requests/thread/${id}`);
      }
    };

    const isDelivered =
      item.status_name === "Delivered" ? "success" : "default";

    return (
      <tr
        ref={ref}
        className="reqst"
        onMouseEnter={() => setShowScrollBar(true)}
        onMouseLeave={() => setShowScrollBar(false)}
        {...rest}
      >
        {/* {status === "queue" && !item.in_progress ? (
          <td className={`scrl${showScrollBar ? " scrlhover" : ""}`}>
            <img
              src="https://spiceblue.co/atd_ui/ticket/assets/images/scroll.png"
              alt="scroll"
              {...dragHandleProps}
            />
          </td>
        ) : null} */}
        {/* {status === "queue" && item.in_progress === 1 && (
          <td className="scrl" />
        )} */}
        {showPriority && (
          <td
            style={{
              borderLeft: index === 0 ? "1px solid #3781b8" : undefined,
            }}
          >
            {status === "queue" && !item.in_progress ? (
              <span className={`scrl${showScrollBar ? " scrlhover" : ""}`}>
                <img
                  src="https://spiceblue.co/atd_ui/ticket/assets/images/scroll.png"
                  alt="scroll"
                  {...dragHandleProps}
                />
              </span>
            ) : null}
            {item.in_progress === 1 && (
              <Tooltip content="Ticket is In Progress" placement="top">
                <Box
                  className="ord success"
                  flex={{ justifyContent: "center" }}
                  style={{ width: 21, height: 22, textAlign: "center" }}
                  margin={{ left: 4 }}
                >
                  <Icon icon="clock" padding={{ vertical: 4 }} />
                </Box>
              </Tooltip>
            )}
            {!item.in_progress && (
              <Box
                className="ord yet"
                flex={{ justifyContent: "center" }}
                style={{ minWidth: 22, height: 24, textAlign: "center" }}
                margin={{ left: 4 }}
              >
                {index + 1}
              </Box>
            )}
          </td>
        )}
        <td
          aria-hidden="true"
          className="chat"
          onClick={() => onClickRequest(item.id)}
        >
          <img
            src={item?.request_icon || defaultRequestIcon}
            alt="ATD"
            width={42}
            onError={(e) => {
              e.target.src = defaultRequestIcon;
            }}
          />
        </td>
        <td
          aria-hidden="true"
          className="chat"
          onClick={() => onClickRequest(item.id)}
        >
          <div className="title1">{item.request_name}</div>
          <div className="cont1">Request #{item.s_no}</div>
        </td>
        {(status === "delivered" || status === "closed") && (
          <td>
            <Badge
              type={item.status_name === "In Progress" ? "info" : isDelivered}
            >
              {item.status_name}
            </Badge>
          </td>
        )}
        <td>
          <div className="cont1 mt-3">
            {moment(item.modified).format("DD MMM")}
          </div>
        </td>
        <td className="wt-4"></td>
        <td className="dropdown">
          {role !== 4 && (
            <Dropdown as={MoreButton} onChange={actionMenuHandler}>
              <Dropdown.Options>
                {actionMenus
                  .filter((menu) => {
                    if (role === 3) {
                      return menu.id !== "closed" && menu;
                    }
                    if (role === 7) {
                      return !["delete"].includes(menu.id) && menu;
                    }
                    return menu;
                  })
                  .map((action) => {
                    return (
                      <Dropdown.Option key={action.id} value={action.id}>
                        {action.id === "delete" && (
                          <span className="red-txt">{action.value}</span>
                        )}
                        {action.id !== "delete" && action.value}
                      </Dropdown.Option>
                    );
                  })}
              </Dropdown.Options>
            </Dropdown>
          )}
        </td>
      </tr>
    );
  },
);

function ClientTabContent({
  user,
  data,
  error,
  fetching,
  hasError,
  activeTabStatus,
  showPriority,
}) {
  const [requests, setRequests] = useState(data || []);

  useEffect(() => {
    setRequests(data);
  }, [data]);

  const handleDragEnd = (results) => {
    const updatedRequests = [...requests];
    const priority = `${updatedRequests[results.source.index].id}->${
      updatedRequests[results.destination.index].id
    }`;
    const [selectedRow] = updatedRequests.splice(results.source.index, 1);
    updatedRequests.splice(results.destination.index, 0, selectedRow);
    setRequests(() => {
      RequestService.updateRequest({
        data: {
          request_ids: updatedRequests.map(({ id }) => id),
          update_type: "priority",
          priority,
        },
      });
      return updatedRequests;
    });
  };

  if (hasError && error.message !== "Aborted") {
    return (
      <EmptyState
        icon="client"
        title="Something went wrong!"
        description={<span>{error.message}</span>}
      />
    );
  }

  if (!fetching && requests?.length === 0) {
    return (
      <EmptyState
        icon="requests"
        title="No results found"
        description={
          <span>There aren&rsquo;t any results for that query.</span>
        }
      />
    );
  }

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Table
        className="table mb-0"
        id={activeTabStatus === "queue" ? "sortableTab" : ""}
        style={{ minWidth: "100%" }}
      >
        <TabContentHeader
          status={activeTabStatus}
          showPriority={showPriority}
        />
        <Droppable droppableId="droppable-1" type="REQUEST">
          {(provided) => (
            <tbody ref={provided.innerRef} {...provided.droppableProps}>
              {requests.map((item, index) => {
                if (item.in_progress) {
                  return (
                    <TabContentItem
                      role={user?.role}
                      key={item.id}
                      ref={provided.innerRef}
                      item={item}
                      index={index}
                      status={activeTabStatus}
                      showPriority={showPriority}
                    />
                  );
                }
                return (
                  <Draggable
                    key={`draggable-${item.id}`}
                    draggableId={`draggable-${item.id}`}
                    index={index}
                  >
                    {/* eslint-disable-next-line no-shadow */}
                    {(provided, snapshot) => (
                      <TabContentItem
                        key={item.id}
                        ref={provided.innerRef}
                        item={item}
                        index={index}
                        role={user?.role}
                        status={activeTabStatus}
                        showPriority={showPriority}
                        dragHandleProps={provided.dragHandleProps}
                        {...provided.draggableProps}
                        style={{
                          backgroundColor:
                            snapshot.isDragging && "var(--light-color)",
                          boxShadow:
                            snapshot.isDragging && "0 0 20px rgb(0 0 0 / 10%)",
                          ...provided.draggableProps.style,
                        }}
                      />
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </tbody>
          )}
        </Droppable>
      </Table>
    </DragDropContext>
  );
}

export default withSelector((state) => state.user)(ClientTabContent);
