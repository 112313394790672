import { useMemo, useState, useRef, useCallback, cloneElement } from "react";
import { usePopper } from "react-popper";
import { Transition } from "react-transition-group";
import classnames from "classnames";
import maxSize from "popper-max-size-modifier";

import useOnClickOutside from "../../hooks/useOnClickOutside";

import Portal from "../Portal/Portal";

import "./Popover.css";

function AtdPopover({
  content,
  render,
  element,
  className,
  children,
  onMount,
  onShow,
  popperOptions,
  renderTop,
  renderFooter,
  scrollContainerOptions,
  visible = false,
  placement = "bottom",
  offset = [0, 10],
  interactive = false,
  bottomGap = 30,
  arrow = true,
  arrowStyle,
  backgroundColor,
  ZIndex,
  width,
  OverFlow,
  ...props
}) {
  const ref = useRef();
  const elementRef = useRef();

  const [isOpen, setIsOpen] = useState(false);

  const isVisible = visible || isOpen;
  const handleOnOpen = onShow ?? setIsOpen;

  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);

  useOnClickOutside(ref, (event) => {
    if (referenceElement && !referenceElement.contains(event.target)) {
      handleOnOpen(false);
    }
  });

  // Create your own apply modifier that adds the styles to the state
  const applyMaxSize = useMemo(
    () => ({
      name: "applyMaxSize",
      enabled: true,
      phase: "beforeWrite",
      requires: ["maxSize"],
      fn({ state }) {
        // The `maxSize` modifier provides this data
        const { width, height } = state.modifiersData.maxSize;

        if (elementRef.current) {
          elementRef.current.style.maxHeight = `${height - bottomGap * 1}px`;
          elementRef.current.style.maxWidth = `${width}px`;
        }

        // maxHeightRef.current = height - 10;

        state.styles.popper = {
          ...state.styles.popper,
          maxWidth: width,
          maxHeight: height,
        };
      },
    }),
    [bottomGap],
  );

  const popperDefaultOptions = {
    modifiers: [
      {
        name: "preventOverflow",
        options: {
          padding: 12,
        },
      },
      maxSize,
      applyMaxSize,
      ...(popperOptions?.modifiers || []),
    ],
    ...popperOptions,
  };

  const defaultPopperOptions =
    popperDefaultOptions && "modifiers" in popperDefaultOptions
      ? popperDefaultOptions?.modifiers
      : [];

  const { styles, attributes, update } = usePopper(
    referenceElement,
    popperElement,
    {
      placement,
      modifiers: [
        {
          name: "preventOverflow",
          options: {
            padding: 12,
          },
        },
        {
          name: "arrow",
          options: {
            element: arrowElement,
          },
        },
        {
          name: "offset",
          options: {
            offset,
          },
        },
        ...defaultPopperOptions,
      ],
    },
  );

  const popperPlacement = attributes?.popper?.["data-popper-placement"];

  let dropdownPlacement = { y: "bottom", x: "left", context: "bottom" };
  switch (popperPlacement) {
    case "bottom":
      dropdownPlacement = { y: "bottom", x: "left", context: "bottom" };
      break;
    case "bottom-start":
      dropdownPlacement = { y: "top", x: "left", context: "bottom" };
      break;
    case "bottom-end":
      dropdownPlacement = { y: "bottom", x: "right", context: "bottom" };
      break;
    case "top":
      dropdownPlacement = { y: "top", x: "left", context: "top" };
      break;
    case "top-start":
      dropdownPlacement = { y: "top", x: "left", context: "top" };
      break;
    case "top-end":
      dropdownPlacement = { y: "top", x: "right", context: "top" };
      break;
    default:
      dropdownPlacement = { y: "bottom", x: "left" };
      break;
  }

  const { y, context } = dropdownPlacement;

  const elementClasses = classnames(
    "ContextualLayer--pointerEvents",
    "ContextualLayer-container",
    className,
    `ContextualLayer-context--${context}`,
    `ContextualLayer-context--any${context}`,
    {
      [`ContextualLayer-context--${context}`]: context,
      "ContextualLayer-layer--top": y === "bottom",
      "ContextualLayer-layer--bottom": y === "top",
      // aligning arrow when dropdown is at position bottom
      "ContextualLayer-layer--topright": popperPlacement === "bottom-end",
      "ContextualLayer-layer--topleft": popperPlacement === "bottom-start",
      // aligning arrow when dropdown is at position top
      "ContextualLayer-layer--bottomleft": popperPlacement === "top-start",
      "ContextualLayer-layer--bottomright": popperPlacement === "top-end",
      "ContextualLayer-layer--anytop":
        y === "bottom" ||
        popperPlacement === "bottom-start" ||
        popperPlacement === "bottom-end",
      "ContextualLayer-layer--anybottom":
        y !== "bottom" && popperPlacement !== "bottom-start",
    },
  );

  const onClose = useCallback(() => {
    handleOnOpen(false);
  }, [handleOnOpen]);

  const handleOpen = useCallback(() => {
    handleOnOpen(true);
  }, [handleOnOpen]);

  return (
    <>
      {cloneElement(children, {
        ref: setReferenceElement,
        className: classnames(children.props?.className, {
          // "dropdown-open": isVisible,
        }),
        // isVisible,
        onClick: (e) => {
          // e.stopPropagation();
          // if (children.props.isDisabled) {
          //   return;
          // }
          if (children.props.onClick) {
            handleOnOpen(true);
            children.props.onClick(e);
            return;
          }
          if (isVisible) {
            handleOnOpen(false);
          } else {
            handleOnOpen(true);
          }
          if (update) {
            update();
          }
        },
      })}
      <Portal>
        <Transition
          in={isVisible}
          className="ContextualPopover-animate"
          timeout={{ enter: 250, exit: 200 }}
          appear
          unmountOnExit
          mountOnEnter
          onEntered={() => {
            if (onMount) {
              onMount({ onClose, onShow: handleOpen, isOpen: isVisible });
            }
          }}
        >
          {(state) => (
            <div ref={ref}>
              <span tabIndex="-1">
                <div
                  {...props}
                  ref={setPopperElement}
                  className={elementClasses}
                  style={{
                    zIndex: ZIndex || 9999,
                    ...styles.popper,
                    width: width || "auto",
                  }}
                >
                  <div
                    className={`ContextualPopover-animate ContextualPopover-animate-${state}`}
                    role="dialog"
                  >
                    <div className="ContextualPopover">
                      {arrow && (
                        <div
                          ref={setArrowElement}
                          className="ContextualPopover-arrowContainer"
                          style={arrowStyle}
                        >
                          <div className="ContextualPopover-arrow" />
                        </div>
                      )}
                      <div
                        className="ContextualPopover-contents"
                        style={{
                          overflow: OverFlow,
                          background: backgroundColor || "#ffff",
                        }}
                      >
                        {renderTop && renderTop()}
                        <div
                          {...scrollContainerOptions}
                          className="ContextualPopover-contents--scrollContainer"
                          style={{ overflow: OverFlow }}
                        >
                          {element &&
                            cloneElement(element, {
                              ref: elementRef,
                              onClick: () => {
                                if (!interactive) {
                                  handleOnOpen(false);
                                } else {
                                  handleOnOpen(true);
                                }
                              },
                            })}
                          {render &&
                            !element &&
                            render({
                              onClose: handleOnOpen(false),
                              onShow: handleOnOpen(true),
                            })}
                          {content && !render && !element && content}
                        </div>
                      </div>
                      {renderFooter && renderFooter()}
                    </div>
                  </div>
                </div>
              </span>
            </div>
          )}
        </Transition>
      </Portal>
    </>
  );
}

export default AtdPopover;
