import "./NewComment.css";
import { useCallback, useEffect, useRef, useState } from "react";
import styled, { css, keyframes } from "styled-components";
import { ZoomFiles, Tooltip, PinUser } from "@atd/components";
import ReactModal from "react-modal";

const getFadeInSlideStyle = () => {
  return keyframes`
  10%, 90% {
    transform: translate3d(-2px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(4px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-8px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(8px, 0, 0);
  }
  `;
};

const StyledDiv = styled.div`
  ${(props) =>
    props.animate &&
    css`
      animation: ${getFadeInSlideStyle()} 0.82s
        cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    `}
`;

const customStyles = {
  overlay: {},
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "20px",
    zIndex: 1045,
  },
};

const ZoomDiv = styled.div`
  position: relative;
  display: block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045;
`;

function NewComment({
  currentIndex,
  mainIndex,
  comment,
  handleChange,
  submitMessage,
  isAnimate,
  onAnimationEnds,
  attachFile,
  setAttachFile,
  referenceFiles,
  userList,
}) {
  const divRef = useRef(null);
  const textAreaRef = useRef(null);
  const [zoomPoup, setZoomPoup] = useState(false);
  const [zoomFiles, setZoomFiles] = useState([]);

  const [existsUser, setExistsUser] = useState(null);

  const onClickUser = (userName) => {
    const textArea = textAreaRef.current;

    // Create a new span element for the username
    const userSpan = document.createElement("span");
    userSpan.style.color = "blue";
    userSpan.innerText = `@${userName}`;

    // Append a space before and after the span
    const textNodeBefore = document.createTextNode(" ");
    const textNodeAfter = document.createTextNode(" ");

    textArea.appendChild(textNodeBefore);
    textArea.appendChild(userSpan);
    textArea.appendChild(textNodeAfter);

    // Set cursor to the end of the content
    const range = document.createRange();
    const selection = window.getSelection();

    if (textArea.childNodes.length > 0) {
      const lastNode = textArea.childNodes[textArea.childNodes.length - 1];
      range.setStart(lastNode, lastNode.length);
      range.setEnd(lastNode, lastNode.length);
    } else {
      const textNode = document.createTextNode("");
      textArea.appendChild(textNode);
      range.setStart(textNode, 0);
      range.setEnd(textNode, 0);
    }

    selection.removeAllRanges();
    selection.addRange(range);

    textArea.focus();

    // Collect the updated content
    const updatedContent = textArea.innerHTML;

    handleChange(updatedContent, mainIndex, currentIndex);
    setExistsUser(false);
  };
  const userSuggestion = useCallback(() => {
    setExistsUser(true);
  }, []);

  const zoomView = useCallback(
    (attachFile, index) => {
      console.log("zoomView", attachFile, zoomFiles);
      const files = attachFile.map((ele, i) => ({
        ...ele,
        isSelect: i === index ? true : false,
      }));

      setZoomPoup(true);
      setZoomFiles(files);
    },
    [zoomFiles],
  );

  const onClose = useCallback(() => {
    setZoomPoup(false);
  }, []);

  const removeFile = (index) => {
    setAttachFile((files) => {
      return files.filter((_, i) => i !== index);
    });
  };

  const handleClickOutside = (event) => {
    if (divRef.current && !divRef.current.contains(event.target)) {
      setExistsUser(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handlePaste = useCallback(
    (event) => {
      console.log("handlePaste", event.clipboardData);
      const items = (event.clipboardData || event.originalEvent.clipboardData)
        .items;

      for (let index in items) {
        const item = items[index];
        if (item.kind === "file") {
          const blob = item.getAsFile();
          if (blob && blob.type.includes("image")) {
            const fileName = blob.name.toLowerCase();
            const reader = new FileReader();
            reader.onload = async (e) => {
              const generateUniqueId = `${Date.now()}-${Math.random()
                .toString(36)
                .substr(2, 9)}`;
              const result = {
                image_url: e.target.result,
                isLoading: true,
                attachment_id: generateUniqueId,
              };
              setAttachFile((files) => [...files, result]);
              const response = await referenceFiles(
                blob,
                generateUniqueId,
                "attachment." + fileName.split(".")[1],
              );
              if (response) {
                setAttachFile((files) => {
                  const findIndex = files.findIndex(
                    (ele) => ele.attachment_id === response.attachment_id,
                  );

                  if (findIndex !== -1) {
                    const updatedFiles = [...files];
                    updatedFiles[findIndex] = response;
                    return updatedFiles;
                  }

                  return files;
                });
              }
            };
            reader.readAsDataURL(blob);
            break;
          }
        }
      }
    },
    [referenceFiles, setAttachFile],
  );

  // const attachFiles = useCallback(
  //   async (event) => {
  //     const files = event.target.files;
  //     if (files !== undefined) {
  //       const file = files[0];
  //       const imageUrl = URL.createObjectURL(file);
  //       const generateUniqueId = `${Date.now()}-${Math.random()
  //         .toString(36)
  //         .substr(2, 9)}`;
  //       const result = {
  //         image_url: imageUrl,
  //         isLoading: true,
  //         attachment_id: generateUniqueId,
  //       };
  //       setAttachFile((files) => [...files, result]);
  //       const response = await referenceFiles(
  //         files[0],
  //         generateUniqueId,
  //         file.name,
  //       );
  //       if (response?.status === "success") {
  //         delete response.status;
  //         setAttachFile((files) => {
  //           const findIndex = files.findIndex(
  //             (ele) => ele.attachment_id === response.attachment_id,
  //           );

  //           if (findIndex !== -1) {
  //             const updatedFiles = [...files];
  //             response.attachment_id = "";
  //             updatedFiles[findIndex] = { ...response };
  //             return updatedFiles;
  //           }

  //           return files;
  //         });
  //       } else if (response?.status === "error") {
  //         setAttachFile((files) => {
  //           const filteredFiles = files.filter(
  //             (ele) => ele.attachment_id !== response.attachment_id,
  //           );
  //           return filteredFiles;
  //         });
  //       }
  //     }
  //   },
  //   [referenceFiles],
  // );

  useEffect(() => {
    if (textAreaRef.current) {
      if (Object.keys(comment)?.length === 0 || comment.trim().length === 0) {
        textAreaRef.current.innerHTML = "";
      }
    }
  }, [comment]);

  const handleKeyDown = (event) => {
    if (event.key === "Backspace") {
      const textArea = textAreaRef.current;
      const currentValue = textArea.innerHTML;
      // Regular expression to match the <span> tags with style="color: blue;"
      const regex = /<span style="color: blue;">(.*?)<\/span>/g;

      // Array to store the matched parts
      const parts = [];

      // Use replace with a callback function to collect the matched parts
      currentValue.replace(regex, (match, group1) => {
        parts.push(group1.trim()); // Push the trimmed content inside <span> tags to parts array
        return ""; // Replace the matched part with an empty string
      });

      // Split the remaining parts by spaces to get other text segments
      const remainingParts = currentValue
        .split(regex)
        .map((part) => part.trim())
        .filter((part) => part !== "");

      // Combine both arrays to get the desired output
      const output = [...remainingParts];
      const array = output.map((item) => item.replace("@", ""));
      if (array?.length > 0) {
        const findName = userList.some(
          (el) =>
            el.user_name.replace(/\s+/g, "").toLowerCase() ===
            array[array?.length - 1].replace(/\s+/g, "").toLowerCase(),
        );
        if (findName) {
          const spans = textArea.getElementsByTagName("span");
          const lastSpan = spans[spans.length - 1];
          if (lastSpan) {
            // Remove the last span element
            lastSpan.parentNode.removeChild(lastSpan);
          }
        }
      }
    }
  };

  return (
    <>
      <div ref={divRef}>
        <PinUser
          existsUser={existsUser}
          onClickUser={onClickUser}
          options={userList}
        />
      </div>
      <StyledDiv
        role="button"
        tabIndex="-1"
        // className="viewport_draggable--container---XH8-"
        data-forward-events-to-fullscreen="true"
        style={{
          x: "241px",
          y: "48px",
          height: comment.trim().length > 0 ? "450px" : "68px",
          width: "298px",
          cursor: "default",
        }}
        animate={isAnimate}
        onAnimationEnd={() => {
          onAnimationEnds();
        }}
      >
        <div
          className="new_comment_container--translatedThreadContainer--2xkOH js-fullscreen-prevent-event-capture"
          style={{ transform: "translate(38px, 10px)" }}
        >
          <div className="wiggle--wiggleContainer--oGy1O">
            <div
              className="new_comment_container--newCommentContainerNext--bCfo7 new_comment_container--threadContainer--XIsVX text--fontPos11--2LvXf text--_fontBase--QdLsd overflow--overflowYAuto--nfK38 overflow--momentumScroll--qtsu7"
              style={{ maxHeight: "calc(338px)" }}
            >
              <div
                className="thread_comment_composer--inputSectionWrapper--Bxyab thread_comment_composer--newComment--eYLj7 thread_comment_composer--composerEmpty--VgMY7"
                data-testid="comment-composer-container"
                style={{
                  display:
                    comment.trim().length === 0 && attachFile?.length === 0
                      ? "flex"
                      : "block",
                }}
              >
                <div
                  className="editable_typeahead--wrapperBase--rwdvD thread_comment_composer--editableTypeahead--9R6Yd text--fontPos13--xW8hS text--_fontBase--QdLsd"
                  data-testid="editable-typeahead"
                >
                  <div className="DraftEditor-root" onPaste={handlePaste}>
                    <div
                      className="public-DraftEditorPlaceholder-root"
                      style={{ whiteSpace: "pre-wrap" }}
                    >
                      <div
                        className="public-DraftEditorPlaceholder-inner"
                        id="placeholder-9da6e"
                        style={{ whiteSpace: "pre-wrap" }}
                      >
                        {comment.trim().length > 0 ? null : "Add a comment"}
                      </div>
                    </div>
                    <div className="DraftEditor-editorContainer">
                      <div
                        ref={textAreaRef}
                        aria-describedby="placeholder-9da6e"
                        className="notranslate public-DraftEditor-content"
                        contentEditable="true"
                        role="textbox"
                        spellCheck="true"
                        tabIndex="0"
                        style={{
                          outline: "none",
                          userSelect: "text",
                          whiteSpace: "pre-wrap",
                          overflowWrap: "break-word",
                          color: "#000",
                          textAlign: "start",
                          unicodeBidi: "normal",
                          direction: "ltr",
                        }}
                        onInput={(event) =>
                          handleChange(
                            event.target.innerText,
                            mainIndex,
                            currentIndex,
                          )
                        }
                        onKeyDown={handleKeyDown}
                      />
                    </div>
                  </div>
                </div>

                {(comment.trim().length > 0 || attachFile?.length > 0) && (
                  <>
                    <div style={{ display: "flex" }}>
                      {attachFile.map((list, index) => (
                        <div key={index}>
                          <div className="attachment--composerContainer--mP7Kx">
                            {list?.image_url && (
                              <div
                                className={` ${
                                  list?.isLoading
                                    ? "attachment--pendingThumbnailContainer--iL-xu"
                                    : "attachment--pendingThumbnailContainer--iL-xus"
                                }`}
                              >
                                {list?.isLoading && (
                                  <div
                                    className="image-loader"
                                    onClick={() => zoomView(attachFile, index)}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="48"
                                      height="48"
                                      viewBox="0 0 24 24"
                                    >
                                      <path
                                        fill="#2f88ff"
                                        d="M12 2A10 10 0 1 0 22 12A10 10 0 0 0 12 2Zm0 18a8 8 0 1 1 8-8A8 8 0 0 1 12 20Z"
                                        opacity="0.5"
                                      />
                                      <path
                                        fill="#2f88ff"
                                        d="M20 12h2A10 10 0 0 0 12 2V4A8 8 0 0 1 20 12Z"
                                      >
                                        <animateTransform
                                          attributeName="transform"
                                          dur="1s"
                                          from="0 12 12"
                                          repeatCount="indefinite"
                                          to="360 12 12"
                                          type="rotate"
                                        />
                                      </path>
                                    </svg>
                                  </div>
                                )}
                                <button
                                  className="attachment--thumbnailButton--CfAkKS"
                                  onClick={() => zoomView(attachFile, index)}
                                >
                                  <img
                                    src={list?.image_url}
                                    alt={list?.file_name}
                                    className="attachment--thumbnailImage--Jx4fas"
                                  />
                                  <div className="attachment--lightOverlay--F8qzZ attachment--overlay--dIw1C"></div>
                                </button>
                                {!list?.isLoading && (
                                  <button
                                    className="attachment--deleteBadge--9aSjm"
                                    onClick={() => removeFile(index)}
                                  >
                                    <span className="svg-container attachment--xIcon--hhlua svg-container-block">
                                      <svg
                                        className="svg"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="10"
                                        height="10"
                                        viewBox="0 0 10 10"
                                      >
                                        <path
                                          fill="#000"
                                          fillOpacity="1"
                                          fillRule="nonzero"
                                          stroke="none"
                                          d="m5 5.71 3.646 3.647.707-.707-3.646-3.647 3.646-3.646L8.646.65 5 4.296 1.353.65l-.707.707 3.646 3.646L.646 8.65l.707.707L5 5.71z"
                                        ></path>
                                      </svg>
                                    </span>
                                  </button>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                )}

                <div
                  className="thread_comment_composer--actions--7LRnN"
                  style={{
                    BorderTop:
                      comment.trim().length > 0
                        ? "1px solid rgba(0, 0, 0, 0.2)"
                        : null,
                  }}
                >
                  {(comment.trim().length > 0 || attachFile?.length > 0) && (
                    <div className="thread_comment_composer--leftComposerButtonGroup--6jQ3I">
                      {userList?.length > 0 && (
                        <Tooltip content="Add mention" placement="bottom">
                          <button
                            className="raw_components--iconButtonEnabled--WmVk5 raw_components--_iconButton---ybo6"
                            tabIndex="0"
                            data-tooltip-type="text"
                            data-tooltip="Add mention"
                            aria-label="Add mention"
                            aria-disabled="false"
                            onClick={() => {
                              userSuggestion();
                            }}
                          >
                            <span className="svg-container svg-container-block">
                              <svg
                                className="svg"
                                xmlns="http://www.w3.org/2000/svg"
                                width="32"
                                height="32"
                                viewBox="0 0 32 32"
                              >
                                <path
                                  fill="#000"
                                  fillOpacity="1"
                                  fillRule="nonzero"
                                  stroke="none"
                                  d="M15.888 24.057c1.362 0 2.947-.41 3.886-.92l-.326-.896c-.69.378-2.122.863-3.528.863-4.225 0-6.846-2.767-6.846-7.076 0-4.25 2.653-7.088 6.641-7.088 4.015 0 6.36 3.042 6.36 6.36 0 2.64-.587 4.027-1.981 4.027-.767 0-1.106-.524-1.106-1.42v-5.925h-1.042v1.157h-.064c-.326-.805-1.38-1.419-2.684-1.336-1.956.134-3.465 1.822-3.465 4.142 0 2.404 1.304 4.277 3.465 4.328 1.419.038 2.326-.729 2.735-1.65h.064c.103 1.215 1.145 1.643 2.084 1.643 2.41 0 3.017-2.524 3.017-4.953 0-3.817-2.761-7.313-7.331-7.313C11.087 8 8 11.19 8 15.996c0 4.916 3.017 8.06 7.888 8.06zm-.556-4.788c-1.694 0-2.576-1.579-2.576-3.375 0-1.694 1.048-3.12 2.608-3.12 1.61 0 2.633.979 2.633 3.12 0 2.129-.933 3.375-2.665 3.375z"
                                ></path>
                              </svg>
                            </span>
                          </button>
                        </Tooltip>
                      )}

                      {/* <label> */}
                      {/* <Tooltip
                        content="Upload up to 5 images and GIFs"
                        placement="bottom"
                      >
                        <div>
                          <input
                            type="file"
                            title=""
                            name="fileUploadIconInput-newCommentComposeres"
                            id="fileUploadIconInput-newCommentComposeres"
                            accept="image/png,image/jpeg,image/gif"
                            multiple=""
                            className="file_upload_icon_input--fileInput--qwRav"
                            onChange={(e) => {
                              attachFiles(e);
                            }}
                          />
                          <span className="svg-container svg-container-block">
                            <svg
                              className="svg"
                              xmlns="http://www.w3.org/2000/svg"
                              width="33"
                              height="33"
                              viewBox="0 0 33 33"
                            >
                              <path
                                fill="#000"
                                fillRule="evenodd"
                                d="M21.835 9.447h-11.2c-.773 0-1.4.627-1.4 1.4V18.5l3.659-3.417.337-.315.341.31 8.974 8.177c.413-.244.69-.693.69-1.207v-11.2c0-.773-.627-1.4-1.4-1.4Zm-12.6 12.6v-2.18l4.005-3.74 8.033 7.32H10.635c-.773 0-1.4-.627-1.4-1.4Zm1.4-13.6c-1.325 0-2.4 1.075-2.4 2.4v11.2c0 1.326 1.075 2.4 2.4 2.4h11.2c1.326 0 2.4-1.074 2.4-2.4v-11.2c0-1.325-1.074-2.4-2.4-2.4h-11.2Zm9.6 5.5c0 .829-.671 1.5-1.5 1.5-.828 0-1.5-.671-1.5-1.5 0-.828.672-1.5 1.5-1.5.829 0 1.5.672 1.5 1.5Zm1 0c0 1.38-1.119 2.5-2.5 2.5-1.38 0-2.5-1.12-2.5-2.5 0-1.38 1.12-2.5 2.5-2.5 1.381 0 2.5 1.12 2.5 2.5Z"
                                clipRule="evenodd"
                              ></path>
                            </svg>
                          </span>
                        </div>
                      </Tooltip> */}

                      {/* </label> */}
                    </div>
                  )}
                  {!attachFile.some((ele) => ele.isLoading) &&
                    comment.trim().length > 0 && (
                      <button
                        className="thread_comment_composer--submitUpArrowIcon--e8uT9 raw_components--iconButtonEnabled--WmVk5 raw_components--_iconButton---ybo6 raw_components--transparentIconButtonEnabled--l2bm3 raw_components--iconButtonEnabled--WmVk5 raw_components--_iconButton---ybo6"
                        tabIndex="0"
                        data-tooltip-type="text"
                        data-tooltip="Submit"
                        aria-label="Submit"
                        aria-disabled="false"
                        data-testid="comments-submit-button"
                        onClick={() => submitMessage(mainIndex, currentIndex)}
                      >
                        <span className="svg-container svg-container-block">
                          <svg
                            className="svg"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="#000"
                              fillOpacity="1"
                              fillRule="nonzero"
                              stroke="none"
                              d="M0 12C0 5.373 5.373 0 12 0c6.627 0 12 5.373 12 12 0 6.627-5.373 12-12 12-6.627 0-12-5.373-12-12z"
                            ></path>
                            <path
                              fill="#fff"
                              fillOpacity="1"
                              fillRule="evenodd"
                              stroke="none"
                              d="m12 5.277-5.361 5.594.722.692L11.5 7.244V18h1V7.244l4.139 4.32.722-.693L12 5.277z"
                            ></path>
                          </svg>
                        </span>
                      </button>
                    )}
                  {((attachFile?.length > 0 &&
                    attachFile.some((ele) => ele.isLoading)) ||
                    comment.trim().length === 0) && (
                    <button
                      className="thread_comment_composer--submitUpArrowIconDisabled--iPBY3 thread_comment_composer--submitUpArrowIcon--e8uT9 raw_components--iconButtonDisabled--SoTjK raw_components--_iconButton---ybo6 raw_components--transparentIconButtonEnabled--l2bm3 raw_components--iconButtonEnabled--WmVk5 raw_components--_iconButton---ybo6"
                      tabIndex="-1"
                      data-tooltip-type="text"
                      data-tooltip="Submit"
                      aria-label="Submit"
                      aria-disabled="true"
                      data-testid="comments-submit-button"
                    >
                      <span className="svg-container svg-container-block">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="#000"
                            fillOpacity="1"
                            fillRule="nonzero"
                            stroke="none"
                            d="M0 12C0 5.373 5.373 0 12 0c6.627 0 12 5.373 12 12 0 6.627-5.373 12-12 12-6.627 0-12-5.373-12-12z"
                          />
                          <path
                            fill="#fff"
                            fillOpacity="1"
                            fillRule="evenodd"
                            stroke="none"
                            d="m12 5.277-5.361 5.594.722.692L11.5 7.244V18h1V7.244l4.139 4.32.722-.693L12 5.277z"
                          />
                        </svg>
                      </span>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </StyledDiv>
      <ReactModal
        role="dialog"
        isOpen={zoomPoup}
        closeTimeoutMS={0}
        overlayClassName="atd-modal-overlay"
        style={customStyles}
        onRequestClose={() => setZoomPoup(false)}
      >
        <ZoomDiv>
          <ZoomFiles zoomFiles={zoomFiles} onClose={onClose} />
        </ZoomDiv>
      </ReactModal>
    </>
  );
}

export default NewComment;
