import React, { useState, useEffect } from "react";
import "./Preview.css";
import { PreviewModal, Button } from "@atd/components";
import axios from "axios";
import AWSService from "../../features/AWS/AWSConfig";
import Spinners from "./Spinners";

export const FilePreview = (file, type) => {
  const [fileUrl, setFileUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState(true);
  const [fileName, setFileName] = useState("");
  const [fileType, setFileType] = useState("");
  const [enableFileType, setEnableFileType] = useState(false);
  const [error, setError] = useState(false);
  const [responseType, setResponseType] = useState("arraybuffer");

  useEffect(() => {
    const fileNames = file.file.split("/").pop();
    setFileName(fileNames);
    const urlObject = new URL(file.file);
    const filePath = urlObject.pathname.substring(1);

    const getObjectParams = {
      Bucket: AWSService.getAWSBucketName(),
      Key: filePath,
    };
    const fileResponseType = {
      csv: "csv",
    };

    if (fileResponseType[type] !== undefined) {
      setResponseType("text");
    } else {
      setResponseType("arraybuffer");
    }
    // Use the AWS SDK to get a pre-signed URL for the S3 object
    AWSService.getAWSAccessKey().getSignedUrl(
      "getObject",
      getObjectParams,
      (err, url) => {
        if (err) {
          console.error("Error generating pre-signed URL:", err);
          return;
        }
        axios
          .get(url, { responseType: "arraybuffer" })
          .then((response) => {
            // Handle the file content or use the URL as needed
            console.log("File content:", response.data);

            const fileExtension = fileNames.split(".").pop().toLowerCase();

            // Map file extensions to file types
            const fileTypeMap = {
              pdf: "application/pdf",
              doc: "application/msword",
              docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
              xls: "application/vnd.ms-excel",
              xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              ppt: "application/vnd.ms-powerpoint",
              pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
              txt: "text/plain",
              mp4: "video/mp4",
              mov: "video/quicktime",
              avi: "video/x-msvideo",
              mkv: "video/x-matroska",
              mp3: "audio/mpeg",
              wav: "audio/wav",
              ogg: "audio/ogg",
              csv: "text/plain",
            };
            setFileType(fileTypeMap[fileExtension]);
            console.log("FileType", fileTypeMap[fileExtension]);

            const blob = new Blob([response.data], {
              type: fileTypeMap[fileExtension],
            }); // Adjust the type accordingly

            // Create a data URL
            const imageUrl = URL.createObjectURL(blob);
            const fileExtensionList = {
              xls: "xls",
              doc: "doc",
              docx: "docx",
              xlsx: "xlsx",
              ppt: "ppt",
              pptx: "pptx",
            };

            if (fileExtensionList[fileExtension] !== undefined) {
              setEnableFileType(true);
              let url = `https://view.officeapps.live.com/op/embed.aspx?src=${file.file}`;
              // Set the URL to the state
              console.log("file url", url, file);
              console.log(
                "hbhehrgf",
                fileExtensionList[fileExtension],
                enableFileType,
                imageUrl,
              );
              setFileUrl(url);
              setLoading(true);
            } else {
              setFileUrl(imageUrl);
              setLoading(true);
            }
          })
          .catch((error) => {
            setError(true);
            // Handle errors
            console.error("Error fetching file:", error);
          });
      },
    );
  }, []);

  const download = () => {
    window.open(file.file);
  };
  console.log("responseType", responseType);
  return (
    <>
      <PreviewModal
        id="adduser"
        title={fileName}
        isOpen={isOpenEditModal}
        onClose={setIsOpenEditModal}
      >
        {error === false && (
          <div className="iframe-preview">
            {loading === true && error === false && (
              <iframe
                title="PDF Viewer"
                srcDoc={`<!DOCTYPE html>
                      <html lang="en">
                        <head>
                          <meta charset="UTF-8">
                          <meta name="viewport" content="width=device-width, initial-scale=1.0">
                          <style>
                                body {
                                margin: 0;
                                padding: 0;
                                height: 100vh; 
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                overflow:hidden;
                              }
                              div {
                                width: 100%;
                                height: 100%;
                              }
                              embed {
                                width: 100%;
                                height: 100%;
                              }
                          </style>
                        </head>
                        <body>
                          <div>
                          ${
                            enableFileType === false
                              ? `<embed src="${fileUrl}" width="100%" height="100%" type="${fileType}" />`
                              : ""
                          }
                         ${
                           enableFileType === true
                             ? `<embed  src="${fileUrl}"" width="100%" height="100%"/>`
                             : ""
                         }
                          </div>
                        </body>
                      </html>`}
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#ffffff",
                }}
              ></iframe>
            )}
            {loading === false && error === false && (
              <Spinners style={{ height: "100%" }} />
            )}
          </div>
        )}
        {error === true && (
          <p className="error-message">Failed to load resource </p>
        )}

        <PreviewModal.Footer>
          <div className="Box-root Box-hideIfEmpty">
            <Button variant="primary" type="button" onClick={() => download()}>
              Download
            </Button>
          </div>
        </PreviewModal.Footer>
      </PreviewModal>
    </>
  );
};
