import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import AWSService from "../../../features/AWS/AWSConfig";
import "./ImagesPreview.css";
import { ThumbnailContainer } from "@atd/components";
import PSD from "psd.js";
import { getCredentialCookies } from "@atd/utils";

export const ImagesPreview = (file) => {
  const [url, setUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [files, setFiles] = useState({});

  const [fileName, setFileName] = useState("");

  useEffect(() => {
    const roleAction = () => {
      const permissionStart =
        !file.annotation_id && getCredentialCookies()?.role === "5"
          ? true
          : file.annotation_id
          ? true
          : false;

      let data = {
        ...file,
        openFile: file.activityType === "delivery" && permissionStart,
      };
      setFiles(data);
    };
    roleAction();
  }, [file]);
  useEffect(() => {
    const fileNames = file.file.split("/").pop();
    setFileName(fileNames);

    const urlObject = new URL(file.file);
    const filePath = urlObject.pathname.substring(1);

    const getObjectParams = {
      Bucket: AWSService.getAWSBucketName(),
      Key: filePath,
    };

    // Use the AWS SDK to get a pre-signed URL for the S3 object
    AWSService.getAWSAccessKey().getSignedUrl(
      "getObject",
      getObjectParams,
      (err, url) => {
        if (err) {
          console.error("Error generating pre-signed URL:", err);
          return;
        }

        axios
          .get(url, { responseType: "arraybuffer" })
          .then((response) => {
            // Handle the file content or use the URL as needed
            const fileBlob = new Blob([response.data]);
            const contentType = response.headers["content-type"];
            const fileType = contentType || fileBlob.type;

            const blob = new Blob([response.data], {
              type: file.type === "svg" ? "image/svg+xml" : fileType,
            });
            if (file.type !== "psd") {
              const imageUrl = URL.createObjectURL(blob);
              // Set the URL to the state
              setUrl(imageUrl);
            } else if (file.type === "psd") {
              // Set the URL to the state
              setUrl(response.data);
            }

            setLoading(true);
          })
          .catch((error) => {
            setError(true);
            // Handle errors
            console.error("Error fetching file:", error);
          });
      },
    );
  }, [setLoading, setUrl, file.file, file.type]);

  const PSDViewer = ({ psdData }) => {
    const canvasRef = useRef(null);
    useEffect(() => {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");

      const renderPSD = async () => {
        const blob = new Blob([psdData]);
        const psd = await PSD.fromDroppedFile(blob);
        const image = await psd.image.toPng();

        canvas.width = 400;
        canvas.height = 250;
        const desiredWidth = 400;
        const desiredHeight = 250;
        ctx.drawImage(image, 0, 0, desiredWidth, desiredHeight);
      };

      renderPSD();

      return () => {
        // Clean up code if necessary
      };
    }, [psdData]);

    return <canvas ref={canvasRef}></canvas>;
  };

  console.log("file", file);

  return (
    <>
      <ul className="Box-root accordion-menu accordion-menu Margin-top--0 Margin-bottom--0">
        <li className="open">
          <section className="content unset-border no-padding">
            <div className="file-wt">
              <div className="imgwrap">
                <div
                  title={fileName}
                  className="imgdiv"
                  draggable="true"
                  style={{
                    marginTop: "0px",
                    opacity: "1",
                    position: "relative",
                  }}
                >
                  {file.activityType === "delivery" &&
                    file.notification !== 0 && (
                      <span className="thread-label" title={file.notification}>
                        {file.notification}
                      </span>
                    )}

                  {loading && error === false && (
                    <>
                      {file?.type !== "psd" ? (
                        <>
                          <ThumbnailContainer
                            url={url}
                            file={files}
                            fileName={fileName}
                            loading={loading}
                            error={error}
                          />
                        </>
                      ) : (
                        <PSDViewer psdData={url} />
                      )}
                    </>
                  )}
                  {loading === false && error === false && (
                    <ThumbnailContainer loading={loading} error={error} />
                  )}
                </div>
              </div>
            </div>
          </section>
        </li>
      </ul>
    </>
  );
};
