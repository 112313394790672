import ReactModal from "react-modal";

import PreviewModalFooter from "./PreviewModalFooter";

import "./PreviewModal.css";

ReactModal.setAppElement(document.getElementById("root"));

function ModalBody({ children, backgroundColor, className, ...rest }) {
  return (
    <div
      style={{
        overflowY: "auto",
        maxHeight: "85vh",
        borderBottomLeftRadius: 4,
        backgroundColor,
      }}
      className={className}
    >
      <div className="pd16" {...rest}>
        {children}
      </div>
    </div>
  );
}

function PreviewModal({
  title,
  children,
  isOpen,
  width,
  hasFooter = true,
  backgroundColor,
  onClose,
  className,
  ...rest
}) {
  const customStyles = {
    overlay: {
      overflowY: "auto",
    },
    content: {
      width: "100%",
      height: "100%",
      backgroundColor: "transparent",
      borderRadius: "unset",
    },
  };

  return (
    <ReactModal
      role="dialog"
      isOpen={isOpen}
      closeTimeoutMS={300}
      onRequestClose={() => onClose(!isOpen)}
      className={`atd-modal-container ${className}`}
      overlayClassName="atd-modal-overlay"
      style={customStyles}
      {...rest}
    >
      {children}
    </ReactModal>
  );
}

PreviewModal.Footer = PreviewModalFooter;
PreviewModal.Body = ModalBody;

export default PreviewModal;
