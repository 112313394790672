import "./Review.css";

import { Spinner, FeedbackRating } from "@atd/components";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getCredentialCookies } from "@atd/utils";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import rating from "../../FeedbackRating/rating";
const Review = React.memo(
  ({
    onClose,
    selectedVersion,
    isSubmit,
    finishReview,
    addOneMoreReview,
    rolePermissions,
    versionHistory,
    createDate,
  }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [reviewStatus, setReviewStatus] = useState("");
    const [additionalComments, setAdditionalComments] = useState("");
    const [addAnotherReview, setAddAnotherReview] = useState(false);
    const [ratingStatus, setRatingStatus] = useState(3);
    const activeRating = rating.find((r) => r.id === ratingStatus);
    const [error, setError] = useState(false);

    useEffect(() => {
      if (isSubmit) {
        setAddAnotherReview(null);
      } else if (isSubmit === false) {
        setAddAnotherReview((prev) => (prev === null ? true : isSubmit));
      }
    }, [isSubmit]);
    useEffect(() => {
      if (
        rolePermissions.request_changes &&
        (getCredentialCookies().role === "5" ||
          getCredentialCookies().role === "3")
      ) {
        setReviewStatus("1");
        setError(false);
      } else if (
        !rolePermissions.request_changes &&
        (getCredentialCookies().role === "5" ||
          getCredentialCookies().role === "3")
      ) {
        setReviewStatus("0");
        setError(true);
      }
    }, [rolePermissions]);

    const handleReviewStatusChange = useCallback(
      (event) => {
        if (
          Object.keys(additionalComments)?.length === 0 &&
          event.target.value === "0"
        ) {
          setError(true);
        } else {
          setError(false);
        }

        setReviewStatus(event.target.value);
      },
      [additionalComments],
    );

    const handleSubmit = useCallback(async () => {
      if (!error) {
        const finishReviewStatus = {
          reviewStatus: reviewStatus,
          additionalComments: reviewStatus === "0" ? additionalComments : "",
          rating: ratingStatus,
        };
        await finishReview(finishReviewStatus);
      }
    }, [additionalComments, error, finishReview, ratingStatus, reviewStatus]);

    const onClosed = () => {
      if (addAnotherReview) {
        navigate(`/requests/thread/${queryParams.get("request_id")}`);
      } else {
        onClose();
      }
    };
    const handleChange = (e) => {
      const value = e.target.value;
      setAdditionalComments(value);
      if (Object.keys(value)?.length === 0 && reviewStatus === "0") {
        rolePermissions.finish_uploading = false;
        setError(true);
      } else {
        rolePermissions.finish_uploading = false;
        rolePermissions.finish_uploading = true;
        setError(false);
      }
    };

    return (
      <>
        <div
          className="zoom-anim-dialog mfp-hide dialog"
          style={{ width: "550px" }}
        >
          <div className="pop-title-new-version Box-divider--light-bottom-1">
            Finish review
            {!isSubmit && (
              <button
                type="button"
                className="new-version-close"
                onClick={onClosed}
              >
                x
              </button>
            )}
          </div>
          {addAnotherReview && (
            <div className="ormsg" onClick={addOneMoreReview}>
              You've requested changes. Add another review.
            </div>
          )}
          <div className="pd16" style={{ overflow: "auto", maxHeight: "70vh" }}>
            {!addAnotherReview &&
              (getCredentialCookies().role === "5" ||
                getCredentialCookies().role === "3") && (
                <div>
                  <div
                    className="pop-title"
                    style={{ padding: "0", background: "transparent" }}
                  >
                    Finish reviewing this version
                  </div>

                  <div className="radio">
                    <input
                      id="first"
                      type="radio"
                      name="numbers"
                      value="0"
                      checked={reviewStatus === "0"}
                      onChange={handleReviewStatusChange}
                      disabled={
                        !rolePermissions?.approve_this_work ||
                        isSubmit ||
                        rolePermissions.request_changes
                      }
                    />
                    <label htmlFor="first" id="first-label">
                      Approve this work
                    </label>
                    <input
                      id="second"
                      type="radio"
                      name="numbers"
                      value="1"
                      checked={reviewStatus === "1"}
                      disabled={isSubmit}
                      onChange={handleReviewStatusChange}
                    />
                    <label htmlFor="second">Request changes</label>
                  </div>

                  {rolePermissions?.approve_this_work &&
                    reviewStatus === "0" && (
                      <>
                        <section className="form">
                          <div className="container-half">
                            <FeedbackRating
                              ratings={ratingStatus}
                              setRating={setRatingStatus}
                            />
                            <h4 className="common-BodyText">
                              {activeRating.description}
                            </h4>
                          </div>
                        </section>
                        <textarea
                          className="txtar"
                          placeholder="Additional comments"
                          onChange={(event) => handleChange(event)}
                          value={additionalComments}
                        />
                      </>
                    )}
                </div>
              )}

            {!addAnotherReview && (
              <>
                {reviewStatus === "0" && (
                  <>
                    <div className="browbtn">
                      <div className="Box-root Flex-flex mgt2 fltrgt">
                        <div className="Box-root Box-hideIfEmpty pointer1">
                          <button
                            type="button"
                            className={`bl-but ${
                              !rolePermissions.finish_uploading || isSubmit
                                ? "disabled-finish-uploading"
                                : ""
                            }`}
                            onClick={handleSubmit}
                            disabled={
                              isSubmit || !rolePermissions.finish_uploading
                            }
                          >
                            {isSubmit ? <Spinner /> : ""} &nbsp; Finish
                            uploading
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {reviewStatus === "1" && (
                  <>
                    <div className="browbtn">
                      <div className="Box-root Flex-flex mgt2 fltrgt">
                        <div className="Box-root Box-hideIfEmpty pointer1">
                          <button
                            type="button"
                            className={`bl-but ${
                              !rolePermissions.request_changes || isSubmit
                                ? "disabled-finish-uploading"
                                : ""
                            }`}
                            onClick={handleSubmit}
                            disabled={
                              isSubmit || !rolePermissions.request_changes
                            }
                          >
                            {isSubmit ? <Spinner /> : ""} &nbsp; Finish
                            uploading
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {reviewStatus === "" && (
                  <>
                    <div className="browbtn">
                      <div className="Box-root Flex-flex mgt2 fltrgt">
                        <div className="Box-root Box-hideIfEmpty pointer1">
                          <button
                            type="button"
                            className={`bl-but ${
                              !rolePermissions.request_changes || isSubmit
                                ? "disabled-finish-uploading"
                                : ""
                            }`}
                            onClick={handleSubmit}
                            disabled={
                              isSubmit || !rolePermissions.request_changes
                            }
                          >
                            {isSubmit ? <Spinner /> : ""} &nbsp; Finish
                            uploading
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}

            <div
              className="pop-title"
              style={{
                padding: "0",
                marginTop: "20px",
                background: "transparent",
              }}
            >
              Version history
            </div>
            <div
              style={{
                maxHeight: "300px",
                position: "relative",
              }}
            >
              {versionHistory?.length > 0 &&
                versionHistory.map((hist, index) => (
                  <div className="timeline">
                    <div
                      className="atd-Fieldset-row"
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                    >
                      <div className="bs-Fieldset-field">
                        <div className="bs-Fieldset-explanation">
                          <div className="circle">
                            <p className="circle-inner">{hist?.version}</p>
                          </div>
                        </div>
                        <div className="version-review">
                          <strong>
                            {hist?.user_name} {hist?.comment_history}
                          </strong>
                          <br />
                          <span style={{ fontSize: "13px", color: "#666" }}>
                            {moment(hist?.created_on).format(
                              "MMM DD, YYYY hh:mm A",
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              {versionHistory?.length >= 1 && (
                <div
                  className="atd-Fieldset-row"
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                >
                  <div className="bs-Fieldset-field">
                    <p className="bs-Fieldset-explanation">
                      <div className="circle">
                        <p className="circle-inner">
                          {versionHistory[versionHistory.length - 1].version}
                        </p>
                      </div>
                    </p>
                    <div className="version-review">
                      <strong>
                        Version{" "}
                        {
                          versionHistory[
                            versionHistory.length - 1
                          ].version?.split("")[1]
                        }
                      </strong>
                      <br />
                      <span style={{ fontSize: "13px", color: "#666" }}>
                        {moment(createDate).format("MMM DD, YYYY hh:mm A")}
                      </span>
                    </div>
                    <p></p>
                  </div>
                </div>
              )}{" "}
              {versionHistory?.length === 0 && (
                <div
                  className="atd-Fieldset-row"
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                >
                  <div className="bs-Fieldset-field">
                    <p className="bs-Fieldset-explanation">
                      <div className="circle">
                        <p className="circle-inner">{selectedVersion}</p>
                      </div>
                    </p>
                    <div className="version-review">
                      <strong>Version {selectedVersion?.split("")[1]}</strong>
                      <br />
                      <span style={{ fontSize: "13px", color: "#666" }}>
                        {moment(createDate).format("MMM DD, YYYY hh:mm A")}
                      </span>
                    </div>
                    <p></p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  },
);

export default Review;
