import React, { useState, useEffect } from "react";
import "./Preview.css";
import { PreviewModal, Button } from "@atd/components";
import axios from "axios";
import AWSService from "../../features/AWS/AWSConfig";
import Spinners from "./Spinners";

export const ImagePreview = (file) => {
  const [pdfUrl, setPdfUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState(true);
  const [fileName, setFileName] = useState("");
  console.log("image file", file);
  useEffect(() => {
    const fileNames = file.file.split("/").pop();
    setFileName(fileNames);
    const urlObject = new URL(file.file);
    const filePath = urlObject.pathname.substring(1);
    const getObjectParams = {
      Bucket: AWSService.getAWSBucketName(),
      Key: filePath,
    };

    // Use the AWS SDK to get a pre-signed URL for the S3 object
    AWSService.getAWSAccessKey().getSignedUrl(
      "getObject",
      getObjectParams,
      (err, url) => {
        if (err) {
          console.error("Error generating pre-signed URL:", err);
          return;
        }

        axios
          .get(url, { responseType: "arraybuffer" })
          .then((response) => {
            // Handle the file content or use the URL as needed
            const fileBlob = new Blob([response.data]);
            const contentType = response.headers["content-type"];
            const fileType = contentType || fileBlob.type;

            const blob = new Blob([response.data], { type: fileType });
            const imageUrl = URL.createObjectURL(blob);
            // Set the URL to the state
            setPdfUrl(imageUrl);
            setLoading(true);
          })
          .catch((error) => {
            setError(true);
            // Handle errors
            console.error("Error fetching file:", error);
          });
      },
    );
  }, []);

  const download = () => {
    window.open(file.file);
  };
  return (
    <>
      <PreviewModal
        id="adduser"
        title={fileName}
        isOpen={isOpenEditModal}
        onClose={setIsOpenEditModal}
      >
        {error === false && (
          <div className="iframe-preview">
            {loading === true && error === false && (
              <img
                src={pdfUrl}
                alt="Loaded Image"
                width="100%"
                height="95%"
                style={{ borderRadius: "5px", background: "white" }}
              />
            )}
            {loading === false && error === false && (
              <Spinners style={{ height: "100%" }} />
            )}
          </div>
        )}
        {error === true && (
          <p className="error-message">Failed to load resource </p>
        )}

        <PreviewModal.Footer>
          <div className="Box-root Box-hideIfEmpty">
            <Button variant="primary" type="button" onClick={() => download()}>
              Download
            </Button>
          </div>
        </PreviewModal.Footer>
      </PreviewModal>
    </>
  );
};
