import { ImagePreview } from "./ImagePreview";
import { FilePreview } from "./FilePreview";
import React, { useState, forwardRef, useEffect } from "react";

const Preview = forwardRef(
  ({ file, name, fullName, type, onRemove, isDeletable }) => {
    const [imageType, setImageType] = useState(null);
    const [fileType, setFileType] = useState(null);

    useEffect(() => {
      console.log("react", type, file);

      try {
        // Check if it's an image file
        const isImage = [
          "jpg",
          "jpeg",
          "png",
          "gif",
          "bmp",
          "svg",
          "raw",
          "tiff",
          "tif",
          "webp",
          "heif",
          "heic",
          "ico",
        ].includes(type);
        // Check if it's a text file
        const isText = [
          "pdf",
          "doc",
          "docx",
          "xls",
          "xlsx",
          "csv",
          "ppt",
          "pptx",
          "txt",
          "mp4",
          "mov",
          "avi",
          "mkv",
          "mp3",
          "wav",
          "ogg",
        ].includes(type);

        if (isImage) {
          setImageType("image");
        } else if (isText) {
          setFileType("file");
        } else {
          window.open(file);
          setImageType("unsupported");
          setFileType("unsupported");
        }
        console.log("fileType", fileType, imageType, isText, isImage);
      } catch (error) {
        console.error("Error fetching or checking file type:", error);
      }
    }, [type]);

    return (
      <>
        {imageType === "image" && <ImagePreview file={file}></ImagePreview>}
        {fileType === "file" && (
          <FilePreview file={file} type={type}></FilePreview>
        )}
      </>
    );
  },
);

Preview.defaultProps = {
  isDeletable: true,
};

export default Preview;
