import React from "react";
import styled from "styled-components";
import { Box, Text } from "@atd/components";
import moment from "moment";
import { Link } from "react-router-dom";
import { getActivityType, getActivityLog } from "@atd/utils";
import Border from "../Border";
const DotSeperator = styled.div`
  display: flex;
  align-items: center;

  & .separator {
    width: 4px;
    height: 4px;
    border-radius: 100px;
    margin: 8px;
    background-color: grey;
  }
`;
const TicketLink = styled(Link)`
  line-height: 20px;
  font-weight: 500;
  font-size: 14px;
`;
const getDeviceName = (type) => {
  const deviceNames = {
    windows: "Windows",
    macos: "mac OS",
    linux: "Linux",
    android: "Android",
    ios: "IOS",
  };
  return deviceNames[type] ? deviceNames[type] : type;
};

const getEntityName = (activity) => {
  return activity?.user_type === "admin" ||
    activity?.type === "logged_in" ||
    activity?.type === "logout"
    ? activity?.type === "status_update"
      ? `The ${activity?.user_name}`
      : activity?.user_name
    : activity?.type === "status_update"
    ? `The ${activity?.company_name}`
    : activity?.type === "request_opened" ||
      activity?.type === "delivery_scheduled"
    ? `${activity?.user_name}`
    : activity?.company_name;
};
function Activity({ activity, assignedUsers }) {
  const date = moment(activity?.created).add(
    -new Date().getTimezoneOffset(),
    "minutes",
  );
  return (
    <>
      <Box
        flex={{ direction: "row" }}
        className="Margin-vertical--10"
        style={{ margin: "10px 0px", marginBottom: "5px" }}
      >
        <Text color="gray900" weight="medium">
          {getEntityName(activity)} {getActivityType(activity)}
        </Text>
        {activity?.s_no ? (
          <TicketLink to={`/requests/thread/${activity.request_id}`}>
            {" "}
            &nbsp; #{activity?.s_no} &nbsp;{" "}
          </TicketLink>
        ) : (
          <> &nbsp;</>
        )}
        <Text color="gray900" weight="medium">
          {getActivityLog(activity, assignedUsers)}
        </Text>
      </Box>
      <Box
        flex={{
          direction: "row",
          alignItems: "center",
          justifyContent: "space-evenly",
        }}
      >
        <Text>{getDeviceName(activity?.user_device)}</Text>
        <DotSeperator>
          <div className="separator"></div>
        </DotSeperator>
        <Text>
          {activity?.user_city},&nbsp;{activity?.user_country}
        </Text>
        <DotSeperator>
          <div className="separator"></div>
        </DotSeperator>
        <Text>{date.format("MMM DD YYYY, h:mm A")}</Text>
      </Box>
      <Border
        border={{ position: "bottom" }}
        margin={{ mt: "20px", mb: "10px" }}
      />
    </>
  );
}

export default Activity;
