import { useState, useEffect } from "react";
import moment from "moment";
import styled from "styled-components";

import { Box, Collapsible } from "@atd/components";
import { isAdmin } from "@atd/utils";
import { withSelector } from "@atd/hoc";

import CreateReply from "./CreateReply";
import Attachment, { Attachments } from "../Attachments";
import { StatusThread } from "../../Status";

const ReplyView = styled(Box)`
  table div {
    position: relative !important;
  }
`;

function Reply(props) {
  const {
    user,
    index,
    type,
    online,
    attachments,
    from_company,
    user_name,
    created,
    body,
    isNew,
    isEditing,
    onCacel,
    user_type,
    client_time_zone,
  } = props;

  const [expanded, setExpanded] = useState(true);

  useEffect(() => {
    setExpanded(true);
  }, []);

  const { role } = user || {};

  if (isNew || isEditing) {
    return (
      <Box flex margin={{ bottom: 20 }}>
        <CreateReply
          type={type}
          index={index}
          attachments={attachments}
          onCacel={onCacel}
        />
      </Box>
    );
  }

  let name;
  if ((!isAdmin() && user_type === "client") || isAdmin()) {
    name = user_name;
  } else {
    name = from_company;
  }

  return (
    <ReplyView flex>
      <Collapsible
        margin={{ top: 0, bottom: 0 }}
        className="accordion-menu"
        style={{ flex: 1 }}
      >
        <Collapsible.Item
          isOpen={expanded}
          title={
            <StatusThread
              name={name}
              isOnline={online}
              clientTimezone={client_time_zone}
            />
          }
          headerElement={
            <span className="cont3 flr">
              {moment(created).format("MMM DD, YYYY hh:mm A")}
            </span>
          }
        >
          <div style={{ padding: "20px" }}>
            <div className="cont3" dangerouslySetInnerHTML={{ __html: body }} />
            {attachments?.length > 0 && (
              <Attachments>
                {attachments.map((file) => (
                  <Attachment
                    isDraggable={!(isAdmin() && user_type === "client")}
                    key={file.link}
                    file={file}
                    isDeletable={false}
                    role={role}
                    type={props.activity.doc_type}
                    activity_type={props.activity.type}
                    replyId={props.id}
                    requestId={props.request_id}
                  />
                ))}
              </Attachments>
            )}
          </div>
        </Collapsible.Item>
      </Collapsible>
    </ReplyView>
  );
}

export default withSelector((state) => state.user)(Reply);
