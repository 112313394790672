import { Button } from "@atd/components";
import { useNavigate, useLocation } from "react-router-dom";

import "./AlertInfo.css";

function PageExitAlert({ setIsOpenEditModal, onExit }) {
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  return (
    <>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div className="Box-root Box-hideIfEmpty Margin-right--8 Margin-bottom--12 Margin-top--12">
          <Button
            className="exit-annotation-page"
            variant="default"
            type="button"
            onClick={() => setIsOpenEditModal(false)}
          >
            No
          </Button>
        </div>
        <div className="Box-root Box-hideIfEmpty  Margin-bottom--12 Margin-top--12">
          <Button
            className="exit-annotation-page"
            variant="primary"
            type="submit"
            onClick={() =>
              navigate(`/requests/thread/${queryParams.get("request_id")}`)
            }
          >
            Yes
          </Button>
        </div>
      </div>
    </>
  );
}

export default PageExitAlert;
