import { useState, useEffect, useCallback, memo } from "react";
import { Field, useFormikContext } from "formik";
import { useSelector } from "react-redux";
import { flatMap, map, filter, debounce } from "lodash";
import { Link } from "react-router-dom";

import RequestService from "@atd/features/request/requestApi";
import { Box, Select, Icon, Tooltip, Text } from "@atd/components";

import PlanDetails from "@atd/pages/Settings/Plans/PlanDetails";

function RequestType({
  touched,
  request,
  activeRequestType,
  designTypeOptions,
  setActiveRequestType,
  setDesignTypeOptions,
  onSetFieldValue,
}) {
  const { errors, values, setFieldValue } = useFormikContext();

  const { dimentions, request_name, request_type } = values || {};
  const { category_item_id } = dimentions?.[0] || {};

  const [hasProtectedType, setHasProtectedType] = useState(false);

  const { request_types } = useSelector((state) => state.requests);

  const flatOptions = flatMap(designTypeOptions, ({ options }) =>
    filter(options, (item) => item.show_lock),
  );

  const disabledItems = map(flatOptions, ({ id }) => id);

  const onChangeRequestType = useCallback(
    async (value) => {
      const activeRequest = designTypeOptions
        ? designTypeOptions?.find((option) =>
            option.options.some((el) => el.id === value),
          )
        : {};

      if (activeRequest) {
        const activeRequestItem = activeRequest.options.find(
          (el) => el.id === value,
        );
        setActiveRequestType(activeRequestItem);

        const fileDimensions = dimentions.map((dimention) => ({
          ...dimention,
          category_id: activeRequest.id,
          category_item_id: value,
        }));

        onSetFieldValue(null, {
          request_type: activeRequest.value,
          request_icon: activeRequestItem.icon,
          file_dimentions: fileDimensions,
          dimentions: fileDimensions,
        });

        const modifiedFileDimention = dimentions.reduce(
          (acc, fileDimention) => {
            if (fileDimention.length > 0 && fileDimention.height > 0) {
              acc.push(`${fileDimention.length}W x ${fileDimention.height}H`);
            }
            return acc;
          },
          [],
        );

        setFieldValue("file_dimentions", modifiedFileDimention.join(", "));
      }
    },
    [
      designTypeOptions,
      dimentions,
      setActiveRequestType,
      setFieldValue,
      onSetFieldValue,
    ],
  );

  useEffect(() => {
    const activeRequest = designTypeOptions
      ? designTypeOptions?.find((option) =>
          option.options.some((el) =>
            request_name.replace(/\s/g, "_").toLowerCase().includes(el.id),
          ),
        )
      : {};

    if (hasProtectedType) {
      const selectedRequest = activeRequest
        ? activeRequest.options.find((el) =>
            request_name.replace(/\s/g, "_").toLowerCase().includes(el.id),
          )
        : null;

      if (selectedRequest) {
        onChangeRequestType(selectedRequest.id);
      }
      if (activeRequest) {
        setFieldValue("request_type", activeRequest.value);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasProtectedType, designTypeOptions, request_name]);

  useEffect(() => {
    let isRequestTypeProtected = false;
    if (request_name) {
      isRequestTypeProtected = disabledItems.some((option) =>
        request_name
          .replace(/\s/g, "_")
          .toLowerCase()
          .includes(option?.toLowerCase()),
      );
    }
    setHasProtectedType(isRequestTypeProtected);
  }, [request_name, disabledItems]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChangeDimentionField = useCallback(
    debounce(async (fieldValue) => {
      if (fieldValue) {
        const dimensions = [
          {
            category_id: dimentions[0].category_id,
            category_item_id: dimentions[0].category_item_id,
            icon: dimentions[0].icon,
            title_id: "custom",
            dimension_string: fieldValue,
            height: 0,
            length: 0,
            units: "px",
          },
        ];
        setFieldValue("dimentions", dimensions);
        await RequestService.updateRequest({
          data: {
            ...values,
            file_dimentions: dimensions,
            dimentions: undefined,
          },
          id: request,
        });
      }
    }, 500),
    [request, dimentions],
  );

  useEffect(() => {
    if (request_types?.length > 0) {
      const dropdownItems = request_types.map((cat) => ({
        id: cat.id,
        value: cat.category_name,
        options: cat.category_items.map((item) => ({
          ...item,
          value: item.name,
        })),
      }));

      if (category_item_id) {
        const activeRequest = dropdownItems.find((option) =>
          option.options.some((el) => el.id === category_item_id),
        );

        if (activeRequest) {
          const activeRequestItem = activeRequest.options.find(
            (el) => el.id === category_item_id,
          );
          setActiveRequestType(activeRequestItem);
        }
      }

      setDesignTypeOptions(dropdownItems);
    }
  }, [
    request_types,
    category_item_id,
    setActiveRequestType,
    setDesignTypeOptions,
  ]);

  const onChangeDimention = useCallback(
    async ({ target }) => {
      const { value, name } = target;
      setFieldValue(name, value);
      await onChangeDimentionField(value);
    },
    [onChangeDimentionField, setFieldValue],
  );

  return (
    <>
      <div className="title2 mgt4">
        Design Type
        <span style={{ color: "red" }}>*</span>
      </div>
      <div className="cont4">
        What type of design would you like us to create?
      </div>
      <div className="FormField mgt1" id="rtype">
        <Select
          interactive
          isOptionGroup
          value={activeRequestType?.id ?? ""}
          // value={category_item_id}
          placeholder="Select your design type"
          disabled={disabledItems}
          options={designTypeOptions}
          onChange={(newValue) => {
            setHasProtectedType(false);
            onChangeRequestType(newValue);
          }}
          containerOptions={{
            style: { width: 344 },
          }}
          inputStyle={{ minWidth: 344 }}
          inputProps={{
            className: `iput ${
              ((errors.request_type && touched.request_type) ||
                hasProtectedType) &&
              "FormField__input--hasError"
            }`,
          }}
          renderOption={({ name, show_lock }) => (
            <>
              {!show_lock && <span>{name}</span>}
              {show_lock && (
                <Tooltip
                  interactive
                  allowHTML
                  content={
                    '<div style="max-width: 200px;">Sorry, it\'s not a part of your current plan. Please <a href="/settings/plans" style="color: #ddd; text-decoration: underline;cursor:pointer">upgrade</a> your plan to unlock.</div>'
                  }
                  placement="top"
                >
                  <Box flex={{ alignItems: "center" }}>
                    <button
                      type="button"
                      style={{
                        background: "none",
                        border: "none",
                        padding: 0,
                        marginRight: 5,
                      }}
                    >
                      <Icon icon="lock" />
                    </button>
                    <span>{name}</span>
                  </Box>
                </Tooltip>
              )}
            </>
          )}
        />
        {errors.request_type && touched.request_type ? (
          <div className="FormField__input--error">{errors.request_type}</div>
        ) : null}
        {hasProtectedType && (
          <div className="FormField__input--error">
            <Box flex={{ alignItems: "center" }}>
              <Icon icon="warning" color="red" padding={{ right: 8 }} />{" "}
              <span style={{ fontWeight: 500 }}>
                Sorry, it&rsquo;s not a part of your current plan. Please{" "}
                <Link to="/settings/plans">upgrade</Link> your plan to unlock.
              </span>{" "}
              <Box margin={{ left: 8 }}>
                <PlanDetails />
              </Box>
            </Box>
          </div>
        )}
      </div>
      {request_type && (
        <>
          <Box
            flex={{ direction: "row", alignItems: "center" }}
            className="wt200"
          >
            <Box className="title2 mgt4 Margin-right--8">
              Design Dimension
              {/* <span style={{ color: "red" }}>*</span> */}
            </Box>
            <Box hideIfEmpty className="mwt58 mgt4">
              <Box
                background="gray100"
                flex={{
                  display: "inline-flex",
                  direction: "row",
                  alignItems: "center",
                }}
                aria-label="0.0%"
                title="0%"
                className="Badge Badge--variant--status"
              >
                <Text
                  as="span"
                  color="gray600"
                  weight="medium"
                  lineHeight={16}
                  size={12}
                  wrap="nowrap"
                  className="Badge-text"
                >
                  Optional
                </Text>
              </Box>
            </Box>
          </Box>
          <div className="cont4">
            What is your preferred design size? Please mention the sizes in
            pixels, inches, centimeters, millimeters, or feet.
            <br /> For example, 10Wx40H px
          </div>
          <div className="FormField mgt1">
            <Field
              name="file_dimentions"
              className={`iput ${
                errors.file_dimentions &&
                touched.file_dimentions &&
                "FormField__input--hasError"
              }`}
              placeholder="eg: 10W x 10H px"
              onChange={onChangeDimention}
            />
          </div>
          <div className="FormField mgt1">
            {errors.file_dimentions && touched.file_dimentions ? (
              <div className="FormField__input--error">
                {errors.file_dimentions}
              </div>
            ) : null}
          </div>
        </>
      )}
    </>
  );
}

export default memo(RequestType);
