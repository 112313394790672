/* eslint-disable jsx-a11y/anchor-is-valid */
import classnames from "classnames";
import React from "react";

import Menu from "./Menu/Menu";
import Profile from "./Profile";

import "./Sidebar.css";

const Sidebar = ({ isVisible, onClose, items }) => {
  // const { inboxes } = useSelector(state => state.inbox);

  // const [collapseNav, setCollapseNav] = useLocalStorage("collapseNav", false);

  const wrapperClasses = classnames("LeftNav__wrapper", {
    visible: isVisible,
  });

  const navClasses = classnames("LeftNav", {
    "is-collapsed": false,
  });

  return (
    <>
      <div className={wrapperClasses}>
        <div className={navClasses}>
          <Profile />
          <Menu>
            <Menu.MenuList>
              {items.length > 0 &&
                items.map((menuItem) => {
                  if (menuItem.restricted) {
                    return null;
                  }
                  if (Array.isArray(menuItem.children)) {
                    menuItem.children = menuItem.children.filter(
                      (list) => !list?.restricted,
                    );
                  }
                  return (
                    <Menu.MenuItem
                      itemText={menuItem.name}
                      link={menuItem.to}
                      icon={() => menuItem.icon}
                      // count={count}
                      collapse={false}
                      onClick={onClose}
                      key={menuItem.to}
                    >
                      {menuItem.children &&
                        menuItem.children.map((subMenu) => (
                          <Menu.List
                            key={subMenu.to}
                            link={subMenu.to}
                            itemText={subMenu.name}
                          />
                        ))}
                    </Menu.MenuItem>
                  );
                })}
            </Menu.MenuList>
            {/* <SmartViewList isCollapsed={collapseNav} /> */}
          </Menu>
        </div>
      </div>
    </>
  );
};

export default React.memo(Sidebar);
