import * as React from "react";

function SvgClient(props) {
  return (
    <svg
      className="Client_svg__SVGInline-svg Client_svg__SVGInline--cleaned-svg Client_svg__SVG-svg Client_svg__db-SideNav-icon-svg Client_svg__db-SideNav-icon--connect-svg Client_svg__wh-16"
      width={16}
      height={16}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none">
        <path
          fill="currentColor"
          d="M9.438 9.313H6.812A4.813 4.813 0 0 0 2 14.125c0 .483.392.875.875.875h10.5a.875.875 0 0 0 .875-.875 4.813 4.813 0 0 0-4.813-4.813ZM3.34 13.686a3.504 3.504 0 0 1 3.473-3.062h2.625a3.505 3.505 0 0 1 3.472 3.063H3.34ZM8.125 8a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm0-5.688A2.19 2.19 0 0 1 10.313 4.5a2.19 2.19 0 0 1-2.188 2.188A2.19 2.19 0 0 1 5.937 4.5a2.19 2.19 0 0 1 2.188-2.188Z"
        />
      </g>
    </svg>
  );
}

export default SvgClient;
