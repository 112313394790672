import "./AlertInfo.css";
import { Spinner } from "@atd/components";

function AlertInfo({ isLoading, message }) {
  return (
    <>
      <div className="visual_bell--messageContainer _overlayBase--_overlayBase text--fontNeg14 text--_fontBase text--_negText">
        <span
          className="visual_bell--messageBase ellipsis--ellipsis"
          style={{ display: isLoading ? "flex" : "" }}
        >
          {isLoading && <Spinner size="large" />}
          <p style={{ marginLeft: "8px" }}>{message}</p>
        </span>
      </div>
    </>
  );
}
export default AlertInfo;
