import { Dropdown, TimePickers } from "@atd/components";

import { forwardRef, useRef, useState, useEffect } from "react";
import moment from "moment";
import prefixStyles from "../InputField/PrefixedInput/PrefixedInput.module.css";
import { DayPickerSingleDateController } from "react-dates";
import "../../pages/Requests/Request/Acitivities/Reply/SendLater.css";
import { getTimeOptions } from "../../helpers/utils";

import { toast } from "react-toastify";
import { Icon } from "@iconify/react";

const ScheduleDateTimePicker = ({ item, onChangeDateTimePicker }) => {
  const timeValueList = getTimeOptions(true);
  const time = timeValueList.find(
    (list) => list.value == item.is_scheduled_data.time_in_seconds,
  );
  const [dataFromChild, setDataFromChild] = useState(null);
  const ScheduleDate = forwardRef((props, ref) => (
    <span>
      <p
        style={{
          position: "relative",
          color: "#3c4257",
          display: "inline-block",
        }}
        {...props}
      >
        <div style={{ display: "flex" }} ref={ref}>
          <div style={{ width: "95px" }}>
            {" "}
            {moment(item.is_scheduled_data?.date_string).format(
              "MMM DD, YYYY",
            )}{" "}
            {time.label}
          </div>
          <span class="IconSVG IconSVG--regular">
            <Icon
              className="IconSVG"
              icon="material-symbols:edit"
              style={{
                position: "absolute",
                top: "10%",
                left: "100%",
                marginLeft: "56px",
                fontSize: "14px",
                fontWeight: "bold",
                cursor: "pointer",
              }}
            />
          </span>
        </div>
      </p>
    </span>
  ));

  const MyDatePicker = ({ onDataFromChild, onSendLater }) => {
    const dateRef = useRef(null);
    const [show, setShow] = useState(false);
    const [dayOffset, setDayOffset] = useState(0);
    const [dateSched, setDateSched] = useState("");
    const [timeSched, setTimeSched] = useState(null);
    const [showTime, setShowTime] = useState(false);
    const [dateInput, setDateInput] = useState(null);
    const [focused, setFocused] = useState(true);
    const [schedDate, setSchedDate] = useState("");
    const [isSubmit, setIsSubmit] = useState(false);

    console.log("ef", show, dateSched);
    useEffect(() => {
      setSchedDate("");
      if (item) {
        setDateInput(moment(item.is_scheduled_data?.date_string));
      }
    }, []);
    const onChangeTimeSchedule = (value) => {
      if (dayOffset === 0 && value <= 14400) {
        const activeDaySelected = moment(dateInput).format("YYYY-MM-DD");
        setDateSched(activeDaySelected);
        setDayOffset(1);
      } else {
        const activeDaySelected = moment(dateInput).format("YYYY-MM-DD");
        setDateSched(activeDaySelected);
      }
      setIsSubmit(false);
      setTimeSched(value);
    };

    const onScheduleClose = (e) => {
      e.preventDefault();
      setShow(!show);
      onDataFromChild(true);
    };

    const onSchedule = (e) => {
      e.preventDefault();

      if (
        moment(dateSched).isValid() &&
        timeSched !== undefined &&
        timeSched !== "" &&
        timeSched !== null
      ) {
        onSendLater(dateSched, timeSched || 0);
        setIsSubmit(true);
        setShow((show) => !show);
        onDataFromChild(true);
      } else if (!moment(dateSched).isValid()) {
        toast.error("select a date schedule");
      } else if (
        timeSched === undefined ||
        timeSched === "" ||
        timeSched === null
      ) {
        toast.error("select a time schedule");
      }
    };

    return (
      <div style={{ listStyle: "none", padding: "11px", width: "265px" }}>
        <div ref={dateRef}>
          <span className="send-later-btn-container send-later-btn-group">
            <li>
              <DayPickerSingleDateController
                noBorder
                date={dateInput}
                daySize={32}
                focused={focused}
                isFocused={true}
                numberOfMonths={1}
                // isOutsideRange={() => true}
                isOutsideRange={(day) =>
                  day.isBefore(moment().startOf("day")) ||
                  day.isAfter(moment().add(3, "M"))
                }
                hideKeyboardShortcutsPanel={true}
                // autoFocus={true}
                horizontalMonthPadding={0}
                renderMonthElement={({ month }) => (
                  <div className="Calendar_sharedComponents_monthWrapper">
                    <div className="Calendar_sharedComponents_month">
                      {month.format("MMMM")}
                    </div>
                    <div>{month.year()}</div>
                  </div>
                )}
                transitionDuration={130}
                // focusedInput={'startDate'}
                onDateChange={(date) => {
                  setSchedDate(moment(date).format("MM/DD/YYYY"));
                  setDateSched(moment(date).format("YYYY-MM-DD"));
                  setDateInput(date);
                  setIsSubmit(false);
                }}
                onFocusChange={() => setFocused(true)}
                renderDayContents={(day) => (
                  <div className="CalendarDay__dayWrapper CalendarDay__dayWrapper--singleDate">
                    <div className="CalendarDay__day">{day.format("D")}</div>
                  </div>
                )}
              />
            </li>
            <li className="datepicker-container-schedule">
              <div>
                <div className={prefixStyles.wrapper}>
                  <span
                    className={prefixStyles.prefix}
                    style={{ minWidth: 8, padding: 0 }}
                  ></span>
                  <input
                    className={prefixStyles.input}
                    type="text"
                    placeholder={"MM/DD/YYYY"}
                    value={schedDate || ""}
                    disabled={false}
                  />
                  <span
                    className={`${prefixStyles.fakeInput}${
                      false ? " " + prefixStyles.fakeInputDisabled : ""
                    }`}
                  />
                </div>
              </div>

              <TimePickers
                appendTo="parent"
                visible={showTime}
                value={timeSched}
                onChange={onChangeTimeSchedule}
                onHide={() => setShowTime(false)}
                placeholder="Time"
                onScheduleDate={schedDate}
              />
            </li>
            <li className="divider-schedular-datetime" />

            <li className="schedule-button-cancel">
              <button
                className="Button Btn Btn--link schd"
                onClick={onScheduleClose}
              >
                Cancel
              </button>

              <button
                disabled={
                  !moment(dateSched).isValid() ||
                  timeSched === undefined ||
                  timeSched === "" ||
                  timeSched === null
                }
                className={`Button Btn Btn--primary ${
                  isSubmit === true ? "enable" : ""
                }`}
                onClick={onSchedule}
              >
                Schedule
              </button>
            </li>
          </span>
        </div>
      </div>
    );
  };
  const onSendLater = (date, time) => {
    onChangeDateTimePicker(date, time);
    console.log("onSendLater", date, time, item);
  };
  const handleDataFromChild = (data) => {
    setDataFromChild(data);
  };
  const onClick = () => {
    setDataFromChild(false);
    if (dataFromChild === false) {
      setDataFromChild("Open");
    }
  };
  return (
    <>
      <span>
        <Dropdown
          as={ScheduleDate}
          item={item}
          ZIndex={9999}
          backgroundColor={"#fafafa"}
          OverFlow={"unset"}
          render={dataFromChild}
          onClick={onClick}
          popover={{ placement: "bottom-start", offset: [5, 6] }}
        >
          {(!dataFromChild || dataFromChild === "Open") && (
            <MyDatePicker
              onDataFromChild={handleDataFromChild}
              onSendLater={onSendLater}
            />
          )}
        </Dropdown>
      </span>
    </>
  );
};
export default ScheduleDateTimePicker;
