import { getCredentialCookies, TimeAgo, readStatus } from "@atd/utils";
import { useRef, useEffect, useState, useCallback, useMemo } from "react";
import {
  Dropdown,
  MoreButton,
  EditComment,
  ZoomFiles,
  Tooltip,
  PinUser,
} from "@atd/components";
import "./ReplyComment.css";
import styled, { css, keyframes } from "styled-components";
import ReactModal from "react-modal";

const ImageDropdown = ({
  isOpen,
  position,
  toggleDropdown,
  file,
  handleCopyImage,
}) => {
  const handleItemClick = (ele) => {
    window.open(ele?.attachment_url);
    toggleDropdown();
  };
  console.log("isOpen", isOpen);

  return (
    isOpen && (
      <div
        tabIndex="0"
        className="zoom-dropdown-menu"
        style={{ top: position.top, left: position.left }}
        role="menu"
        aria-labelledby="dropdown-button"
        onClick={(e) => e.stopPropagation()}
      >
        <div
          className="zoom-dropdown-item"
          role="menuitem"
          tabIndex="0"
          onClick={() => handleItemClick(file)}
        >
          Download media file
        </div>
        <div
          className="zoom-dropdown-item"
          role="menuitem"
          tabIndex="0"
          onClick={() => handleCopyImage(file)}
        >
          Copy as PNG
        </div>
      </div>
    )
  );
};

const getFadeInSlideStyles = (annotationX, annotationY) => {
  return keyframes`
    from {
      left: ${annotationX}"%";
      top: ${annotationY}"%";
    }
    to {
      left: 16.3%;
      top: 2.5%;
    }
  `;
};

const getFadeInSlideStyle = () => {
  return keyframes`
  10%, 90% {
    transform: translate3d(-2px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(4px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-8px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(8px, 0, 0);
  }
  `;
};

const StyledDiv = styled.div`
  ${(props) =>
    props.animate &&
    css`
      animation: ${getFadeInSlideStyles(props.annotationX, props.annotationY)}
        0.5s;
      animation-fill-mode: forwards;
      transition: left 0.2s ease-out, top 0.2s ease-out, height 0.2s ease-in-out;
    `}
  ${(props) =>
    props.isEdit &&
    css`
      animation: ${getFadeInSlideStyle()} 0.82s
        cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    `}
`;

const customStyles = {
  overlay: {},
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "20px",
    zIndex: 1045,
  },
};

const ZoomDiv = styled.div`
  position: relative;
  display: block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045;
`;

function ReplyComment({
  annotation,
  comment,
  mainIndex,
  handleReplyChange,
  submitReply,
  onReplyClose,
  onUpdateReply,
  onResolve,
  removeImage,
  forWardAction,
  backWardAction,
  OnMoveTopCorner,
  moveTopCorner,
  onAnimationEnds,
  isAnimate,
  isValidation,
  actionMenuHandler,
  isCommentEmpty,
  isCommentNotEmpty,
  referenceFiles,
  setAttachFile,
  attachFile,
  deleteAttachementFile,
  rolePermission,
  userList,
}) {
  console.log("replycomment", annotation, isAnimate, isValidation);

  const textAreaRef = useRef(null);
  const endOfMessagesRef = useRef(null);
  const divRef = useRef(null);
  const [history, setHistory] = useState(null);
  const [zoomPoup, setZoomPoup] = useState(false);
  const [zoomFiles, setZoomFiles] = useState([]);
  const [dropdownPosition, setDropdownPosition] = useState({
    top: 0,
    left: 0,
  });
  const [dropdownId, setDropdownId] = useState(null);
  const [actionMenu, setActionMenu] = useState([]);

  const [existsUser, setExistsUser] = useState(null);

  const editMenu = useMemo(() => [{ id: 0, value: "Edit" }], []);
  const actions = useMemo(
    () => [
      { id: 0, value: "Mark as unread", enable: rolePermission.change_status },
      { id: 1, value: "Mark as read", enable: rolePermission.change_status },
      // { id: 2, value: "Copy link", enable: true },
      { id: 3, value: "Delete thread", enable: rolePermission.delete_thread },
    ],
    [rolePermission.change_status, rolePermission.delete_thread],
  );

  useEffect(() => {
    console.log("ReplyComment", annotation);
    console.log("moveTopCorner", moveTopCorner);
    if (annotation) {
      setHistory(annotation);
    }
  }, [annotation, moveTopCorner]);

  const openEdit = (index) => {
    const updatedHistory = {
      ...history,
      list: history.list.map((item, idx) =>
        idx === index
          ? { ...item, enableEdit: true }
          : { ...item, enableEdit: false },
      ),
    };
    onUpdateReply(mainIndex, index, true, null);
    setHistory(updatedHistory);
  };

  const cancelEdit = (index) => {
    const updatedHistory = {
      ...history,
      list: history.list.map((item, idx) =>
        idx === index ? { ...item, enableEdit: false } : item,
      ),
    };
    onUpdateReply(mainIndex, index, false, null);
    setHistory(updatedHistory);
    console.log("cancelEdit", index, history, annotation);
  };

  const updateRecord = (index, message, attachmentFile) => {
    const updatedHistory = {
      ...history,
      list: history.list.map((item, idx) =>
        idx === index ? { ...item, enableEdit: false } : item,
      ),
    };
    onUpdateReply(mainIndex, index, false, message, attachmentFile);
    setHistory(updatedHistory);
  };

  const zoomView = useCallback(
    (attachments, index) => {
      console.log("zoomView", attachments, zoomFiles);
      const files = attachments.map((ele, i) => ({
        ...ele,
        isSelect: i === index ? true : false,
      }));

      setZoomPoup(true);
      setZoomFiles(files);
    },
    [zoomFiles],
  );

  const onClose = useCallback(() => {
    setZoomPoup(false);
  }, []);

  const handlePaste = useCallback(
    (event) => {
      const items = (event.clipboardData || event.originalEvent.clipboardData)
        .items;

      for (let index in items) {
        const item = items[index];
        if (item.kind === "file") {
          const blob = item.getAsFile();
          if (blob && blob.type.includes("image")) {
            const fileName = blob.name.toLowerCase();
            const reader = new FileReader();
            reader.onload = async (e) => {
              const generateUniqueId = `${Date.now()}-${Math.random()
                .toString(36)
                .substr(2, 9)}`;
              const result = {
                image_url: e.target.result,
                isLoading: true,
                attachment_id: generateUniqueId,
              };
              setAttachFile((files) => [...files, result]);
              const response = await referenceFiles(
                blob,
                generateUniqueId,
                "attachment." + fileName.split(".")[1],
              );
              if (response) {
                setAttachFile((files) => {
                  const findIndex = files.findIndex(
                    (ele) => ele.attachment_id === response.attachment_id,
                  );

                  if (findIndex !== -1) {
                    const updatedFiles = [...files];
                    updatedFiles[findIndex] = response;
                    return updatedFiles;
                  }

                  return files;
                });
              }
            };
            reader.readAsDataURL(blob);
            break;
          }
        }
      }
    },
    [referenceFiles, setAttachFile],
  );

  const removeFile = (index) => {
    setAttachFile((files) => {
      return files.filter((_, i) => i !== index);
    });
  };
  // const attachFiles = useCallback(
  //   async (event) => {
  //     const files = event.target.files;
  //     if (files) {
  //       const file = files[0];
  //       const imageUrl = URL.createObjectURL(file);
  //       const generateUniqueId = `${Date.now()}-${Math.random()
  //         .toString(36)
  //         .substr(2, 9)}`;
  //       const result = {
  //         image_url: imageUrl,
  //         isLoading: true,
  //         attachment_id: generateUniqueId,
  //       };
  //       setAttachFile((files) => [...files, result]);
  //       const response = await referenceFiles(
  //         files[0],
  //         generateUniqueId,
  //         file.name,
  //       );
  //       if (response) {
  //         setAttachFile((files) => {
  //           const findIndex = files.findIndex(
  //             (ele) => ele.attachment_id === response.attachment_id,
  //           );

  //           if (findIndex !== -1) {
  //             const updatedFiles = [...files];
  //             updatedFiles[findIndex] = response;
  //             return updatedFiles;
  //           }

  //           return files;
  //         });
  //       }
  //     }
  //   },
  //   [referenceFiles, setAttachFile],
  // );

  const userSuggestion = useCallback(() => {
    setExistsUser(true);
  }, []);

  const handleCopyImage = async (file) => {
    try {
      // Fetch the image data
      const response = await fetch(file?.image_url);
      let imageData = await response.blob();

      // Convert GIF to PNG if attachment format is GIF
      if (file.attachment_format === "gif") {
        imageData = await convertGifToPng(imageData);
      }

      // Create a Blob from the image data
      const imageBlob = new Blob([imageData], {
        type: "image/png", // Always copy as PNG format
      });

      // Copy the Blob to the clipboard
      let clipBoardItem = {};
      clipBoardItem["image/png"] = imageBlob;

      await navigator.clipboard.write([new ClipboardItem(clipBoardItem)]);

      setDropdownId(null);
      console.log("Image copied to clipboard successfully!");
    } catch (error) {
      setDropdownId("");
      console.error("Error copying image to clipboard:", error);
    }
  };

  async function convertGifToPng(gifBlob) {
    return new Promise((resolve, reject) => {
      const img = document.createElement("img");
      img.onload = function () {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        canvas.toBlob(resolve, "image/png");
      };
      img.onerror = reject;
      img.src = URL.createObjectURL(gifBlob);
    });
  }

  const handleContextMenu = (e, atttachmentId) => {
    console.log("handleContextMenu");
    e.preventDefault();
    setDropdownId(atttachmentId);
    setDropdownPosition({ top: 110, left: 80 });
  };

  function getPixel(attachmentLength) {
    if (attachmentLength > 2) {
      return "94px";
    } else if (attachmentLength === 1) {
      return "100%";
    } else {
      return "140px";
    }
  }
  function getPixelLoader(attachmentLength) {
    if (attachmentLength > 2) {
      return "94px";
    } else if (attachmentLength === 1) {
      return "290px";
    } else {
      return "140px";
    }
  }

  const onClickUser = (userName) => {
    const textArea = textAreaRef.current;

    // Create a new span element for the username
    const userSpan = document.createElement("span");
    userSpan.style.color = "blue";
    userSpan.innerText = `@${userName}`;

    // Append a space before and after the span
    const textNodeBefore = document.createTextNode(" ");
    const textNodeAfter = document.createTextNode(" ");

    textArea.appendChild(textNodeBefore);
    textArea.appendChild(userSpan);
    textArea.appendChild(textNodeAfter);

    // Set cursor to the end of the content
    const range = document.createRange();
    const selection = window.getSelection();

    if (textArea.childNodes.length > 0) {
      const lastNode = textArea.childNodes[textArea.childNodes.length - 1];
      range.setStart(lastNode, lastNode.length);
      range.setEnd(lastNode, lastNode.length);
    } else {
      const textNode = document.createTextNode("");
      textArea.appendChild(textNode);
      range.setStart(textNode, 0);
      range.setEnd(textNode, 0);
    }

    selection.removeAllRanges();
    selection.addRange(range);

    textArea.focus();

    // Collect the updated content
    const updatedContent = textArea.innerHTML;

    handleReplyChange(updatedContent);
    setExistsUser(false);
  };
  useEffect(() => {
    if (annotation) {
      const isRead = readStatus(annotation)?.read_status?.read;
      const menu = actions.reduce((acc, curr) => {
        if (
          ((isRead && curr.id !== 1) || (!isRead && curr.id !== 0)) &&
          curr.enable
        ) {
          acc.push(curr);
        }
        if (curr.id === 3 && !annotation.pin_id) {
          acc.push(curr);
        }
        return acc;
      }, []);
      const uniqueData = menu.filter(
        (item, index, self) =>
          index === self.findIndex((t) => t.id === item.id),
      );
      setActionMenu(uniqueData);
    }
  }, [actions, annotation]);

  const handleKeyDown = (event) => {
    if (event.key === "Backspace") {
      const textArea = textAreaRef.current;
      const currentValue = textArea.innerHTML;
      // Regular expression to match the <span> tags with style="color: blue;"
      const regex = /<span style="color: blue;">(.*?)<\/span>/g;

      // Array to store the matched parts
      const parts = [];

      // Use replace with a callback function to collect the matched parts
      currentValue.replace(regex, (match, group1) => {
        parts.push(group1.trim()); // Push the trimmed content inside <span> tags to parts array
        return ""; // Replace the matched part with an empty string
      });

      // Split the remaining parts by spaces to get other text segments
      const remainingParts = currentValue
        .split(regex)
        .map((part) => part.trim())
        .filter((part) => part !== "");

      // Combine both arrays to get the desired output
      const output = [...remainingParts];
      const array = output.map((item) => item.replace("@", ""));
      if (array?.length > 0) {
        const findName = userList.some(
          (el) =>
            el.user_name.replace(/\s+/g, "").toLowerCase() ===
            array[array?.length - 1].replace(/\s+/g, "").toLowerCase(),
        );
        if (findName) {
          const spans = textArea.getElementsByTagName("span");
          const lastSpan = spans[spans.length - 1];
          if (lastSpan) {
            // Remove the last span element
            lastSpan.parentNode.removeChild(lastSpan);
          }
        }
      }
    }
  };

  useEffect(() => {
    if (textAreaRef.current) {
      if (Object.keys(comment)?.length === 0) {
        textAreaRef.current.focus();
        textAreaRef.current.innerHTML = "";
      }
    }
    if (endOfMessagesRef.current) {
      const timeoutId = setTimeout(() => {
        endOfMessagesRef.current.scrollIntoView({
          behavior: "smooth",
          block: "end",
        });
      }, 600);

      // Cleanup the timeout if the component unmounts before the timeout completes
      return () => clearTimeout(timeoutId);
    }
  }, [comment]);

  console.log("Reply Comment 34687565", history, annotation, rolePermission);
  return (
    <>
      <StyledDiv
        annotationX={annotation.x}
        annotationY={annotation.y}
        isEdit={isValidation}
        className={`draggable_modal--positionedContainer--HdvZU js-fullscreen-prevent-event-capture js-fullscreen-wheel-event-passthrough thread_comment--threadContainer--oH8Ia ${
          !moveTopCorner ? "reply-comment" : "reply-comment-move-top-corner"
        }`}
        style={{
          bottom: annotation.y > 75 && !moveTopCorner ? annotation.y : "auto",
        }}
        aria-modal="true"
        data-cancel-insertable-resource-drag-and-drop="true"
        animate={isAnimate}
        onAnimationEnd={() => {
          onAnimationEnds();
        }}
      >
        <div
          className="wiggle--wiggleContainer--oGy1O"
          onClick={() => setDropdownId(null)}
        >
          <div
            className="draggable_modal--frame--keEDC modal--modalShadow--d-rJf"
            style={{
              boxShadow:
                "var(--elevation-400-menu-panel,0 2px 14px rgba(0,0,0,.15),0 0 0 .5px rgba(0,0,0,.2))",
              pointerEvents: "auto",
            }}
          >
            <div className="cx_hFull--460YR">
              <div aria-hidden="true" tabIndex="0"></div>
              <div className="cx_hFull--460YR">
                <div
                  className="draggable_modal--contentContainer--5tm46"
                  data-testid="comment-thread-draggable-modal"
                >
                  <div
                    role="none"
                    data-non-interactive="true"
                    style={{ height: "100%", width: "100%" }}
                    data-keyboard-receiver="5"
                  >
                    <div
                      className="thread_comment--threadContainerInner--Bzqm7"
                      role="region"
                      tabIndex="-1"
                      aria-label="Currently expanded comment thread. Close this dialog to return to the board."
                    >
                      <div
                        className="thread_comment--threadHeaderContainer--0TaTS"
                        data-testid="thread-header-container"
                      >
                        <div
                          className="thread_comment--_threadHeader--59aR8 thread_comment--threadHeaderLeft--gppoV"
                          data-onboarding-key="thread-header-left-section"
                        >
                          {annotation.resolved.resolve === false && (
                            <>
                              <button
                                className={`raw_components--_iconButton---ybo6 raw_components--iconButtonDisabled--SoTjK thread_comment--threadHeaderButton--psBeY  action ${
                                  isCommentEmpty
                                    ? "raw_components--_iconButtons"
                                    : "thread_comment--threadHeaderButtonInactive--LhTNi"
                                }`}
                                aria-label="Previous comment thread"
                                data-tooltip="Previous"
                                data-tooltip-type="text"
                                tabIndex="-1"
                                data-testid="threadModalLeft"
                                disabled={!isCommentEmpty}
                                onClick={() => backWardAction()}
                              >
                                <span className="svg-container svg-container-block">
                                  <svg
                                    className="svg"
                                    height="10"
                                    viewBox="0 0 6 10"
                                    width="6"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="m4.646.646.708.708-3.647 3.646 3.647 3.646-.708.708-4.354-4.354z"
                                      fill="#000"
                                      fillOpacity=".8"
                                      fillRule="evenodd"
                                      stroke="none"
                                    ></path>
                                  </svg>
                                </span>
                              </button>
                              <button
                                className={`raw_components--_iconButton---ybo6 raw_components--iconButtonDisabled--SoTjK thread_comment--threadHeaderButton--psBeY   action ${
                                  isCommentNotEmpty
                                    ? "raw_components--_iconButtons"
                                    : "thread_comment--threadHeaderButtonInactive--LhTNi"
                                }`}
                                aria-label="Next comment thread"
                                data-tooltip="Next"
                                data-tooltip-type="text"
                                tabIndex="-1"
                                disabled={!isCommentNotEmpty}
                                data-testid="threadModalRight"
                                onClick={() => forWardAction()}
                              >
                                <span className="svg-container svg-container-block">
                                  <svg
                                    className="svg"
                                    height="10"
                                    viewBox="0 0 6 10"
                                    width="6"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="m1.354.646-.708.708 3.646 3.646-3.646 3.646.708.708 4.354-4.354z"
                                      fill="#000"
                                      fillOpacity=".8"
                                      fillRule="evenodd"
                                      stroke="none"
                                    ></path>
                                  </svg>
                                </span>
                              </button>
                            </>
                          )}
                        </div>

                        <div className="thread_comment--_threadHeader--59aR8 thread_comment--threadHeaderRight--1gMsu">
                          {!annotation.resolved.resolve &&
                            actionMenu?.length > 0 && (
                              <Dropdown
                                as={MoreButton}
                                onChange={(event) => {
                                  actionMenuHandler(history, event, mainIndex);
                                }}
                              >
                                <Dropdown.Options>
                                  {actionMenu.map((action) => (
                                    <Dropdown.Option
                                      key={action.id}
                                      value={action.id}
                                    >
                                      {action.value}
                                    </Dropdown.Option>
                                  ))}
                                </Dropdown.Options>
                              </Dropdown>
                            )}

                          {(rolePermission?.resolve ||
                            rolePermission?.un_resolve) &&
                            annotation.pin_id &&
                            rolePermission?.finish_review && (
                              <Tooltip
                                content={
                                  annotation.resolved.resolve === false
                                    ? "Mark as resolved"
                                    : "Mark as unresolved"
                                }
                                placement="bottom"
                              >
                                <button
                                  className="raw_components--_iconButton---ybo6 raw_components--iconButtonEnabled--WmVk5"
                                  aria-label="Mark as resolved"
                                  data-tooltip="Mark as resolved"
                                  data-tooltip-type="text"
                                  tabIndex="0"
                                  aria-disabled="false"
                                  onClick={() => onResolve(annotation)}
                                >
                                  <span
                                    className="svg-container svg-container-block"
                                    style={{
                                      fill:
                                        annotation.resolved.resolve === true
                                          ? "#0ebc0e"
                                          : "",
                                    }}
                                  >
                                    <svg
                                      className="svg"
                                      height="16"
                                      viewBox="0 0 16 16"
                                      width="16"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M15 8c0 3.866-3.134 7-7 7-3.866 0-7-3.134-7-7 0-3.866 3.134-7 7-7 3.866 0 7 3.134 7 7zm1 0c0 4.418-3.582 8-8 8-4.418 0-8-3.582-8-8 0-4.418 3.582-8 8-8 4.418 0 8 3.582 8 8zM5.307 7.693 4.6 8.4l2.475 2.475 4.596-4.596-.707-.707-3.89 3.889-1.767-1.768z"
                                        fill="#000"
                                        fillOpacity="1"
                                        fillRule="evenodd"
                                        stroke="none"
                                      ></path>
                                    </svg>
                                  </span>
                                </button>
                              </Tooltip>
                            )}

                          {!moveTopCorner && (
                            <button
                              className="raw_components--_iconButton---ybo6 raw_components--iconButtonEnabled--WmVk5"
                              aria-label="Dock to side"
                              data-tooltip="Dock to side"
                              data-tooltip-type="text"
                              tabIndex="0"
                              onClick={() => {
                                OnMoveTopCorner();
                              }}
                            >
                              <span className="svg-container svg-container-block">
                                <svg
                                  className="svg"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  width="16"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M15.5 3c0-1.38-1.12-2.5-2.5-2.5H3C1.62.5.5 1.62.5 3v10c0 1.38 1.12 2.5 2.5 2.5h8v-1H3c-.828 0-1.5-.672-1.5-1.5V3c0-.828.672-1.5 1.5-1.5h10c.828 0 1.5.672 1.5 1.5v6.98c0 1.357-1.656 2.017-2.589 1.032L7.162 6H11V5H5.5v5.5h1V6.755l4.685 4.945c1.554 1.64 4.315.54 4.315-1.72V3z"
                                    fill="#000"
                                    fillOpacity="1"
                                    fillRule="evenodd"
                                    stroke="none"
                                  ></path>
                                </svg>
                              </span>
                            </button>
                          )}
                          <button
                            className="thread_comment--threadHeaderButton--psBeY close_button--closeX--Bc-63"
                            aria-label="Close comment"
                            data-testid="close-button"
                            data-not-draggable="true"
                            role="presentation"
                            type="button"
                            onClick={() => onReplyClose(mainIndex)}
                          >
                            <span className="svg-container svg-container-block">
                              <svg
                                className="svg"
                                height="12"
                                viewBox="0 0 12 12"
                                width="12"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="m6 5.293 4.789-4.79.707.708-4.79 4.79 4.79 4.789-.707.707-4.79-4.79-4.789 4.79-.707-.707L5.293 6 .502 1.211 1.21.504 6 5.294z"
                                  fill="#000"
                                  fillOpacity="1"
                                  fillRule="nonzero"
                                  stroke="none"
                                ></path>
                              </svg>
                            </span>
                          </button>
                        </div>
                      </div>
                      <div
                        className="scroll_container--clipContainer--5rNO2"
                        role="none"
                        data-non-interactive="true"
                        style={{ width: "350px", maxHeight: "180px" }}
                      >
                        <div
                          className="scroll_container--full--CiWTy scroll_container--scrollContainer--DYS0c"
                          role="none"
                          data-non-interactive="true"
                        >
                          <div
                            className="scroll_container--full--CiWTy"
                            role="none"
                            data-non-interactive="true"
                          >
                            <div data-non-interactive="true">
                              <div
                                className="visually_hidden--visuallyHidden--To537"
                                role="status"
                                aria-atomic="true"
                                aria-live="polite"
                              ></div>
                              {history?.list?.length > 0 &&
                                history?.list.map(
                                  (ele, index) =>
                                    (ele.comment !== "" ||
                                      ele.attachments?.length > 0) && (
                                      <div key={index}>
                                        {ele.enableEdit === true && (
                                          <div className="text--_fontBase--QdLsd text--fontPos11--2LvXf thread_comment--_threadElement--PYPeg thread_comment--threadElement--4grcZ">
                                            <div
                                              className="thread_comment--threadSubelementNoMargin--aGW6z"
                                              style={{ minHeight: "20px" }}
                                            ></div>

                                            <span
                                              className="avatar--avatar--fFibd avatar--circle--ag36Q"
                                              aria-hidden="true"
                                              data-testid="initial-avatar"
                                              style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                color: "#fff",
                                                backgroundColor: "#9747ff",
                                                width: "24px",
                                                height: "24px",
                                                lineHeight: "24px",
                                                fontSize: "12px",
                                              }}
                                            >
                                              {ele?.user_name.split("")[0]}
                                            </span>
                                            {(rolePermission.edit ||
                                              !ele.comment_id) && (
                                              <div
                                                className="thread_comment--threadSubelementNoMargin--aGW6z thread_comment--indentedThreadSubelement--gs1fI thread_comment--threadSubelement--7-le3"
                                                style={{ textAlign: "start" }}
                                                tabIndex="-1"
                                              >
                                                <EditComment
                                                  comment={ele.comment}
                                                  attachments={ele.attachments}
                                                  removeImage={removeImage}
                                                  mainIndex={mainIndex}
                                                  currentIndex={index}
                                                  cancelEdit={cancelEdit}
                                                  updateRecord={updateRecord}
                                                  userList={userList}
                                                  referenceFiles={
                                                    referenceFiles
                                                  }
                                                  deleteAttachementFile={
                                                    deleteAttachementFile
                                                  }
                                                  comment_id={ele.comment_id}
                                                />
                                              </div>
                                            )}
                                          </div>
                                        )}
                                        {ele.enableEdit === false && (
                                          <div className="text--_fontBase--QdLsd text--fontPos11--2LvXf thread_comment--_threadElement--PYPeg thread_comment--threadElement--4grcZ">
                                            <div className="thread_comment--threadSubelementNoMargin--aGW6z">
                                              <div className="thread_comment--leftJustified--HHuN0">
                                                <a>
                                                  <span
                                                    className="avatar--avatar--fFibd avatar--circle--ag36Q thread_comment--authorAvatar--xOCLt"
                                                    aria-hidden="true"
                                                    data-testid="initial-avatar"
                                                    style={{
                                                      display: "flex",
                                                      justifyContent: "center",
                                                      color: "#fff",
                                                      backgroundColor:
                                                        "#9747ff",
                                                      width: "24px",
                                                      height: "24px",
                                                      lineHeight: "24px",
                                                      fontSize: "12px",
                                                    }}
                                                  >
                                                    {
                                                      ele?.user_name.split(
                                                        "",
                                                      )[0]
                                                    }
                                                  </span>
                                                </a>
                                                <a
                                                  className="text--_fontBase--QdLsd text--_fontBase--QdLsd text--fontPos13--xW8hS text--fontPos13--xW8hS thread_comment--headerLabel--Oq7ar thread_comment--authorHandle--fmYkb"
                                                  dir="auto"
                                                >
                                                  {ele?.user_name}
                                                </a>
                                                <div className="text--_fontBase--QdLsd text--fontPos13--xW8hS thread_comment--headerLabel--Oq7ar thread_comment--grayLabel--6ZZkD">
                                                  <span
                                                    data-tooltip="Apr 24, 2024, 5:54 PM"
                                                    data-tooltip-type="text"
                                                  >
                                                    <span>
                                                      <TimeAgo
                                                        timestamp={
                                                          ele.modified_date
                                                        }
                                                      />
                                                    </span>
                                                  </span>
                                                </div>
                                              </div>

                                              {((!annotation.resolved.resolve &&
                                                rolePermission.edit &&
                                                ele.comment_id &&
                                                ele.user_type === "admin") ||
                                                !ele.comment_id) && (
                                                <>
                                                  <div className="thread_comment--rightJustified--lK8hG">
                                                    <div>
                                                      <Dropdown
                                                        as={MoreButton}
                                                        ZIndex={9999}
                                                        OverFlow={"hidden"}
                                                        width={"90px"}
                                                        popover={{
                                                          placement: "bottom",
                                                        }}
                                                        onChange={() =>
                                                          openEdit(index)
                                                        }
                                                      >
                                                        <Dropdown.Options>
                                                          {editMenu.map(
                                                            (action) => (
                                                              <Dropdown.Option
                                                                key={action.id}
                                                                value={
                                                                  action.id
                                                                }
                                                              >
                                                                {action.value}
                                                              </Dropdown.Option>
                                                            ),
                                                          )}
                                                        </Dropdown.Options>
                                                      </Dropdown>
                                                    </div>
                                                  </div>
                                                </>
                                              )}
                                            </div>
                                            <div
                                              className="thread_comment--threadSubelementNoMargin--aGW6z thread_comment--indentedThreadSubelement--gs1fI thread_comment--threadSubelement--7-le3"
                                              style={{ textAlign: "start" }}
                                              tabIndex="-1"
                                            >
                                              <div
                                                className="text--_fontBase--QdLsd text--fontPos13--xW8hS comment_message--commentMessage--kZCzD thread_comment--text--FHTSU"
                                                data-testid="comment-message"
                                                dir="auto"
                                              >
                                                <span
                                                  dangerouslySetInnerHTML={{
                                                    __html: ele.comment,
                                                  }}
                                                ></span>
                                              </div>
                                            </div>
                                            {ele.attachments?.length > 0 && (
                                              <div className="thread_comment--attachmentsContainer--lOeTy">
                                                {ele.attachments.map(
                                                  (item, index) => (
                                                    <>
                                                      <div
                                                        className="attachment--publishedThumbnailContainer--oFj0f"
                                                        key={index}
                                                      >
                                                        {item.image_url && (
                                                          <div
                                                            onContextMenu={(
                                                              e,
                                                            ) =>
                                                              handleContextMenu(
                                                                e,
                                                                item.attachment_id,
                                                              )
                                                            }
                                                          >
                                                            <ImageDropdown
                                                              isOpen={
                                                                dropdownId ===
                                                                item.attachment_id
                                                              }
                                                              position={
                                                                dropdownPosition
                                                              }
                                                              file={
                                                                ele.attachments[
                                                                  index
                                                                ]
                                                              }
                                                              handleCopyImage={
                                                                handleCopyImage
                                                              }
                                                              toggleDropdown={() =>
                                                                setDropdownId(
                                                                  null,
                                                                )
                                                              }
                                                            />
                                                            <button
                                                              className="attachment--thumbnailButton--CfAkK"
                                                              style={{
                                                                width: getPixel(
                                                                  ele
                                                                    .attachments
                                                                    .length,
                                                                ),
                                                                height:
                                                                  getPixel(
                                                                    ele
                                                                      .attachments
                                                                      .length,
                                                                  ),
                                                              }}
                                                              onClick={() =>
                                                                zoomView(
                                                                  ele.attachments,
                                                                  index,
                                                                )
                                                              }
                                                            >
                                                              <img
                                                                alt={
                                                                  item.attachment_full_name
                                                                }
                                                                className="attachment--thumbnailImage--Jx4faT"
                                                                src={
                                                                  item.image_url
                                                                }
                                                              />
                                                              {index >= 2 &&
                                                                ele.attachments
                                                                  ?.length >
                                                                  3 && (
                                                                  <div className="attachment--darkOverlay--l3K9x attachment--overlay--dIw1C">
                                                                    +
                                                                    {ele
                                                                      .attachments
                                                                      ?.length -
                                                                      3}
                                                                  </div>
                                                                )}
                                                            </button>
                                                          </div>
                                                        )}
                                                        {!item.image_url && (
                                                          <div
                                                            key={index}
                                                            className="attachment--thumbnailImage--Jx4faz"
                                                            style={{
                                                              width:
                                                                getPixelLoader(
                                                                  ele
                                                                    .attachments
                                                                    .length,
                                                                ),
                                                              height:
                                                                getPixelLoader(
                                                                  ele
                                                                    .attachments
                                                                    .length,
                                                                ),
                                                            }}
                                                          >
                                                            <div
                                                              className="image-loader"
                                                              style={{
                                                                width:
                                                                  getPixelLoader(
                                                                    ele
                                                                      .attachments
                                                                      .length,
                                                                  ),
                                                                height:
                                                                  getPixelLoader(
                                                                    ele
                                                                      .attachments
                                                                      .length,
                                                                  ),
                                                              }}
                                                            >
                                                              <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="48"
                                                                height="48"
                                                                viewBox="0 0 24 24"
                                                              >
                                                                <path
                                                                  fill="#2f88ff"
                                                                  d="M12 2A10 10 0 1 0 22 12A10 10 0 0 0 12 2Zm0 18a8 8 0 1 1 8-8A8 8 0 0 1 12 20Z"
                                                                  opacity="0.5"
                                                                />
                                                                <path
                                                                  fill="#2f88ff"
                                                                  d="M20 12h2A10 10 0 0 0 12 2V4A8 8 0 0 1 20 12Z"
                                                                >
                                                                  <animateTransform
                                                                    attributeName="transform"
                                                                    dur="1s"
                                                                    from="0 12 12"
                                                                    repeatCount="indefinite"
                                                                    to="360 12 12"
                                                                    type="rotate"
                                                                  />
                                                                </path>
                                                              </svg>
                                                            </div>
                                                          </div>
                                                        )}
                                                      </div>
                                                    </>
                                                  ),
                                                )}
                                              </div>
                                            )}
                                          </div>
                                        )}
                                      </div>
                                    ),
                                )}

                              {annotation.resolved.resolve === false &&
                                rolePermission.comment && (
                                  <>
                                    <div
                                      className="thread_comment_composer--replyComposerContainer---8DSl"
                                      aria-label="Reply to comment with Balasubramanikandan T"
                                    >
                                      <div className="thread_comment_composer--replyAuthorElement--qze1r">
                                        <span
                                          className="avatar--avatar--fFibd avatar--circle--ag36Q"
                                          aria-hidden="true"
                                          data-testid="initial-avatar"
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            color: "#fff",
                                            backgroundColor: "#9747ff",
                                            width: "24px",
                                            height: "24px",
                                            lineHeight: "24px",
                                            fontSize: "12px",
                                          }}
                                        >
                                          {
                                            getCredentialCookies().name.split(
                                              "",
                                            )[0]
                                          }
                                        </span>
                                      </div>
                                      <div ref={divRef}>
                                        <PinUser
                                          existsUser={existsUser}
                                          onClickUser={onClickUser}
                                          options={userList}
                                        />
                                      </div>
                                      <div
                                        className=" thread_comment_composer--composerEmpty--VgMY78 thread_comment_composer--inputSectionWrapper--Bxyab"
                                        data-testid="comment-composer-container"
                                        style={{
                                          display:
                                            !comment && attachFile.length === 0
                                              ? "flex"
                                              : "block",
                                        }}
                                      >
                                        <div
                                          className=" text--_fontBase--QdLsd text--fontPos13--xW8hS editable_typeahead--wrapperBase--rwdvD thread_comment_composer--editableTypeahead--9R6Yd"
                                          data-testid="editable-typeahead"
                                        >
                                          <div
                                            className="DraftEditor-root"
                                            onPaste={handlePaste}
                                          >
                                            <div className="public-DraftEditorPlaceholder-root">
                                              {!comment && (
                                                <div
                                                  className="public-DraftEditorPlaceholder-inner"
                                                  style={{
                                                    whiteSpace: "pre-wrap",
                                                  }}
                                                  id="placeholder-4oip6"
                                                >
                                                  Reply
                                                </div>
                                              )}
                                            </div>

                                            <div className="DraftEditor-editorContainer">
                                              <div
                                                ref={textAreaRef}
                                                aria-describedby="placeholder-9da6e"
                                                className="notranslate public-DraftEditor-content"
                                                contentEditable="true"
                                                role="textbox"
                                                spellCheck="true"
                                                tabIndex="0"
                                                style={{
                                                  outline: "none",
                                                  userSelect: "text",
                                                  whiteSpace: "pre-wrap",
                                                  overflowWrap: "break-word",
                                                  color: "#000",
                                                  textAlign: "start",
                                                  unicodeBidi: "normal",
                                                  direction: "ltr",
                                                }}
                                                onInput={(event) =>
                                                  handleReplyChange(
                                                    event.target.innerText,
                                                  )
                                                }
                                                onKeyDown={handleKeyDown}
                                              />
                                            </div>
                                          </div>
                                        </div>

                                        {attachFile?.length > 0 && (
                                          <>
                                            <div style={{ display: "flex" }}>
                                              {attachFile.map((list, index) => (
                                                <div key={index}>
                                                  <div className="attachment--composerContainer--mP7Kx">
                                                    {list?.image_url && (
                                                      <div
                                                        className={` ${
                                                          list?.isLoading
                                                            ? "attachment--pendingThumbnailContainer--iL-xu"
                                                            : "attachment--pendingThumbnailContainer--iL-xus"
                                                        }`}
                                                      >
                                                        {list?.isLoading && (
                                                          <div
                                                            className="image-loader"
                                                            onClick={() =>
                                                              zoomView(
                                                                attachFile,
                                                                index,
                                                              )
                                                            }
                                                          >
                                                            <svg
                                                              xmlns="http://www.w3.org/2000/svg"
                                                              width="48"
                                                              height="48"
                                                              viewBox="0 0 24 24"
                                                            >
                                                              <path
                                                                fill="#2f88ff"
                                                                d="M12 2A10 10 0 1 0 22 12A10 10 0 0 0 12 2Zm0 18a8 8 0 1 1 8-8A8 8 0 0 1 12 20Z"
                                                                opacity="0.5"
                                                              />
                                                              <path
                                                                fill="#2f88ff"
                                                                d="M20 12h2A10 10 0 0 0 12 2V4A8 8 0 0 1 20 12Z"
                                                              >
                                                                <animateTransform
                                                                  attributeName="transform"
                                                                  dur="1s"
                                                                  from="0 12 12"
                                                                  repeatCount="indefinite"
                                                                  to="360 12 12"
                                                                  type="rotate"
                                                                />
                                                              </path>
                                                            </svg>
                                                          </div>
                                                        )}
                                                        <button
                                                          className="attachment--thumbnailButton--CfAkKS"
                                                          onClick={() =>
                                                            zoomView(
                                                              attachFile,
                                                              index,
                                                            )
                                                          }
                                                        >
                                                          <img
                                                            src={
                                                              list?.image_url
                                                            }
                                                            alt={
                                                              list?.file_name
                                                            }
                                                            className="attachment--thumbnailImage--Jx4fas"
                                                          />
                                                          <div className="attachment--lightOverlay--F8qzZ attachment--overlay--dIw1C"></div>
                                                        </button>
                                                        {!list?.isLoading && (
                                                          <button
                                                            className="attachment--deleteBadge--9aSjm"
                                                            onClick={() =>
                                                              removeFile(index)
                                                            }
                                                          >
                                                            <span className="svg-container attachment--xIcon--hhlua svg-container-block">
                                                              <svg
                                                                className="svg"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="10"
                                                                height="10"
                                                                viewBox="0 0 10 10"
                                                              >
                                                                <path
                                                                  fill="#000"
                                                                  fillOpacity="1"
                                                                  fillRule="nonzero"
                                                                  stroke="none"
                                                                  d="m5 5.71 3.646 3.647.707-.707-3.646-3.647 3.646-3.646L8.646.65 5 4.296 1.353.65l-.707.707 3.646 3.646L.646 8.65l.707.707L5 5.71z"
                                                                ></path>
                                                              </svg>
                                                            </span>
                                                          </button>
                                                        )}
                                                      </div>
                                                    )}
                                                  </div>
                                                </div>
                                              ))}
                                            </div>
                                          </>
                                        )}

                                        <div className="thread_comment_composer--actions--7LRnN">
                                          {(comment ||
                                            attachFile?.length > 0) && (
                                            <div className="thread_comment_composer--leftComposerButtonGroup--6jQ3I">
                                              {userList?.length > 0 && (
                                                <Tooltip
                                                  content="Add mention"
                                                  placement="bottom"
                                                >
                                                  <button
                                                    className="raw_components--iconButtonEnabled--WmVk5 raw_components--_iconButton---ybo6"
                                                    tabIndex="0"
                                                    data-tooltip-type="text"
                                                    data-tooltip="Add mention"
                                                    aria-label="Add mention"
                                                    aria-disabled="false"
                                                    onClick={() => {
                                                      userSuggestion();
                                                    }}
                                                  >
                                                    <span className="svg-container svg-container-block">
                                                      <svg
                                                        className="svg"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="32"
                                                        height="32"
                                                        viewBox="0 0 32 32"
                                                      >
                                                        <path
                                                          fill="#000"
                                                          fillOpacity="1"
                                                          fillRule="nonzero"
                                                          stroke="none"
                                                          d="M15.888 24.057c1.362 0 2.947-.41 3.886-.92l-.326-.896c-.69.378-2.122.863-3.528.863-4.225 0-6.846-2.767-6.846-7.076 0-4.25 2.653-7.088 6.641-7.088 4.015 0 6.36 3.042 6.36 6.36 0 2.64-.587 4.027-1.981 4.027-.767 0-1.106-.524-1.106-1.42v-5.925h-1.042v1.157h-.064c-.326-.805-1.38-1.419-2.684-1.336-1.956.134-3.465 1.822-3.465 4.142 0 2.404 1.304 4.277 3.465 4.328 1.419.038 2.326-.729 2.735-1.65h.064c.103 1.215 1.145 1.643 2.084 1.643 2.41 0 3.017-2.524 3.017-4.953 0-3.817-2.761-7.313-7.331-7.313C11.087 8 8 11.19 8 15.996c0 4.916 3.017 8.06 7.888 8.06zm-.556-4.788c-1.694 0-2.576-1.579-2.576-3.375 0-1.694 1.048-3.12 2.608-3.12 1.61 0 2.633.979 2.633 3.12 0 2.129-.933 3.375-2.665 3.375z"
                                                        ></path>
                                                      </svg>
                                                    </span>
                                                  </button>
                                                </Tooltip>
                                              )}

                                              {/* <label> */}
                                              {/* <Tooltip
                                                content="Upload up to 5 images and GIFs"
                                                placement="bottom"
                                              >
                                                <div>
                                                  <input
                                                    type="file"
                                                    name="fileUploadIconInput-newCommentComposeres"
                                                    id="fileUploadIconInput-newCommentComposeres"
                                                    accept="image/png,image/jpeg,image/gif"
                                                    multiple=""
                                                    className="file_upload_icon_input--fileInput--qwRav"
                                                    disabled={
                                                      attachFile?.length > 4
                                                    }
                                                    onChange={(e) => {
                                                      attachFiles(e);
                                                    }}
                                                  />
                                                  <span
                                                    className="svg-container svg-container-block"
                                                    style={{
                                                      fill:
                                                        attachFile?.length > 4
                                                          ? "#0000004d"
                                                          : "",
                                                    }}
                                                  >
                                                    <svg
                                                      className="svg"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      width="33"
                                                      height="33"
                                                      viewBox="0 0 33 33"
                                                    >
                                                      <path
                                                        fill="#000"
                                                        fillRule="evenodd"
                                                        d="M21.835 9.447h-11.2c-.773 0-1.4.627-1.4 1.4V18.5l3.659-3.417.337-.315.341.31 8.974 8.177c.413-.244.69-.693.69-1.207v-11.2c0-.773-.627-1.4-1.4-1.4Zm-12.6 12.6v-2.18l4.005-3.74 8.033 7.32H10.635c-.773 0-1.4-.627-1.4-1.4Zm1.4-13.6c-1.325 0-2.4 1.075-2.4 2.4v11.2c0 1.326 1.075 2.4 2.4 2.4h11.2c1.326 0 2.4-1.074 2.4-2.4v-11.2c0-1.325-1.074-2.4-2.4-2.4h-11.2Zm9.6 5.5c0 .829-.671 1.5-1.5 1.5-.828 0-1.5-.671-1.5-1.5 0-.828.672-1.5 1.5-1.5.829 0 1.5.672 1.5 1.5Zm1 0c0 1.38-1.119 2.5-2.5 2.5-1.38 0-2.5-1.12-2.5-2.5 0-1.38 1.12-2.5 2.5-2.5 1.381 0 2.5 1.12 2.5 2.5Z"
                                                        clipRule="evenodd"
                                                      ></path>
                                                    </svg>
                                                  </span>
                                                </div>
                                              </Tooltip> */}

                                              {/* </label> */}
                                            </div>
                                          )}
                                          {!attachFile.some(
                                            (ele) => ele.isLoading,
                                          ) &&
                                            comment && (
                                              <button
                                                className="thread_comment_composer--submitUpArrowIcon--e8uT9 raw_components--iconButtonEnabled--WmVk5 raw_components--_iconButton---ybo6 raw_components--transparentIconButtonEnabled--l2bm3 raw_components--iconButtonEnabled--WmVk5 raw_components--_iconButton---ybo6"
                                                tabIndex="0"
                                                data-tooltip-type="text"
                                                data-tooltip="Submit"
                                                aria-label="Submit"
                                                aria-disabled="false"
                                                data-testid="comments-submit-button"
                                                onClick={() => {
                                                  submitReply(mainIndex);
                                                }}
                                              >
                                                <span className="svg-container svg-container-block">
                                                  <svg
                                                    className="svg"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                  >
                                                    <path
                                                      fill="#000"
                                                      fillOpacity="1"
                                                      fillRule="nonzero"
                                                      stroke="none"
                                                      d="M0 12C0 5.373 5.373 0 12 0c6.627 0 12 5.373 12 12 0 6.627-5.373 12-12 12-6.627 0-12-5.373-12-12z"
                                                    ></path>
                                                    <path
                                                      fill="#fff"
                                                      fillOpacity="1"
                                                      fillRule="evenodd"
                                                      stroke="none"
                                                      d="m12 5.277-5.361 5.594.722.692L11.5 7.244V18h1V7.244l4.139 4.32.722-.693L12 5.277z"
                                                    ></path>
                                                  </svg>
                                                </span>
                                              </button>
                                            )}
                                          {((attachFile?.length > 0 &&
                                            attachFile.some(
                                              (ele) => ele.isLoading,
                                            )) ||
                                            !comment) && (
                                            <button
                                              className="thread_comment_composer--submitUpArrowIconDisabled--iPBY3 thread_comment_composer--submitUpArrowIcon--e8uT9 raw_components--iconButtonDisabled--SoTjK raw_components--_iconButton---ybo6 raw_components--transparentIconButtonEnabled--l2bm3 raw_components--iconButtonEnabled--WmVk5 raw_components--_iconButton---ybo6"
                                              tabIndex="-1"
                                              data-tooltip-type="text"
                                              data-tooltip="Submit"
                                              aria-label="Submit"
                                              aria-disabled="true"
                                              data-testid="comments-submit-button"
                                            >
                                              <span className="svg-container svg-container-block">
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="24"
                                                  height="24"
                                                  viewBox="0 0 24 24"
                                                >
                                                  <path
                                                    fill="#000"
                                                    fillOpacity="1"
                                                    fillRule="nonzero"
                                                    stroke="none"
                                                    d="M0 12C0 5.373 5.373 0 12 0c6.627 0 12 5.373 12 12 0 6.627-5.373 12-12 12-6.627 0-12-5.373-12-12z"
                                                  />
                                                  <path
                                                    fill="#fff"
                                                    fillOpacity="1"
                                                    fillRule="evenodd"
                                                    stroke="none"
                                                    d="m12 5.277-5.361 5.594.722.692L11.5 7.244V18h1V7.244l4.139 4.32.722-.693L12 5.277z"
                                                  />
                                                </svg>
                                              </span>
                                            </button>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                            </div>
                            <div ref={endOfMessagesRef} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </StyledDiv>

      <ReactModal
        role="dialog"
        isOpen={zoomPoup}
        closeTimeoutMS={0}
        overlayClassName="atd-modal-overlay"
        style={customStyles}
        onRequestClose={() => setZoomPoup(false)}
      >
        <ZoomDiv>
          <ZoomFiles zoomFiles={zoomFiles} onClose={onClose} />
        </ZoomDiv>
      </ReactModal>
    </>
  );
}

export default ReplyComment;
