import { Dropdown, TimePickers } from "@atd/components";

import { forwardRef, useRef, useState, useEffect } from "react";
import moment from "moment";
import prefixStyles from "../InputField/PrefixedInput/PrefixedInput.module.css";
import { DayPickerSingleDateController } from "react-dates";
import "../../pages/Requests/Request/Acitivities/Reply/SendLater.css";
// import { getTimeOptions } from "../../helpers/utils";

import { toast } from "react-toastify";

const ScheduledDateTimePicker = ({ item, onChangeDateTimePicker }) => {
  // const timeValueList = getTimeOptions(true);
  // const time = timeValueList.find(
  //   (list) => list.value == item.is_scheduled_data.time_in_seconds,
  // );
  const [dataFromChild, setDataFromChild] = useState(null);
  const ScheduleDate = forwardRef((props, ref) => (
    <span>
      <p
        style={{
          position: "relative",
          color: "#3c4257",
          display: "inline-block",
        }}
        {...props}
      >
        <div style={{ display: "inline-block" }} ref={ref}>
          <span class="IconSVG IconSVG--regular" style={{ cursor: "pointer" }}>
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16px"
                height="16px"
                viewBox="0 0 24 24"
              >
                <g fill="none" stroke="currentColor" stroke-width="2">
                  <circle cx="12" cy="12" r="7" />
                  <path
                    stroke-linecap="round"
                    d="M5.965 3.136a4 4 0 0 0-2.829 2.829m14.899-2.829a4 4 0 0 1 2.829 2.829M12 8v3.75c0 .138.112.25.25.25H15"
                  />
                </g>
              </svg>
            </span>
          </span>
        </div>
      </p>
    </span>
  ));

  const MyDatePicker = ({ onDataFromChild, onSendLater }) => {
    const dateRef = useRef(null);
    const [show, setShow] = useState(false);
    const [dayOffset, setDayOffset] = useState(0);
    const [dateSched, setDateSched] = useState("");
    const [timeSched, setTimeSched] = useState(null);
    const [showTime, setShowTime] = useState(false);
    const [dateInput, setDateInput] = useState(null);
    const [focused, setFocused] = useState(true);
    const [schedDate, setSchedDate] = useState("");
    const [isSubmit, setIsSubmit] = useState(false);

    console.log("ef", show, dateSched);
    useEffect(() => {
      setSchedDate("");
      if (item) {
        setDateInput("");
      }
    }, []);
    const onChangeTimeSchedule = (value) => {
      if (dayOffset === 0 && value <= 14400) {
        const activeDaySelected = moment(dateInput).format("YYYY-MM-DD");
        setDateSched(activeDaySelected);
        setDayOffset(1);
      } else {
        const activeDaySelected = moment(dateInput).format("YYYY-MM-DD");
        setDateSched(activeDaySelected);
      }
      setIsSubmit(false);
      setTimeSched(value);
    };

    const onScheduleClose = (e) => {
      e.preventDefault();
      setShow(!show);
      onDataFromChild(true);
    };

    const onSchedule = (e) => {
      e.preventDefault();

      if (
        moment(dateSched).isValid() &&
        timeSched !== undefined &&
        timeSched !== "" &&
        timeSched !== null
      ) {
        onSendLater(dateSched, timeSched || 0);
        setIsSubmit(true);
        onDataFromChild(true);
        setShow((show) => !show);
      } else if (!moment(dateSched).isValid()) {
        toast.error("select a date schedule");
      } else if (
        timeSched === undefined ||
        timeSched === "" ||
        timeSched === null
      ) {
        toast.error("select a time schedule");
      }
    };

    const clearScheduled = () => {
      onSendLater("clear", 0);
      setIsSubmit(true);
      onDataFromChild(true);
      setShow((show) => !show);
    };
    return (
      <div style={{ listStyle: "none", padding: "11px", width: "265px" }}>
        <div ref={dateRef}>
          <span className="send-later-btn-container send-later-btn-group">
            <li>
              <div onClick={clearScheduled} className="clear-Schedul">
                Clear Schedule
              </div>
            </li>
            <hr />
            <li>
              <DayPickerSingleDateController
                noBorder
                date={dateInput}
                daySize={32}
                focused={focused}
                isFocused={true}
                numberOfMonths={1}
                // isOutsideRange={() => true}
                isOutsideRange={(day) =>
                  day.isBefore(moment().startOf("day")) ||
                  day.isAfter(moment().add(3, "M"))
                }
                hideKeyboardShortcutsPanel={true}
                // autoFocus={true}
                horizontalMonthPadding={0}
                renderMonthElement={({ month }) => (
                  <div className="Calendar_sharedComponents_monthWrapper">
                    <div className="Calendar_sharedComponents_month">
                      {month.format("MMMM")}
                    </div>
                    <div>{month.year()}</div>
                  </div>
                )}
                transitionDuration={130}
                // focusedInput={'startDate'}
                onDateChange={(date) => {
                  setSchedDate(moment(date).format("MM/DD/YYYY"));
                  setDateSched(moment(date).format("YYYY-MM-DD"));
                  setDateInput(date);
                  setIsSubmit(false);
                }}
                onFocusChange={() => setFocused(true)}
                renderDayContents={(day) => (
                  <div className="CalendarDay__dayWrapper CalendarDay__dayWrapper--singleDate">
                    <div className="CalendarDay__day">{day.format("D")}</div>
                  </div>
                )}
              />
            </li>
            <li className="datepicker-container-schedule">
              <div>
                <div className={prefixStyles.wrapper}>
                  <span
                    className={prefixStyles.prefix}
                    style={{ minWidth: 8, padding: 0 }}
                  ></span>
                  <input
                    className={prefixStyles.input}
                    type="text"
                    placeholder={"MM/DD/YYYY"}
                    value={schedDate || ""}
                    disabled={false}
                  />
                  <span
                    className={`${prefixStyles.fakeInput}${
                      false ? " " + prefixStyles.fakeInputDisabled : ""
                    }`}
                  />
                </div>
              </div>

              <TimePickers
                appendTo="parent"
                visible={showTime}
                value={timeSched}
                onChange={onChangeTimeSchedule}
                onHide={() => setShowTime(false)}
                placeholder="Time"
                onScheduleDate={schedDate}
              />
            </li>
            <li className="divider-schedular-datetime" />

            <li className="schedule-button-cancel">
              <button
                className="Button Btn Btn--link schd"
                onClick={onScheduleClose}
              >
                Cancel
              </button>

              <button
                disabled={
                  !moment(dateSched).isValid() ||
                  timeSched === undefined ||
                  timeSched === "" ||
                  timeSched === null
                }
                className={`Button Btn Btn--primary ${
                  isSubmit === true ? "enable" : ""
                }`}
                onClick={onSchedule}
              >
                Schedule
              </button>
            </li>
          </span>
        </div>
      </div>
    );
  };
  const onSendLater = (date, time) => {
    onChangeDateTimePicker(date, time, item);
    console.log("onSendLater", date, time, item);
  };
  const handleDataFromChild = (data) => {
    setDataFromChild(data);
  };
  const onClick = () => {
    setDataFromChild(false);
    if (dataFromChild === false) {
      setDataFromChild("Open");
    }
  };
  return (
    <>
      <span>
        <Dropdown
          as={ScheduleDate}
          item={item}
          ZIndex={9999}
          backgroundColor={"#fafafa"}
          OverFlow={"unset"}
          render={dataFromChild}
          onClick={onClick}
          popover={{ placement: "bottom-start", offset: [5, 6] }}
        >
          {(!dataFromChild || dataFromChild === "Open") && (
            <MyDatePicker
              onDataFromChild={handleDataFromChild}
              onSendLater={onSendLater}
            />
          )}
        </Dropdown>
      </span>
    </>
  );
};
export default ScheduledDateTimePicker;
