import * as React from "react";

const SvgReports = (props) => (
  <svg
    className="Reports_svg__SVGInline-svg Reports_svg__SVGInline--cleaned-svg Reports_svg__SVG-svg Reports_svg__db-SideNav-icon-svg Reports_svg__db-SideNav-icon--reports-svg Reports_svg__wh-16"
    height={16}
    width={16}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g fill="none">
      <path
        fill="currentColor"
        d="M10.277 7.036a.808.808 0 0 1-1.048.041L6.269 4.86 2.422 7.912a.835.835 0 0 1-1.23-.137c-.302-.353-.24-.903.137-1.206l4.376-3.502a.876.876 0 0 1 1.073-.016l2.96 2.218 3.846-3.077c.378-.302.928-.24 1.207.137a.836.836 0 0 1-.137 1.23l-4.377 3.477Zm-4.9 1.092a.874.874 0 1 1 1.75 0v5.252a.874.874 0 1 1-1.75 0V8.128Zm-3.502 2.626a.875.875 0 1 1 1.751 0v2.626a.875.875 0 1 1-1.75 0v-2.626Zm8.755-.875v3.501a.874.874 0 1 1-1.751 0v-3.5a.874.874 0 1 1 1.75 0Zm1.75-1.751a.874.874 0 1 1 1.751 0v5.252a.874.874 0 1 1-1.75 0V8.128Z"
        className="Reports_svg__db-SideNav-iconFill--secondary"
      />
    </g>
  </svg>
);

export default SvgReports;
