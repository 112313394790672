import ReactModal from "react-modal";
import { Review } from "@atd/components";

function FinishReview({
  isOpen,
  finishReview,
  onClose,
  selectedVersion,
  isSubmit,
  addOneMoreReview,
  rolePermissions,
  versionHistory,
  createDate,
}) {
  const customStyles = {
    overlay: {},
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "20px",
      zIndex: 1045,
    },
  };
  return (
    <>
      <ReactModal
        role="dialog"
        isOpen={isOpen}
        closeTimeoutMS={300}
        overlayClassName="atd-modal-overlay"
        style={customStyles}
      >
        <Review
          rolePermissions={rolePermissions}
          finishReview={finishReview}
          onClose={onClose}
          isSubmit={isSubmit}
          selectedVersion={selectedVersion}
          addOneMoreReview={addOneMoreReview}
          versionHistory={versionHistory}
          createDate={createDate}
        />
      </ReactModal>
    </>
  );
}
export default FinishReview;
