import * as React from "react";

const SvgSettings = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
    <title>Settings Icon</title>
    <path
      fill="currentColor"
      d="M14.57 9.616a.68.68 0 0 0-.339-.585l-.9-.519c.019-.154.027-.334.027-.512s-.01-.358-.027-.536l.9-.52a.68.68 0 0 0 .338-.584c0-.582-1.349-3.506-2.311-3.506a.667.667 0 0 0-.338.092l-.896.519a5.337 5.337 0 0 0-.932-.54V1.888a.673.673 0 0 0-.501-.653 7.047 7.047 0 0 0-3.613 0 .673.673 0 0 0-.5.653v1.038a5.338 5.338 0 0 0-.932.54l-.897-.52a.667.667 0 0 0-.338-.09C2.424 2.855 1 5.635 1 6.36c0 .236.126.46.338.585l.9.518a5.484 5.484 0 0 0 0 1.072l-.9.52a.668.668 0 0 0-.338.56c0 .582 1.349 3.505 2.311 3.505a.68.68 0 0 0 .339-.091l.896-.519c.293.212.603.392.931.54v1.037c0 .307.205.576.502.653.59.18 1.196.259 1.781.259.609 0 1.216-.079 1.807-.236a.673.673 0 0 0 .502-.652v-1.038c.328-.148.638-.328.931-.54l.896.519a.666.666 0 0 0 .339.092c.912.001 2.334-2.782 2.334-3.529Zm-2.432 2.024-1.194-.691c-1.15.83-.884.676-2.164 1.255v1.373a5.746 5.746 0 0 1-1.991 0v-1.373c-1.284-.58-1.017-.425-2.165-1.255l-1.193.69a5.675 5.675 0 0 1-.996-1.725l1.17-.664c-.047-.7-.083-.974-.083-1.25 0-.274.035-.55.104-1.251l-1.19-.685a5.676 5.676 0 0 1 .995-1.728l1.194.69c1.15-.83.884-.676 2.165-1.254V2.399a5.745 5.745 0 0 1 1.99 0v1.374c1.284.58 1.017.425 2.165 1.255l1.194-.69c.43.51.767 1.095.995 1.725l-1.193.687c.07.701.104.975.104 1.25 0 .273-.034.549-.104 1.25l1.192.688a5.281 5.281 0 0 1-.995 1.701ZM7.785 5.35A2.63 2.63 0 0 0 5.16 7.975 2.63 2.63 0 0 0 7.785 10.6a2.63 2.63 0 0 0 2.625-2.625A2.63 2.63 0 0 0 7.785 5.35Zm0 3.963a1.313 1.313 0 1 1 0-2.626 1.313 1.313 0 0 1 0 2.625Z"
    />
  </svg>
);

export default SvgSettings;
