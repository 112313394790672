import moment from "moment";
import uuid from "react-uuid";

import { ExternalLink } from "@atd/components";

import { TitleStyle, Table } from "./Payment.style";
import { useState, useEffect } from "react";

function InvoiceHistory({ data }) {
  const [invoiceList, setInvoiceList] = useState([]);
  const [enableShowAll, setEnableShowAll] = useState(false);

  useEffect(() => {
    if (data.length > 4) {
      const firstList = data.filter((list, index) => index < 4);
      setInvoiceList(firstList);
      setEnableShowAll(true);
    } else if (data.length <= 4) {
      setInvoiceList(data);
      setEnableShowAll(false);
    }
  }, []);
  const showAll = () => {
    if (data.length > 0) {
      const secondList = data.filter((list, index) => index >= 4);
      setInvoiceList([...invoiceList, ...secondList]);
      setEnableShowAll(false);
    }
  };
  return (
    <>
      <TitleStyle>Invoice History</TitleStyle>
      <Table className="table wt-100 mgb10">
        <thead>
          <tr className="left">
            <th className="title2">Date</th>
            <th className="title2">Description</th>
            <th className="title2">Amount</th>
            <th className="title2">Status</th>
          </tr>
        </thead>
        <tbody>
          {data?.length === 0 && (
            <tr className="center">
              <td colSpan={3}>No Invoice History</td>
            </tr>
          )}
          {invoiceList &&
            invoiceList.length > 0 &&
            invoiceList.map((invoice) => (
              <tr key={uuid()}>
                <td>{moment(invoice.date).format("MM-DD-YYYY")}</td>
                <td>
                  <ExternalLink to={invoice.invoice_url} target="_blank">
                    <div className="bl-cont-blue"> {invoice.description}</div>
                  </ExternalLink>
                </td>
                <td>
                  <div className="bl-cont-black">
                    {invoice.currency}
                    {""}
                    {invoice.amount}
                  </div>
                </td>
                <td className="bl-cont-black">{invoice?.status}</td>
              </tr>
            ))}
          {enableShowAll && (
            <tr key={uuid()}>
              <td>
                {" "}
                <div
                  className="Button Btn Btn--secondary"
                  onClick={() => {
                    showAll();
                  }}
                >
                  Show all
                </div>
              </td>
              <td></td>
              <td></td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
}

export default InvoiceHistory;
