import { useState } from "react";

import Modal from "react-bootstrap/Modal";
import "./TrialWork.css";

function TrialWorks() {
  const [lgShow, setLgShow] = useState(false);

  return (
    <>
      <div onClick={() => setLgShow(true)}>How does the trial work?</div>
      <Modal
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        className="trial-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            <h5 className="modal-title">
              How The <span>Trial Works?</span>
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="main">
            <p>
              You sign up now and enjoy a 7-day trial period with no charges. If
              you decide All Time Design isn't right for you, cancel anytime
              before day 7, no questions asked. Billing starts only after the
              trial period, and there are no contracts to tie you down. Keep in
              mind that our services are designed for flexibility, operating on
              a month-to-month basis at a fixed rate.
            </p>
            <p>
              But why do we request payment details upon sign-up? This is to
              ensure that we're onboarding clients who are genuinely interested
              in our service and how it can help them achieve their business
              goals.
            </p>
            <p className="fst-italics">
              Read below for detailed guidelines on our 7-day trial and some
              handy tips to help you determine if All Time Design is the perfect
              match for your needs.
            </p>
            <h3>
              If you sign up for our Standard, Pro, or Premium Plan, you will be
              able to make 3 design requests based on the following guidelines
            </h3>
            <div className="list_group">
              <h6>
                During your trial, you are only entitled to make{" "}
                <b>3 requests.</b>
              </h6>
              <ul>
                <li>
                  Each request equals 1 design, whether it's a single print or
                  digital creative or up to <strong>2-3 pages</strong> of a
                  multi-page design, depending on the complexity of the content.
                </li>
                <li>
                  <strong>Note:</strong> Designs received during this trial
                  period will have a watermark on them.
                </li>
              </ul>
            </div>
            <div className="list_group">
              <h4>
                The following requests can not be fulfilled during the trial
                period:
              </h4>
              <ul>
                <li>Resizing of trial designs</li>
                <li>Logo designs</li>
                <li>Brand Mascot or Brand Character designs</li>
                <li>Landing Page designs</li>
                <li>Presentation designs</li>
                <li>
                  Looking for inspiration for your trial design requests? Check
                  out our FAQs!
                </li>
              </ul>
            </div>

            <div className="list_group">
              <h4>The following requests are limited during trials:</h4>
              <ul>
                <li>
                  <strong>Illustration requests:</strong> You're limited to one
                  simple illustration request. Complex illustrations are not
                  available during trials.
                </li>
                <li>
                  <strong>Multipage design requests:</strong> If you want a
                  multi-page design like an ebook during your trial, we'll only
                  complete 2-3 pages as one design request, depending on the
                  content's volume.
                </li>
              </ul>
            </div>

            <h3>
              If you sign up for our Ultimate Plan, you will be able to make 1
              design request based on the following guidelines
            </h3>
            <div className="list_group">
              <h6>
                During your trial, you are only entitled to make{" "}
                <b>1 requests.</b>
              </h6>
              <ul>
                <li>
                  Each request equals 1 single-product or single-page landing
                  page + limited revisions within the{" "}
                  <strong>7-day trial period.</strong>
                </li>
                <li>
                  <strong>Note:</strong> During product trials, access to full
                  content may be restricted. You can upgrade to a paid
                  subscription for unrestricted access
                </li>
              </ul>
            </div>
            <div className="list_group">
              <h4>
                The following requests can not be fulfilled during the trial
                period:
              </h4>
              <ul>
                <li>Lead generation landing page</li>
                <li>Webinar landing page</li>
                <li>HTML/CSS Development</li>
                <li>JavaScript Development</li>
                <li>Software/App landing page</li>
                <li>E-commerce landing page</li>
                <li>Click-through page</li>
              </ul>
            </div>

            <div className="list_group">
              <h4>The following requests are limited during trials:</h4>
              <ul>
                <li>
                  <strong>Multi-Page/Product Landing Page:</strong> Please note
                  that our trial period only includes single-page/product
                  landing page requests. Complex, multiple-page landing pages
                  are not available during trials.
                </li>
                <li>
                  <strong>Design Revisions:</strong> While our ultimate plan
                  offers unlimited design revisions, please be aware that during
                  your 7-day free trial, you are entitled to up to 3 revisions.
                </li>
              </ul>
            </div>

            <p>
              <i>
                Got questions about the trial terms? Chat with us live at{" "}
                <a
                  className="weblink"
                  target="_blank"
                  href="https://alltimedesign.com/"
                  rel="noreferrer"
                >
                  https://alltimedesign.com/
                </a>
              </i>
            </p>
            <p>
              Remember to submit your trial request(s) promptly so we can
              clarify and start working on them, including any revisions you may
              need. This ensures you get a firsthand experience of what it's
              like working with the All Time Design Team. With this experience,
              you can make an informed decision within your 7-day trial period
              regarding a full subscription.
            </p>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default TrialWorks;
