import { useLocation } from "react-router-dom";
import { useEffect, useState, useCallback } from "react";
import axios from "axios";
import AWSService from "../../../../features/AWS/AWSConfig";
import PreviewAnnotation from "./AnnotationSetup.js/PreviewAnnotation";
import { useNavigate } from "react-router-dom";
import AnnotationService from "@atd/features/request/annotationApi";
import { rolePermission } from "@atd/utils";
import { pdfjs } from "react-pdf";

// Set PDF.js worker source
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function Annotation() {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  const [fileResponse, setFileResponse] = useState({});
  const [errorResponse, setErrorResponse] = useState([]);
  const [queryParam, setQueryParam] = useState(null);
  const [annotationMessage, setAnnotationMessage] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const [rolePermissions, setRolePermissions] = useState("");

  useEffect(() => {
    const getQueryParams = () => {
      if (queryParams) {
        const queryParam = {
          request_id: queryParams.get("request_id"),
          reply_id: queryParams.get("reply_id"),
          attachment_id: queryParams.get("attachment_id"),
          annotation_id: queryParams.get("annotation_id"),
          page_Id: queryParams.get("page_Id"),
        };
        setQueryParam(queryParam);
      }
    };

    getQueryParams();
  }, []);

  const getAnnotation = useCallback(async (annotation_id) => {
    if (annotation_id) {
      try {
        const response = await AnnotationService.get(annotation_id);

        if (response.status === "success") {
          const role_action = response.results.approve_work === 1 ? 1 : 0;
          setRolePermissions(rolePermission(role_action));
          setAnnotationMessage(response.results);
        }
      } catch (err) {
        console.log("error", err.message);
      }
    }
  }, []);

  const onChangeVersionTypes = useCallback(async (annotation_id) => {
    if (annotation_id) {
      try {
        const response = await AnnotationService.get(annotation_id);

        if (response.status === "success") {
          setAnnotationMessage(response.results);
        }
      } catch (err) {
        console.log("error", err.message);
      }
    }
  }, []);

  useEffect(() => {
    const getReply = async () => {
      try {
        const payload = {
          request_id: queryParams.get("request_id"),
          reply_id: queryParams.get("reply_id"),
        };
        const responses = await AnnotationService.getAnnotationFile(
          payload.request_id,
          payload.reply_id,
        );
        if (responses?.code === 200) {
          setFileResponse(responses?.results);
          if (queryParams.get("annotation_id")) {
            const payload = {
              annotation_id: queryParams.get("annotation_id"),
            };
            await getAnnotation(payload.annotation_id);
          } else {
            setRolePermissions(rolePermission(0));
          }
        }
      } catch (error) {
        console.error("Error fetching reply:", error);
        setErrorResponse(true);
      }
    };

    // Call the API only once when the component mounts
    getReply();
  }, []); // Empty dependency array means this effect runs only once

  const fetchFileData = useCallback(async (attachmentDetails) => {
    const chunkSize = 500 * 1024 * 1024; // 500MB chunk size

    try {
      const { url, attachment_id, annotation_id, reply_id } = attachmentDetails;
      const urlObject = new URL(url);
      const filePath = urlObject.pathname.substring(1);

      const getObjectParams = {
        Bucket: AWSService.getAWSBucketName(),
        Key: filePath,
      };

      // Generate pre-signed URL
      const signedUrl = await new Promise((resolve, reject) => {
        AWSService.getAWSAccessKey().getSignedUrl(
          "getObject",
          getObjectParams,
          (err, url) => {
            if (err) {
              console.error("Error generating pre-signed URL:", err);
              reject(err);
              return;
            }
            resolve(url);
          },
        );
      });

      let allChunks = [];
      let start = 0;
      let isCompleteFlag = false;

      while (!isCompleteFlag) {
        try {
          const response = await axios.get(signedUrl, {
            responseType: "arraybuffer", // Or "blob" if not processing in arraybuffer
            headers: {
              Range: `bytes=${start}-${start + chunkSize - 1}`,
            },
          });

          allChunks.push(response.data);

          const contentRange = response.headers["content-range"];
          if (contentRange) {
            const { end, length } = getRangeAndLength(contentRange);
            start = end + 1;
            isCompleteFlag = isComplete({ end, length });
          } else {
            isCompleteFlag = true; // No Content-Range header means we are done
          }
        } catch (err) {
          console.error("Error downloading chunk:", err);
          break;
        }
      }

      // Combine all chunks into a single Blob
      const combinedBlob = new Blob(allChunks);
      const arrayBuffer = await combinedBlob.arrayBuffer();

      // Process the file (example for image handling)
      const fileType = attachmentDetails.format.toLowerCase();
      if (
        [
          "jpg",
          "jpeg",
          "png",
          "gif",
          "bmp",
          "cur",
          "ico",
          "svg",
          "jfif",
          "jpe",
          "jps",
        ].includes(fileType)
      ) {
        const fileBlob = new Blob([arrayBuffer], { type: `image/${fileType}` });
        const imageUrl = URL.createObjectURL(fileBlob);
        return [
          {
            imageUrl: imageUrl,
            attachment_id,
            annotation_id,
            reply_id,
            url_link: url,
          },
        ];
      } else {
        // Handle other file types (e.g., PDF)
        const imagesList = [];
        const canvas = document.createElement("canvas");
        canvas.setAttribute("className", "canv");

        try {
          const pdf = await pdfjs.getDocument({ data: arrayBuffer }).promise;

          for (let i = 1; i <= pdf.numPages; i++) {
            const page = await pdf.getPage(i);
            const scale = 2.0;
            const viewport = page.getViewport({ scale });
            canvas.height = viewport.height;
            canvas.width = viewport.width;
            const renderContext = {
              canvasContext: canvas.getContext("2d"),
              viewport: viewport,
            };
            await page.render(renderContext).promise;
            const img = canvas.toDataURL("image/png");
            imagesList.push({
              imageUrl: img,
              attachment_id,
              annotation_id,
              reply_id,
              url_link: url,
            });
          }
        } catch (err) {
          console.error("Error processing file:", err);
        }

        return imagesList;
      }
    } catch (error) {
      console.error("Error fetching file data:", error);
    }
  }, []);

  // Helper functions
  const getRangeAndLength = (contentRange) => {
    const [range, length] = contentRange.split("/");
    const [, end] = range.split("-");
    return {
      end: parseInt(end, 10),
      length: parseInt(length, 10),
    };
  };

  const isComplete = ({ end, length }) => end === length - 1;

  const updateQueryParams = useCallback(
    (newParams) => {
      const searchParams = new URLSearchParams(location.search);

      Object.keys(newParams).forEach((key) => {
        searchParams.set(key, newParams[key]);
      });

      navigate(`${location.pathname}?${searchParams.toString()}`, {
        replace: true,
      });
    },
    [location.pathname, location.search, navigate],
  );

  const addAnnotation = async (payload) => {
    try {
      setIsSubmit(true);
      const response = await AnnotationService.add(payload);
      if (response.status === "success") {
        navigate(`/requests/thread/${queryParams.get("request_id")}`);
        // const queryParam = {
        //   request_id: queryParams.get("request_id"),
        //   reply_id: queryParams.get("reply_id"),
        //   attachment_id: queryParams.get("attachment_id"),
        //   annotation_id: response.results.id,
        //   page_Id: queryParams.get("page_Id"),
        // };
        // setQueryParam(queryParam);
        // setAnnotationMessage(response.results);
        // updateQueryParams({
        //   annotation_id: response.results.id,
        // });
        // setIsSubmit(false);
        // const approve_status = response.results.approve_work === 1 ? 1 : 0;
        // setRolePermissions(rolePermission(approve_status));
      } else {
        setIsSubmit(null);
      }
    } catch (err) {
      setIsSubmit(null);
      console.log("Error on create a new annotation");
    }
  };

  const updateAnnotation = async (payload) => {
    try {
      setIsSubmit(true);
      const response = await AnnotationService.update(
        payload,
        queryParam.annotation_id,
      );

      if (response.status === "success") {
        navigate(`/requests/thread/${queryParams.get("request_id")}`);
        // setAnnotationMessage(response.results);
        // updateQueryParams({
        //   annotation_id: response.results.id,
        // });
        // const queryParam = {
        //   request_id: queryParams.get("request_id"),
        //   reply_id: queryParams.get("reply_id"),
        //   attachment_id: queryParams.get("attachment_id"),
        //   annotation_id: response.results.id,
        //   page_Id: queryParams.get("page_Id"),
        // };
        // setQueryParam(queryParam);
        // setIsSubmit(false);
        // const approve_status = response.results.approve_work === 1 ? 1 : 0;
        // setRolePermissions(rolePermission(approve_status));
      } else {
        setIsSubmit(null);
      }
    } catch (err) {
      setIsSubmit(null);

      console.log("Error on create a new annotation");
    }
  };

  const addNewVersion = async (payload) => {
    try {
      const response = await AnnotationService.newDeliverRequest(
        payload,
        queryParams.get("request_id"),
      );
      return response;
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

  const uploadFiles = async (event) => {
    try {
      const response = await AnnotationService.getAttachement(
        queryParams.get("reply_id"),
        event.target.files[0],
      );
      return response;
    } catch (error) {
      console.error("Error occurred:", error);
      return error;
    }
  };

  const onChangeStatus = async (payload) => {
    try {
      const response = await AnnotationService.changeStatus(payload);
      return response;
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };
  const onResolve = async (payload) => {
    try {
      const response = await AnnotationService.resolve(payload);
      return response;
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };
  const onDelete = async (payload) => {
    try {
      const response = await AnnotationService.deleteThread(payload);
      return response;
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

  const downloadFile = useCallback((versionType) => {
    if (versionType !== undefined && versionType !== "") {
      console.log("Download", versionType);
      const findSelectFile = versionType.files.find((item) => item.isSelect);
      if (Object.keys(findSelectFile)?.length > 0) {
        window.open(findSelectFile?.url_link);
      }
    }
  }, []);

  return (
    <>
      <PreviewAnnotation
        rolePermissions={rolePermissions}
        fileResponse={fileResponse}
        annotationMessage={annotationMessage}
        errorResponse={errorResponse}
        queryParam={queryParam}
        isSubmit={isSubmit}
        fetchFileData={fetchFileData}
        onChangeVersionTypes={onChangeVersionTypes}
        addAnnotation={addAnnotation}
        updateAnnotation={updateAnnotation}
        addNewVersion={addNewVersion}
        uploadFiles={uploadFiles}
        onChangeStatus={onChangeStatus}
        onResolve={onResolve}
        onDelete={onDelete}
        updateQueryParams={updateQueryParams}
        downloadFile={downloadFile}
      />
    </>
  );
}

export default Annotation;
