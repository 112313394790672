import React from "react";
import { useDropzone } from "react-dropzone";
import { useFormikContext } from "formik";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import { useAttachment } from "@atd/hooks";
import RequestApi from "@atd/features/request/requestApi";

import NotesEditor from "./NotesEditor";
import EditorBottomToolbar from "../EditorBottomToolbar";
import AttachmentsBox from "./AttachmentsBox";

// const ALLOWED_FILE_TYPES = ".pdf,image/jpeg,.psd,.ai,.indd,.docx,.ppt,.pptx";

const CreateNoteContainer = styled.div`
  position: relative;
  flex: 1;
`;

function CreateNote({
  index,
  noteId,
  versions,
  attachments: currentAttachments,
  isEditing,
  onCacel,
}) {
  const params = useParams();

  const { values, setFieldValue, isSubmitting, setSubmitting } =
    useFormikContext();

  const {
    error,
    progress,
    attachments,
    isUploading,
    onSetAttachments,
    onRemoveAttachment,
  } = useAttachment(currentAttachments, { request_id: params.id });

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    // accept: ALLOWED_FILE_TYPES,
    noKeyboard: true,
    onDrop: (files) => {
      onSetAttachments(files);
    },
  });

  const onSubmit = async (e) => {
    e.preventDefault();

    setSubmitting(true);

    const notePayload = {
      body: values.threads[index].body,
      attachments,
      version: values.threads[index].version,
    };

    try {
      const { status, results, message } = isEditing
        ? await RequestApi.updateNote(params.id, noteId, notePayload)
        : await RequestApi.addNote(params.id, notePayload);

      if (status === "error") {
        throw Error(message);
      }

      const requestsData = {
        body: values.threads[index].body,
        attachments,
        version: values.threads[index].version,
        ...results,
      };

      if (requestsData) {
        setFieldValue(`threads.${index}`, {
          isNew: false,
          isEditing: false,
          isOpen: true,
          type: "note",
          ...requestsData,
        });
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <CreateNoteContainer {...getRootProps()}>
      <NotesEditor
        disabled={isSubmitting}
        requestId={params.id}
        model={values.threads[index].body}
        onModelChange={(model) => setFieldValue(`threads.${index}.body`, model)}
      />
      <EditorBottomToolbar
        open={open}
        body={values.threads[index].body}
        inputProps={{ ...getInputProps() }}
        onSubmit={onSubmit}
        onCancel={onCacel}
        type={"Note"}
      />
      <AttachmentsBox
        progress={progress}
        uploading={isUploading}
        attachments={attachments}
        showVersionsDropdown={true}
        version={values.threads[index].version}
        versions={versions}
        error={error}
        onChangeVersion={(e) =>
          setFieldValue(`threads.${index}.version`, e.target.value)
        }
        onRemove={onRemoveAttachment}
      />
    </CreateNoteContainer>
  );
}

export default CreateNote;
