import { useEffect, useState, useRef } from "react";
import "./PinOut.css";
import { PinTooltip } from "@atd/components";

function PinOut({
  mainIndex,
  firstLetter,
  message,
  onClicked,
  isClick,
  totalMessage,
}) {
  const [firstLetters, setFirstLetters] = useState(null);

  const [showTooltip, setShowTooltip] = useState(false);
  const tooltipRef = useRef(null);

  useEffect(() => {
    if (firstLetter) {
      setFirstLetters(firstLetter);
    }
  }, [firstLetter]);
  const onMouseEnter = () => {
    if (!isClick) setShowTooltip(true);
  };

  const onMouseLeave = (event) => {
    if (
      !tooltipRef.current ||
      (event.relatedTarget && !tooltipRef.current.contains(event.relatedTarget))
    ) {
      setShowTooltip(false);
    }
  };

  return (
    <>
      <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        {!showTooltip && (
          <div
            className={`
            ${isClick ? "zoom-in" : "zoom-out"}
        ${
          firstLetters?.length > 0
            ? "comment_cluster_elements--avatarPinContainer---daUJ"
            : ""
        }
      `}
          >
            <figma-avatar
              clamp="2"
              className="comment_cluster_elements--avatarsRoot--lXrbc"
              style={{
                display: "flex",
              }}
            >
              {firstLetters?.length > 0 &&
                firstLetters.map((ele, index) => (
                  <div
                    className="comment_cluster_elements--avatarsContainer--tcQC0"
                    style={{
                      marginLeft: index >= 1 ? "-8px" : "",
                      zIndex: index === 0 ? "9999" : "",
                    }}
                  >
                    <div
                      className="comment_cluster_elements--avatarsPrimaryContainer--v1PL-"
                      style={{
                        display: "flex",
                      }}
                    >
                      <figma-avatar
                        initial="B"
                        id="1269236027411200408"
                        className="comment_cluster_elements--avatarContainer--MIWC0"
                      >
                        <span
                          className="comment_cluster_elements--fallbackAvatar--Wzl2w comment_cluster_element_updatable--avatarImage--DbdLm"
                          style={{
                            display: "flex",
                            backgroundColor: "rgb(151, 71, 255)",
                            color: "#fff",
                          }}
                        >
                          {ele.firstLetter}
                        </span>
                      </figma-avatar>
                    </div>
                    <div></div>
                  </div>
                ))}
            </figma-avatar>
          </div>
        )}
        {showTooltip && (
          <div ref={tooltipRef}>
            <PinTooltip
              firstLetters={firstLetters}
              message={message}
              totalMessage={totalMessage}
              onClicked={onClicked}
              mainIndex={mainIndex}
              setShowTooltip={setShowTooltip}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default PinOut;
