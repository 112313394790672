import { axios, getCurrentTimeZone } from "@atd/utils";
import { toast } from "react-toastify";

class RequestIfNoReply {
  async getAllRequestsIfNoReply(payload, cancelToken) {
    try {
      delete payload?.activeTab;

      const { data } = await axios.get("/ifnoreply_admin", {
        params: { ...payload, time_zone: getCurrentTimeZone() },
        cancelToken,
      });

      if (data.status === "error") {
        throw data;
      }
      return data;
    } catch (err) {
      if (err.code !== "ERR_CANCELED") {
        toast.error(err.message);
      }
      throw err;
    }
  }
  async IfNoReplyUpDateStatus(payload) {
    try {
      const { data } = await axios.put(
        `/ifnoreply_admin?time_zone=${getCurrentTimeZone()}`,
        payload,
      );
      if (data.status === "error") {
        throw data;
      }
      if (data.status === "success") {
        toast.success("Update Successfully");
      }
      return data;
    } catch (err) {
      if (err.code !== "ERR_CANCELED") {
        toast.error(err.message);
      }
      throw err;
    }
  }
  async getRequestsCount(params) {
    delete params?.activeTab;
    const { data } = await axios.get("/requests_count", {
      params,
    });
    return data;
  }
}
export default new RequestIfNoReply();
