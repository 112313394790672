import "./ThumbnailContainer.css";
import { Spinner, Tooltip } from "@atd/components";

function ThumbnailContainer({ url, fileName, loading, error, file }) {
  const openFile = async () => {
    if ("caches" in window) {
      const cacheNames = await caches.keys();
      cacheNames.forEach((cacheName) => {
        caches.delete(cacheName);
      });
    }
    if (file.openFile) {
      window.location.href = `/requests/annotation?request_id=${file.requestId}&reply_id=${file.replyId}&attachment_id=${file.attachmentId}&annotation_id=${file.annotation_id}&page_Id=0`;
    } else {
      window.open(file.file);
    }
  };
  return (
    <>
      {file?.type !== "pdf" && (
        <div className="mk-card project-card project-card-ready">
          <div className="mk-card__top">
            <span className="link">
              {loading && error === false && (
                <div className="thumbnail-container thumbnail-loaded">
                  <div className="thumbnail-border"></div>
                  <div className="thumbnail image-thumbnail image-loaded">
                    <img
                      className="image-loader-img thumbnail image-thumbnail-img"
                      src={url}
                      alt={fileName}
                      style={{ visibility: "visible" }}
                    />
                  </div>
                </div>
              )}
              {loading === false && error === false && (
                <>
                  {" "}
                  <div style={{ marginTop: "40%" }}>
                    <Spinner size="middle" />
                  </div>
                </>
              )}
              {file?.openFile && (
                <div className="hover-overlay" onClick={openFile}>
                  <span className="overlay-cta open-cta">
                    <svg
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon"
                      width="16"
                      height="16"
                    >
                      <g clipPath="url(#open_svg__a)">
                        <path
                          d="M1 11.77v2.153A1.077 1.077 0 0 0 2.077 15h11.846A1.077 1.077 0 0 0 15 13.923V2.077A1.077 1.077 0 0 0 13.923 1H2.077A1.077 1.077 0 0 0 1 2.077V4.23m.404 3.737h9.423m0 0-3.769 3.77m3.77-3.77-3.77-3.77"
                          stroke="#000"
                          strokeWidth="1.2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="open_svg__a">
                          <path fill="#fff" d="M0 0h16v16H0z"></path>
                        </clipPath>
                      </defs>
                    </svg>
                    Open
                  </span>
                </div>
              )}
              {!file?.openFile && (
                <div className="hover-overlay" onClick={openFile}>
                  <span className="overlay-cta open-cta">
                    <svg
                      data-style="fill"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon"
                      width="16"
                      height="16"
                    >
                      <g clip-path="url(#download_svg__a)">
                        <path
                          d="M1.6 12a.6.6 0 1 0-1.2 0h1.2Zm14 0a.6.6 0 1 0-1.2 0h1.2Zm-7-10.5a.6.6 0 0 0-1.2 0h1.2ZM7.576 12.424a.6.6 0 0 0 .848 0l3.819-3.818a.6.6 0 1 0-.849-.849L8 11.152 4.606 7.757a.6.6 0 1 0-.849.849l3.819 3.818ZM.4 12v2h1.2v-2H.4ZM2 15.6h12v-1.2H2v1.2ZM15.6 14v-2h-1.2v2h1.2ZM14 15.6a1.6 1.6 0 0 0 1.6-1.6h-1.2a.4.4 0 0 1-.4.4v1.2ZM.4 14A1.6 1.6 0 0 0 2 15.6v-1.2a.4.4 0 0 1-.4-.4H.4Zm7-12.5V12h1.2V1.5H7.4Z"
                          fill="#000"
                        ></path>
                      </g>
                      <defs>
                        <clipPath id="download_svg__a">
                          <path fill="#fff" d="M0 0h16v16H0z"></path>
                        </clipPath>
                      </defs>
                    </svg>
                    Download
                  </span>
                </div>
              )}
            </span>
          </div>

          <div className="mk-card__bottom">
            <Tooltip content={file?.full_name} placement="bottom">
              <div className="project-name-and-activity">
                <div className="entity-name project-name">
                  <div className="mk-inline-edit" tabIndex="0">
                    <div className="mk-inline-edit__display">
                      <span className="value">{file?.full_name}</span>
                    </div>
                  </div>
                </div>
              </div>
            </Tooltip>
            {file?.openFile && (
              <div className="mk-card__icons">
                <div className="mk-icon-with-text">
                  <svg
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon mk-icon-with-text__icon project-card__icon"
                    width="16"
                    height="16"
                  >
                    <path
                      d="m1.5 8 3.648 4.104a.5.5 0 0 0 .727.021L14.5 3.5"
                      stroke="#000"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                    />
                  </svg>
                  <span className="mk-icon-with-text__text project-card__icon">
                    {file?.resolved_count ? file?.resolved_count : 0}
                  </span>
                </div>
                <div className="mk-icon-with-text">
                  <svg
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon mk-icon-with-text__icon project-card__icon"
                    width="16"
                    height="16"
                  >
                    <path
                      d="M2 12.2h3.159a.1.1 0 0 1 .07.03l2.7 2.7a.1.1 0 0 0 .142 0l2.7-2.7a.1.1 0 0 1 .07-.03H14a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v9.2a1 1 0 0 0 1 1Z"
                      stroke="#000"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                    />
                  </svg>
                  <span className="mk-icon-with-text__text project-card__icon">
                    {file?.comment_count ? file?.comment_count : 0}
                  </span>
                </div>
                <div className="mk-icon-with-text">
                  <svg
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon mk-icon-with-text__icon project-card__icon"
                    width="16"
                    height="16"
                  >
                    <path
                      d="M9.579 1v4.363a.5.5 0 0 0 .506.5l3.865-.048M9.58 1l4.371 4.815M9.58 1H3a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V5.87l-.05-.055M4 12l1.923-3.846a.1.1 0 0 1 .173-.01l2.49 3.735a.1.1 0 0 0 .165.003l1.501-2.101a.1.1 0 0 1 .163 0L12 12"
                      stroke="#000"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                    />
                  </svg>
                  <span className="mk-icon-with-text__text project-card__icon">
                    1
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {file?.type === "pdf" && (
        <>
          <div className="mk-card project-card project-card-ready">
            {file.activityType === "delivery" && file.notification !== 0 && (
              <span className="thread-label" title={file.notification}>
                {file.notification}
              </span>
            )}
            <div className="mk-card__top">
              <span className="link">
                <div className="thumbnail-container thumbnail-loaded">
                  <div className="thumbnail-border-pdf"></div>
                  <div className="thumbnail-pdf  image-loaded">
                    <span className="pdfinner FileCard__Icon">
                      <svg
                        id="pdf_svg__Layer_1"
                        xmlns="http://www.w3.org/2000/svg"
                        x="0"
                        y="0"
                        viewBox="0 0 240 234"
                        xmlSpace="preserve"
                      >
                        <g id="pdf_svg__Zutq0U_00000119816876212938959800000001261090816457292438_">
                          <path
                            d="M199.2 233.1H44.3c-2.3-1.1-5-1.1-7.2-2.6-6.2-4.2-9.2-9.8-8.6-17.3V20.7C27.7 9.1 38.4 1.3 48.2 1.4c38.3.4 76.5.2 114.8.1 4.7 0 8.5 1.4 11.8 4.7 12.5 12.4 24.9 24.9 37.3 37.3 3.8 3.8 4.4 8.6 4.4 13.5.1 51.5.1 103 0 154.6 0 4-.1 8.2-2.3 11.9-3.5 5.8-8.5 8.8-15 9.6z"
                            style={{ fill: "rgb(254, 33, 22)" }}
                          ></path>
                          <path
                            d="M28 20.1c.2-.1.4-.1.7-.1 0 1.7.1 3.3.1 5v185.4c0 1.4-.1 2.8-.1 4.2-.3.3-.5.3-.6-.1C28 149.7 28 84.9 28 20.1z"
                            style={{ fill: "rgb(254, 151, 147)" }}
                          ></path>
                          <path
                            class="pdf_svg__st8"
                            d="M28 214.5c.2.2.4.2.6.1 1.2 7.8 5.5 13.1 12.5 16.6 1.1.5 2.6.4 3.1 1.8-4.8 0-9.5-.1-14.3.1-1.7.1-2-.3-2-2 .2-5.5.1-11 .1-16.6z"
                          ></path>
                          <path
                            d="M35.7 117.5V25.3c0-10.4 5.7-16.1 16.3-16.1 35.7 0 71.4 0 107.1-.1 4.3 0 7.6 1.2 10.6 4.3C181 25 192.5 36.4 204 47.8c3.3 3.2 4.6 6.8 4.6 11.3-.1 50 0 100-.1 150 0 11-5.6 16.5-16.7 16.5H52.3c-11.3 0-16.7-5.5-16.7-16.8.1-30.3.1-60.8.1-91.3z"
                            style={{ fill: "rgb(254, 254, 254)" }}
                          ></path>
                          <path
                            d="M126.2 61.5c-.2 7.4-1.9 13.7-3.8 20-.6 2-.4 3.6.7 5.4 3.2 4.8 6.5 9.5 10.5 13.8 1.7 1.8 3.2 2.5 5.7 2 6.5-1.1 13-1.5 19.5-.2 1 .2 2 .5 3 .8 4.7 1.7 6.7 4.1 5.9 7.7-1 4.3-4.5 4.5-7.9 4.5-8.3.1-15.6-3-21.8-8.3-1.8-1.5-3.4-1.9-5.6-1.4-6.4 1.4-12.7 3-19 4.9-2.4.7-3.9 1.9-5.2 4.1-3.5 6.3-7.6 12.2-12.9 17.2-2.4 2.2-5 4.1-8.2 4.9-3.4.8-6.5.2-8.6-2.8-2.2-3.2-.6-6 1.6-8.3 5.2-5.6 11.9-8.8 18.8-11.7 2.9-1.2 4.8-3 6.2-5.8 4.2-8.5 7.6-17.4 10.9-26.3.6-1.8.4-3.1-.3-4.7-2.7-6.5-4.4-13.2-3.8-20.3.4-4.9 2.3-7.3 5.6-7.6 3.8-.4 6.6 1.9 8 6.4.6 2.1.6 4.3.7 5.7z"
                            style={{ fill: " rgb(254, 31, 20)" }}
                          ></path>
                          <path
                            className="pdf_svg__st8"
                            d="M121 86.8c3.7 6.1 7.6 11.4 12.6 16.6-7.4 1.8-14.4 3.2-22 5.9 3.7-7.6 6.4-14.7 9.4-22.5z"
                          ></path>
                          <path
                            className="pdf_svg__st11"
                            d="M137.6 104.7c7.1-1 13-1.8 19-.7 2.9.5 5.1 1.9 5 5.3-.1 2.7-2.8 4.6-6.3 4.2-6.5-.8-11.9-4.2-17.7-8.8zM100.3 115.9c-2.9 5.5-6.2 10.4-10.6 14.6-1.2 1.1-2.5 2.2-3.9 3.1-1.9 1.2-3.9 1.6-5.5-.3-1.5-1.8-.8-3.6.5-5.3.7-.9 1.5-1.8 2.3-2.5 5-4.4 10.9-7.4 17.2-9.6z"
                          ></path>
                          <path
                            d="M117.7 76.9c-3.1-7.4-5-14-3.9-21.2.4-2.3 1.3-4.8 4.3-4.5 2.7.3 3.3 2.6 3.4 4.9.3 6.9-1.7 13.4-3.8 20.8z"
                            style={{ fill: "rgb(254, 251, 251)" }}
                          ></path>
                        </g>
                        <path
                          className="pdf_svg__st13"
                          d="M93 173.6v13.9h-4.4v-30.9h7.2c2.1 0 3.7.1 4.7.4 1 .2 1.9.7 2.6 1.2.9.7 1.6 1.7 2.2 2.9.5 1.2.8 2.6.8 4 0 1.5-.3 2.8-.8 4.1-.5 1.2-1.2 2.2-2.2 2.9-.7.6-1.6 1-2.6 1.2-1 .2-2.6.4-4.7.4H93zm.3-4.1h1.2c2.6 0 4.4-.3 5.3-.9.9-.6 1.3-1.7 1.3-3.2 0-1.6-.5-2.8-1.4-3.5-.9-.7-2.7-1-5.2-1h-1.2v8.6zM110.2 187.5v-30.9h6.4c4.2 0 7.1.2 8.8.7 1.7.4 3.2 1.2 4.4 2.2 1.7 1.4 3 3.1 3.8 5.3.9 2.1 1.3 4.6 1.3 7.3s-.4 5.2-1.3 7.3c-.9 2.1-2.1 3.9-3.8 5.3-1.3 1-2.7 1.8-4.3 2.2-1.6.4-4.2.7-7.6.7h-7.7zm4.8-4.5h4c2.2 0 3.8-.2 4.9-.5 1.1-.3 2-.8 2.9-1.6 1.1-1 1.9-2.2 2.5-3.7.6-1.5.8-3.2.8-5.2s-.3-3.7-.8-5.2c-.6-1.5-1.4-2.7-2.5-3.7-.8-.7-1.8-1.3-3-1.6-1.1-.3-2.9-.5-5.4-.5H115v22zM140.4 187.5v-30.9h16.3v4.3h-11.5v7.7h11.5v4.4h-11.5v14.5h-4.8z"
                        ></path>
                      </svg>
                    </span>
                  </div>
                </div>

                {file?.openFile && (
                  <div className="hover-overlay" onClick={openFile}>
                    <span className="overlay-cta open-cta">
                      <svg
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon"
                        width="16"
                        height="16"
                      >
                        <g clipPath="url(#open_svg__a)">
                          <path
                            d="M1 11.77v2.153A1.077 1.077 0 0 0 2.077 15h11.846A1.077 1.077 0 0 0 15 13.923V2.077A1.077 1.077 0 0 0 13.923 1H2.077A1.077 1.077 0 0 0 1 2.077V4.23m.404 3.737h9.423m0 0-3.769 3.77m3.77-3.77-3.77-3.77"
                            stroke="#000"
                            strokeWidth="1.2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="open_svg__a">
                            <path fill="#fff" d="M0 0h16v16H0z"></path>
                          </clipPath>
                        </defs>
                      </svg>
                      Open
                    </span>
                  </div>
                )}
                {!file?.openFile && (
                  <div className="hover-overlay" onClick={openFile}>
                    <span className="overlay-cta open-cta">
                      <svg
                        data-style="fill"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon"
                        width="16"
                        height="16"
                      >
                        <g clip-path="url(#download_svg__a)">
                          <path
                            d="M1.6 12a.6.6 0 1 0-1.2 0h1.2Zm14 0a.6.6 0 1 0-1.2 0h1.2Zm-7-10.5a.6.6 0 0 0-1.2 0h1.2ZM7.576 12.424a.6.6 0 0 0 .848 0l3.819-3.818a.6.6 0 1 0-.849-.849L8 11.152 4.606 7.757a.6.6 0 1 0-.849.849l3.819 3.818ZM.4 12v2h1.2v-2H.4ZM2 15.6h12v-1.2H2v1.2ZM15.6 14v-2h-1.2v2h1.2ZM14 15.6a1.6 1.6 0 0 0 1.6-1.6h-1.2a.4.4 0 0 1-.4.4v1.2ZM.4 14A1.6 1.6 0 0 0 2 15.6v-1.2a.4.4 0 0 1-.4-.4H.4Zm7-12.5V12h1.2V1.5H7.4Z"
                            fill="#000"
                          ></path>
                        </g>
                        <defs>
                          <clipPath id="download_svg__a">
                            <path fill="#fff" d="M0 0h16v16H0z"></path>
                          </clipPath>
                        </defs>
                      </svg>
                      Download
                    </span>
                  </div>
                )}
              </span>
            </div>

            <div className="mk-card__bottom">
              <Tooltip content={file?.full_name} placement="bottom">
                <div className="project-name-and-activity">
                  <div className="entity-name project-name">
                    <div className="mk-inline-edit" tabIndex="0">
                      <div className="mk-inline-edit__display">
                        <span className="value">{file?.full_name}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </Tooltip>
              {file?.openFile && (
                <div className="mk-card__icons">
                  <div className="mk-icon-with-text">
                    <svg
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon mk-icon-with-text__icon project-card__icon"
                      width="16"
                      height="16"
                    >
                      <path
                        d="m1.5 8 3.648 4.104a.5.5 0 0 0 .727.021L14.5 3.5"
                        stroke="#000"
                        stroke-width="1.2"
                        stroke-linecap="round"
                      ></path>
                    </svg>
                    <span class="mk-icon-with-text__text project-card__icon">
                      {file?.resolved_count ? file?.resolved_count : 0}
                    </span>
                  </div>
                  <div className="mk-icon-with-text">
                    <svg
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon mk-icon-with-text__icon project-card__icon"
                      width="16"
                      height="16"
                    >
                      <path
                        d="M2 12.2h3.159a.1.1 0 0 1 .07.03l2.7 2.7a.1.1 0 0 0 .142 0l2.7-2.7a.1.1 0 0 1 .07-.03H14a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v9.2a1 1 0 0 0 1 1Z"
                        stroke="#000"
                        stroke-width="1.2"
                        stroke-linecap="round"
                      ></path>
                    </svg>
                    <span class="mk-icon-with-text__text project-card__icon">
                      {file?.comment_count ? file?.comment_count : 0}
                    </span>
                  </div>
                  <div className="mk-icon-with-text">
                    <svg
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon mk-icon-with-text__icon project-card__icon"
                      width="16"
                      height="16"
                    >
                      <path
                        d="M9.579 1v4.363a.5.5 0 0 0 .506.5l3.865-.048M9.58 1l4.371 4.815M9.58 1H3a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V5.87l-.05-.055M4 12l1.923-3.846a.1.1 0 0 1 .173-.01l2.49 3.735a.1.1 0 0 0 .165.003l1.501-2.101a.1.1 0 0 1 .163 0L12 12"
                        stroke="#000"
                        stroke-width="1.2"
                        stroke-linecap="round"
                      ></path>
                    </svg>
                    <span className="mk-icon-with-text__text project-card__icon">
                      {!file?.pdfPageLoading && <Spinner size="small" />}
                      {file?.pdfPageLoading && (
                        <span>{file?.pdfPageCount}</span>
                      )}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default ThumbnailContainer;
