const dayString = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const date = new Date();
const nextOtherDay = date.getDay() + 2;

export const quickPickDates = [
  {
    offset: 0,
    name: "Today",
  },
  {
    offset: 1,
    name: "Tomorrow",
  },
  {
    offset: 2,
    name: dayString[nextOtherDay === dayString.length ? 0 : nextOtherDay],
  },
];
export const timeOptionsDurValue = [
  { value: 0, label: "12:00am" },
  { value: 1800, label: "12:30am" },
  { value: 3600, label: "01:00am" },
  { value: 5400, label: "01:30am" },
  { value: 7200, label: "02:00am" },
  { value: 9000, label: "02:30am" },
  { value: 10800, label: "03:00am" },
  { value: 12600, label: "03:30am" },
  { value: 14400, label: "04:00am" },
  { value: 16200, label: "04:30am" },
  { value: 18000, label: "05:00am" },
  { value: 19800, label: "05:30am" },
  { value: 21600, label: "06:00am" },
  { value: 23400, label: "06:30am" },
  { value: 25200, label: "07:00am" },
  { value: 27000, label: "07:30am" },
  { value: 28800, label: "08:00am" },
  { value: 30600, label: "08:30am" },
  { value: 32400, label: "09:00am" },
  { value: 34200, label: "09:30am" },
  { value: 36000, label: "10:00am" },
  { value: 37800, label: "10:30am" },
  { value: 39600, label: "11:00am" },
  { value: 41400, label: "11:30am" },
  { value: 43200, label: "12:00pm" },
  { value: 45000, label: "12:30pm" },
  { value: 46800, label: "01:00pm" },
  { value: 48600, label: "01:30pm" },
  { value: 50400, label: "02:00pm" },
  { value: 52200, label: "02:30pm" },
  { value: 54000, label: "03:00pm" },
  { value: 55800, label: "03:30pm" },
  { value: 57600, label: "04:00pm" },
  { value: 59400, label: "04:30pm" },
  { value: 61200, label: "05:00pm" },
  { value: 63000, label: "05:30pm" },
  { value: 64800, label: "06:00pm" },
  { value: 66600, label: "06:30pm" },
  { value: 68400, label: "07:00pm" },
  { value: 70200, label: "07:30pm" },
  { value: 72000, label: "08:00pm" },
  { value: 73800, label: "08:30pm" },
  { value: 75600, label: "09:00pm" },
  { value: 77400, label: "09:30pm" },
  { value: 79200, label: "10:00pm" },
  { value: 81000, label: "10:30pm" },
  { value: 82800, label: "11:00pm" },
  { value: 84600, label: "11:30pm" },
];

export const getTimeOptions = (isDurationValue) => {
  const timeOptionsDefValue = [
    { value: "00:00", label: "12:00am" },
    { value: "00:30", label: "12:30am" },
    { value: "01:00", label: "01:00am" },
    { value: "01:30", label: "01:30am" },
    { value: "02:00", label: "02:00am" },
    { value: "02:30", label: "02:30am" },
    { value: "03:00", label: "03:00am" },
    { value: "03:30", label: "03:30am" },
    { value: "04:00", label: "04:00am" },
    { value: "04:30", label: "04:30am" },
    { value: "05:00", label: "05:00am" },
    { value: "05:30", label: "05:30am" },
    { value: "06:00", label: "06:00am" },
    { value: "06:30", label: "06:30am" },
    { value: "07:00", label: "07:00am" },
    { value: "07:30", label: "07:30am" },
    { value: "08:00", label: "08:00am" },
    { value: "08:30", label: "08:30am" },
    { value: "09:00", label: "09:00am" },
    { value: "09:30", label: "09:30am" },
    { value: "10:00", label: "10:00am" },
    { value: "10:30", label: "10:30am" },
    { value: "11:00", label: "11:00am" },
    { value: "11:30", label: "11:30am" },
    { value: "12:00", label: "12:00pm" },
    { value: "12:30", label: "12:30pm" },
    { value: "13:00", label: "01:00pm" },
    { value: "13:30", label: "01:30pm" },
    { value: "14:00", label: "02:00pm" },
    { value: "14:30", label: "02:30pm" },
    { value: "15:00", label: "03:00pm" },
    { value: "15:30", label: "03:30pm" },
    { value: "16:00", label: "04:00pm" },
    { value: "16:30", label: "04:30pm" },
    { value: "17:00", label: "05:00pm" },
    { value: "17:30", label: "05:30pm" },
    { value: "18:00", label: "06:00pm" },
    { value: "18:30", label: "06:30pm" },
    { value: "19:00", label: "07:00pm" },
    { value: "19:30", label: "07:30pm" },
    { value: "20:00", label: "08:00pm" },
    { value: "20:30", label: "08:30pm" },
    { value: "21:00", label: "09:00pm" },
    { value: "21:30", label: "09:30pm" },
    { value: "22:00", label: "10:00pm" },
    { value: "22:30", label: "10:30pm" },
    { value: "23:00", label: "11:00pm" },
    { value: "23:30", label: "11:30pm" },
  ];

  if (isDurationValue) {
    return timeOptionsDurValue;
  }

  return timeOptionsDefValue;
};
