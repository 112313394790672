/* eslint-disable no-nested-ternary */
import { useState, useEffect } from "react";
import moment from "moment";
import styled from "styled-components";
import uuid from "react-uuid";
import { useDispatch, useSelector } from "react-redux";
import { flatMap, map } from "lodash";
import { calculateExpireDate } from "@atd/utils";
import {
  AssignUser,
  Box,
  Text,
  Badge,
  Tooltip,
  ContentState,
} from "@atd/components";
import { defaultAvatarUrl, isAdmin } from "@atd/utils";
import {
  getRequest,
  updateCurrentRequest,
  getRequestThreads,
} from "@atd/features";
import RequestService from "@atd/features/request/requestApi";

const Title = styled(Text)`
  line-height: 20px;
  font-weight: 500;
  font-size: 14px;
  color: #3c4257;
  width: 100px;
`;

function RequestDetailItem({ label, children }) {
  return (
    <Box flex={{ alignItems: "center" }} margin={{ top: 12 }}>
      <Title as={Box}>{label}:</Title> <span className="cont2">{children}</span>
    </Box>
  );
}

function RequestDetail({
  id,
  s_no,
  users,
  status,
  priority,
  modified,
  assigned_to,
  submited_on,
  in_progress,
  status_name,
  trial_start,
  trial_end,
  trial_status,
}) {
  console.log("trial_start", trial_start, trial_end);
  const [projectInfo, setProjectInfo] = useState({
    status: "",
    assigned_to: "",
    status_name: "",
  });

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { isRequestLoading } = useSelector((state) => state.requests);
  useEffect(() => {
    setProjectInfo({
      status,
      assigned_to: assigned_to ?? null,
      status_name,
    });
  }, [status, status_name, assigned_to]);

  const { role } = user || {};
  const isStatusNew = !in_progress && status === 1;

  const onUpdateInfo = async (value, type) => {
    if (type === "assign") {
      setProjectInfo((prevProjectInfo) => ({
        ...prevProjectInfo,
        assigned_to: value,
        status_name,
      }));
      await RequestService.assignUser(id, {
        assigned_to: value,
        assigned_to_string: value.toString(),
      });
      await dispatch(getRequest(id));
      await dispatch(
        getRequestThreads({
          id,
          params: { tab: "details" },
          isUpdate: true,
        }),
      );
    } else if (type === "status") {
      setProjectInfo((prevProjectInfo) => ({
        ...prevProjectInfo,
        status: value,
        status_name: "In Progress",
      }));
      await RequestService.linkRequest({
        id,
        params: {
          update_type: "in_progress",
        },
      });
      await dispatch(
        updateCurrentRequest({
          status: 1,
          in_progress: 1,
          status_name: "In Progress",
        }),
      );
      await dispatch(getRequest(id));
      await dispatch(
        getRequestThreads({
          id,
          params: { tab: "details" },
          isUpdate: true,
        }),
      );
    }
  };

  const usersList = flatMap(users, ({ users: userOptions }) =>
    map(userOptions, (item) => ({
      label: item.name,
      value: item.id,
      icon: item.user_icon,
    })),
  );

  const assignedUsers =
    projectInfo &&
    usersList.filter((option) =>
      projectInfo.assigned_to?.includes(option.value),
    );

  if (isRequestLoading) {
    return <ContentState />;
  }

  return (
    <>
      {isAdmin() && (
        <>
          <RequestDetailItem label="Assigned to">
            <Box style={{ flex: 1 }}>
              {status === 7 || (in_progress && status !== 6) ? (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <>
                  {assignedUsers.length > 0
                    ? assignedUsers.map((user) => (
                        <Tooltip content={user?.label} key={uuid()}>
                          <img
                            src={user?.icon || defaultAvatarUrl}
                            alt={user?.value}
                            className="client-avt"
                          />
                        </Tooltip>
                      ))
                    : "N/A"}
                </>
              ) : null}
              {(status !== 7 && !in_progress) || status === 6 ? (
                <AssignUser
                  multiple
                  users={users}
                  value={projectInfo.assigned_to || []}
                  isDisabled={in_progress || [6, 7].includes(role)}
                  onChange={(value) => {
                    onUpdateInfo(value, "assign");
                  }}
                />
              ) : null}
            </Box>
          </RequestDetailItem>
          <RequestDetailItem label="Status">
            <Box style={{ flex: 1 }}>
              <Badge type={isStatusNew ? "success" : "default"}>
                {status_name}
              </Badge>
            </Box>
          </RequestDetailItem>
          <RequestDetailItem label="Priority">
            <Box>
              <Badge
                type={
                  priority === 1
                    ? "error-inverse"
                    : [2, 3].includes(priority)
                    ? "warning"
                    : "success"
                }
              >
                {priority === 1
                  ? "High"
                  : [2, 3].includes(priority)
                  ? "Medium"
                  : "Low"}
              </Badge>
            </Box>
          </RequestDetailItem>
        </>
      )}
      <RequestDetailItem label="Request #">{s_no}</RequestDetailItem>
      <RequestDetailItem label="Submitted">
        {moment(submited_on).format("MMM DD, YYYY hh:mm a")}
      </RequestDetailItem>
      <RequestDetailItem label="Last Updated">
        {moment(modified).format("MMM DD, YYYY hh:mm a")}
      </RequestDetailItem>
      {trial_status === 1 && (
        <div className="trial-status">
          Trial period expires on {calculateExpireDate(trial_end)}
        </div>
      )}
    </>
  );
}

export default RequestDetail;
