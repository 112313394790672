import React, { useCallback, useRef, forwardRef } from "react";
import Tippy from "@tippyjs/react/headless";

import sharedSmartDelay from "../../utils/sharedDelay";

import "./Tooltips.css";

const Tooltips = forwardRef(
  (
    {
      style,
      content,
      children,
      hasHeader,
      maxWidth,
      appendTo,
      allowHTML = false,
      placement,
      ...rest
    },
    ref,
  ) => {
    // Show with a smart delay, hide immediately
    const delayArrayRef = useRef([sharedSmartDelay.getCurrentDelay(), 0]);

    // Update Tippy's delay when we try to interact with it.
    const handleOnTrigger = useCallback(() => {
      delayArrayRef.current[0] = sharedSmartDelay.getCurrentDelay();
    }, []);

    // Tell the smart delay that the tooltip has been shown.
    const handleOnShow = useCallback(() => {
      sharedSmartDelay.show();
    }, []);

    // Tell the smart delay that the tooltip has been hidden.
    const handleOnHide = useCallback(() => {
      sharedSmartDelay.hide();
    }, []);

    return (
      <Tippy
        ref={ref}
        delay={delayArrayRef.current}
        onTrigger={handleOnTrigger}
        onShow={handleOnShow}
        onHide={handleOnHide}
        appendTo={appendTo}
        disabled={!content}
        placement={placement}
        render={(attrs) => {
          return (
            <div
              className="Tooltip_tooltip"
              style={{ maxWidth: maxWidth || "200px", ...style }}
              {...attrs}
            >
              {allowHTML ? (
                <div
                  className="Tooltip_content"
                  dangerouslySetInnerHTML={{ __html: content }}
                />
              ) : (
                <div className="Tooltip_content">{content}</div>
              )}

              <div className="Tooltip_arrow" data-popper-arrow="true"></div>
            </div>
          );
        }}
        {...rest}
      >
        {children}
      </Tippy>
    );
  },
);

export default Tooltips;
