import React from "react";

import { FilePreview } from "@atd/components";

import styled from "styled-components";

import { useDrag } from "react-dnd";

export const Attachments = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  align-content: center;

  & div {
    margin-right: 0;
    margin-bottom: 0;
  }
`;

function Attachment({
  file,
  user_name,
  role,
  type,
  replyId,
  requestId,
  activity_type,
  isDraggable = true,
  ...rest
}) {
  console.log("file345", file);
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "file",
    item: { file },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (
    <div
      ref={isDraggable ? drag : null}
      style={{
        marginTop: 10,
        opacity: isDragging ? "0.7" : 1,
      }}
    >
      <FilePreview
        type={file.format}
        file={file.link}
        fullName={file.full_name}
        version={file.version}
        name={file?.name}
        activityType={type}
        replyId={replyId}
        requestId={requestId}
        attachmentId={file.attachment_id}
        annotation_id={file.annotation_id}
        notification={file.notification}
        activity_type={activity_type}
        {...rest}
      />
    </div>
  );
}

export default Attachment;
