import React, { forwardRef } from "react";
import styled from "styled-components";

import { fileName } from "@atd/utils";

import FileCardIcon from "./FileCardIcon";

const FixeBox = styled.div`
  font-size: 14px;
  border: 1px solid #e0e1e4;
  border-radius: 6px;
  padding: 10px 10px;
  width: max-content;
  cursor: pointer;
  margin-right: 15px;
  margin-bottom: 10px;
  background: white;

  display: flex;
  align-items: center;
  /* margin-bottom: 0; */

  & > img {
    margin-right: 8px;
    max-width: 20px;
  }
`;

const FileCard = forwardRef(
  (
    {
      list,
      file,
      name,
      fullName,
      role,
      type,
      onRemove,
      isDeletable,
      notification,
      ...rest
    },
    ref,
  ) => (
    <>
      <FixeBox
        ref={ref}
        {...rest}
        title={fullName || name}
        style={{ position: "relative" }}
      >
        {type === "pdf" && notification > 0 && (
          <span class="thread-label" title={fullName || name}>
            {notification}
          </span>
        )}
        <FileCardIcon icon={type} /> {fileName(name, type)}
        {isDeletable && (
          <>
            <span
              aria-hidden="true"
              className="SVGInline SVGInline--cleaned SVG Icon Icon--cancel Icon-color Icon-color--gray600 va2"
              style={{ transform: "translateY(1.1px)", marginLeft: 15 }}
              onClick={onRemove}
            >
              <svg
                aria-hidden="true"
                className="SVGInline-svg SVGInline--cleaned-svg SVG-svg Icon-svg Icon--cancel-svg Icon-color-svg Icon-color--gray600-svg"
                height={12}
                width={12}
                viewBox="0 0 16 16"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 6.585l4.593-4.592a1 1 0 0 1 1.415 1.416L9.417 8l4.591 4.591a1 1 0 0 1-1.415 1.416L8 9.415l-4.592 4.592a1 1 0 0 1-1.416-1.416L6.584 8l-4.59-4.591a1 1 0 1 1 1.415-1.416z"
                  fillRule="evenodd"
                />
              </svg>
            </span>
          </>
        )}
      </FixeBox>
    </>
  ),
);

FileCard.defaultProps = {
  isDeletable: true,
};

export default FileCard;
