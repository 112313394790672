/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import rating, { ratingIcon } from "./rating";
import "./FeedbackRating.css";
function FeedbackRating({ ratings, setRating }) {
  console.log("ratings34567890", ratings);
  const CurrentRatingIcon = ratingIcon[ratings]?.icon;
  const CurrentRatingColor = ratingIcon[ratings]?.activeColor;

  return (
    <div className="rating-control" animate-in-index={6}>
      {rating.map(({ id, label }, index) => {
        const RatingIcon = ratingIcon[id].icon;

        if (!RatingIcon) {
          return null;
        }

        return (
          <div
            key={`${label}-${id}`}
            className="rating-option"
            onClick={() => setRating(index + 1)}
          >
            <div
              className="icon"
              style={{
                transform: `scale(${ratings === index + 1 ? 0 : 1})`,
                fill: "#C6CCD0",
              }}
            >
              <RatingIcon />
            </div>
            <div
              className="label"
              style={{
                transform: `translateY(${ratings === index + 1 ? 9 : 0}px)`,
                color: "rgb(171, 178, 182)",
              }}
            >
              {label}
            </div>
          </div>
        );
      })}
      <div
        className="current-rating"
        style={{ transform: `translateX(${(ratings - 1) * 100}%)` }}
      >
        <div className="svg-wrapper" style={{ fill: CurrentRatingColor }}>
          <CurrentRatingIcon />
        </div>
      </div>
    </div>
  );
}

export default FeedbackRating;
